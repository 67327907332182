import imgSanitize from "@axvdex/utils/imgSanitize";
import { ReactComponent as IcnFarm } from "../../assets/icons/icn-grow-heart.svg";

export const FarmRepresentation = ({
  address,
  inputAsset,
  outputAssets,
  sizeScale = 1,
}: {
  address: string;
  inputAsset: { label: string; symbol: string };
  outputAssets: { label: string; symbol: string }[];
  sizeScale?: number;
}) => {
  const assetSize = 3 * sizeScale;
  const farmImageSize = 1.25 * sizeScale;

  return (
    <>
      <li key={"farm_" + address}>
        <span title={inputAsset.label} aria-label={inputAsset.label}>
          <img src={imgSanitize(inputAsset.symbol)} alt={inputAsset.label} style={{ width: assetSize + "em" }} />
        </span>
      </li>
      <IcnFarm style={{ width: farmImageSize + "em", marginLeft: ".25em", marginRight: ".25em" }} />
      {outputAssets.map((outputAsset, idx) => {
        return (
          <li key={idx}>
            <span title={outputAsset.label} aria-label={outputAsset.label}>
              <img src={imgSanitize(outputAsset.symbol)} alt={outputAsset.label} style={{ width: assetSize + "em" }} />
            </span>
          </li>
        );
      })}
    </>
  );
};
