import { ReactNode } from "react";
import clsx from "clsx";
import Button from "./Button";

interface ButtonMenuProps {
  extraClassName: string;
  title: string;
  onClick: () => void;
  children?: ReactNode;
}

function ButtonMenu({ extraClassName, title, onClick, children }: ButtonMenuProps) {
  return children ? (
    <Button title={title} extraClassName={clsx("btnMenu", "btnMenuWithText", extraClassName)} onClick={onClick}>
      {children && children}
      <span className="btnMenuIcon" />
    </Button>
  ) : (
    <Button title={title} btnVariant="icon" extraClassName={clsx("btnMenu", extraClassName)} onClick={onClick}>
      <span className="btnMenuIcon" />
    </Button>
  );
}

export default ButtonMenu;
