import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { ChevronLeft, ChevronRight } from "react-feather";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  ColumnDef,
} from "@tanstack/react-table";
import useLanguage from "@axvdex/hooks/useLanguage";
import Button from "./common/Button";

interface TableDnDProps<Data> {
  tableId: string;
  customData: Data[];
  customColumns: ColumnDef<Data>[];
  pagination: { pageIndex: number; pageSize: number };
  setPagination: React.Dispatch<
    React.SetStateAction<{
      pageIndex: number;
      pageSize: number;
    }>
  >;
  extraClassName?: string;
  mobileResponsiveStyling?: "isResponsive" | "isResponsiveNoThVisible"; // table display block | table display block w/ th not visible
}

function DashboardPaginationTable<Data>({
  tableId,
  customData,
  customColumns,
  extraClassName,
  mobileResponsiveStyling,
  pagination,
  setPagination,
}: TableDnDProps<Data>) {
  const { i18 } = useLanguage();

  const [data, setData] = useState([]);
  const [columns] = useState(() => [...customColumns]);

  useEffect(() => {
    setData([...customData]);
  }, [customData]);

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id, //good to have guaranteed unique row ids/keys for rendering
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    autoResetPageIndex: false,
    onPaginationChange: setPagination,
    state: { pagination },
  });

  const paginationBulletsButtons = Array.from({ length: table.getPageCount() }, (_, i) => (
    <li key={i}>
      <button
        className={clsx("btnTablePaginationBullet", pagination.pageIndex === i && "isCurrent")}
        onClick={() => table.setPageIndex(i)}
        title={`${i18("Go to page", "global.pagination.goToPage")} ${i + 1}`}
      />
    </li>
  ));

  return (
    <>
      <section className="dashboardGridItemSectionTable">
        <table
          className={clsx(
            "table",
            extraClassName && extraClassName,
            mobileResponsiveStyling && mobileResponsiveStyling
          )}
        >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
                {
                  // <th className="tableCta">
                  //   <Button
                  //     btnVariant="icon"
                  //     btnColor="purple"
                  //     icon={<RotateCcw />}
                  //     title={i18("Reset table", "dashboard.dndtable.resetTable")}
                  //     onClick={rerender}
                  //   />
                  // </th>
                }
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={`${tableId}-row-${row.id}`}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {table.getPageCount() > 1 && (
        <section className="dashboardGridItemsSectionTablePagination">
          <Button
            extraClassName="btnTablePagination previous"
            title={i18("Previous page", "global.pagination.previousPage")}
            btnVariant="icon"
            btnColor="dark-medium"
            icon={<ChevronLeft />}
            onClick={table.previousPage}
            disabled={!table.getCanPreviousPage()}
          />

          <ul className="tablePaginationBulletBtnList">{paginationBulletsButtons.map(bullet => bullet)}</ul>

          <Button
            extraClassName="btnTablePagination next"
            title={i18("Next page", "global.pagination.nextPage")}
            btnVariant="icon"
            btnColor="dark-medium"
            icon={<ChevronRight />}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          />
        </section>
      )}
    </>
  );
}

export default DashboardPaginationTable;
