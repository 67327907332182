import { addListener, createListenerMiddleware, TypedAddListener, TypedStartListening } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "state";

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export type AppAddListener = TypedAddListener<RootState, AppDispatch>;

const listenerMiddleware = createListenerMiddleware();

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as AppAddListener;

export default listenerMiddleware;
