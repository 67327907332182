import { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { ColumnDef } from "@tanstack/react-table";
import { Star, Circle, Lock, Search, ArrowDown, DollarSign } from "react-feather";
import { includes, lowerCase } from "lodash";

import { useNavigate } from "react-router-dom";
import { loadState, persistState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";
import { updateUserFavouriteAssets } from "@axvdex/api/user";
import { IAsset } from "@axvdex/utils/interfaces";
import {
  myAssetsUpdateAction,
  updateAssetsUnmint,
  updateNativeBalance,
  updateTokenBalance,
  updateUserGlobalSettingsFields,
} from "@axvdex/state/wallet/walletThunks";
import { updateFavouriteAssets } from "@axvdex/state/wallet/walletSlice";
import { formatBalance } from "@axvdex/utils/formatNumber";
import DashboardWelcomeDialog from "@axvdex/components/DashboardWelcomeDialog";
import { hasPermitStored } from "@axvdex/utils/localStorage";
import { dashboardMyAssetsList } from "@axvdex/mocks/DummyData";
import imgSanitize from "@axvdex/utils/imgSanitize";

import useLanguage from "@axvdex/hooks/useLanguage";
import styles from "../styles/DashboardMyAssets.module.scss";
import { ReactComponent as IcnWallet } from "../assets/icons/icn-wallet.svg";
import ButtonFavourite from "./common/ButtonFavourite";
import DragHandle from "./common/DragHandle";
import CustomDropdown from "./common/CustomDropdown";
import CustomInputButton from "./form-element/CustomInputButton";
import CustomSelect from "./form-element/CustomSelect";
import DashboardDndPaginationTable from "./DashboardDndPaginationTable";
import MyAssetsManageTokensModal from "./modals/MyAssetsManageTokensModal";
import { getTokenList } from "./tokenListForSelect";
import CustomLoader from "./common/CustomLoader";
import DashboardMultiDndPaginationTable from "./DashboardMultiDndPaginationTable";
import Button from "./common/Button";
import {
  selectAssetBalances,
  selectAssets,
  selectAssetUnmints,
  selectContracts,
  selectFavouriteAssets,
  selectGlobalSettings,
  selectIsWalletLoading,
  selectUserAssets,
  selectUserLoading,
  selectVestingLockedAmount,
  selectWalletInfo,
} from "state/wallet/walletSelectors";
import { useAppDispatch, useAppSelector } from "state";
import BigNumber from "bignumber.js";

export interface TableAssetsProps extends IAsset {
  usdConversion: number;
  tokenAmount: number;
  lockedAmount: string | null;
  isFavourite: boolean;
  row: number;
  unDraggable: boolean;
  unmints?: { unmints: string[]; readyUnmints: string[] };
}

function DashboardMyAssets() {
  const { i18 } = useLanguage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoadingWallet = useAppSelector(selectIsWalletLoading);
  const walletInfo = useAppSelector(selectWalletInfo);
  const assetsStored = useAppSelector(selectAssets);
  const userAssets = useAppSelector(selectUserAssets);
  const assetBalances = useAppSelector(selectAssetBalances);
  const vestingLockedAmount = useAppSelector(selectVestingLockedAmount);
  const userAssetsUnmints = useAppSelector(selectAssetUnmints);
  const contracts = useAppSelector(selectContracts);
  const userLoading = useAppSelector(selectUserLoading);
  const globalSettings = useAppSelector(selectGlobalSettings);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [showNotConnectedData, setShowNotConnectedData] = useState(false);
  const [allAssets, setAllAssets] = useState<TableAssetsProps[]>([]);
  const [assetsToRender, setAssetsToRender] = useState<TableAssetsProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState(null);
  const [sorting, setSorting] = useState(null);
  const [tokenList, setTokenList] = useState([]);
  const favouriteAssets = useAppSelector(selectFavouriteAssets);

  const [myAssetsSearchMenuIsOpen, setMyAssetsSearchMenuIsOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [fuzzySearchString, setFuzzySearchString] = useState("");
  const [assetSearchOption, setAssetSearchOption] = useState("");

  useEffect(() => {
    if (!isLoadingWallet && !walletInfo.isConnected) {
      setAllAssets([]);
      setShowNotConnectedData(true);
    } else {
      setShowNotConnectedData(false);
      setIsLoading(true);
    }
  }, [walletInfo.isConnected, isLoadingWallet]);

  useEffect(() => {
    if (userLoading || !globalSettings) setIsLoading(true);
    else {
      setFilter(globalSettings?.dashboardMyAssetsActiveFilters?.display ?? "all");
      setSorting(globalSettings?.dashboardMyAssetsActiveFilters?.sorting ?? "amount");
    }
  }, [userLoading, globalSettings]);

  useEffect(() => {
    if (isLoading && globalSettings && sorting && filter && assetsToRender.length) setIsLoading(false);
  }, [sorting, filter, isLoading, assetsToRender, globalSettings]);

  useEffect(() => {
    const tokenList = getTokenList(assetsStored);
    setTokenList(tokenList);

    let preparedAssets = [];
    // only show assets when user request is complete

    if (userAssets.length || Object.keys(assetsStored).length) {
      if ("manual" === sorting) {
        const userAssetDetails = userAssets.map(assetID => {
          return {
            ...assetsStored[assetID],
            key: assetID,
            isFavourite: favouriteAssets.includes(assetID),
          };
        });

        preparedAssets = userAssetDetails.concat(
          Object.entries(assetsStored)
            // hide assets that have 0 balance and are hidden or are not in the user assets
            .filter(([assetID]: [string, IAsset]) => {
              if (!userAssetDetails.find(asset => asset.id === assetID)) {
                return !(assetsStored[assetID].isHidden && "0" === assetBalances[assetID]);
              }
              return false;
            })
            // sort it to have the native ones first (if its isSourceDenom go to the top)
            .sort(function (a, b) {
              if (a[1].isNative === b[1].isNative) {
                return a[1].isSourceDenom ? -1 : 1;
              }
              return a[1].isNative ? -1 : 1;
            })
            .map(([assetID]: [string, IAsset]) => {
              return {
                ...assetsStored[assetID],
                key: assetID,
                isFavourite: favouriteAssets.includes(assetID),
              };
            })
        );
      } else {
        preparedAssets = Object.entries(assetsStored)
          // hide assets that have 0 balance and are hidden
          .filter(([assetID]: [string, IAsset]) => {
            return !(assetsStored[assetID].isHidden && "0" === assetBalances[assetID]);
          })
          .map(([assetID]: [string, IAsset]) => {
            return {
              ...assetsStored[assetID],
              key: assetID,
              isFavourite: favouriteAssets.includes(assetID),
            };
          })
          .sort(function (a, b) {
            const aValue = BigNumber(assetBalances[a.key]).div(Math.pow(10, a.decimals)).times(a.price);
            const bValue = BigNumber(assetBalances[b.key]).div(Math.pow(10, b.decimals)).times(b.price);

            // sort by amounts first
            if (aValue.gt(bValue)) return -1;
            if (aValue.lt(bValue)) return 1;

            // sort by alpha second
            return a.symbol.localeCompare(b.symbol);
          });
      }

      // filter out assets that dont have an id (that are not present on the assets stored / token list)
      preparedAssets = preparedAssets.filter(asset => asset.id);
    }

    // unDraggable field was added to each row so the button is hidden when set to true
    // this is a hacky solution, it needs to be refactored with a better solution (problem: parent height is smaller than the chield when it has search open, so it blurs out)
    const fillEmptySpace = false;
    let parsedAssets = [];

    if (fillEmptySpace) {
      [...preparedAssets, ...new Array(Math.ceil(preparedAssets.length / 5)).fill({ id: "dummyRow" })].forEach(
        function (value, i) {
          if ("dummyRow" !== value.id) {
            parsedAssets.push(value);
          } else {
            parsedAssets.push({ id: "dummyRow-" + i, unDraggable: true });
          }
        }
      );
    } else {
      parsedAssets = [...preparedAssets];
    }

    setAllAssets(parsedAssets);
    // eslint-disable-next-line
  }, [assetsStored, userAssets, favouriteAssets, walletInfo.isConnected, assetBalances, sorting]);

  //useEffect to update myAssets
  const updateMyAssetsState = async allAssets => {
    const preparedAssets = [...allAssets];
    // OLD: request balances to preparedAssets that are visible on the current pagination
    //
    //const requests = [];
    // for (const [index, preparedAsset] of preparedAssets.entries()) {
    //   const paginationBottomLimit = pagination.pageIndex * pagination.pageSize;
    //   const paginationTopLimit = pagination.pageIndex * pagination.pageSize + pagination.pageSize;
    //   if (!assetBalances[preparedAsset.id] && paginationBottomLimit <= index && index < paginationTopLimit) {
    //     // ONLY GET BALANCE OF THE ASSETS THAT ARE BEING SHOWN AND DONT HAVE A BALANCE YET
    //     if (!preparedAsset.isNative) {
    //       requests.push(
    //         dispatch(updateTokenBalance({ tokenAddress: preparedAsset.address, userAddress: walletInfo.walletAddress }))
    //       );
    //     } else {
    //       requests.push(
    //         dispatch(updateNativeBalance({ denom: preparedAsset.denom, userAddress: walletInfo.walletAddress }))
    //       );
    //     }
    //   } else {
    //     requests.push(Promise.resolve());
    //   }
    // }
    // const newBalances = await Promise.all(requests);

    // OLD: request balances to preparedAssets that are visible on the current pagination
    //
    // const requestsUnmints = [];
    // for (const [index, preparedAsset] of preparedAssets.entries()) {
    //   const paginationBottomLimit = pagination.pageIndex * pagination.pageSize;
    //   const paginationTopLimit = pagination.pageIndex * pagination.pageSize + pagination.pageSize;
    //   if (!unmints[preparedAsset.id] && paginationBottomLimit <= index && index < paginationTopLimit) {
    //     // ONLY GET BALANCE OF THE ASSETS THAT ARE BEING SHOWN AND DONT HAVE A BALANCE YET
    //     if (preparedAsset.isDerivative && preparedAsset.derivativeContract) {
    //       requestsUnmints.push(
    //         (async () => {
    //           const unmintObj = await getUnmints(preparedAsset.derivativeContract);
    //           unmints[preparedAsset.id] = unmintObj;
    //         })()
    //       );
    //     } else {
    //       unmints[preparedAsset.id] = { unmints: 0, readyUnmints: 0 };
    //     }
    //   }
    // }
    // await Promise.all(requestsUnmints);

    // request all balances using batch query
    const requestsBalances = [];
    const requestsUnmints = [];
    const requestsUnmintsAssetIDs = [];
    for (const [index, preparedAsset] of preparedAssets.entries()) {
      if (!assetBalances[preparedAsset.id]) {
        // ONLY GET BALANCE OF THE ASSETS THAT ARE BEING SHOWN AND DONT HAVE A BALANCE YET
        if (!preparedAsset.isNative) {
          requestsBalances.push(
            dispatch(updateTokenBalance({ tokenAddress: preparedAsset.address, userAddress: walletInfo.walletAddress }))
          );
        } else {
          requestsBalances.push(
            dispatch(updateNativeBalance({ denom: preparedAsset.denom, userAddress: walletInfo.walletAddress }))
          );
        }
      } else {
        requestsBalances.push(Promise.resolve());
      }
      if (!userAssetsUnmints[preparedAsset.id]) {
        if (preparedAsset.isDerivative && preparedAsset.derivativeContract) {
          requestsUnmintsAssetIDs.push(preparedAsset.id);
          requestsUnmints.push(
            dispatch(
              updateAssetsUnmint({
                derivativeAddress: preparedAsset.derivativeContract,
                userAddress: walletInfo.walletAddress,
                assetID: preparedAsset.id,
                contracts,
              })
            )
          );
        }
      }
    }

    const allRequests = await Promise.all([...requestsBalances, ...requestsUnmints]);
    const newBalances = allRequests.slice(0, preparedAssets.length);
    const newUnmints = allRequests.slice(preparedAssets.length, preparedAssets.length + requestsUnmintsAssetIDs.length);

    const assetsRows = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.assetsRows);
    for (const [index, asset] of preparedAssets.entries()) {
      let balance = assetBalances[asset.id];
      if (!balance && newBalances[index]) {
        balance = newBalances[index].payload;
      }
      if (!balance) continue;

      const unmintRequestIndex = requestsUnmintsAssetIDs.findIndex((assetID: string) => assetID === asset.id);
      const unmints =
        unmintRequestIndex !== -1
          ? newUnmints[unmintRequestIndex].payload || { unmints: [], readyUnmints: [] }
          : userAssetsUnmints[asset.id] || { unmints: [], readyUnmints: [] };

      const balanceFormated = formatBalance(balance, assetsStored[asset.id].price, assetsStored[asset.id].decimals);
      preparedAssets[index] = {
        ...asset,
        tokenAmount: balanceFormated.amount,
        lockedAmount:
          asset.id === process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM && vestingLockedAmount && "0" !== vestingLockedAmount
            ? formatBalance(
                vestingLockedAmount,
                assetsStored[process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM].price,
                assetsStored[process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM].decimals
              ).amount
            : null,
        usdConversion: balanceFormated.usdConversion,
        row: assetsRows[asset.id] || -1,
        unmints,
      };
    }
    setAssetsToRender(preparedAssets);

    setSearchString("");
  };

  useEffect(() => {
    walletInfo.isConnected &&
      allAssets.length > 0 &&
      updateMyAssetsState(getAssetsToRender(allAssets, filter, fuzzySearchString, assetSearchOption));
  }, [allAssets, walletInfo.isConnected, filter, fuzzySearchString, assetSearchOption]);

  const getAssetsToRender = (allAssets, filter, searchString, searchOption) => {
    const filterAssets = allAssets => {
      if ("favourites" === filter) {
        const swapOrder = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.assetsRows);
        return allAssets
          .filter(asset_ => asset_.isFavourite)
          .map(asset_ => ({ ...asset_, orderIndex: swapOrder[asset_.id] }));
      }
      return allAssets;
    };
    const searchAssets = allAssets => {
      if (searchString) {
        return allAssets.filter(asset => includes(lowerCase(asset.symbol), lowerCase(searchString)));
      } else if (searchOption) {
        return allAssets.filter(asset => asset.symbol === searchOption);
      }

      return allAssets;
    };
    const assetsToRender = searchAssets(filterAssets(allAssets));
    return assetsToRender;
  };

  const myAssetsUpdate = async (updatedData: string[]) => {
    await dispatch(myAssetsUpdateAction({ updatedData }));
  };

  const FavouriteBtn = ({ id }: { id: string }) => {
    const walletInfo = useAppSelector(selectWalletInfo);
    const favouriteAssets = useAppSelector(selectFavouriteAssets);
    const dispatch = useAppDispatch();
    const toggleFavourite = async () => {
      const newFavs = [...favouriteAssets];
      const favIndex = newFavs.findIndex(fav => fav === id);
      if (favIndex === -1) {
        newFavs.push(id);
      } else {
        newFavs.splice(favIndex, 1);
      }
      await updateUserFavouriteAssets(
        { favoriteAssets: newFavs },
        {
          address: walletInfo.walletAddress,
          signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[
            process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress
          ],
        }
      );
      dispatch(updateFavouriteAssets(newFavs));
    };

    return (
      <ButtonFavourite
        btnColor="purple"
        onToggleFavourite={toggleFavourite}
        isFavourite={favouriteAssets.includes(id)}
      />
    );
  };

  const assetsColumns = useMemo<ColumnDef<TableAssetsProps>[]>(() => {
    return [
      {
        id: "symbol",
        accessorKey: "symbol",
        header: () => <span>{i18("Token", "dashboard.assets.table.symbol")}</span>,
        cell: ({ row }) => {
          return !row.id.includes("dummyRow") ? (
            <div className="flexbox">
              <span className="tokenIcon" title={row.original?.symbol} aria-label={row.original?.symbol}>
                <img
                  src={imgSanitize(row.original.symbol)}
                  alt={`${row.original.tokenAmount} ${row.original?.symbol}`}
                />
              </span>
              <span className="tokenName">{row.original.symbol}</span>
            </div>
          ) : null;
        },
      },
      {
        id: "tokenAmount",
        accessorKey: "tokenAmount",
        header: () => <span>{i18("Amount", "dashboard.assets.table.amount")}</span>,
        cell: ({ row }) => {
          return row.id.includes("dummyRow") ? null : !row.original.lockedAmount ? (
            row.original.tokenAmount
          ) : (
            <div className="amount">
              <span>{row.original.tokenAmount}</span>
              <span className="locked flexbox">
                <small>{row.original.lockedAmount}</small>
                <Lock />
              </span>
            </div>
          );
        },
      },
      {
        id: "usdConversion",
        accessorKey: "usdConversion",
        header: () => <span>{i18("USD", "dashboard.assets.table.usdConversion")}</span>,
        cell: info => info.getValue(),
      },
      {
        id: "actions",
        header: () => <span>{i18("Actions", "dashboard.assets.table.actions")}</span>,
        cell: ({ row }) =>
          !row.id.includes("dummyRow") ? (
            <>
              <div className="buttonContainer">
                {(row.original.isDerivative || row.original.xDerivativeTarget || row.original.isNative) && (
                  <MyAssetsManageTokensModal asset={row.original} isOnlyIcon={true} />
                )}
                <FavouriteBtn id={row.id} />
              </div>
            </>
          ) : null,
      },
    ];
  }, [i18]);

  const updateFilter = (type_: string) => {
    setPagination({ pageSize: 5, pageIndex: 0 });
    dispatch(
      updateUserGlobalSettingsFields({
        dashboardMyAssetsActiveFilters: { display: type_, sorting: sorting },
      })
    );
    setFilter(type_);
  };

  const updateSorting = (type_: string) => {
    setPagination({ pageSize: 5, pageIndex: 0 });
    dispatch(
      updateUserGlobalSettingsFields({
        dashboardMyAssetsActiveFilters: { display: filter, sorting: type_ },
      })
    );
    setSorting(type_);
  };

  const onInputChange = (value: string) => {
    setSearchString(value);
    setMyAssetsSearchMenuIsOpen(!!value);
  };

  const handleSearchKeyDown = event => {
    if ("Enter" === event.key) {
      setFuzzySearchString(event.target.value);
      setMyAssetsSearchMenuIsOpen(false);
      // updateMyAssetsState(getAssetsToRender(allAssets, filter, event.target.value, ""), pagination);
      event.preventDefault();
    }
  };

  const handleSearchOnChange = (option, { action }) => {
    if ("select-option" === action) {
      setAssetSearchOption(option.value);
      setFuzzySearchString("");
    }
    if ("clear" === action) {
      setAssetSearchOption("");
    }
  };

  return (
    <section
      className={clsx(styles.dashboardGridItemMyAssets, "dashboardGridItemMyAssets", "withGradientBorderBottom")}
    >
      <div className="dashboardGridItemTitle">
        <h2>{i18("My Assets", "dashboard.assets.title")}</h2>
        <div
          className="buttonContainer"
          style={
            showNotConnectedData ||
            (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet))
              ? { pointerEvents: "none", filter: "blur(0.3rem)" }
              : {}
          }
        >
          <CustomDropdown
            extraClassName="dashboardMenuDropdown assets"
            customDropdownToggle={{
              btnVariant: "icon",
              btnColor: "dark-medium",
              btnTitle: i18("Menu", "dashboard.assets.menu.title"),
            }}
            content={
              <>
                <fieldset>
                  <legend className="label">{i18("Display", "dashboard.assets.filter.header")}</legend>
                  <div className="btnGroup vertical noBorder">
                    <CustomInputButton
                      type="radio"
                      id="all"
                      name="assets_sort"
                      labelText={i18("All", "dashboard.assets.filter.all")}
                      labelIcon={<Circle />}
                      checked={"all" === filter}
                      onChange={() => {}}
                      onClick={() => {
                        setFuzzySearchString("");
                        updateFilter("all");
                      }}
                    />
                    <CustomInputButton
                      type="radio"
                      id="favourites"
                      name="assets_sort"
                      labelText={i18("Favourites", "dashboard.assets.filter.favourites")}
                      labelIcon={<Star />}
                      checked={"favourites" === filter}
                      onChange={() => {}}
                      onClick={() => {
                        setFuzzySearchString("");
                        updateFilter("favourites");
                      }}
                    />
                  </div>
                </fieldset>
                <div className="subGroup">
                  <fieldset>
                    <legend className="label">{i18("Sorting", "dashboard.assets.sorting.header")}</legend>
                    <div className="btnGroup vertical noBorder">
                      <CustomInputButton
                        type="radio"
                        id="amount"
                        name="assets_sorting"
                        labelText={i18("Amount", "dashboard.assets.sorting.amount")}
                        labelIcon={<DollarSign />}
                        checked={"amount" === sorting}
                        onChange={() => {}}
                        onClick={() => {
                          updateSorting("amount");
                        }}
                      />
                      <CustomInputButton
                        type="radio"
                        id="manual"
                        name="assets_sorting"
                        labelText={i18("Manual", "dashboard.assets.sorting.manual")}
                        labelIcon={<Circle />}
                        checked={"manual" === sorting}
                        onChange={() => {}}
                        onClick={() => {
                          updateSorting("manual");
                        }}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="subGroup">
                  <span className="label">{i18("Tokens", "dashboard.assets.addTokensToWallet")}</span>

                  <div className="btnGroup vertical noBorder">
                    <Button
                      id="addtokenstoWallet"
                      name="addtokenstoWallet"
                      extraClassName="btnAddTokensToWallet"
                      text={i18("Add to wallet", "dashboard.assets.addTokensToWallet")}
                      title={i18("Add to wallet", "dashboard.assets.addTokensToWallet")}
                      icon={<IcnWallet />}
                      iconPlacement="left"
                      onClick={() => {
                        navigate("/help/tokens", { state: { from: "Assets" } });
                      }}
                    />
                  </div>
                </div>

                <CustomSelect
                  name="assets_search"
                  labelText={i18("Search", "dashboard.assets.assetsSearch")}
                  hiddenLabel={true}
                  placeholder={i18("Search", "dashboard.assets.assetsSearch")}
                  items={tokenList}
                  customSelectIcon={<Search />}
                  inputValue={searchString}
                  hideChevronIcon={true}
                  onInputChange={onInputChange}
                  onChange={handleSearchOnChange}
                  onKeyDown={handleSearchKeyDown}
                  menuIsOpen={myAssetsSearchMenuIsOpen}
                  isClearable={true}
                />
              </>
            }
          />

          <DragHandle extraClassName="ghostDragHandle" />
        </div>
      </div>

      {/* -- Welcome Dialog --*/}
      {showNotConnectedData ||
      (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) ? (
        <DashboardWelcomeDialog />
      ) : (
        <></>
      )}
      {/* -- End Welcome Dialog --*/}

      {showNotConnectedData ||
      (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) ||
      !hasPermitStored(walletInfo) ? (
        <div style={{ pointerEvents: "none", filter: "blur(0.3rem)" }}>
          <DashboardDndPaginationTable
            tableId="myAssetsTable"
            extraClassName="dashboardAssetsTable"
            customData={dashboardMyAssetsList}
            customColumns={assetsColumns}
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      ) : isLoading ? (
        <CustomLoader size="xs" />
      ) : (
        <DashboardMultiDndPaginationTable
          tableId="myAssetsTable"
          extraClassName="dashboardAssetsTable"
          customData={assetsToRender}
          customColumns={assetsColumns}
          onOrderUpdate={updatedData => myAssetsUpdate(updatedData)}
          pagination={pagination}
          setPagination={setPagination}
          hasManageAssetsButton={true}
          hasDraggableButton={"manual" === sorting}
        />
      )}
    </section>
  );
}

export default DashboardMyAssets;
