import { Helmet } from "react-helmet-async";

/*
 A number of random filenames
*/
const images = ["social_001.png"];

interface SEOProps {
  title: string;
  description: string;
  type?: string;
  imageUrl?: string;
}

function SEO({ title, description, type = "website", imageUrl = "" }: SEOProps) {
  const company = "Astrovault",
    url = window.location.href.replace(/\/+$/, "");
  title = `${title} :: ${company}`;
  if (!imageUrl) {
    imageUrl = `${url}${require(`../assets/images/${images[Math.floor(Math.random() * images.length)]}`)}`;
  }

  return (
    <Helmet prioritizeSeoTags>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      imageUrl && (<meta property="og:image" content={imageUrl} />)
      <meta property="og:type" content={type} />
      <meta property="og:url" content={`${url}/`} />
      <meta property="og:site_name" content={company} />
      {/* End Facebook tags */}
      {/* X/Twitter tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      imageUrl && (<meta name="twitter:image" content={imageUrl} />)
      <meta name="twitter:site" content="@axvdex" />
      <meta name="twitter:creator" content={company} />
      <meta name="twitter:card" content={type} />
      {/* End Twitter tags */}
    </Helmet>
  );
}

export default SEO;
