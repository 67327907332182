import { ReactNode } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import clsx from "clsx";
import useLanguage from "@axvdex/hooks/useLanguage";
import styles from "../styles/ErrorPageLayout.module.scss";

interface ErrorPageProps {
  extraClassName?: string;
  errorNumber: string;
  children: ReactNode;
}

function ErrorPageLayout({ extraClassName, errorNumber, children }: ErrorPageProps) {
  const { i18 } = useLanguage();

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Astrovault | {i18("Error", "errorPageLayout.title")} {errorNumber}
        </title>
      </Helmet>

      <section className={clsx(styles.errorPageLayout, "errorPageLayout", extraClassName && extraClassName)}>
        <div className="errorPageWrapper">
          <h1 className="errorPageTitle textGradient">{errorNumber}</h1>

          {children}
        </div>
      </section>
    </HelmetProvider>
  );
}

export default ErrorPageLayout;
