import clsx from "clsx";
import WalletConnectListItems from "@axvdex/components/WalletConnectListItems";
import useLanguage from "@axvdex/hooks/useLanguage";
import { useGlobalModalContext } from "../../providers/GlobalModalProvider";
import Button from "../common/Button";
import CustomModal from "../common/CustomModal";
import styles from "../../styles/WalletModal.module.scss";
import { useState } from "react";
import CustomLoader from "../common/CustomLoader";

function WalletSelectionModal() {
  const { i18 } = useLanguage();
  const { modal, hideModal } = useGlobalModalContext();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <CustomModal
      isOpen={modal.modalType === "walletSelectionModal" && modal.isOpen}
      onClose={hideModal}
      extraClassName={clsx(styles.walletModal, "walletModal")}
    >
      <section className="sectionModalHeader">
        <h1 className="h2">{i18("Connect wallet", "walletModal.header")}</h1>
      </section>

      <section className="sectionWallet">
        <div className="walletConnectListWrapper withGradientBorderBottom">
          <WalletConnectListItems isLoading={isLoading} setIsLoading={setIsLoading} />
        </div>

        <div className="buttonContainer modalButtonContainer">
          <Button
            btnColor="gradientText"
            text={i18("Cancel", "walletModal.btnCancel.text")}
            title={i18("Cancel and close", "walletModal.btnCancel.title")}
            onClick={hideModal}
          />
          {isLoading && (
            <span style={{ marginLeft: "auto" }}>
              <CustomLoader size="xs" />
            </span>
          )}
        </div>
      </section>
    </CustomModal>
  );
}

export default WalletSelectionModal;
