import clsx from "clsx";
import { useNavigate } from "react-router";
import BigNumber from "bignumber.js";
import React from "react";
import useLanguage from "@axvdex/hooks/useLanguage";
import CustomInputButton from "@axvdex/components/form-element/CustomInputButton";
import { useAppSelector } from "@axvdex/state";
import { selectOutbid, selectSalesBids, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import imgSanitize from "@axvdex/utils/imgSanitize";
import styles from "@axvdex/styles/Outbid.module.scss";
import { IOutbidSale } from "@axvdex/utils/interfaces";
import Button from "../common/Button";
import { ReactComponent as IcnTicket } from "../../assets/icons/icn-outbid-ticket.svg";
import SaleOperatorControls from "./SaleOperatorControls";

interface SalesSelectGridProps {
  topFilterOptions: { label: string; id: string }[];
  topFilter: { label: string; id: string };
  setTopFilter: React.Dispatch<React.SetStateAction<{ label: string; id: string }>>;
  setOutbidSaleSelected: React.Dispatch<React.SetStateAction<string>>;
}

const SalesSelectGrid = ({
  topFilterOptions,
  topFilter,
  setTopFilter,
  setOutbidSaleSelected,
}: SalesSelectGridProps) => {
  const { i18 } = useLanguage();
  const outbid = useAppSelector(selectOutbid);
  const salesBids = useAppSelector(selectSalesBids);
  const walletInfo = useAppSelector(selectWalletInfo);
  const navigate = useNavigate();

  const getOutbidGlobalStatus = (outbid: IOutbidSale) => {
    if (!outbid.salesState || Object.keys(outbid.salesState).length === 0)
      return {
        text: "Upcoming",
        id: "upcoming",
      };
    if (outbid.saleVestings && outbid.saleVestings.length > 0) {
      let vestingConcluded = true;
      for (const saleVesting of outbid.saleVestings) {
        if (new Date().getTime() / 1000 < saleVesting.end_vesting_timestamp) {
          vestingConcluded = false;
          break;
        }
      }
      if (vestingConcluded) {
        return {
          text: "Completed",
          id: "completed",
        };
      }
      return {
        text: "Vesting",
        id: "vesting",
      };
    }

    return {
      text: "Live",
      id: "live",
    };
  };

  const userHasBid = (outbidSale: IOutbidSale) => {
    for (const saleAddr of Object.keys(outbidSale.salesState)) {
      if (salesBids && salesBids[saleAddr] && salesBids[saleAddr].bids.length > 0) return true;
    }
    return false;
  };

  return (
    <>
      <div className="salesSectionHeader flexbox withGradientBorderBottom">
        <h1>{i18("Outbid", "sales.title")}</h1>
        <fieldset>
          <legend className="visuallyHidden">{i18("Display", "pools.topFilter.legend")}</legend>
          <div className="btnGroup">
            {topFilterOptions.map(topFilterOption => (
              <CustomInputButton
                key={"input_filter_" + topFilterOption.id}
                type="radio"
                name={topFilterOption.label}
                labelText={topFilterOption.label}
                id={topFilterOption.id}
                value={topFilterOption.id}
                checked={topFilter.id === topFilterOption.id}
                onChange={() => {}}
                onClick={() => setTopFilter(topFilterOption)}
              />
            ))}
          </div>
        </fieldset>
      </div>

      {outbid && (
        <section className="outbidSectionCardGrid gridBox">
          {Object.values(outbid)
            .filter(outbidSale =>
              "all" === topFilter.id ? true : getOutbidGlobalStatus(outbidSale).id === topFilter.id
            )
            .map(outbidSale => (
              <article
                key={outbidSale.address}
                tabIndex={0}
                className={clsx(styles.outbidCard, "outbidCard")}
                onClick={() => {
                  if (Object.keys(outbidSale.salesState)?.length > 0) {
                    setOutbidSaleSelected(outbidSale.label);
                    navigate("/outbid/" + outbidSale.label);
                  }
                }}
                style={{
                  cursor: Object.keys(outbidSale.salesState)?.length > 0 ? "pointer" : "auto",
                }}
              >
                <div className="buttonContainer">
                  {(outbidSale.config?.operator === walletInfo.walletAddress ||
                    outbidSale.config?.owner === walletInfo.walletAddress) && (
                    <SaleOperatorControls outbidSale={outbidSale} />
                  )}
                  {userHasBid(outbidSale) && (
                    <Button btnVariant="icon" style={{ margin: "1em" }}>
                      <IcnTicket style={{ fontSize: "2em" }} />
                    </Button>
                  )}
                </div>

                <div className="outbidCardHeader">
                  {outbidSale.projectSymbol && (
                    <img src={imgSanitize(outbidSale.projectSymbol)} alt={outbidSale.projectSymbol} />
                  )}
                </div>

                <div style={{ marginTop: ".5em", marginBottom: "1em" }}>
                  <h2>{outbidSale.project}</h2>
                  <small>{outbidSale.projectDescription}</small>
                </div>
                <div style={{ display: "flex", placeContent: "space-between", alignItems: "center" }}>
                  <div>
                    <span>Status: {getOutbidGlobalStatus(outbidSale).text}</span>
                    {"upcoming" !== getOutbidGlobalStatus(outbidSale).id && (
                      <>
                        <br />
                        <span>
                          {" "}
                          Raised: $
                          {Object.values(outbidSale.salesState)
                            .reduce(function (acc, obj) {
                              return acc.plus(BigNumber(obj.sale_state.state.tickets_count));
                            }, BigNumber(0))
                            .div(Math.pow(10, 6))
                            .decimalPlaces(2)
                            .toNumber()
                            .toLocaleString("en-US")}
                        </span>
                      </>
                    )}
                    {"upcoming" === getOutbidGlobalStatus(outbidSale).id && outbidSale.projectLunchDate && (
                      <span>
                        {outbidSale.projectLunchDate && (
                          <span>
                            <br />
                            <span>Launch Date: {new Date(outbidSale.projectLunchDate).toLocaleString()}</span>
                          </span>
                        )}
                      </span>
                    )}
                  </div>
                  {outbidSale.projectLinks?.map(projectLink => (
                    <button
                      key={projectLink.url}
                      className={clsx("btn")}
                      title={projectLink.label}
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        window.open(projectLink.url, "_blank");
                      }}
                    >
                      {projectLink.label}
                    </button>
                  ))}
                </div>
              </article>
            ))}
        </section>
      )}
    </>
  );
};

export default SalesSelectGrid;
