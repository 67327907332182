import clsx from "clsx";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { useMemo, useState } from "react";
import { findIndex } from "lodash";
import useLanguage from "@axvdex/hooks/useLanguage";
import MainLayout from "../layouts/MainLayout";
import HelpSubPageSection from "../components/HelpSubPageSection";
import HelpSubPageSidebar from "../components/HelpSubPageSidebar";
import LogoGravitate from "../assets/images/help/dashboard/logo-gravitate-white.svg";
import styles from "../styles/Help.module.scss";

function HelpDashboard() {
  const { i18 } = useLanguage();

  const sectionSummary = [
    {
      name: i18("My Assets", "help.sectionSummary.dashboard.myAssets.name"),
      url: "#myAssets",
      nestedSubSections: [
        {
          name: i18(
            "Asset information",
            "help.sectionSummary.dashboard.myAssets.nestedSubSections.myAssetsAssetInformation.name"
          ),
          url: "#myAssetsAssetInformation",
        },
        {
          name: i18(
            "Manage xAssets",
            "help.sectionSummary.dashboard.myAssets.nestedSubSections.myAssetsGetxAsset.name"
          ),
          url: "#myAssetsGetxAsset",
        },
        {
          name: i18(
            "Manage unminting",
            "help.sectionSummary.dashboard.myAssets.nestedSubSections.myAssetsUnminting.name"
          ),
          url: "#myAssetsUnminting",
        },
        {
          name: i18(
            "Using other networks",
            "help.sectionSummary.dashboard.myAssets.nestedSubSections.myAssetsUsingIbc.name"
          ),
          url: "#myAssetsUsingIbc",
        },
        {
          name: i18(
            "Favouriting and dragging",
            "help.sectionSummary.dashboard.myAssets.nestedSubSections.myAssetsFavouritingDragging.name"
          ),
          url: "#myAssetsFavouritingDragging",
        },
      ],
    },
    {
      name: i18("My Pools", "help.sectionSummary.dashboard.myPools.name"),
      url: "#myPools",
      nestedSubSections: [
        {
          name: i18("Messages", "help.sectionSummary.dashboard.myPools.nestedSubSections.myPoolsMessages.name"),
          url: "#myPoolsMessages",
        },
      ],
    },
    {
      name: i18("My Gravitate", "help.sectionSummary.dashboard.myGravitate.name"),
      url: "#myGravitate",
      suffix: (
        <span className="iconWrapper">
          <Trans i18nKey="help.grvt8Icon">
            {[
              "(",
              <img key="img" src={LogoGravitate} alt="GRVT8 Icon" loading="lazy" className="isIcon GRVT8" />,
              <span key="nbsp">&nbsp;</span>,
              "GRVT8",
              ")",
            ]}
          </Trans>
        </span>
      ),
    },
    {
      name: i18("My History", "help.sectionSummary.dashboard.myHistory.name"),
      url: "#myHistory",
    },
    {
      name: i18("The Market", "help.sectionSummary.dashboard.theMarket.name"),
      url: "#theMarket",
    },
    {
      name: i18("Dashboard customisation", "help.sectionSummary.dashboard.dashboardCustomisation.name"),
      url: "#dashboardCustomisation",
    },
  ];

  const [index, setIndex] = useState(-1);

  // const sep = "&#x25B6;";  // cant use html codes, grr
  const sep = "::";

  const slides = useMemo(() => {
    return [
      // add images with id passed to onImageClick function also add alt text as title
      {
        src: require("../assets/images/help/dashboard/Picture1.webp"),
        id: "dashboard/Picture1",
        title: i18(`Dashboard ${sep} My Assets`, "help.dashboard.picture1.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture2.webp"),
        id: "dashboard/Picture2",
        title: i18(`Dashboard ${sep} My Assets ${sep} Header`, "help.dashboard.picture2.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture3.webp"),
        id: "dashboard/Picture3",
        title: i18(`Dashboard ${sep} My Assets ${sep} Customise view`, "help.dashboard.picture3.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture4.webp"),
        id: "dashboard/Picture4",
        title: i18(`Dashboard ${sep} My Assets ${sep} Asset information`, "help.dashboard.picture4.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture5.webp"),
        id: "dashboard/Picture5",
        title: i18(`Dashboard ${sep} My Assets ${sep} Manage token button`, "help.dashboard.picture5.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture6.webp"),
        id: "dashboard/Picture6",
        title: i18(`Dashboard My Assets ${sep} Manage tokens view`, "help.dashboard.picture6.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture7.webp"),
        id: "dashboard/Picture7",
        title: i18(`Dashboard ${sep} My Assets ${sep} Manage ${sep} Minting view`, "help.dashboard.picture7.title", {
          sep,
        }),
      },
      {
        src: require("../assets/images/help/dashboard/missing-image-1.png"),
        id: "dashboard/missing-image-1",
        title: i18(
          `Dashboard ${sep} My Assets ${sep} Manage ${sep} Minting in use`,
          "help.dashboard.missingImage1.title",
          { sep }
        ),
      },
      {
        src: require("../assets/images/help/dashboard/missing-image-2.png"),
        id: "dashboard/missing-image-2",
        title: i18(
          `Dashboard ${sep} My Assets ${sep} Manage ${sep} Minting ${sep} Approve pop-up`,
          "help.dashboard.missingImage2.title",
          { sep }
        ),
      },
      {
        src: require("../assets/images/help/dashboard/missing-image-3.png"),
        id: "dashboard/missing-image-3",
        title: i18(
          `Dashboard ${sep} My Assets ${sep} Manage ${sep} Unminting view`,
          "help.dashboard.missingImage3.title",
          {
            sep,
          }
        ),
      },
      {
        src: require("../assets/images/help/dashboard/missing-image-4.png"),
        id: "dashboard/missing-image-4",
        title: i18(
          `Dashboard ${sep} My Assets ${sep} Manage ${sep} Unminting in use`,
          "help.dashboard.missingImage4.title",
          { sep }
        ),
      },
      {
        src: require("../assets/images/help/dashboard/missing-image-5.png"),
        id: "dashboard/missing-image-5",
        title: i18(
          `Dashboard ${sep} My Assets ${sep} Manage ${sep} Approve unminting`,
          "help.dashboard.missingImage5.title",
          { sep }
        ),
      },
      {
        src: require("../assets/images/help/dashboard/missing-image-6.png"),
        id: "dashboard/missing-image-6",
        title: i18(
          `Dashboard ${sep} My Assets ${sep} Manage ${sep} Unmints pending `,
          "help.dashboard.missingImage6.title",
          { sep }
        ),
      },
      {
        src: require("../assets/images/help/dashboard/missing-image-7.png"),
        id: "dashboard/missing-image-7",
        title: i18(
          `Dashboard ${sep} My Assets ${sep} Manage ${sep} Using other networks`,
          "help.dashboard.missingImage7.title",
          {
            sep,
          }
        ),
      },

      {
        src: require("../assets/images/help/dashboard/Picture9.webp"),
        id: "dashboard/Picture9",
        title: i18(
          `Dashboard ${sep} My Assets ${sep} Manage ${sep} Select other network`,
          "help.dashboard.picture9.title",
          { sep }
        ),
      },
      {
        src: require("../assets/images/help/dashboard/Picture10.webp"),
        id: "dashboard/Picture10",
        title: i18(`Dashboard ${sep} My Assets ${sep} Favourite button`, "help.dashboard.picture10.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture11.webp"),
        id: "dashboard/Picture11",
        title: i18(`Dashboard ${sep} My Assets ${sep} Drag this to reorder items`, "help.dashboard.picture11.title", {
          sep,
        }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture12.webp"),
        id: "dashboard/Picture12",
        title: i18(`Dashboard ${sep} My Assets ${sep} Before dragging row`, "help.dashboard.picture12.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture13.webp"),
        id: "dashboard/Picture13",
        title: i18(`Dashboard ${sep} My Assets ${sep} After reordered row`, "help.dashboard.picture13.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture14.webp"),
        id: "dashboard/Picture14",
        title: i18(`Dashboard ${sep} My Pools`, "help.dashboard.picture14.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture15.webp"),
        id: "dashboard/Picture15",
        title: i18(`Dashboard ${sep} My Pools ${sep} Actions menu`, "help.dashboard.picture15.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture16.webp"),
        id: "dashboard/Picture16",
        title: i18(`Dashboard ${sep} The Market`, "help.dashboard.picture16.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture17.webp"),
        id: "dashboard/Picture17",
        title: i18(`Dashboard ${sep} My History`, "help.dashboard.picture17.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture18.webp"),
        id: "dashboard/Picture18",
        title: i18(`Dashboard ${sep} My History ${sep} A previous trade`, "help.dashboard.picture18.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture19.webp"),
        id: "dashboard/Picture19",
        title: i18(`Dashboard ${sep} My History ${sep} Previous trade messages`, "help.dashboard.picture19.title", {
          sep,
        }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture20.webp"),
        id: "dashboard/Picture20",
        title: i18(`Footer ${sep} Menu messages`, "help.dashboard.picture20.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture21.webp"),
        id: "dashboard/Picture21",
        title: i18(`Dashboard ${sep} Customise to your taste #1`, "help.dashboard.picture21.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture22.webp"),
        id: "dashboard/Picture22",
        title: i18(`Dashboard ${sep} Customise to your taste #2`, "help.dashboard.picture22.title", { sep }),
      },
      {
        src: require("../assets/images/help/dashboard/Picture11.webp"),
        id: "dashboard/Picture23",
        title: i18(`Dashboard ${sep} My Assets ${sep} Drag this to reorder panels`, "help.dashboard.picture23.title", {
          sep,
        }),
      },
    ];
  }, []);

  const onImageClick = (imageName: string) => {
    const index_ = findIndex(slides, { id: imageName });
    setIndex(index_);
  };

  return (
    <MainLayout
      pageClass={clsx(styles.help, "help", "helpDashboard")}
      headTitle={i18("Help :: Dashboard", "help.dashboard.headTitle")}
      headDescription={i18("Help for dashboard on Astrovault", "help.dashboard.headDescription")}
    >
      <div className="helpPageWrapper">
        <div className="sectionWrapperLg">
          <section className="helpSectionHeader withGradientBorderBottom">
            <h1>{i18("Exploring Your Dashboard", "help.dashboard.section.title")}</h1>

            <p>
              <Trans i18nKey="help.dashboard.section.description.trans">
                With a wallet successfully connected, let&#8217;s take a look at what the dashboard can do for you.
              </Trans>
            </p>
          </section>

          <div className="helpSubPageGrid">
            <HelpSubPageSidebar
              summaryTitle={i18("Dashboard", "help.dashboard.sidebar.title")}
              items={sectionSummary}
            />

            <div className="helpSubPageContent">
              <HelpSubPageSection
                sectionId="myAssets"
                sectionTitle={i18("My Assets", "help.dashboard.subpage.section.myAssets.title")}
              >
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myAssets.trans.0">
                    <strong>My Assets</strong> displays the quantity of listed tokens that are currently in your
                    Astrovault connected wallet.
                  </Trans>
                </p>
                <img
                  src={require(`../assets/images/help/dashboard/Picture1.webp`)}
                  alt={i18("Dashboard My Assets", "help.dashboard.subpage.section.myAssets.picture1.alt")}
                  width={976}
                  height={857}
                  loading="lazy"
                  className="md80 hasMargin lightboxImageOpener"
                  onClick={() => onImageClick("dashboard/Picture1")}
                />
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myAssets.trans.1">
                    This list grows as Astrovault adds support for more tokens. If you have a favourite token not
                    listed, it will be soon! The arrows at the bottom of the panel will move through the list of tokens.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myAssets.trans.2">
                    <strong>My Assets</strong> can be customised using the 3 dot button at the top of the panel.
                  </Trans>
                </p>
                <img
                  src={require(`../assets/images/help/dashboard/Picture2.webp`)}
                  alt={i18(
                    "Dashboard My Assets header dropdown",
                    "help.dashboard.subpage.section.myAssets.picture2.alt"
                  )}
                  width={908}
                  height={109}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                  onClick={() => onImageClick("dashboard/Picture2")}
                />
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myAssets.trans.3">
                    You are greeted with choices to customise the view.
                  </Trans>
                </p>
                <img
                  src={require(`../assets/images/help/dashboard/Picture3.webp`)}
                  alt={i18(
                    "Dashboard My Assets header dropdown menu",
                    "help.dashboard.subpage.section.myAssets.picture3.alt"
                  )}
                  width={1001}
                  height={843}
                  loading="lazy"
                  className="md80 hasMargin lightboxImageOpener"
                  onClick={() => onImageClick("dashboard/Picture3")}
                />
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myAssets.trans.4">
                    These choices make it easy to select which tokens you prefer to see.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myAssets.trans.5">
                    Clicking favourites will only show tokens that you have favourited with the star icon, we will go
                    over this in the next section.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myAssets.trans.6">
                    The search feature displays tokens that match whatever you enter there, and clicking on a search
                    list item will focus solely on that token.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myAssets.trans.7">
                    As the list of tokens in your wallet grows, these tools will be invaluable.
                  </Trans>
                </p>
                <h3 id="myAssetsAssetInformation">
                  {i18("Asset information", "help.dashboard.subpage.section.myAssets.assetInformation.title")}
                </h3>
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myAssets.trans.8">
                    Let&#8217;s take a look at one of the tokens, in this case the Archway ARCH token, and see ours take
                    a look at one of the tokens, in this case the Archway ARCH token, and see our choices.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/dashboard/Picture4.webp`)}
                  alt={i18("Dashboard My Assets Table", "help.dashboard.subpage.section.myAssets.picture4.alt")}
                  width={1001}
                  height={310}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                  onClick={() => onImageClick("dashboard/Picture4")}
                />
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myAssets.trans.9">
                    The ARCH token name, the amount held in the wallet, and the token worth in United States Dollar, are
                    displayed. All of this is standard information, but Astrovault does far more than standard DEXs.
                  </Trans>
                </p>
                <img
                  src={require(`../assets/images/help/dashboard/Picture5.webp`)}
                  alt={i18(
                    "Dashboard My Assets Table Manage your Tokens",
                    "help.dashboard.subpage.section.myAssets.picture5.alt"
                  )}
                  width={40}
                  height={40}
                  loading="lazy"
                  className="sm lightboxImageOpener"
                  onClick={() => onImageClick("dashboard/Picture5")}
                />
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myAssets.trans.10">
                    1. This is the Manage Tokens button, and it contains powerful tools.
                  </Trans>
                </p>
                <img
                  src={require(`../assets/images/help/dashboard/Picture6.webp`)}
                  alt={i18(
                    "Dashboard My Assets Manage your Tokens Modal",
                    "help.dashboard.subpage.section.myAssets.picture6.alt"
                  )}
                  width={786}
                  height={989}
                  loading="lazy"
                  className="md80 hasMargin lightboxImageOpener"
                  onClick={() => onImageClick("dashboard/Picture6")}
                />
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myAssets.trans.11">
                    You are able to Mint or Unmint derivative assets, or Deposit and Withdraw assets across the IBC
                    network, directly from your dashboard.
                  </Trans>
                </p>
                <h3 id="myAssetsGetxAsset">
                  {i18("Manage xAssets", "help.dashboard.subpage.section.myAssets.myAssetsGetxAsset.title")}
                </h3>
                <img
                  src={require(`../assets/images/help/dashboard/Picture7.webp`)}
                  alt={i18(
                    "Dashboard My Assets Manage your Tokens Modal Actions",
                    "help.dashboard.subpage.section.myAssets.picture7.alt"
                  )}
                  width={783}
                  height={925}
                  loading="lazy"
                  className="md80 hasMargin lightboxImageOpener"
                  onClick={() => onImageClick("dashboard/Picture7")}
                />
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myAssets.trans.12">
                    This view allows transforming ARCH and other assets into staking derivative versions (or “x”
                    versions). Derivative tokens earn rewards when deposited in an Astrovault pool.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/dashboard/missing-image-1.png`)}
                  alt={i18(
                    "Dashboard My Assets Manage your Tokens Modal Other Actions",
                    "help.dashboard.subpage.section.myAssets.missingImage1.alt"
                  )}
                  width={774}
                  height={1018}
                  loading="lazy"
                  className="md80 hasMargin lightboxImageOpener"
                  onClick={() => onImageClick("dashboard/missing-image-1")}
                />
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myAssets.trans.13">
                    In this case 5 ARCH will yield 5 xARCH in this &quot;Get&quot; action. The connected wallet (the
                    Cosmostation wallet in this example) will create a pop up to approve the action.
                  </Trans>
                </p>
                <img
                  src={require(`../assets/images/help/dashboard/missing-image-2.png`)}
                  alt={i18(
                    "Dashboard My Assets Manage your Tokens Other Actions Wallet",
                    "help.dashboard.subpage.section.myAssets.missingImage2.alt"
                  )}
                  width={774}
                  height={1018}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                  onClick={() => onImageClick("dashboard/missing-image-2")}
                />
                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myAssets.trans.14"}>
                    After approval, your wallet has 5 xARCH more than before. Sweet!
                  </Trans>
                </p>
                <h3 id="myAssetsUnminting">
                  {i18("Manage unminting", "help.dashboard.subpage.section.myAssets.manageUnminting.title")}
                </h3>
                <p>
                  <Trans i18nKey={"help.dashboard.subpage.sectin.myAssets.trans.15"}>
                    If you wish to unmint your “x” asset, press the downward arrow, your view will look like this.
                  </Trans>
                </p>
                <img
                  src={require(`../assets/images/help/dashboard/missing-image-3.png`)}
                  alt={i18(
                    "Dashboard My Assets Manage your Tokens Other Actions Wallet Confirmed",
                    "help.dashboard.subpage.section.myAssets.missingImage3.alt"
                  )}
                  width={774}
                  height={1018}
                  loading="lazy"
                  className="md80 hasMargin lightboxImageOpener"
                  onClick={() => onImageClick("dashboard/missing-image-3")}
                />
                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myAssets.trans.16"}>
                    In this case let us unmint xARCH and see what happens.
                  </Trans>
                </p>
                <img
                  src={require(`../assets/images/help/dashboard/missing-image-4.png`)}
                  onClick={() => onImageClick("dashboard/missing-image-4")}
                  alt={i18(
                    "Dashboard My Assets Manage your Tokens Other Actions Results",
                    "help.dashboard.subpage.section.myAssets.missingImage4.alt"
                  )}
                  width={774}
                  height={1018}
                  loading="lazy"
                  className="md80 hasMargin lightboxImageOpener"
                />
                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myAssets.trans.17"}>
                    There&#8217;s a network processing period displayed that you must wait for the unmint action to
                    return the original asset value back to the wallet.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myAssets.trans.18"}>
                    Approve the transaction in your wallet to accept the unminting process.
                  </Trans>
                </p>
                <img
                  src={require(`../assets/images/help/dashboard/missing-image-5.png`)}
                  onClick={() => onImageClick("dashboard/missing-image-5")}
                  alt={i18(
                    "Dashboard My Assets Manage your Tokens Other Actions - Unminting",
                    "help.dashboard.subpage.section.myAssets.missingImage5.alt"
                  )}
                  width={774}
                  height={1018}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />
                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myAssets.trans.19"}>
                    Make sure to check back to claim your unminted tokens.
                  </Trans>
                </p>
                <img
                  src={require(`../assets/images/help/dashboard/missing-image-6.png`)}
                  onClick={() => onImageClick("dashboard/missing-image-6")}
                  alt={i18(
                    "Dashboard My Assets Manage your Tokens Other Actions Unminting Claim",
                    "help.dashboard.subpage.section.myAssets.missingImage6.alt"
                  )}
                  width={774}
                  height={1018}
                  loading="lazy"
                  className="md80 hasMargin lightboxImageOpener"
                />
                <h3 id="myAssetsUsingIbc">
                  {i18("Using other networks", "help.dashboard.subpage.section.myAssets.myAssetsUsingIbc.title")}
                </h3>
                <img
                  src={require(`../assets/images/help/dashboard/missing-image-7.png`)}
                  onClick={() => onImageClick("dashboard/missing-image-7")}
                  alt={i18(
                    "Dashboard My Assets Manage your Tokens Other Actions - Using other networks",
                    "help.dashboard.subpage.section.myAssets.missingImage7.alt"
                  )}
                  width={774}
                  height={1018}
                  loading="lazy"
                  className="md80 hasMargin lightboxImageOpener"
                />
                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myAssets.trans.20"}>
                    Deposit will transfer assets from another Cosmos network into Archway.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myAssets.trans.21"}>
                    Withdraw will transfer assets to another Cosmos network from Archway.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myAssets.trans.22"}>
                    It is **IMPORTANT** that you make sure your wallet address is correct! Astrovault will default fill
                    the address tied to the connected wallet, and you can change it to send or fetch to/from elsewhere.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myAssets.trans.23"}>
                    You can deposit from, and withdraw to, the following networks.
                  </Trans>
                </p>
                <img
                  src={require(`../assets/images/help/dashboard/Picture9.webp`)}
                  onClick={() => onImageClick("dashboard/Picture9")}
                  alt={i18(
                    "Dashboard My Assets Manage your Tokens Networks",
                    "help.dashboard.subpage.section.myAssets.picture9.alt"
                  )}
                  width={693}
                  height={392}
                  loading="lazy"
                  className="md80 hasMargin lightboxImageOpener"
                />
                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myAssets.trans.24"}>
                    More IBC networks will be added. Learn more about the{" "}
                    <Link
                      to="https://ibc.cosmos.network/"
                      target={"_blank"}
                      rel="noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      {i18("IBC Network", "help.dashboard.subpage.section.myAssets.trans.24")}
                    </Link>
                    .
                  </Trans>
                </p>
                <h3 id="myAssetsFavouritingDragging">
                  {i18("Favouriting and dragging", "help.dashboard.subpage.section.myAssets.favouritingDragging.title")}
                </h3>
                <p>
                  2.&nbsp;
                  <Trans i18nKey={"help.dashboard.subpage.section.myAssets.trans.25"}>
                    The star will favourite the token, allowing it to be displayed when you select the
                    &quot;Favourite&quot; button on the &quot;My Assets&quot; panel.
                  </Trans>
                </p>
                <img
                  src={require(`../assets/images/help/dashboard/Picture10.webp`)}
                  onClick={() => onImageClick("dashboard/Picture10")}
                  alt={i18("Dashboard My Assets Table", "help.dashboard.subpage.section.myAssets.picture10.alt")}
                  width={40}
                  height={40}
                  loading="lazy"
                  className="sm lightboxImageOpener"
                />

                <p>
                  3.&nbsp;
                  <Trans i18nKey={"help.dashboard.subpage.section.myAssets.trans.26"}>
                    This drag handle allows you to change the order of assets
                  </Trans>
                </p>
                <img
                  src={require(`../assets/images/help/dashboard/Picture11.webp`)}
                  onClick={() => onImageClick("dashboard/Picture11")}
                  alt={i18(
                    "Dashboard My Assets Table Drag Feature",
                    "help.dashboard.subpage.section.myAssets.picture11.alt"
                  )}
                  width={40}
                  height={40}
                  loading="lazy"
                  className="sm lightboxImageOpener"
                />
                <img
                  src={require(`../assets/images/help/dashboard/Picture12.webp`)}
                  onClick={() => onImageClick("dashboard/Picture12")}
                  alt={i18("Dashboard My Assets table handle", "help.dashboard.subpage.section.myAssets.picture12.alt")}
                  width={981}
                  height={289}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />
                <img
                  src={require(`../assets/images/help/dashboard/Picture13.webp`)}
                  onClick={() => onImageClick("dashboard/Picture13")}
                  alt={i18(
                    "Dashboard My Assets table reordered row",
                    "help.dashboard.subpage.section.myAssets.picture13.alt"
                  )}
                  width={960}
                  height={275}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myAssets.trans.27"}>
                    Now we&#8217;re familiar with the <strong>My Assets</strong> panel let&#8217;s take a look at the{" "}
                    <strong>My Pools</strong> panel.
                  </Trans>
                </p>
              </HelpSubPageSection>

              <HelpSubPageSection
                extraClassName="withGradientBorderTop"
                sectionId="myPools"
                sectionTitle={i18("My Pools", "help.dashboard.subpage.section.myPools.title")}
              >
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myPools.intro">
                    This panel displays information about the Astrovault pools that you are active in and will look
                    similar to this:
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/dashboard/Picture14.webp`)}
                  onClick={() => onImageClick("dashboard/Picture14")}
                  alt={i18("Dashboard My Pools", "help.dashboard.subpage.section.myPools.picture14.alt")}
                  width={977}
                  height={633}
                  loading="lazy"
                  className="md80 hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myPools.trans.0">
                    Each pool card shows the tokens of the pool you are participating in.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myPools.trans.1">
                    The Total Value Locked (TVL) in the pool.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myPools.trans.2">
                    The amount of tokens you have in the pool.
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myPools.trans.3">
                    And finally, the amount of rewards you will receive for participating in the pool.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/dashboard/Picture15.webp`)}
                  onClick={() => onImageClick("dashboard/Picture15")}
                  alt={i18("Dashboard My Pools Dropdown", "help.dashboard.subpage.section.myPools.picture15.alt")}
                  width={435}
                  height={274}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myPools.trans.4">
                    Clicking the 3 dots in the top right corner of the pool you are looking at drops down a menu with
                    these choices.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myPools.trans.5">
                    You can quickly go to the Pools page to look at the pool you are in as well as others.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myPools.trans.6">
                    You can also collect your pool rewards directly from the Dashboard view with just the click of a
                    button. Nice!
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myPools.trans.7">
                    Learn more about Astrovault{" "}
                    <Link to="/help/pool" rel="noreferrer" className="btn" data-color="gradient" data-variant="link">
                      pools
                    </Link>
                    .
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myPools.trans.8"}>
                    Now let&#8217;s move on to another unique feature of Astrovault,{" "}
                    <strong>
                      Gravitate{" "}
                      <span className="iconWrapper">
                        <Trans i18nKey="help.grvt8Icon">
                          {[
                            "(",
                            <img
                              key="img"
                              src={LogoGravitate}
                              alt={i18("GRVT8 - Gravitate Icon", "grvt8.icon.alt")}
                              width={10}
                              height={14}
                              loading="lazy"
                              className="isIcon GRVT8"
                            />,
                            <span key="nbsp">&nbsp;</span>,
                            "GRVT8",
                            ")",
                          ]}
                        </Trans>
                      </span>{" "}
                      rewards
                    </strong>
                    .
                  </Trans>
                </p>
              </HelpSubPageSection>

              <HelpSubPageSection
                extraClassName="withGradientBorderTop"
                sectionId="myGravitate"
                sectionTitle={i18("My Gravitate", "help.dashboard.subpage.section.myGravitate.title")}
                suffix={
                  <span className="iconWrapper">
                    <Trans i18nKey="help.grvt8Icon">
                      {[
                        "(",
                        <img
                          key="img"
                          src={LogoGravitate}
                          alt="GRVT8 Icon"
                          loading="lazy"
                          className="isIcon GRVT8 title"
                        />,
                        <span key="nbsp">&nbsp;</span>,
                        "GRVT8",
                        ")",
                      ]}
                    </Trans>
                  </span>
                }
              >
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myGravitate.trans.0">
                    Gravitate{" "}
                    <span className="iconWrapper">
                      <Trans i18nKey="help.grvt8Icon">
                        {[
                          "(",
                          <img
                            key="img"
                            src={LogoGravitate}
                            alt="GRVT8 Icon"
                            width={10}
                            height={14}
                            loading="lazy"
                            className="isIcon GRVT8"
                          />,
                          <span key="nbsp">&nbsp;</span>,
                          "GRVT8",
                          ")",
                        ]}
                      </Trans>
                    </span>{" "}
                    rewards are awarded for every trade that you make, and can be redeemed for AXV tokens.
                  </Trans>
                </p>
              </HelpSubPageSection>

              <HelpSubPageSection
                extraClassName="withGradientBorderTop"
                sectionId="myHistory"
                sectionTitle={i18("My History", "help.dashboard.subpage.section.myHistory.title")}
              >
                <p>
                  <Trans i18nKey="help.dashboard.subpage.section.myHistory.trans.0">
                    This panel shows your trade history with the most recent first.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/dashboard/Picture17.webp`)}
                  onClick={() => onImageClick("dashboard/Picture17")}
                  alt={i18("Dashboard My History", "help.dashboard.subpage.section.myHistory.picture17.alt")}
                  width={979}
                  height={787}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myHistory.trans.1"}>
                    It displays the date, time, and what was traded.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/dashboard/Picture18.webp`)}
                  onClick={() => onImageClick("dashboard/Picture18")}
                  alt={i18("Dashboard My History Table Row", "help.dashboard.subpage.section.myHistory.picture18.alt")}
                  width={898}
                  height={110}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myHistory.trans.2"}>
                    The button in the menu to the right has two curved arrows and is the <strong>Trade</strong> button
                    which allows to quickly trade the same token pair. Awesome way to save yourself some time.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myHistory.trans.3"}>
                    As usual, the star will favourite the trade pair so you can come back to trade it again and again.
                  </Trans>
                </p>

                <h3 id="myPoolsMessages">
                  {i18("Messages", "help.dashboard.subpage.section.myHistory.messages.title")}
                </h3>

                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myHistory.trans.4"}>
                    Under the <strong>Messages</strong> tab you will see the following information.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/dashboard/Picture19.webp`)}
                  onClick={() => onImageClick("dashboard/Picture19")}
                  alt={i18(
                    "Dashboard My History Table - Messages",
                    "help.dashboard.subpage.section.myHistory.picture19.alt"
                  )}
                  width={922}
                  height={984}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myHistory.trans.5"}>
                    This is incredibly useful information. As you can see in the screenshot the most recent transaction
                    failed, and Astrovault makes it easy to see why.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myHistory.trans.6"}>
                    <strong>Messages</strong> can also be seen at the bottom right of the view in the menu. Clicking the
                    top right arrow icon inside the menu moves it to the top of the view.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/dashboard/Picture20.webp`)}
                  onClick={() => onImageClick("dashboard/Picture20")}
                  alt={i18("Footer Menu Messages", "help.dashboard.subpage.section.myHistory.picture20.alt")}
                  width={922}
                  height={984}
                  loading="lazy"
                  className="md80 hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.myHistory.trans.7"}>
                    While you&#8217;re there, make sure to follow Astrovault on{" "}
                    <Link
                      to="https://astrovault.io/discord"
                      target={"_blank"}
                      rel="noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      {i18("Discord", "")}
                    </Link>
                    ,{" "}
                    <Link
                      to="https://astrovault.io/telegram"
                      target={"_blank"}
                      rel="noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      {i18("Telegram", "")}
                    </Link>
                    , and{" "}
                    <Link
                      to="https://astrovault.io/twitter"
                      target={"_blank"}
                      rel="noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      {i18("Twitter", "")}
                    </Link>
                  </Trans>
                </p>
              </HelpSubPageSection>

              <HelpSubPageSection
                extraClassName="withGradientBorderTop"
                sectionId="theMarket"
                sectionTitle={i18("The Market", "help.dashboard.subpage.section.market.title")}
              >
                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.market.trans.0"}>
                    This panel shows the markets that Astrovault participates in and allows you to check the price as
                    well as recent price action.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/dashboard/Picture16.webp`)}
                  onClick={() => onImageClick("dashboard/Picture16")}
                  alt={i18("Dashboard The Market", "help.dashboard.subpage.section.market.picture16.alt")}
                  width={958}
                  height={969}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.market.trans.1"}>
                    Once again, the arrows at the bottom move you back and forth through the token list.
                  </Trans>
                </p>
              </HelpSubPageSection>
              <HelpSubPageSection
                extraClassName="withGradientBorderTop"
                sectionId="dashboardCustomisation"
                sectionTitle={i18(
                  "Dashboard customisation",
                  "help.dashboard.subpage.section.dashboardCustomisation.title"
                )}
              >
                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.dashboardCustomisation.trans.0"}>
                    Can you spot the difference between these two pictures?
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.dashboardCustomisation.trans.1"}>This one...</Trans>
                </p>

                <img
                  src={require(`../assets/images/help/dashboard/Picture21.webp`)}
                  onClick={() => onImageClick("dashboard/Picture21")}
                  alt={i18("Dashboard Default", "help.dashboard.subpage.section.dashboardCustomisation.picture21.alt")}
                  width={922}
                  height={984}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.dashboardCustomisation.trans.2"}>
                    And this one...
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/dashboard/Picture22.webp`)}
                  onClick={() => onImageClick("dashboard/Picture22")}
                  alt={i18(
                    "Dashboard Customised",
                    "help.dashboard.subpage.section.dashboardCustomisation.picture22.alt"
                  )}
                  width={922}
                  height={984}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.dashboardCustomisation.trans.3"}>
                    That’s right, your dashboard panels can be moved around.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.dashboardCustomisation.trans.4"}>
                    Quickly customise your dashboard by dragging and dropping the drag handle.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/dashboard/Picture11.webp`)}
                  onClick={() => onImageClick("dashboard/Picture23")}
                  alt={i18(
                    "Dashboard Drag Handle",
                    "help.dashboard.subpage.section.dashboardCustomisation.picture23.alt"
                  )}
                  width={40}
                  height={40}
                  loading="lazy"
                  className="sm lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey={"help.dashboard.subpage.section.dashboardCustomisation.trans.5"}>
                    Astrovault is all about you and your experience. We want you to be comfortable when you connect into
                    your dashboard every day, so make it your own.
                  </Trans>
                </p>
              </HelpSubPageSection>
            </div>
          </div>
        </div>
      </div>
      <Lightbox
        index={index}
        slides={slides}
        open={index >= 0}
        close={() => setIndex(-1)}
        plugins={[Captions, Thumbnails]}
        className={styles.lightbox}
      />
    </MainLayout>
  );
}

export default HelpDashboard;
