import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { LogOut, UserPlus } from "react-feather";
// import { Bell, Settings } from "react-feather";
import { IWalletInfo } from "@axvdex/state/wallet/initialState";
import { postFaucet } from "@axvdex/api/user";
import { sendToast } from "@axvdex/state/wallet/walletSlice";

import useLanguage from "@axvdex/hooks/useLanguage";
import { useGlobalModalContext } from "../../providers/GlobalModalProvider";
import Button from "../common/Button";
// import AstrovaultTokenIcon from "../common/AstrovaultTokenIcon";
import styles from "../../styles/UserWallet.module.scss";
// import { DummyUserInfo } from "../../mocks/DummyData";

import { ReactComponent as LogoCosmostation } from "../../assets/logos/logo-wallet-cosmostation.svg";
import { ReactComponent as LogoKeplr } from "../../assets/logos/logo-wallet-keplr.svg";
import { ReactComponent as LogoLeap } from "../../assets/logos/logo-wallet-leap.svg";
// import { ReactComponent as LogoArchx } from "../../assets/logos/logo-wallet-archx.svg";
import { ReactComponent as FaucetConstIcon } from "../../assets/tokens/logo-arch.svg";
import UserSettingsModal from "../modals/UserSettingsModal";
import NotificationsModal from "../modals/NotificationsModal";
import { useAppDispatch } from "state";
import { disconnectWallet, updateNativeBalance } from "state/wallet/walletThunks";
import { loadState, WHITE_LIST_PERSISTED_STATE_KEYS } from "state/persist";

interface UserWalletProps {
  hideInfo?: boolean;
  walletInfo: IWalletInfo;
  onClick: () => void;
}

function UserWallet({ hideInfo, walletInfo, onClick: onClickToToggleSideMenu }: UserWalletProps) {
  const dispatch = useAppDispatch();
  const { i18 } = useLanguage();

  // const walletInfo = useAppSelector(selectWalletInfo);
  const [isFaucetLoading, setIsFaucetLoading] = useState(false);
  const [isUserConnected, setIsUserConnected] = useState(!!walletInfo.isConnected);

  const handleConnectWallet = useCallback(async () => {
    if (!loadState(WHITE_LIST_PERSISTED_STATE_KEYS.agreedCompliance)) {
      showModal("complianceModal", {
        connectWalletAfter: true,
      });
    } else {
      showModal("walletSelectionModal");
    }
  }, []);

  const handleDisconnectWallet = useCallback(async () => {
    await dispatch(disconnectWallet());
  }, []);

  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    setIsUserConnected(!!walletInfo.isConnected);
  }, [walletInfo.isConnected]);

  const handleUseFaucet = async () => {
    let err;

    try {
      setIsFaucetLoading(true);
      const res = await postFaucet({ address: walletInfo.walletAddress }, {});
      console.log(res.err.error);
      if (res.ok) {
        await dispatch(
          sendToast({
            type: "tx-success",
            info: { msg: "Got 10.00 CONST from faucet", toastID: "" + new Date().getTime() },
          })
        );
        await dispatch(
          updateNativeBalance({
            userAddress: walletInfo.walletAddress,
            denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM,
          })
        );
      } else {
        err = res.err.error;
      }
    } catch (err) {
      /* empty */
    }

    if (err) {
      if (err.message.includes("already transferred")) {
        await dispatch(
          sendToast({
            type: "tx-fail",
            info: {
              msg: "Failed to get CONST from faucet. Reason: Quota exceeded!",
              toastID: "" + new Date().getTime(),
            },
          })
        );
      } else {
        await dispatch(
          sendToast({
            type: "tx-fail",
            info: {
              msg: "Failed to get CONST from faucet. Reason: " + err.message,
              toastID: "" + new Date().getTime(),
            },
          })
        );
      }
    }
    setIsFaucetLoading(false);
  };

  const walletKind = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet),
    walletName = walletKind.charAt(0).toUpperCase() + walletKind.slice(1);

  return (
    <div className={clsx(styles.userWallet, "userWallet")}>
      {isUserConnected ? (
        <>
          <div className="userWalletWrapper">
            <div
              className={clsx(
                "userWalletAvatar",
                !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[
                  process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress
                ]
                  ? "isClickableWithRed"
                  : "isClickable"
              )}
              onClick={() => {
                if (
                  !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[
                    process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress
                  ]
                ) {
                  showModal("permitAuthenticationModal");
                } else {
                  onClickToToggleSideMenu();
                }
              }}
              title={
                !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[
                  process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress
                ]
                  ? i18("Create Permit is required", "userWallet.createPermitRequired")
                  : i18(`${walletName} wallet connected`, "userWallet.connected.title", { walletName })
              }
            >
              {/* Badge when permit has not been created yet */}
              {!loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[
                process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress
              ] && (
                <span className="badge red">
                  <span className="badgeIcon">!</span>
                </span>
              )}

              {("cosmostation" === walletKind && <LogoCosmostation />) ||
                ("keplr" === walletKind && <LogoKeplr />) ||
                ("leap" === walletKind && <LogoLeap />) ||
                // ("archx" === walletKind && <LogoArchx />) ||
                walletInfo.walletOwnerName.slice(0, 2)}
            </div>

            {!hideInfo && (
              <div
                className="userWalletInfo"
                title={i18(`Copy address to clipboard`, "userWallet.copyToClipboard.title")}
                onClick={() => {
                  // copy to clipboard
                  navigator.clipboard.writeText(walletInfo.walletAddress);
                  dispatch(
                    sendToast({
                      type: "info",
                      noStore: true,
                      info: {
                        msg: i18("Address copied to clipboard", "toast.addressCopied.text"),
                        toastID: "" + new Date().getTime(),
                      },
                    })
                  );
                }}
              >
                <div className="userWalletName">
                  {walletInfo.walletOwnerName}&nbsp;{}
                </div>

                <div className="userWalletId">
                  <span>{walletInfo.walletAddress.slice(0, -4)}</span>
                  <span>{walletInfo.walletAddress.slice(-4)}</span>
                </div>
              </div>
            )}

            {!hideInfo &&
            loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[
              process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress
            ] ? (
              <div style={{ width: "3em" }}>
                <UserSettingsModal />
                <Button
                  style={{ height: "2em" }}
                  extraClassName="btnDisconnectWallet"
                  title={i18(`Disconnect ${walletName} wallet`, "userWallet.disconnectBtn.title", { walletName })}
                  btnVariant="icon"
                  icon={<LogOut />}
                  onClick={handleDisconnectWallet}
                />
              </div>
            ) : (
              <Button
                extraClassName="btnDisconnectWallet"
                title={i18(`Disconnect ${walletName} wallet`, "userWallet.disconnectBtn.title", { walletName })}
                btnVariant="icon"
                icon={<LogOut />}
                onClick={handleDisconnectWallet}
              />
            )}
          </div>
          <NotificationsModal hideInfo={hideInfo} />
          {process.env.REACT_APP_MODE !== "MAINNET" && (
            <Button
              disabled={isFaucetLoading}
              extraClassName="btnFaucet"
              btnSize="sm"
              title={i18("Claim 10.00 CONST Faucet", "userWallet.faucet.title")}
              icon={
                <span className={"faucetIcon"}>
                  <FaucetConstIcon />
                </span>
              }
              onClick={handleUseFaucet}
            >
              {!hideInfo && <>{i18("10.00 CONST Faucet", "userWallet.faucet.text")}</>}
            </Button>
          )}

          {/* <Button
            extraClassName="btnCollectRewards"
            btnSize="sm"
            btnColor="gradient"
            title={i18("Collect Rewards", "userWallet.collectReward.title")}
            icon={<AstrovaultTokenIcon />}
            onClick={handleOpenRewardsModal}
          >
            {!hideInfo && (
              <>
                {i18("Collect", "userWallet.collectReward.text")} {DummyUserInfo.userRewardsAmount}
              </>
            )}
          </Button> */}
        </>
      ) : (
        <Button
          extraClassName="btnConnectWallet"
          btnColor="gradient"
          title={i18("Connect Your Wallet", "userWallet.connectBtn.title")}
          text={hideInfo ? "" : i18("Connect Wallet", "userWallet.connectBtn.text")}
          icon={hideInfo && <UserPlus />}
          onClick={handleConnectWallet}
        />
      )}
    </div>
  );
}

export default UserWallet;
