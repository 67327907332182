import PoolsTable from "../components/pools/PoolsTable";

function Pool() {
  return (
    <>
      <PoolsTable />
    </>
  );
}

export default Pool;
