import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { ChevronLeft, ChevronRight } from "react-feather";
import clsx from "clsx";
import BigNumber from "bignumber.js";
import useLanguage from "@axvdex/hooks/useLanguage";
import { selectSalesBids, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { redeemFromVesting } from "@axvdex/state/outbid/saleThunks";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
import Button from "../common/Button";
import CustomLoader from "../common/CustomLoader";
import CustomModal from "../common/CustomModal";
import styles from "../../styles/Outbid.module.scss";

const SaleRedeemSection = ({ outbidSale, selectedSale, salesBids }: any) => {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const walletInfo = useAppSelector(selectWalletInfo);
  const [NftModalOpen, setNFTModalOpen] = useState(false);
  const [loadingVesting, setLoadingVesting] = useState(false);
  const [loadingNFT, setLoadingNFT] = useState(false);

  useEffect(() => {
    if (selectedSale && walletInfo.isConnected) {
      checkVestingRedeemable();
      checkNFTRedeemable();
    }
  }, [selectedSale, walletInfo]);

  const [vestingRedeemable, setVestingRedeemable] = useState(null);

  const checkVestingRedeemable = async () => {
    setLoadingVesting(true);
    const res = await rpcClientQuerySmartContractWrapper(outbidSale.config.vesting_addr, {
      vesting_redeemable: {
        address: walletInfo.walletAddress,
        sale_id: selectedSale.id,
      },
    });
    setVestingRedeemable(res);
    setLoadingVesting(false);
  };

  const [nftRedeemable, setNFTRedeemable] = useState(null);
  const [NFTdetails, setNFTDetails] = useState(null);
  // const [nftImage, setNFTImage] = useState(null);

  const checkNFTRedeemable = async () => {
    setLoadingNFT(true);
    const index = outbidSale.salesState[selectedSale.value].top_bidders.findIndex(
      topBidder => topBidder.address === walletInfo.walletAddress
    );
    if (-1 !== index) {
      try {
        const res = await rpcClientQuerySmartContractWrapper(outbidSale.config.cw721_addr, {
          all_nft_info: { token_id: selectedSale.id + "-" + (index + 1) },
        });
        setNFTRedeemable(false);
        setNFTDetails(res);
      } catch (e) {
        console.log(e);
        if (e.message.includes("not found")) setNFTRedeemable(true);
        setNFTDetails(null);
      }
    } else {
      setNFTRedeemable(false);
      setNFTDetails(null);
    }
    setLoadingNFT(false);
  };

  const vestingRedeemText = () => {
    const vestingAssetAmount = BigNumber(salesBids[selectedSale.value].total_final_ticket_amount)
      .times(outbidSale.saleVestings[selectedSale.id - 1]?.ticket_to_target_ratio)
      .div(Math.pow(10, 6))
      .decimalPlaces(6)
      .toString(10);

    if (new Date().getTime() < outbidSale.saleVestings[selectedSale.id - 1]?.end_vesting_timestamp * 1000) {
      return (
        <span>
          {i18("Vested until", "sales.redeem.vestedUntil")}
          <br />
          <br />
          {new Date(outbidSale.saleVestings[selectedSale.id - 1]?.end_vesting_timestamp * 1000).toLocaleString()}
          <br />
          <br />{" "}
          <NumericFormat
            className="ticketAmount"
            displayType="text"
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={4}
            suffix={" " + outbidSale.projectSymbol}
            value={vestingAssetAmount}
          />
        </span>
      );
    } else if (true === vestingRedeemable) {
      return (
        <span>
          {i18("Redeem Vesting", "sales.redeem.redeemVesting")}
          <br /> <br />{" "}
          <NumericFormat
            className="ticketAmount"
            displayType="text"
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={4}
            suffix={" " + outbidSale.projectSymbol}
            value={vestingAssetAmount}
          />
        </span>
      );
    } else if (false === vestingRedeemable) {
      return (
        <span>
          {i18("Already Redeemed", "sales.redeem.alreadyRedeemed")}
          <br /> <br />{" "}
          <NumericFormat
            className="ticketAmount"
            displayType="text"
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={4}
            suffix={" " + outbidSale.projectSymbol}
            value={vestingAssetAmount}
          />
        </span>
      );
    }
  };

  const [isTransactionLoading, setIsTransactionLoading] = useState(false);
  const handleRedeemVesting = async () => {
    setIsTransactionLoading(true);
    try {
      await dispatch(
        redeemFromVesting({
          walletAddress: walletInfo.walletAddress,
          vestingAddress: outbidSale.config.vesting_addr,
          sale_id: selectedSale.id,
          targetAssetAddr: outbidSale.config.target_asset_info.token?.contract_addr,
          i18,
        })
      );
    } catch (e) {
      /* empty */
    }
    await checkVestingRedeemable();
    setIsTransactionLoading(false);
  };

  const [isTransactionNFTLoading, setIsTransactionNFTLoading] = useState(false);
  const handleNFTMint = async () => {
    setIsTransactionNFTLoading(true);
    try {
      await window.client.execute(
        walletInfo.walletAddress,
        outbidSale.address,
        {
          mint_sale_top_bidder_nft: {
            sale_id: selectedSale.id,
          },
        },
        "auto"
      );
    } catch (e) {
      /* empty */
    }

    setNFTModalOpen(true);
    checkNFTRedeemable();
    setIsTransactionNFTLoading(false);
  };

  if (
    !outbidSale.saleVestings ||
    !salesBids ||
    !selectedSale ||
    !salesBids[selectedSale.value] ||
    0 === salesBids[selectedSale.value].bids.length
  )
    return null;
  return (
    <div style={{ marginBottom: "1em" }}>
      <h1>{i18("Redeem", "sales.redeem")}</h1>
      <div style={{ display: "flex", marginBottom: "1em", placeContent: "space-evenly", alignItems: "center" }}>
        {null !== vestingRedeemable && (
          <Button
            style={{ maxWidth: "15em", padding: "1em" }}
            btnColor="gradient"
            disabled={!vestingRedeemable}
            onClick={() => handleRedeemVesting()}
          >
            {(loadingVesting || isTransactionLoading) && <CustomLoader size="xs" />}
            {!loadingVesting && !isTransactionLoading && vestingRedeemText()}
          </Button>
        )}
        {null !== nftRedeemable && true === nftRedeemable && (
          <Button
            style={{ maxWidth: "15em", padding: "1em" }}
            btnColor="gradient"
            disabled={!nftRedeemable || isTransactionNFTLoading}
            onClick={() => handleNFTMint()}
          >
            {(loadingNFT || isTransactionNFTLoading) && <CustomLoader size="xs" />}
            {!loadingNFT && !isTransactionNFTLoading && true === nftRedeemable ? (
              <div>
                <span>{i18("You won a bonus NFT!", "sales.redeem.nftWon")}</span>
                <br />
                <br />
                <span>{i18("Redeem NFT", "sales.redeem.nftRedeem")}</span>
              </div>
            ) : (
              ""
            )}
          </Button>
        )}
        {NFTdetails &&
          outbidSale.nftSaleImages &&
          outbidSale.nftSaleImages[NFTdetails.info.extension.attributes[0].value] &&
          outbidSale.nftSaleImages[NFTdetails.info.extension.attributes[0].value][
            NFTdetails.info.extension.attributes[1].value
          ] && (
            <div
              style={{ padding: "1em", textAlign: "center", cursor: "pointer" }}
              onClick={() => setNFTModalOpen(true)}
            >
              <img
                style={{ maxWidth: "15em", padding: "1em" }}
                src={
                  outbidSale.nftSaleImages[NFTdetails.info.extension.attributes[0].value][
                    NFTdetails.info.extension.attributes[1].value
                  ][0]
                }
              ></img>
              <br />
              <span>
                {NFTdetails.info.extension.name.split("/")[1] + " | " + NFTdetails.info.extension.name.split("/")[2]}
              </span>
            </div>
          )}
        {NFTdetails && (
          <NFTModal
            NftModalOpen={NftModalOpen}
            setNFTModalOpen={setNFTModalOpen}
            saleID={NFTdetails.info.extension.attributes[0].value}
            topNumber={NFTdetails.info.extension.attributes[1].value}
            outbidSale={outbidSale}
          />
        )}
      </div>
    </div>
  );
};

export function NFTModal({ NftModalOpen, setNFTModalOpen, saleID, topNumber, outbidSale, saleAddress }: any) {
  const { i18 } = useLanguage();
  const salesBids = useAppSelector(selectSalesBids);
  const [images, setImages] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 1,
  });

  useEffect(() => {
    if (outbidSale.nftSaleImages && outbidSale.nftSaleImages[saleID] && outbidSale.nftSaleImages[saleID][topNumber]) {
      setImages(outbidSale.nftSaleImages[saleID][topNumber]);
      setPagination({
        pageIndex: 0,
        pageSize: 1,
      });
    }
  }, [NftModalOpen, outbidSale]);

  useEffect(() => {
    if (NftModalOpen && salesBids && salesBids[saleAddress] && salesBids[saleAddress].bids_with_incentives.length > 0) {
      getNftDetails();
    }
  }, [NftModalOpen, outbidSale, salesBids]);

  const getNftDetails = async () => {
    const images = [];
    for (const bid of salesBids[saleAddress].bids_with_incentives) {
      for (const incentive of bid.incentives) {
        if (incentive.nft) {
          const res = await rpcClientQuerySmartContractWrapper(incentive.nft.contract_addr, {
            all_nft_info: { token_id: incentive.nft.token_id },
          });

          if (res?.info?.extension.image) images.push(res.info.extension.image);
        }
      }
    }
    setImages(existingImages => [...existingImages, ...images]);
  };

  const paginationBulletsButtons = Array.from({ length: images.length }, (_, i) => (
    <li key={i}>
      <button
        className={clsx("btnTablePaginationBullet", i === pagination.pageIndex && "isCurrent")}
        onClick={() =>
          setPagination({
            ...pagination,
            pageIndex: i,
          })
        }
        title={`${i18("Go to page", "global.pagination.goToPage")} ${i + 1}`}
      />
    </li>
  ));

  return (
    <>
      <CustomModal
        extraClassName={clsx(styles.sales, "sales")}
        isOpen={NftModalOpen}
        onClose={() => setNFTModalOpen(false)}
      >
        <section className="sectionModalHeader">
          <h2 className="h2">{"NFT"}</h2>
        </section>
        <div style={{ padding: ".5em", textAlign: "center" }}>
          <img style={{ maxWidth: "30em", padding: "1em" }} src={images[pagination.pageIndex]}></img>
          <br />
          <span>
            {pagination.pageIndex <= outbidSale.nftSaleImages[saleID][topNumber].length - 1
              ? "Sale " + saleID + " | " + "Top " + topNumber
              : "Bid Reward"}
          </span>
        </div>
        {images.length > 1 && (
          <section
            className="statisticsGridItemsSectionTablePagination"
            style={{ paddingTop: ".5rem", paddingBottom: "1.5rem" }}
          >
            <Button
              extraClassName="btnTablePagination previous"
              title={i18("Previous page", "global.pagination.previousPage")}
              btnVariant="icon"
              btnColor="dark-medium"
              icon={<ChevronLeft />}
              onClick={() => setPagination({ ...pagination, pageIndex: pagination.pageIndex - 1 })}
              disabled={0 === pagination.pageIndex}
            />

            <ul className="tablePaginationBulletBtnList">{paginationBulletsButtons.map(bullet => bullet)}</ul>

            <Button
              extraClassName="btnTablePagination next"
              title={i18("Next page", "global.pagination.nextPage")}
              btnVariant="icon"
              btnColor="dark-medium"
              icon={<ChevronRight />}
              onClick={() => setPagination({ ...pagination, pageIndex: pagination.pageIndex + 1 })}
              disabled={pagination.pageIndex === images.length - 1}
            />
          </section>
        )}
        <div className="buttonContainer">
          <Button
            style={{ marginLeft: "auto" }}
            btnColor="gradient"
            text={"Dismiss"}
            title={"Dismiss"}
            onClick={() => {
              setNFTModalOpen(false);
            }}
          />
        </div>
      </CustomModal>
    </>
  );
}

export default SaleRedeemSection;
