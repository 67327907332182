// 1st step: go thought all available pools and create all possible hops of swaps between 2 different assets
let hops: { [assetX: string]: { [assetY: string]: string } } = {};

export const computeRouterHops = (allPools: any) => {
  const newHops: { [assetX: string]: { [assetY: string]: string } } = {};
  // loop thought all available pools
  Object.keys(allPools).forEach((poolAddress: string) => {
    // filter out pools that have swap disabled
    if (!allPools[poolAddress].owner_settings.is_swap_enabled) return null;

    const assetIDs = allPools[poolAddress].poolAssets.map((asset: any) => {
      if (asset.info.token) {
        return asset.info.token.contract_addr;
      }
      return asset.info.native_token.denom;
    });

    // loop thought all assets in the pool
    assetIDs.forEach((assetID1: string) => {
      if (!(assetID1 in newHops)) {
        newHops[assetID1] = {};
      }
      // save all possible hops of this pool (multiple hops in case of a stable pool with +2 assets)
      assetIDs.forEach((assetID2: string) => {
        if (assetID1 !== assetID2) {
          newHops[assetID1][assetID2] = poolAddress;
        }
      });
    });
  });

  hops = newHops;
};

export const getRouterHops = () => {
  return hops;
};
