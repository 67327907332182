// import { useTranslation } from "react-i18next";
import clsx from "clsx";
import useLanguage from "@axvdex/hooks/useLanguage";
import MainLayout from "../layouts/MainLayout";
import styles from "../styles/About.module.scss";
import { AboutPageContent } from "./About";

function About() {
  const { i18 } = useLanguage();
  return (
    <MainLayout
      pageClass={clsx(styles.about, "about")}
      headTitle={i18("Landing", "landing.headTitle")}
      headDescription={i18("About Astrovault", "landing.headDescription")}
    >
      <AboutPageContent isLanding={true} />
    </MainLayout>
  );
}

export default About;
