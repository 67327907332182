import { useEffect, useState } from "react";
import clsx from "clsx";
import { getAnalytics } from "@axvdex/api/statistics";
import CustomLoader from "@axvdex/components/common/CustomLoader";
import VolumeChart from "@axvdex/components/statistics/VolumeChart";
import LiquidityChart from "@axvdex/components/statistics/LiquidityChart";
import POLChart from "@axvdex/components/statistics/POLChart";
import POLTable from "@axvdex/components/statistics/POLTable";
import StatisticsPoolsTable from "@axvdex/components/statistics/StatisticsPoolsTable";
import { abbreviateNumber, abbreviateUSD } from "@axvdex/utils/formatNumber";
import useLanguage from "@axvdex/hooks/useLanguage";
import StatisticsAssetsTable from "@axvdex/components/statistics/StatisticsAssetsTable";
import MainLayout from "../layouts/MainLayout";
import styles from "../styles/Statistics.module.scss";

export interface PoolAnalyticsDataSamples {
  [key: string]: [number, number, number][];
}

export interface poolTableData {
  [key: string]: {
    liquidityInUSD: number;
    volume24hInUSD: number;
    volume7dInUSD: number;
    volume30dInUSD: number;
  };
}

export interface assetTableData {
  [key: string]: {
    name: string;
    symbol: string;
    curPriceInUSD: number;
    changePrice: number;
    volume24hMintedInUSD: number;
    volume24hInUSD: number;
    liquidityInUSD: number;
  };
}

interface AnalyticsData {
  currentTVL: number;
  axvBurned: number;
  axvMarketCapitalization: string;
  current24hVolume: number;
  currentLiquidity: number;
  timestamp: string;
  poolsAnalyticsDataSamples: PoolAnalyticsDataSamples;
  poolsTableData: poolTableData;
  assetsTableData: assetTableData;
  polBalances: {
    [key: string]: {
      amount: string;
      amountInUSD: number;
    };
  };
}

const Statistics = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [statistics, setStatistics] = useState<AnalyticsData>(null);
  useEffect(() => {
    const run = async () => {
      setLoading(true);
      try {
        const { data } = await getAnalytics({});
        setStatistics(data);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    run();
  }, []);

  if (loading) {
    return <CustomLoader isFixed={true} showGRVT8 />;
  }

  return <StatisticsComponent statistics={statistics} />;
};

function StatisticsComponent({ statistics }: { statistics: AnalyticsData }) {
  const { i18 } = useLanguage();

  return (
    <MainLayout
      pageClass={clsx(styles.statistics, "statistics")}
      headTitle={i18("Statistics", "statistics.headTitle")}
      headDescription={i18("Statistics for Liquidity and Volume", "statistics.headDescription")}
    >
      <div className="statsPageWrapper">
        <section className="statsSectionHeader">
          <h1>{i18("Statistics", "statistics.title")}</h1>

          <div className="statsSectionGrid statsSectionHeaderGrid">
            <div className="statsSectionGridItem">
              <p className="statsSectionGridItemTitle textGrey">
                {i18("Total Value Locked (TVL)", "statistics.header.tvl")}
              </p>

              <span className="statsSectionGridItemValue pricing">{abbreviateUSD(statistics?.currentTVL)}</span>
            </div>

            <div className="statsSectionGridItem">
              <p className="statsSectionGridItemTitle textGrey">
                {i18("Market Capitalisation", "statistics.header.marketCapitalisation")}
              </p>

              <span className="statsSectionGridItemValue pricing">
                {abbreviateUSD(statistics?.axvMarketCapitalization)}
              </span>
            </div>
            <div className="statsSectionGridItem">
              <p className="statsSectionGridItemTitle textGrey">
                {i18("Burned AXV", "statistics.header.allTimeBurnedAXV")}
              </p>

              <span className="statsSectionGridItemValue">{abbreviateNumber(statistics?.axvBurned)}</span>
            </div>
          </div>
        </section>

        <section className="statsSectionGrid statsSectionGraphGrid">
          <LiquidityChart
            analyticsData={statistics?.poolsAnalyticsDataSamples}
            curLiquidity={statistics?.currentLiquidity}
          />
          <VolumeChart
            analyticsData={statistics?.poolsAnalyticsDataSamples}
            tableData={statistics?.poolsTableData}
            current24hVolume={statistics?.current24hVolume}
          />
        </section>

        <section className="statsSectionGrid statsSectionTable">
          <div>
            <POLTable polBalances={statistics.polBalances} />
          </div>

          <div>
            <POLChart polBalances={statistics.polBalances} />
          </div>
        </section>

        <StatisticsAssetsTable analyticsData={statistics?.assetsTableData} />

        <StatisticsPoolsTable analyticsData={statistics?.poolsTableData} />
      </div>
    </MainLayout>
  );
}

export default Statistics;
