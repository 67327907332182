export const transformWalletAddress = (address: string) => {
  if (!address) return "";
  return address.substring(0, 7) + "..." + address.substring(address.length - 3, address.length);
};

export const storeTxResultLocally = txResult => {
  if (!localStorage || !localStorage.setItem || !txResult.transactionHash) {
    return;
  }

  const result = {
    data: new TextDecoder().decode(txResult.data),
    logs: txResult.logs,
  };

  localStorage.setItem(txResult.transactionHash, JSON.stringify(result));
};

export const displayDecimals = (num, dec) => {
  const magnitude = 10 ** dec;
  return Math.round(num * magnitude + Number.EPSILON) / magnitude;
};

export const numberWithCommas = num => {
  if (!num) return;
  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const displayValueWithUnits = (num: number) => {
  if (num >= 1000) {
    if (num < 1000000) {
      return num / 1000 + "K";
    } else if (num < 1000000000) {
      return num / 1000000 + "M";
    } else if (num < 1000000000000) {
      return num / 1000000000 + "B";
    } else if (num < 1000000000000000) {
      return num / 1000000000000 + "T";
    }
    return "Infinity";
  }
  return String(Math.round(num));
};

export const compareArrayOfObject = (
  requestedArray,
  itemSort,
  descendingOrder = true,
  isNumber = true,
  isDate = false
) => {
  const compareByString = (a, b) => {
    if (descendingOrder) {
      return a[itemSort].localeCompare(b[itemSort]);
    }

    return b[itemSort].localeCompare(a[itemSort]);
  };

  const compareByNumber = (a, b) => {
    if (descendingOrder) {
      return Number(b[itemSort]) - Number(a[itemSort]);
    }

    return Number(a[itemSort]) - Number(b[itemSort]);
  };

  const compareByDate = (a, b) => {
    if (descendingOrder) {
      return new Date(b[itemSort]).getTime() - new Date(a[itemSort]).getTime();
    }

    return new Date(a[itemSort]).getTime() - new Date(b[itemSort]).getTime();
  };

  return isNumber
    ? requestedArray.sort(compareByNumber)
    : isDate
    ? requestedArray.sort(compareByDate)
    : requestedArray.sort(compareByString);
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => key === value);
};
