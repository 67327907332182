import { filter, keys, map, mapValues } from "lodash";
import BigNumber from "bignumber.js";
import { humanizeAmount } from "@axvdex/utils/formatNumber";
import imgSanitize from "@axvdex/utils/imgSanitize";
import { IAsset } from "@axvdex/utils/interfaces";

export interface Asset {
  // tokenWalletBalance: string;
  id: string;
  isNative: boolean;
  denom: string;
  address: string;
  decimals: number;
  isDerivative: boolean;
  symbol: string;
  label: string;
  price: number;
}

export interface TradeInfo {
  fromFieldValue: number;
  fromTokenListDialog: boolean;
  fromFieldValueInUSD: number;
  fromAsset: Asset;
  toAsset: Asset;
  toFieldValue: number;
  toFieldValueInUSD: number;
  toTokenListDialog: boolean;
}

export const initialTradeInfo: TradeInfo = {
  fromTokenListDialog: false,
  toTokenListDialog: false,
  fromFieldValue: 0,
  fromAsset: {
    id: "",
    isNative: false,
    denom: "",
    address: "",
    decimals: 0,
    isDerivative: false,
    symbol: "",
    label: "",
    price: 0,
  },
  toAsset: {
    id: "",
    isNative: false,
    denom: "",
    address: "",
    decimals: 0,
    isDerivative: false,
    symbol: "",
    label: "",
    price: 0,
  },
  toFieldValue: 0,
  fromFieldValueInUSD: 0,
  toFieldValueInUSD: 0,
};

interface AssetForTrade {
  symbol: string;
  balance: string;
  usdBalance: string;
}

export const getTokenList = (
  assetsStored: {
    [key: string]: IAsset;
  },
  assetBalances,
  favouriteAssets
): [
  {
    [asset: string]: AssetForTrade;
  },
  {
    value: string;
    label: string;
    optionPrefix: JSX.Element;
    optionSuffix: string;
  }[]
] => {
  const list = {};

  // loop through assetsStored and add the symbol to the asset object and ignore hidden assets
  for (const key in assetsStored) {
    const asset_ = assetsStored[key];
    if (asset_.isHidden) {
      continue;
    }

    const assetBalance = assetBalances[asset_.address || asset_.denom];
    const balance = assetBalance ? humanizeAmount(assetBalance, asset_.decimals) : "-";
    const usdBalance = assetBalance ? humanizeAmount(parseFloat(assetBalance) * asset_.price, asset_.decimals) : "-";
    list[key] = {
      symbol: asset_.symbol,
      balance,
      usdBalance,
    };
  }

  const tokenList = map(keys(list), key => {
    const asset_ = list[key];
    const balance = asset_.balance;
    return {
      value: asset_.symbol,
      label: asset_.symbol,
      id: key,
      optionPrefix: <img src={imgSanitize(asset_.symbol)} alt={""} />,
      optionSuffix:
        balance === "-"
          ? "-"
          : BigNumber(balance).decimalPlaces(2).toString() +
            " ($" +
            BigNumber(asset_.usdBalance).decimalPlaces(2).toString() +
            ")",
    };
  })
    .sort(function (a, b) {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
      // Sorting by dollar amount commented out until we have a way to display both sorting types on the UI for user to choose
      // const aSuffix = a.optionSuffix === "-" ? "0" : a.optionSuffix.split("($")[1].split(")")[0];
      // const bSuffix = b.optionSuffix === "-" ? "0" : b.optionSuffix.split("($")[1].split(")")[0];
      // if (BigNumber(aSuffix).gt(bSuffix)) {
      //   return -1;
      // }
      // if (BigNumber(aSuffix).lt(bSuffix)) {
      //   return 1;
      // }
      // return 0;
    })
    .sort(function (a, b) {
      if (!favouriteAssets.includes(a.id) && favouriteAssets.includes(b.id)) {
        return 1;
      }
      if (favouriteAssets.includes(a.id) && !favouriteAssets.includes(b.id)) {
        return -1;
      }
    });

  return [list, tokenList];
};
