import { useEffect } from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import StatusMessages from "@axvdex/components/StatusMessages";
import { WHITE_LIST_PERSISTED_STATE_KEYS, persistState } from "@axvdex/state/persist";
import { useAppDispatch } from "@axvdex/state";
import { connectWalletWithDispatch } from "@axvdex/state/wallet/walletThunks";
import { useGlobalModalContext } from "@axvdex/providers/GlobalModalProvider";
import useLanguage from "@axvdex/hooks/useLanguage";
import MainLayout from "../layouts/MainLayout";
import DashboardGrid from "../components/DashboardGrid";
import styles from "../styles/Dashboard.module.scss";

function Dashboard() {
  const { i18 } = useLanguage();
  const { walletName } = useParams();
  const dispatch = useAppDispatch();
  const { hideModal, showModal } = useGlobalModalContext();

  useEffect(() => {
    if (walletName && window[walletName]) {
      initMobileHook(walletName);
    }
  }, []);

  const initMobileHook = async walletName => {
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet, walletName);
    if ((await dispatch(connectWalletWithDispatch({ showModal }))).payload) {
      hideModal();
      showModal("permitAuthenticationModal");
    }
  };

  return (
    <MainLayout
      pageClass={clsx(styles.dashboard, "dashboard")}
      headTitle={i18("Dashboard", "dashboard.headTitle")}
      headDescription={i18(
        "Dashboard of Astrovault DEX with the most ARCH liquidity for the Archway Token Listing at CoinGecko and CoinMarketCap",
        "dashboard.headDescription"
      )}
    >
      <h1 className="visuallyHidden">{i18("Dashboard", "dashboard.title")}</h1>
      <StatusMessages />
      <DashboardGrid />
    </MainLayout>
  );
}

export default Dashboard;
