import clsx from "clsx";
import { NumericFormat } from "react-number-format";
import { isEqual } from "lodash";
// import { Info } from "react-feather";
import { Tooltip } from "react-tooltip";
import ButtonFavorite from "@axvdex/components/common/ButtonFavourite";
import styles from "@axvdex/styles/PoolCard.module.scss";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import {
  selectAssets,
  selectFarmsWithBalance,
  // selectPoolsWithBalance
} from "@axvdex/state/wallet/walletSelectors";
import imgSanitize from "@axvdex/utils/imgSanitize";
import { abbreviateUSD } from "@axvdex/utils/formatNumber";
// import { IFarm } from "@axvdex/utils/interfaces";
import useLanguage from "@axvdex/hooks/useLanguage";
// import Button from "../common/Button";
import { triggerFarmFavouriteChange } from "@axvdex/state/farm/farmThunks";
// import { ReactComponent as IcnFarm } from "../../assets/icons/icn-grow-heart.svg";

import CollectRewardModal from "../modals/CollectRewardModal";
import { FarmRepresentation } from "../common/FarmRepresentation";

interface FarmCardProps {
  onClick: (farmAddress: string) => void;
  farm: any;
  isSelected: boolean;
  favorite: boolean;
}

function FarmCard({ farm, isSelected, onClick, favorite }: FarmCardProps) {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const assets = useAppSelector(selectAssets, isEqual);
  const farmsWithBalance = useAppSelector(selectFarmsWithBalance);

  const toggleFavourite = async () => {
    await dispatch(triggerFarmFavouriteChange({ isFavourite: !favorite, farmAddress: farm?.address }));
  };

  return (
    <article className={clsx(styles.poolCard, "poolCard", isSelected && "isSelected")} tabIndex={0}>
      <div className="buttonContainer">
        {farmsWithBalance.find(farmWithBalance => farmWithBalance.contract_addr === farm.address) &&
          farm.reward_sources.length > 0 && (
            <CollectRewardModal key={farm.label} poolsToShow={[]} farmsToShow={[farm]} triggerElement="icon" />
          )}
        <ButtonFavorite btnColor="purple" onToggleFavourite={toggleFavourite} isFavourite={favorite} />
      </div>
      {/* <div className="poolCardHeader" onClick={() => onClick(farm.address)}>
        <ul className="tokenGridList poolCardTokenList icons">
          {farm.assetIDs.map((assetID, assetIndex) => (
            <li key={assetIndex}>
              <span title={assets[assetID]?.label} aria-label={assets[assetID]?.label}>
                <img src={imgSanitize(assets[assetID]?.symbol)} alt={assets[assetID]?.label} />
              </span>
            </li>
          ))}
        </ul>
      </div> */}
      <div className="poolCardBody" onClick={() => onClick(farm.address)}>
        <div className="poolCardBodyHeader">
          <ul className="poolCardTokenList name">
            {farm && (
              <FarmRepresentation
                address={farm.address}
                inputAsset={{
                  label: assets[farm.config.inc_token]?.label,
                  symbol: assets[farm.config.inc_token]?.symbol,
                }}
                outputAssets={farm.reward_sources.map(rewardSource => {
                  const rewardAsset =
                    assets[
                      rewardSource.reward_asset.token?.contract_addr || rewardSource.reward_asset.native_token?.denom
                    ];
                  return {
                    label: rewardAsset?.label,
                    symbol: rewardAsset?.symbol,
                  };
                })}
              />
            )}
          </ul>

          <ul className="poolCardTokenList name" style={{ marginTop: "1em" }}>
            {farm.reward_sources.map(rewardSource => (
              <li key={rewardSource.reward_asset.token?.contract_addr || rewardSource.reward_asset.native_token?.denom}>
                <span>
                  {
                    assets[
                      rewardSource.reward_asset.token?.contract_addr || rewardSource.reward_asset.native_token?.denom
                    ].symbol
                  }
                </span>
              </li>
            ))}
          </ul>

          <div className="poolCardChain">
            <span>{i18("Farm", "dashboard.farm.farmTitle")}</span>
          </div>
        </div>

        <div className="poolCardRewards">
          <ul className="poolCardRewardsList">
            <li data-tooltip-id={"tooltip" + farm.address}>
              <div className={clsx("inlineFlexbox")}>
                <span className={"poolAttribute"}>{i18("APR ", "poolCard.body.apr.text")}</span>
                <NumericFormat
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  decimalScale={2}
                  value={`${
                    farm && farm.percentageAPRs && farm.percentageAPRs.length > 0
                      ? farm.percentageAPRs.reduce((sum, source) => sum + source, 0) || 0
                      : 0
                  }`}
                  suffix="%"
                />
                <ul className="tokenGridList">
                  {farm &&
                    farm.reward_sources.map((rewardSource, idx) => {
                      const rewardAsset =
                        assets[
                          rewardSource.reward_asset.token?.contract_addr ||
                            rewardSource.reward_asset.native_token?.denom
                        ];
                      return (
                        <li key={idx}>
                          <span title={rewardAsset.symbol} aria-label={rewardAsset.symbol}>
                            <img className="tokenIcon" src={imgSanitize(rewardAsset.symbol)} alt={rewardAsset.symbol} />
                          </span>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </li>
            {farm && farm.reward_sources.length > 1 && (
              <Tooltip
                id={"tooltip" + farm.address}
                place="top"
                style={{ backgroundColor: "var(--dark)", color: "white", zIndex: 3 }}
                render={() => (
                  <div style={{ textAlign: "center" }}>
                    <span style={{ fontSize: "1.5em" }}>APR distribution</span>
                    <br />
                    {farm.reward_sources.map((rewardSource, i) => {
                      const rewardAsset =
                        assets[
                          rewardSource.reward_asset.token?.contract_addr ||
                            rewardSource.reward_asset.native_token?.denom
                        ];
                      return (
                        <>
                          <div
                            className={clsx("inlineFlexbox")}
                            key={farm.address + "_" + i}
                            style={{ marginTop: "1em" }}
                          >
                            <ul className="tokenGridList">
                              <li>
                                <span title={rewardAsset.symbol} aria-label={rewardAsset.symbol}>
                                  <img
                                    className="tokenIcon"
                                    src={imgSanitize(rewardAsset.symbol)}
                                    alt={rewardAsset.symbol}
                                  />
                                </span>
                              </li>
                            </ul>
                            <NumericFormat
                              displayType="text"
                              thousandSeparator=","
                              decimalSeparator="."
                              decimalScale={2}
                              value={`${farm.percentageAPRs[i]}`}
                              suffix="%"
                            />
                          </div>
                          <br />
                        </>
                      );
                    })}
                  </div>
                )}
              />
            )}
            <li>
              <span className={"poolAttribute"}>{i18("TVL ", "poolCard.body.tvl.text")}</span>
              <span>{abbreviateUSD(farm?.farm_tvl || 0)}</span>
            </li>
          </ul>
        </div>
      </div>
    </article>
  );
}

export default FarmCard;
