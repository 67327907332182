import i18n, { ResourceLanguage, use } from "i18next";
import { initReactI18next } from "react-i18next";
import { languages } from "../config/languageConfig";

const localizedLanguages = languages.reduce((acc: Record<string, ResourceLanguage>, lang: string) => {
  acc[lang] = {
    translations: require(`./locales/${lang}/translations.json`),
  };

  return acc;
}, {});

use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: localizedLanguages,
  ns: ["translations"],
  defaultNS: "translations",
});
i18n.languages = languages;

export default i18n;
