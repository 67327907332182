import clsx from "clsx";
import { Link } from "react-router-dom";
import useLanguage from "@axvdex/hooks/useLanguage";
// import { ReactComponent as LogoImage } from "../../assets/logos/logo-image.svg";
import { ReactComponent as LogoImage } from "../../assets/logos/logo-image.svg";
import { ReactComponent as LogoMonogram } from "../../assets/logos/astrovault.svg";
import styles from "../../styles/Logo.module.scss";

interface LogoProps {
  unlink?: boolean;
  hideMonogram?: boolean;
}

function Logo({ unlink, hideMonogram }: LogoProps) {
  const { i18 } = useLanguage();

  return (
    <>
      {unlink ? (
        <span className={clsx(styles.logo, "logo")} aria-label="Astrovault">
          <span className="logoImage">
            <LogoImage />
          </span>

          {!hideMonogram && (
            <span className="logoMonogram">
              <LogoMonogram />
            </span>
          )}
        </span>
      ) : (
        <Link
          to="/"
          className={clsx(styles.logo, "logo")}
          title={i18("Home", "common.logo.link.title")}
          aria-label={i18("Home", "common.logo.link.aria-label")}
        >
          <span className="logoImage">
            <LogoImage />
          </span>

          {!hideMonogram && (
            <span className="logoMonogram">
              <LogoMonogram />
            </span>
          )}
        </Link>
      )}
    </>
  );
}

export default Logo;
