import React, { useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";
import clsx from "clsx";
import { DragDropContext, Draggable, Droppable, DropResult } from "@hello-pangea/dnd";
import { useResizeDetector } from "react-resize-detector";
import { ChevronLeft, ChevronRight } from "react-feather";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import useLanguage from "@axvdex/hooks/useLanguage";
import { ReactComponent as IcnHandle } from "../assets/icons/icn-handle-spacing.svg";
import Button from "./common/Button";
import CustomLoader from "./common/CustomLoader";
import MyAssetsManageTokensModal from "./modals/MyAssetsManageTokensModal";

interface TableDnDProps<Data> {
  tableId: string;
  customData: Data[];
  customColumns: ColumnDef<Data>[];
  pagination: { pageIndex: number; pageSize: number };
  setPagination: React.Dispatch<
    React.SetStateAction<{
      pageIndex: number;
      pageSize: number;
    }>
  >;
  extraClassName?: string;
  mobileResponsiveStyling?: "isResponsive" | "isResponsiveNoThVisible"; // table display block | table display block w/ th not visible
  onOrderUpdate?: ((updatedData: string[]) => Promise<void>) | ((updatedData: string[]) => void);
  hasManageAssetsButton?: boolean;
  hasDraggableButton?: boolean;
}

function DashboardMultiDndPaginationTable<Data>({
  tableId,
  customData,
  customColumns,
  extraClassName,
  mobileResponsiveStyling,
  onOrderUpdate,
  pagination,
  setPagination,
  hasManageAssetsButton,
  hasDraggableButton = true,
}: TableDnDProps<Data>) {
  const { i18 } = useLanguage();
  const { width: dndWidth, height: dndHeight, ref: dndRef } = useResizeDetector();
  const [lockedSize, setLockedSize] = useState({ width: 0, height: 0 });

  const [data, setData] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [rowBeingDragged, setRowBeingDragged] = useState(null);
  const [isLoading] = useState(false);
  const [showDnd, setShowDnd] = useState(true);

  const previousButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const paginationBulletsRefs = useRef([]);

  //const rerender = () => setData([...customData]);

  const reorderRow = (startIndex: number, endIndex: number) => {
    const result = [...data];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    setData([...result]);
    onOrderUpdate(result.map(res => res.id));
  };

  const handleOnDragStart = (result: DropResult, _t) => {
    setLockedSize({ width: dndWidth, height: dndHeight });
    setIsDragging(true);
    setRowBeingDragged(result.source);
  };

  const handleOnDragEnd = async (result: DropResult) => {
    setRowBeingDragged(null);
    setIsDragging(false);
    if (!result.destination || isEqual(result.destination, result.source)) return;
    setShowDnd(false);

    const startIndex = result.source.index + pagination.pageIndex * pagination.pageSize;
    const endIndex = result.destination.index + pagination.pageIndex * pagination.pageSize;
    reorderRow(startIndex, endIndex);
    setShowDnd(true);
  };

  useEffect(() => {
    if (isEqual(data, customData)) return;
    setData([...customData]);
  }, [customData]);

  const onPaginationChange = (newPagination: { pageIndex: number; pageSize: number }) => {
    setPagination(newPagination);
  };

  const table = useReactTable({
    data: data,
    columns: [...customColumns],
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id, //good to have guaranteed unique row ids/keys for rendering
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    autoResetPageIndex: false,
    onPaginationChange,
    state: { pagination },
  });

  const handleMouseUp = async (mouseEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>, buttonId: string) => {
    const [destinationDroppableId, bulletinIdx] = buttonId.split("-");
    if (mouseEvent.button !== 0) {
      return;
    }
    await actualWork(destinationDroppableId, bulletinIdx, buttonId);
  };

  const actualWork = async (destinationDroppableId, bulletinIdx, buttonId) => {
    if (!rowBeingDragged || !destinationDroppableId || !isDragging) {
      return;
    }
    setShowDnd(false);
    const startIndex = rowBeingDragged.index + pagination.pageIndex * pagination.pageSize;
    switch (destinationDroppableId) {
      case "nextPage": {
        let endIndex = (pagination.pageIndex + 1 + 1) * pagination.pageSize - 1;
        endIndex = endIndex > data.length - 1 ? data.length - 1 : endIndex;
        await reorderRow(startIndex, endIndex);
        break;
      }
      case "previousPage": {
        let endIndex = pagination.pageIndex * pagination.pageSize - 1;
        endIndex = endIndex < 0 ? 0 : endIndex;
        await reorderRow(startIndex, endIndex);
        break;
      }
      case "bulletin": {
        let endIndex = (Number(bulletinIdx) + 1) * pagination.pageSize - 1;
        endIndex = endIndex > data.length - 1 ? data.length - 1 : endIndex;
        await reorderRow(startIndex, endIndex);
        break;
      }
      default: {
        console.debug("hadleMouseEnter", buttonId);
      }
    }
    setIsDragging(false);
    setRowBeingDragged(null);
    setShowDnd(true);
  };

  const paginationBulletsButtons = Array.from({ length: table.getPageCount() }, (_, i) => (
    <li key={i}>
      <button
        className={clsx(
          "btnTablePaginationBullet",
          pagination.pageIndex === i && "isCurrent",
          isDragging && "isDragging"
        )}
        onClick={() => table.setPageIndex(i)}
        title={`${i18("Go to page", "global.pagination.goToPage")} ${i + 1}`}
        onMouseUp={e => handleMouseUp(e, `bulletin-${i}`)}
        id={`bulletin-${i}`}
        ref={el => paginationBulletsRefs.current.push(el)}
      />
    </li>
  ));

  const getTableBody = hasDraggableButton => {
    const rows = table.getRowModel().rows;
    return rows.map((row, index) => (
      <Draggable key={`${tableId}-row-${row.id}`} draggableId={`${tableId}-row-${row.id}`} index={index}>
        {(provided, snapshot) => {
          return (
            <tr
              ref={provided.innerRef}
              {...provided.draggableProps}
              className={snapshot.isDragging ? "isDragging" : ""}
            >
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
              <td className="tableCta">
                <button
                  style={{ display: hasDraggableButton ? undefined : "none" }}
                  className="btn dragHandle"
                  data-variant="icon"
                  data-color="purple"
                  title={i18("Drag row", "dashboard.dndtable.dragRow")}
                  {...provided.dragHandleProps}
                  onTouchEnd={e => handleTouchEnd(e)}
                  onTouchMove={e => handleTouchMove(e)}
                >
                  <IcnHandle />
                </button>
              </td>
            </tr>
          );
        }}
      </Draggable>
    ));
  };

  const handleTouchMove = async e => {
    if (isDragging && e?.changedTouches?.length > 0) {
      const myLocation = e.changedTouches[0];
      const realTarget = document.elementFromPoint(myLocation.clientX, myLocation.clientY);
      if (
        nextButtonRef.current === realTarget ||
        nextButtonRef.current === realTarget?.parentElement ||
        nextButtonRef.current === realTarget?.parentElement?.parentElement
      ) {
        nextButtonRef.current.classList.add("hover");
      } else {
        nextButtonRef.current.classList.remove("hover");
      }

      if (
        previousButtonRef.current === realTarget ||
        previousButtonRef.current === realTarget?.parentElement ||
        previousButtonRef.current === realTarget?.parentElement?.parentElement
      ) {
        previousButtonRef.current.classList.add("hover");
      } else {
        previousButtonRef.current.classList.remove("hover");
      }

      if (paginationBulletsRefs.current.includes(realTarget)) {
        const idx = paginationBulletsRefs.current.indexOf(realTarget);
        paginationBulletsRefs.current[idx]?.classList?.add("hover");
      } else {
        paginationBulletsRefs.current.forEach(el => el?.classList?.remove("hover"));
      }
    }
  };

  const handleTouchEnd = async e => {
    if (isDragging && e?.changedTouches?.length > 0) {
      const myLocation = e.changedTouches[0];
      const realTarget = document.elementFromPoint(myLocation.clientX, myLocation.clientY);
      if (
        nextButtonRef.current === realTarget ||
        nextButtonRef.current === realTarget?.parentElement ||
        nextButtonRef.current === realTarget?.parentElement?.parentElement
      ) {
        await actualWork("nextPage", null, "nextPage");
      }
      if (
        previousButtonRef.current === realTarget ||
        previousButtonRef.current === realTarget?.parentElement ||
        previousButtonRef.current === realTarget?.parentElement?.parentElement
      ) {
        await actualWork("previousPage", null, "previousPage");
      }
      if (paginationBulletsRefs.current.includes(realTarget)) {
        const idx = paginationBulletsRefs.current.indexOf(realTarget);
        await actualWork("bulletin", idx, `bulletin-${idx}`);
      }
    }
  };

  return (
    <>
      <section className={`dashboardGridItemSectionTable`}>
        {isLoading && <CustomLoader extraClassName="loader" size="sm" />}
        <div className="gridContainer">
          {isLoading && (
            <div
              className="stackedItems"
              style={{
                minWidth: `${lockedSize.width}px`,
                minHeight: `${lockedSize.height}px`,
              }}
            />
          )}
          {showDnd && (
            <div ref={dndRef} className="stackedItems">
              <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={handleOnDragStart}>
                <Droppable droppableId={tableId} direction="vertical">
                  {(provided, snapshot) => (
                    <table
                      className={clsx(
                        "table",
                        extraClassName && extraClassName,
                        mobileResponsiveStyling && mobileResponsiveStyling,
                        snapshot.isDraggingOver && "isDragging",
                        isLoading && "makeTransparent"
                      )}
                    >
                      <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                              <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header, header.getContext())}
                              </th>
                            ))}
                            <th className="tableCta">
                              {/*temporary fix for accessibility*/}
                              <span className="visuallyHidden">{i18("Drag", "dashboard.dndtable.drag")}</span>
                              {/*<Button*/}
                              {/*  btnVariant="icon"*/}
                              {/*  btnColor="purple"*/}
                              {/*  icon={<RotateCcw />}*/}
                              {/*  title="Reset table"*/}
                              {/*  onClick={rerender}*/}
                              {/*/>*/}
                            </th>
                          </tr>
                        ))}
                      </thead>
                      <tbody ref={provided.innerRef} {...provided.droppableProps}>
                        {getTableBody(hasDraggableButton)}
                        {provided.placeholder}
                      </tbody>
                    </table>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
        </div>
      </section>

      {hasManageAssetsButton && <MyAssetsManageTokensModal asset={null} isOnlyIcon={false} />}

      {table.getPageCount() > 1 && (
        <section className="dashboardGridItemsSectionTablePagination">
          <Button
            ref={previousButtonRef}
            extraClassName={clsx("btnTablePagination previous", isDragging && "isDragging")}
            title={i18("Previous page", "global.pagination.previousPage")}
            btnVariant="icon"
            id="previousPage"
            btnColor="dark-medium"
            icon={<ChevronLeft />}
            onClick={table.previousPage}
            disabled={!table.getCanPreviousPage()}
            onMouseUp={e => {
              // noinspection JSIgnoredPromiseFromCall
              handleMouseUp(e, "previousPage");
            }}
          />

          <ul className="tablePaginationBulletBtnList">{paginationBulletsButtons.map(bullet => bullet)}</ul>

          <Button
            ref={nextButtonRef}
            extraClassName={clsx("btnTablePagination next", isDragging && "isDragging")}
            title={i18("Next page", "global.pagination.nextPage")}
            btnVariant="icon"
            id="nextPage"
            btnColor="dark-medium"
            icon={<ChevronRight />}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            onMouseUp={e => {
              // noinspection JSIgnoredPromiseFromCall
              handleMouseUp(e, "nextPage");
            }}
          />
        </section>
      )}
    </>
  );
}

export default DashboardMultiDndPaginationTable;
