import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import { Slide, ToastContainer } from "react-toastify";
import clsx from "clsx";
import { extend } from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { useEffectUnsafe } from "./hooks/useEffectUnsafe";
import BackgroundToastSender from "./components/BackgroundToastSender";
import { useGlobalModalContext } from "./providers/GlobalModalProvider";
import { getStatusCounters } from "./api/misc";
import { socket } from "./config/socket";
import { IAsset, IContract, IFarm, IPool } from "./utils/interfaces";
import AppRoutes from "Routes";
import { connectWalletWithDispatch } from "state/wallet/walletThunks";

import { getListOfAssets, getListOfPools } from "api/pools";
import {
  setLoadingWallet,
  updateAssets,
  updateChains,
  updateContracts,
  updateFarms,
  updatePools,
  updateStatusCounters,
  updateWsAssetFields,
  updateWsContractFields,
  updateWsFarmFields,
  updateWsPoolFields,
} from "state/wallet/walletSlice";
import { getListOfContracts } from "api/airdrop";
import { getChains } from "api/chains";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "state/persist";
import { useAppDispatch } from "state";
import { computeRouterHops } from "utils/swapScripts/preComputedRouterHops";
import { getListOfFarms } from "./api/farms";

extend(localizedFormat);

function App() {
  const dispatch = useAppDispatch();
  const { showModal } = useGlobalModalContext();
  // requests that are only triggered on page load
  const getInitApiInfo = async () => {
    try {
      const pools: Record<string, IPool> = {};
      const farms: Record<string, IFarm> = {};
      const assets: Record<string, IAsset> = {};
      const contracts: Record<string, IContract> = {};

      const [assetList, poolList, farmList, contractList, chainsInfo, statusCounters] = await Promise.all([
        getListOfAssets(),
        getListOfPools(),
        getListOfFarms(),
        getListOfContracts(),
        getChains(),
        getStatusCounters(),
      ]);

      poolList.data?.forEach(pool => {
        pools[pool.address] = pool;
      });

      farmList.data?.forEach(farm => {
        farms[farm.address] = farm;
      });

      assetList.data?.forEach(asset => {
        if (asset.isNative) {
          assets[asset.denom] = asset;
        } else {
          assets[asset.address] = asset;
        }
      });

      contractList.data?.forEach(contract => {
        contracts[contract.label] = contract;
      });

      computeRouterHops(pools);
      dispatch(updateAssets(assets));
      dispatch(updatePools(pools));
      dispatch(updateFarms(farms));
      dispatch(updateContracts(contracts));
      dispatch(updateChains(chainsInfo.data));
      dispatch(updateStatusCounters(statusCounters.data.statusCounters));

      // start listening for poolUpdate websocket events from API
      socket.on("poolUpdate", data => {
        try {
          dispatch(updateWsPoolFields(JSON.parse(data)));
        } catch (e) {
          console.log(e);
        }
      });

      socket.on("assetUpdate", data => {
        try {
          dispatch(updateWsAssetFields(JSON.parse(data)));
        } catch (e) {
          console.log(e);
        }
      });

      socket.on("contractUpdate", data => {
        try {
          dispatch(updateWsContractFields(JSON.parse(data)));
        } catch (e) {
          console.log(e);
        }
      });

      socket.on("farmUpdate", data => {
        try {
          dispatch(updateWsFarmFields(JSON.parse(data)));
        } catch (e) {
          console.log(e);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  // requests that get repeated
  const getRefreshData = async () => {
    try {
      const pools = {};
      const assets = {};

      const [assetList, poolList] = await Promise.all([getListOfAssets(), getListOfPools()]);
      poolList.data?.forEach(pool => {
        pools[pool.address] = pool;
      });

      assetList.data?.forEach(asset => {
        if (asset.isNative) {
          assets[asset.denom] = asset;
        } else {
          assets[asset.address] = asset;
        }
      });

      computeRouterHops(pools);
      dispatch(updateAssets(assets));
      dispatch(updatePools(pools));
    } catch (e) {
      console.log(e);
    }
  };

  useEffectUnsafe(() => {
    const onHeaderLoad = async () => {
      await getInitApiInfo();

      if (loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) {
        await dispatch(connectWalletWithDispatch({ showModal })).unwrap();
      } else {
        dispatch(setLoadingWallet(false));
      }
    };

    onHeaderLoad();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //loader on index.html
    const el = document.querySelector(".indexCustomLoader");
    if (el) {
      el.remove();
    }

    const interval = setInterval(() => {
      getRefreshData();
    }, 90000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <AppRoutes />
        <ToastContainer
          className={clsx("customToastContainer")}
          bodyClassName="customToastBody"
          toastClassName="customToast"
          position="bottom-right"
          hideProgressBar={true}
          draggable={false}
          transition={Slide}
          theme="dark"
        />
        <BackgroundToastSender />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
