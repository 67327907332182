import clsx from "clsx";
// import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import LogoGravitate from "@axvdex/assets/images/help/dashboard/logo-gravitate-white.svg";
import { loadState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";
import { useAppSelector } from "@axvdex/state";
import { selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import MainLayout from "../layouts/MainLayout";
import styles from "../styles/Help.module.scss";
import useLanguage from "../hooks/useLanguage";

function Help() {
  const { i18 } = useLanguage();
  const walletInfo = useAppSelector(selectWalletInfo);
  const [walletName, setWalletName] = useState(``);

  const getWallet = () => {
    const walletKind = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
    const walletName = walletKind.charAt(0).toUpperCase() + walletKind.slice(1);
    setWalletName(walletName);
  };

  useEffect(() => {
    getWallet();
  }, [walletInfo]);

  const ContentTable = [
    {
      name: i18("Main Pages", "help.contentTable.name"),
      contents: [
        {
          section: i18("Dashboard", "help.contentTable.contents.section.dashboard"),
          sectionUrl: "/help/dashboard",
          sectionUrlText: i18("Learn More", "help.contentTable.contents.sectionUrlText.dashboard"),
          subSections: [
            {
              subSectionName: i18("My Assets", "help.contentTable.contents.subSections.subSectionName.myAssets"),
              subSectionUrl: "/help/dashboard#myAssets",
              nestedSubSections: [
                {
                  name: i18(
                    "Asset information",
                    "help.contentTable.contents.subSections.nestedSubSections.name.myAssetsAssetInformation"
                  ),
                  url: "/help/dashboard#myAssetsAssetInformation",
                },
                {
                  name: i18(
                    "Manage xAssets",
                    "help.contentTable.contents.subSections.nestedSubSections.name.myAssetsGetxAsset"
                  ),
                  url: "/help/dashboard#myAssetsGetxAsset",
                },
                {
                  name: i18(
                    "Manage unminting",
                    "help.contentTable.contents.subSections.nestedSubSections.name.myAssetsUnminting"
                  ),
                  url: "/help/dashboard#myAssetsUnminting",
                },
                {
                  name: i18(
                    "Using other networks",
                    "help.contentTable.contents.subSections.nestedSubSections.name.myAssetsUsingIbc"
                  ),
                  url: "/help/dashboard#myAssetsUsingIbc",
                },
                {
                  name: i18(
                    "Favouriting and dragging",
                    "help.contentTable.contents.subSections.nestedSubSections.name.myAssetsFavouritingDragging"
                  ),
                  url: "/help/dashboard#myAssetsFavouritingDragging",
                },
              ],
            },
            {
              subSectionName: i18("My Pools", "help.contentTable.contents.subSections.subSectionName.myPools"),
              subSectionUrl: "/help/dashboard#myPools",
              nestedSubSections: [
                {
                  name: i18(
                    "Messages",
                    "help.contentTable.contents.subSections.nestedSubSections.name.myPoolsMessages"
                  ),
                  url: "/help/dashboard#myPoolsMessages",
                },
              ],
            },
            {
              subSectionName: i18("My Gravitate", "help.contentTable.contents.subSections.subSectionName.myGravitate"),
              subSectionUrl: "/help/dashboard#myGravitate",
              suffix: (
                <span className="iconWrapper">
                  {/*0: (, 1: <img/>, 2: <p>, 3: GRVT8, 4: )*/}
                  <Trans i18nKey="help.grvt8Icon">
                    {[
                      "(",
                      <img key="img" src={LogoGravitate} alt="GRVT8" loading="lazy" className="isIcon GRVT8" />,
                      <span key="nbsp">&nbsp;</span>,
                      "GRVT8",
                      ")",
                    ]}
                  </Trans>
                </span>
              ),
            },
            {
              subSectionName: i18("My History", "help.contentTable.contents.subSections.subSectionName.myHistory"),
              subSectionUrl: "/help/dashboard#myHistory",
            },
            {
              subSectionName: i18("The Market", "help.contentTable.contents.subSections.subSectionName.theMarket"),
              subSectionUrl: "/help/dashboard#theMarket",
            },
            {
              subSectionName: i18(
                "Dashboard customisation",
                "help.contentTable.contents.subSections.subSectionName.dashboardCustomisation"
              ),
              subSectionUrl: "/help/dashboard#dashboardCustomisation",
            },
          ],
        },
        {
          section: i18("Trade", "help.contentTable.contents.section.trade"),
          sectionUrl: "/help/trade",
          sectionUrlText: i18("Learn More", "help.contentTable.contents.sectionUrlText.trade"),
          subSections: [
            {
              subSectionName: i18(
                "Trading assets",
                "help.contentTable.contents.subSections.subSectionName.tradingAssets"
              ),
              subSectionUrl: "/help/trade#tradingAssets",
            },
            {
              subSectionName: i18(
                "Minting X assets",
                "help.contentTable.contents.subSections.subSectionName.mintingAssets"
              ),
              subSectionUrl: "/help/trade#mintingAssets",
            },
          ],
        },
        {
          section: i18("Pool", "help.contentTable.contents.section.pool"),
          sectionUrl: "/help/pool",
          sectionUrlText: i18("Learn More", "help.contentTable.contents.sectionUrlText.pool"),
          subSections: [
            {
              subSectionName: i18("Pools", "help.contentTable.contents.subSections.subSectionName.pools"),
              subSectionUrl: "/help/pool#pools",
              nestedSubSections: [
                {
                  name: i18(
                    "Search and display",
                    "help.contentTable.contents.subSections.nestedSubSections.name.poolsPoolSorting"
                  ),
                  url: "/help/pool#poolsPoolSorting",
                },
              ],
            },
            {
              subSectionName: i18(
                "Standard Pools",
                "help.contentTable.contents.subSections.subSectionName.standardPools"
              ),
              subSectionUrl: "/help/pool#standardPools",
              nestedSubSections: [
                {
                  name: i18(
                    "Deposit",
                    "help.contentTable.contents.subSections.nestedSubSections.name.standardPoolsDeposit"
                  ),
                  url: "/help/pool#standardPoolsDeposit",
                },
                {
                  name: i18(
                    "Withdraw",
                    "help.contentTable.contents.subSections.nestedSubSections.name.standardPoolsWithdraw"
                  ),
                  url: "/help/pool#standardPoolsWithdraw",
                },
              ],
            },
            {
              subSectionName: i18("Stable Pools", "help.contentTable.contents.subSections.subSectionName.stablePools"),
              subSectionUrl: "/help/pool#stablePools",
              nestedSubSections: [
                {
                  name: i18(
                    "Deposit",
                    "help.contentTable.contents.subSections.nestedSubSections.name.stablePoolsDeposit"
                  ),
                  url: "/help/pool#stablePoolsDeposit",
                },
                {
                  name: i18(
                    "Withdraw",
                    "help.contentTable.contents.subSections.nestedSubSections.name.stablePoolsWithdraw"
                  ),
                  url: "/help/pool#stablePoolsWithdraw",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: i18("Other", "help.contentTable.name.other"),
      contents: [
        {
          section: i18("Wallet", "help.contentTable.contents.section.wallet"),
          sectionUrl: "/help/wallet",
          sectionUrlText: i18("Learn More", "help.contentTable.contents.sectionUrlText.wallet"),
          subSections: [
            {
              subSectionName: i18(
                "Getting started",
                "help.contentTable.contents.subSections.subSectionName.gettingStarted"
              ),
              subSectionUrl: "/help/wallet#gettingStarted",
            },
            {
              subSectionName: i18(
                "Connect a wallet",
                "help.contentTable.contents.subSections.subSectionName.connectWallet"
              ),
              subSectionUrl: "/help/wallet#connectWallet",
            },
          ],
        },
        {
          section: i18("Tokens", "help.contentTable.contents.section.tokens"),
          sectionUrl: "/help/tokens",
          sectionUrlText: i18("Learn More", "help.contentTable.contents.sectionUrlText.tokens"),
          subSections: [
            {
              subSectionName: i18(
                `Add tokens to ${walletName} wallet`,
                "help.contentTable.contents.subSections.subSectionName.addTokensToWallet",
                { walletName }
              ),
              subSectionUrl: "/help/tokens#addTokensToWallet",
            },
            {
              subSectionName: i18(
                `How to identify layer 2 tokens`,
                "help.contentTable.contents.subSections.subSectionName.aboutLayer2Tokens"
              ),
              subSectionUrl: "/help/tokens#aboutLayer2Tokens",
            },
          ],
        },
      ],
    },
  ];

  return (
    <MainLayout
      pageClass={clsx(styles.help, "help", "helpContentTable")}
      headTitle={i18("Help", "help.headTitle")}
      headDescription={i18("Help for Astrovault", "help.headDescription")}
    >
      <div className="helpPageWrapper">
        <div className="sectionWrapperSm">
          <section className="helpSectionHeader helpContentTableHeader">
            <h1>{i18("Help", "help.section.title")}</h1>

            <p className="textGrey">
              <Trans i18nKey="help.section.infoText">
                Welcome to a place of DEFI,
                <br />
                the feel of this place, though familiar, is an evolution, an evolution proudly brought to Archway from
                team Astrovault.
              </Trans>
            </p>
          </section>

          <section className={clsx("helpContentTable")}>
            {ContentTable.map((section, idx) => {
              return (
                <section className="helpContentTableSubSection" key={idx}>
                  <h2 className="h4 helpContentTableSubSectionTitle">{section.name}</h2>

                  <div className="withGradientBorder">
                    <ul className="helpContentTableList">
                      {section.contents.map((item, idx) => {
                        return (
                          <li key={idx}>
                            <div className="flexbox">
                              <span>{item.section}</span>

                              <Link
                                to={item.sectionUrl}
                                className="btn"
                                data-color="gradient"
                                data-variant="link"
                                data-size="sm"
                              >
                                <span className="btnWrapper">
                                  <span className="btnText">{i18("Learn more", "help.learnMore")}</span>
                                </span>
                              </Link>
                            </div>

                            {item.subSections && (
                              <ul className="helpContentTableSubList">
                                {item.subSections.map((subItem, idx) => (
                                  <li key={idx}>
                                    <HashLink
                                      className="btn"
                                      data-variant="link"
                                      data-size="sm"
                                      to={subItem.subSectionUrl}
                                    >
                                      {subItem.subSectionName}
                                      {subItem.suffix}
                                    </HashLink>

                                    {subItem.nestedSubSections && (
                                      <ul>
                                        {subItem.nestedSubSections.map((nestedSubItem, nestedSubItemIdx) => (
                                          <li key={nestedSubItemIdx}>
                                            <HashLink
                                              className="btn"
                                              data-variant="link"
                                              data-size="sm"
                                              to={nestedSubItem.url}
                                            >
                                              {nestedSubItem.name}
                                              {subItem.suffix}
                                            </HashLink>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </section>
              );
            })}
          </section>
        </div>
      </div>
    </MainLayout>
  );
}

export default Help;
