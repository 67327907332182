import { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
// import { Trans } from "react-i18next";
import { NumericFormat } from "react-number-format";
// import { AlertCircle, ArrowDown, Eye } from "react-feather";
import { ChevronLeft, ChevronRight, List } from "react-feather";
import BigNumber from "bignumber.js";
import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { Tooltip } from "react-tooltip";
import Button from "@axvdex/components/common/Button";
import CustomModal from "@axvdex/components/common/CustomModal";
import { useAppSelector } from "@axvdex/state";
import { selectAssets, selectSalesBids, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
// import { fetchWrapper } from "@axvdex/api";
import imgSanitize from "@axvdex/utils/imgSanitize";
import useLanguage from "@axvdex/hooks/useLanguage";
import { IOutbidSale } from "@axvdex/utils/interfaces";
import { ReactComponent as IcnTrophy } from "../../assets/icons/icn-trophy.svg";

// import { ReactComponent as IcnSwap } from "../../assets/icons/icn-exchange.svg";
// import { ReactComponent as IcnGravitate } from "../../assets/tokens/logo-grvt8.svg";
// import { ReactComponent as LogoArch } from "../../assets/logos/logo-arch.svg";
import { ListItemsProps } from "../form-element/CustomSelect";
// import DashboardPaginationTable from "../DashboardPaginationTable";
// import { ColumnDef } from "@tanstack/react-table";
import styles from "../../styles/Outbid.module.scss";

interface SaleMyTicketsModalProps {
  outbidSale: IOutbidSale;
  selectedSale: ListItemsProps;
  paginationMyBidsModal: {
    pageIndex: number;
    pageSize: number;
  };
  setPaginationMyBidsModal: React.Dispatch<
    React.SetStateAction<{
      pageIndex: number;
      pageSize: number;
    }>
  >;
}

function SaleMyTicketsModal({
  outbidSale,
  selectedSale,
  paginationMyBidsModal,
  setPaginationMyBidsModal,
}: SaleMyTicketsModalProps) {
  const { i18 } = useLanguage();
  const walletInfo = useAppSelector(selectWalletInfo);
  const assets = useAppSelector(selectAssets);
  const salesBids = useAppSelector(selectSalesBids);

  const [visualiserModalIsOpen, setVisualiserModalIsOpen] = useState(false);
  const handleToggleVisualiserModal = useCallback(() => {
    setVisualiserModalIsOpen(visualiserModalIsOpen => !visualiserModalIsOpen);
  }, []);
  const handleCloseVisualiserModal = () => setVisualiserModalIsOpen(false);

  const bonusAmountMsg = () => {
    // if the sale is not finished, a message with all bonus amount will show up if the user has any bonus
    if (
      ["WhitelistBidding", "Bidding", "BonusBidding"].includes(
        outbidSale.salesState[selectedSale.value].sale_state.status
      )
    ) {
      const amount = BigNumber(salesBids[selectedSale.value].eligible_bonus_amount)
        .div(Math.pow(10, 6))
        .decimalPlaces(6)
        .toString(10);
      return "0" !== amount ? (
        <small>
          {i18(
            "Your potential first movers bonus winnings is " + amount + " tickets",
            "sales.myTicketsModal.table.bonusAmount",
            {
              amount,
            }
          )}
        </small>
      ) : null;
    }
  };

  const defaultColumns = useMemo(() => {
    return [
      {
        accessorKey: "date",
        header: () => <span>{i18("Date", "dashboard.history.messages.table.date")}</span>,
        cell: ({ row }) => {
          return (
            <span>
              {new Date(row.original.timestamp * 1000).toLocaleDateString()}
              <br />
              {new Date(row.original.timestamp * 1000).toLocaleTimeString()}
            </span>
          );
        },
      },
      {
        id: "asset",
        header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            {i18("Asset Spent", "sales.myTicketsModal.table.header.assetSpent")}
          </div>
        ),
        cell: ({ row }) => {
          if (!row.original.bid_asset.state) return null;
          return (
            <div
              className="flexbox"
              style={{
                justifyContent: "center",
              }}
            >
              <span
                className="tokenIcon"
                title={row.original.bid_asset.state.symbol}
                aria-label={row.original.bid_asset.state.symbol}
              >
                <img
                  src={imgSanitize(row.original.bid_asset.state.symbol)}
                  alt={`${row.original.bid_asset.state.symbol}`}
                  style={{ width: "2em" }}
                />
              </span>
              <span>
                {BigNumber(row.original.bid_asset.amount)
                  .div(Math.pow(10, row.original.bid_asset.state.decimals))
                  .decimalPlaces(4)
                  .toString(10)}
              </span>
            </div>
          );
        },
      },
      {
        id: "ticket",
        header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            {i18("Tickets", "sales.myTicketsModal.table.header.tickets")}
          </div>
        ),
        cell: ({ row }) => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            {BigNumber(row.original.ticket_amount).div(Math.pow(10, 6)).decimalPlaces(4).toString(10)}
            <br />
            {BigNumber(row.original.bonus_elegible_amount).gt("0") && (
              <small
                data-tooltip-id="tooltip_bonusAmount"
                className="textGrey"
                style={{
                  textDecoration:
                    ["WhitelistBidding", "Bidding", "BonusBidding"].includes(
                      outbidSale.salesState[selectedSale.value].sale_state.status
                    ) || row.original.is_bonus_amount_obtainable
                      ? undefined
                      : "line-through",
                }}
              >
                (+{BigNumber(row.original.bonus_elegible_amount).div(Math.pow(10, 6)).decimalPlaces(4).toString(10)})
              </small>
            )}
            <Tooltip
              id="tooltip_bonusAmount"
              place="bottom"
              style={{ backgroundColor: "var(--dark)", color: "white", zIndex: 3 }}
              render={() => (
                <div>
                  <span>
                    {["WhitelistBidding", "Bidding", "BonusBidding"].includes(
                      outbidSale.salesState[selectedSale.value].sale_state.status
                    ) || row.original.is_bonus_amount_obtainable
                      ? i18("First movers bonus tickets", "sales.myTicketsModal.table.tooltip.bonusAmount")
                      : i18(
                          "No first movers reward because sale closed before bonus time",
                          "sales.myTicketsModal.table.tooltip.closedBeforeBonusTime"
                        )}
                  </span>
                </div>
              )}
            />
            <br />
            {row.original.incentives && (
              <>
                {" "}
                <IcnTrophy
                  data-tooltip-id="tooltip_incentives"
                  style={{ width: "1.5em", color: "yellow", marginTop: ".5em" }}
                />
                <Tooltip
                  id="tooltip_incentives"
                  place="right"
                  style={{ backgroundColor: "var(--dark)", color: "white", zIndex: 3 }}
                  render={() => (
                    <div>
                      {(row.original.incentives.incentives || row.original.incentives).map(
                        (incentive: any, index: number) => (
                          <>
                            {incentive.asset && (
                              <div className="inlineFlexbox" style={{ marginLeft: ".5em" }}>
                                <span style={{ fontSize: "1em", marginRight: ".25em" }}>
                                  {BigNumber(incentive.asset.amount)
                                    .div(
                                      Math.pow(
                                        10,
                                        assets[
                                          incentive.asset.info.token?.contract_addr ||
                                            incentive.asset.info.native_token?.denom
                                        ].decimals
                                      )
                                    )
                                    .toString(10)}
                                </span>
                                <img
                                  style={{ width: "1.5em" }}
                                  className="tokenIcon"
                                  src={imgSanitize(
                                    assets[
                                      incentive.asset.info.token?.contract_addr ||
                                        incentive.asset.info.native_token?.denom
                                    ]?.symbol
                                  )}
                                  alt={assets[incentive[1]]?.symbol}
                                />
                              </div>
                            )}
                            {incentive.nft && <span style={{ fontSize: "1em", marginLeft: ".5em" }}>NFT</span>}
                          </>
                        )
                      )}
                    </div>
                  )}
                />
              </>
            )}
          </div>
        ),
      },
    ];
  }, [i18]);

  const [columns] = useState(() => [...defaultColumns]);
  const [data, setData] = useState(() => []);

  useEffect(() => {
    if (assets && salesBids && selectedSale && salesBids[selectedSale.value]) {
      setData(
        salesBids[selectedSale.value].bids_with_details.map((bid: any) => {
          const incentives = salesBids[selectedSale.value].bids_with_incentives?.find(
            incentives => incentives.bid_id === bid.id
          );
          return {
            ...bid,
            is_bonus_amount_obtainable: salesBids[selectedSale.value].is_bonus_amount_obtainable,
            bid_asset: {
              ...bid.bid_asset,
              state: assets[bid.bid_asset.info.native_token?.denom || bid.bid_asset.info.token?.contract_addr],
            },
            incentives,
          };
        })
      );
    }
  }, [salesBids, selectedSale, assets]);

  const table = useReactTable({
    data,
    state: {
      pagination: paginationMyBidsModal,
    },
    columns,
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPaginationMyBidsModal,
    getCoreRowModel: getCoreRowModel(),
    autoResetAll: false,
  });

  const paginationBulletsButtons = Array.from({ length: table.getPageCount() }, (_, i) => (
    <li key={i}>
      <button
        className={clsx("btnTablePaginationBullet", i === paginationMyBidsModal.pageIndex && "isCurrent")}
        onClick={() => table.setPageIndex(i)}
        title={`${i18("Go to page", "global.pagination.goToPage")} ${i + 1}`}
      />
    </li>
  ));

  const showNFTWinner = () => {
    if (!outbidSale.salesState || !selectedSale || !outbidSale.salesState[selectedSale.value]) return null;
    const index = outbidSale.salesState[selectedSale.value].top_bidders.findIndex(
      topBidder => topBidder.address === walletInfo.walletAddress
    );
    if (-1 === index) return null;

    return (
      <div className="tradeVisualiserTotalGridWrapper">
        <div className="tradeVisualiserTotalGriditem">
          <>
            <small>{i18("NFT Winner", "sales.myTicketsModal.table.header.nftWinner")}</small>
            <span className={"inlineFlexbox"}>
              {0 === index ? "1st place" : 1 === index ? "2nd place" : "3rd place"}
            </span>
          </>
        </div>
      </div>
    );
  };

  const showLastBidWinner = () => {
    if (!salesBids || !selectedSale || !salesBids[selectedSale.value]) return null;
    if (!salesBids[selectedSale.value].obtainable_last_bidder_amount) return null;

    return (
      <div className="tradeVisualiserTotalGridWrapper">
        <div className="tradeVisualiserTotalGriditem">
          <>
            <small>{i18("Last Bid Winner", "sales.myTicketsModal.table.header.lastBidWinner")}</small>
            <span className={"inlineFlexbox"}>
              <NumericFormat
                className="ticketAmount"
                displayType="text"
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={4}
                value={BigNumber(salesBids[selectedSale.value].obtainable_last_bidder_amount)
                  .div(Math.pow(10, 6))
                  .toString(10)}
              />
            </span>
          </>
        </div>
      </div>
    );
  };

  return (
    <>
      <Button
        title={"Bid list"}
        icon={<List />}
        style={{
          minHeight: "fit-content",
          //border: ".05em solid white",
        }}
        onClick={handleToggleVisualiserModal}
      >
        this sale
      </Button>

      {/* <Button
        title={"Bid list"}
        btnVariant="icon"
        btnColor="dark-medium"
        icon={<List />}
        btnSize="sm"
        onClick={handleToggleVisualiserModal}
      /> */}

      {selectedSale && salesBids && salesBids[selectedSale.value] && (
        <CustomModal
          isOpen={visualiserModalIsOpen}
          onClose={handleCloseVisualiserModal}
          extraClassName={clsx(styles.sales, "sales")}
        >
          <section className="sectionModalHeader">
            <h2 className="h2">{i18("My Tickets - ", "sales.myTicketsModal.title") + selectedSale.label}</h2>
          </section>
          {!salesBids || 0 === salesBids[selectedSale.value].bids.length ? (
            <>
              <span>{i18("No tickets bought on this sale...", "sales.myTicketsModal.noTicketsBought")}</span>
            </>
          ) : (
            <>
              <div
                style={{ textAlign: "center", marginBottom: "1.5em", display: "flex", placeContent: "space-around" }}
              >
                <div className="tradeVisualiserTotalGridWrapper">
                  <div className="tradeVisualiserTotalGriditem">
                    <>
                      <small>{i18("Total Tickets Amount", "sales.myTicketsModal.table.header.totalTickets")}</small>
                      <span className={"inlineFlexbox"}>
                        <NumericFormat
                          className="ticketAmount"
                          displayType="text"
                          thousandSeparator=","
                          decimalSeparator="."
                          decimalScale={4}
                          value={BigNumber(salesBids[selectedSale.value].total_final_ticket_amount)
                            .div(Math.pow(10, 6))
                            .toString(10)}
                        />
                      </span>
                    </>
                  </div>
                </div>
                {showLastBidWinner()}
                {showNFTWinner()}
              </div>
              <div style={{ textAlign: "center", margin: "1em" }}>{bonusAmountMsg()}</div>
              <table className={clsx("table", "statsTable")}>
                <thead>
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map(cell => (
                        <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              {1 < table.getPageCount() && (
                <section className="statisticsGridItemsSectionTablePagination" style={{ paddingTop: "1rem" }}>
                  <Button
                    extraClassName="btnTablePagination previous"
                    title={i18("Previous page", "global.pagination.previousPage")}
                    btnVariant="icon"
                    btnColor="dark-medium"
                    icon={<ChevronLeft />}
                    onClick={table.previousPage}
                    disabled={!table.getCanPreviousPage()}
                  />

                  <ul className="tablePaginationBulletBtnList">{paginationBulletsButtons.map(bullet => bullet)}</ul>

                  <Button
                    extraClassName="btnTablePagination next"
                    title={i18("Next page", "global.pagination.nextPage")}
                    btnVariant="icon"
                    btnColor="dark-medium"
                    icon={<ChevronRight />}
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  />
                </section>
              )}
            </>
          )}
        </CustomModal>
      )}
    </>
  );
}

export default SaleMyTicketsModal;
