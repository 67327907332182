import { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { useReactTable, getCoreRowModel, flexRender, ColumnDef } from "@tanstack/react-table";
import { NumericFormat } from "react-number-format";
import BigNumber from "bignumber.js";
import {
  formatBalance,
  fromHumanAmountToTokenBalance,
  humanizeAmount,
  maxDecimalsToDisplay,
} from "@axvdex/utils/formatNumber";
import { hybridLPTokenRepresentation } from "@axvdex/utils/unstakeSimulation";
import { selectAssetBalances, selectAssets, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import {
  executePoolAction,
  increasePoolAllowance,
  updateLpStakingBalance,
  updateNativeBalance,
  updateTokenBalance,
} from "@axvdex/state/wallet/walletThunks";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import Button from "@axvdex/components/common/Button";
import stakeSimulation, { hybridStakeCheckUnbalancingThreshold } from "@axvdex/utils/swapScripts/stakeSimulation";
import imgSanitize from "@axvdex/utils/imgSanitize";
import useLanguage from "@axvdex/hooks/useLanguage";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import CustomNumericInput from "../form-element/CustomNumericInput";
import CustomLoader from "../common/CustomLoader";

interface PoolCardStandardDepositTableProps {
  id: string;
  symbol: string;
  image: string;
  total: number;
  deposited: {
    amount: number;
    usdConversion: number;
  };
  disabled_deposit_assets: Array<number>;
  allowanceLoading: boolean;
  allowance?: {
    allowance: string;
    expires: any;
  } | null;
  input: string;
  balance: number | null;
}

interface ComponentProps {
  poolData: any;
  [key: string]: any;
}

function PoolCardHybridDepositTable({ poolData }: ComponentProps) {
  const { i18 } = useLanguage();
  const assetBalances = useAppSelector(selectAssetBalances);
  const assets = useAppSelector(selectAssets);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState<PoolCardStandardDepositTableProps[]>([]);
  const [simulationValues, setSimulationValues] = useState<any>({});
  const [isTransactionStarts, setIsTransactionStarts] = useState(false);
  const dispatch = useAppDispatch();
  const walletInfo = useAppSelector(selectWalletInfo);

  useEffect(() => {
    update();
  }, [walletInfo.isConnected, assetBalances]);

  const update = async () => {
    // if there is no wallet connect and auto connect is enabled, we should show the loader as we are trying to auto connect
    if (!walletInfo.isConnected && loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) {
      setIsLoading(true);
      return;
    }
    if (data.length === 0) setIsLoading(true);
    // get asset balances of this pool that are not already in state
    const requests = [];
    for (const [i, poolAsset] of poolData.poolAssets.entries()) {
      if (!assetBalances[poolData.assetIDs[i]] && walletInfo.isConnected) {
        if (poolAsset.info.token) {
          requests.push(
            dispatch(
              updateTokenBalance({
                userAddress: walletInfo.walletAddress,
                tokenAddress: poolAsset.info.token.contract_addr,
              })
            )
          );
        } else {
          requests.push(
            dispatch(
              updateNativeBalance({ userAddress: walletInfo.walletAddress, denom: poolAsset.info.native_token.denom })
            )
          );
        }
      } else {
        requests.push(Promise.resolve());
      }
    }

    const poolAssetBalances = await Promise.all(requests);

    // get balance of this user on this pool
    let lpStakingBalance = assetBalances[poolData?.lp_staking];
    if (!assetBalances[poolData?.lp_staking] && walletInfo.isConnected) {
      lpStakingBalance = (
        await dispatch(
          updateLpStakingBalance({ userAddress: walletInfo.walletAddress, lpStakingAddress: poolData?.lp_staking })
        )
      ).payload;
    }

    // get allowances of this user for tokens on this pool
    const allowanceResponses = await Promise.all([
      //across all tokens on this pool, check if the allowance was already provided
      ...poolData.poolAssets.reduce((acc, asset) => {
        if (asset.info.token && walletInfo.isConnected) {
          const allowanceResponse = rpcClientQuerySmartContractWrapper(asset.info.token.contract_addr, {
            allowance: {
              owner: walletInfo.walletAddress,
              spender: poolData.address,
            },
          });

          acc = [...acc, allowanceResponse];
          return acc;
        }

        return [...acc, null];
      }, []),
    ]);

    const pPoolData = poolData.poolAssets.map((asset, i) => {
      return {
        id: poolData?.assetIDs[i],
        total: humanizeAmount(asset.amount, assets[poolData?.assetIDs[i]]?.decimals),
        symbol: assets[poolData?.assetIDs[i]]?.symbol,
        deposited: formatBalance(
          hybridLPTokenRepresentation((lpStakingBalance as string) || "0", poolData).refund_assets[i] || "0",
          assets[poolData?.assetIDs[i]]?.price,
          assets[poolData?.assetIDs[i]]?.decimals
        ),
        disabled_deposit_assets: poolData.disabled_actions
          ? poolData.disabled_actions.deposit_disabled_for_indexes
          : [],
        input: "",
        allowance: allowanceResponses[i],
        balance: poolAssetBalances[i]
          ? formatBalance(
              poolAssetBalances[i].payload,
              assets[poolData?.assetIDs[i]].price,
              assets[poolData?.assetIDs[i]].decimals
            ).amount || 0
          : formatBalance(
              assetBalances[poolData?.assetIDs[i]],
              assets[poolData?.assetIDs[i]].price,
              assets[poolData?.assetIDs[i]].decimals
            ).amount || 0,
      };
    });
    setData(pPoolData);
    setIsLoading(false);
  };

  const handleIncreaseAllowance = async (tokenAddress, index) => {
    setData(prevData => {
      const newData = [...prevData];
      newData[index].allowanceLoading = true;
      return newData;
    });

    const res = await dispatch(
      increasePoolAllowance({
        walletAddress: walletInfo.walletAddress,
        poolData,
        tokenAddress,
        i18,
      })
    );

    setData(prevData => {
      const newData = [...prevData];
      newData[index].allowanceLoading = false;
      newData[index].allowance = res.payload as { allowance: string; expires: any } | null;
      return newData;
    });
  };

  const handleHybridPoolInputChange = (amount, index) => {
    setData(prevData => {
      const amounts = prevData.map(row => row.input.replaceAll(",", ""));
      if (index !== null) {
        amounts[index] = amount.replaceAll(",", "");
      }
      const newData = [...prevData];
      const transformData = stakeSimulation(poolData, amounts);
      if (!transformData) {
        return newData.map((row, _) => {
          return { ...row, input: "" };
        });
      }
      const returnAmounts = transformData.returnAmounts;
      delete transformData.returnAmounts;
      setSimulationValues({ ...transformData });
      return newData.map((row, index) => {
        return { ...row, input: returnAmounts[index] };
      });
    });
  };

  const handleSubmit = async () => {
    setIsTransactionStarts(true);

    await dispatch(
      executePoolAction({
        walletAddress: walletInfo.walletAddress,
        poolData,
        simulationValues,
        isDeposit: true,
        i18,
      })
    );

    poolData.poolAssets.forEach((_, i) => {
      handleHybridPoolInputChange("0", i);
    });

    setIsTransactionStarts(false);
  };

  const defaultColumns = useMemo<ColumnDef<PoolCardStandardDepositTableProps>[]>(() => {
    return [
      {
        id: "token",
        header: () => <span>{i18("Token Balance", "poolCardTable.table.columns.token")}</span>,
        cell: ({ row }) => (
          <div className="flexbox">
            <span className="tokenIcon" title={row.original.symbol} aria-label={row.original.symbol}>
              <img
                src={imgSanitize(row.original.symbol)}
                alt={row.original.symbol}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  dispatch(
                    updateTokenBalance({
                      userAddress: walletInfo.walletAddress,
                      tokenAddress: row.original.id,
                    })
                  )
                }
              />
            </span>

            {row.original.balance !== null && <span>{row.original.balance}</span>}
          </div>
        ),
      },
      {
        id: "deposited",
        accessorKey: "deposited",
        header: () => <span>{i18("Deposited", "poolCardTable.table.columns.deposited")}</span>,
        cell: ({ row }) => (
          <div className="flexbox deposited">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={maxDecimalsToDisplay(BigNumber(row.original.deposited.amount || 0))}
              value={BigNumber(row.original.deposited.amount || 0)
                .decimalPlaces(
                  maxDecimalsToDisplay(BigNumber(row.original.deposited.amount || 0)),
                  BigNumber.ROUND_FLOOR
                )
                .toString()}
            />

            <NumericFormat
              className="tableInputValueConversion"
              displayType="text"
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              prefix={"($"}
              suffix={")"}
              value={BigNumber(row.original.deposited.usdConversion || 0)
                .decimalPlaces(2, BigNumber.ROUND_FLOOR)
                .toString()}
            />
          </div>
        ),
      },
      {
        id: "actions",
        header: () => <span className="visuallyHidden">{i18("Actions", "poolCardTable.table.columns.actions")}</span>,
        cell: ({ row }) => {
          return (
            <>
              {row.original.allowance?.allowance !== "0" || assets[row.original.id].isNative ? (
                <>
                  {!row.original.disabled_deposit_assets.includes(row.index) && (
                    <>
                      <div className="formGroupInline">
                        {row.original.balance !== null && (
                          <Button
                            text={i18("Max", "poolCardTable.maxBtn.text")}
                            title={i18("Set Max Value", "poolCardTable.maxBtn.title")}
                            btnColor="gradientText"
                            onClick={() => handleHybridPoolInputChange(row.original.balance + "", row.index)}
                          />
                        )}
                        <CustomNumericInput
                          extraClassName="derivativeFromUsdFormGroup"
                          name={`amount_${row.index}`}
                          labelText={i18("Enter amount", "poolCardTable.input.label")}
                          hiddenLabel={true}
                          placeholder={"0"}
                          decimalScale={6}
                          value={row.original.input}
                          onChange={e => {
                            handleHybridPoolInputChange(e.target.value, row.index);
                            // setAmountInputs(amountInput => {
                            //   const newAmountInput = [...amountInputs];
                            //   newAmountInput[row.index] = e.target.value;
                            //   return newAmountInput;
                            // });
                          }}
                        />
                      </div>

                      <div className="tableInputValueConversion withMarginTop">
                        ($
                        {
                          formatBalance(
                            fromHumanAmountToTokenBalance(
                              row.original.input?.replaceAll(",", ""),
                              assets[row.original.id].decimals
                            ),
                            assets[row.original.id].price,
                            assets[row.original.id].decimals
                          ).usdConversion
                        }
                        )
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {!row.original.allowanceLoading ? (
                    <Button
                      text={i18(`Approve ${assets[row.original.id].symbol}`, "poolCardTable.approveBtn.text", {
                        symbol: assets[row.original.id].symbol,
                      })}
                      title={i18(`Approve set ${assets[row.original.id].symbol}`, "poolCardTable.approveBtn.title", {
                        symbol: assets[row.original.id].symbol,
                      })}
                      btnColor="gradientText"
                      onClick={() => handleIncreaseAllowance(assets[row.original.id].address, row.index)}
                    />
                  ) : (
                    <CustomLoader text={i18("Processing...", "poolCardTable.loader.text")} size="xs" />
                  )}
                </>
              )}
            </>
          );
        },
      },
    ];
  }, [i18]);

  const [columns, setColumns] = useState(() => [...defaultColumns]);

  // useEffect(() => {
  //   setData(DummyPoolCardDetailsTableList);
  // }, [defaultColumns]);

  useEffect(() => {
    setColumns([...defaultColumns]);
  }, [defaultColumns]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const shouldDisableButton = amountWithinInput => {
    return amountWithinInput.find((value, index) => {
      let valueParsed = value;
      if (value === "") valueParsed = "0";
      return !BigNumber(valueParsed)
        .times(Math.pow(10, assets[poolData.assetIDs[index]].decimals))
        .lte(assetBalances[poolData.assetIDs[index]]);
    });
  };

  const submitButtonText = () => {
    const action = i18("Deposit", "poolCardTable.submitBtn.text.deposit");
    if (data.every(value => !value.input || !Number(value.input))) {
      return {
        buttonText: i18("Enter Amount", "poolCardTable.submitBtn.text.enterAmount"),
        disabled: true,
      };
    }

    if (!poolData?.owner_settings?.is_deposit_enabled) {
      return {
        buttonText: i18("Deposit disabled", "poolCardTable.submitBtn.text.disabled", {
          action,
        }),
        disabled: true,
      };
    }

    if (isTransactionStarts) {
      return {
        buttonText: i18("Executing...", "poolCardTable.submitBtn.text.executing"),
        disabled: true,
      };
    }

    if (shouldDisableButton(data.map(row => row.input))) {
      return {
        buttonText: i18("Not enough balance", "poolCardTable.submitBtn.text.notEnoughBalance"),
        disabled: true,
      };
    }

    // check if this deposit would trigger unbalancing threshold error
    if (
      poolData.settings.max_deposit_unbalancing_threshold &&
      hybridStakeCheckUnbalancingThreshold(
        poolData,
        data.map((value, i) => {
          if (value.input === "") return BigInt(0);
          return BigInt(
            BigNumber(value.input)
              .times(Math.pow(10, poolData.assetDecimals[i]))
              .decimalPlaces(0, BigNumber.ROUND_FLOOR)
              .toString(10)
          );
        })
      )
    ) {
      return {
        buttonText: i18(
          "Deposit is unbalancing the pool more than " +
            (parseInt(poolData.settings.max_deposit_unbalancing_threshold) * 100) / 1000000 +
            "%",
          "poolCardTable.submitBtn.text.unbalancingThreshold",
          {
            action,
            unbalancingThreshold: (parseInt(poolData.settings.max_deposit_unbalancing_threshold) * 100) / 1000000,
          }
        ),
        disabled: true,
      };
    }

    return {
      buttonText: action,
      disabled: false,
    };
  };

  return (
    <section className={clsx("poolCardModalSectionTable withGradientBorderBottom")}>
      {isLoading ? (
        <div style={{ textAlign: "center", marginBottom: "1.5em" }}>
          <CustomLoader size="xs" />
        </div>
      ) : (
        <table
          className={clsx(
            "table",
            "withGradientBorderRows",
            "poolCardModalDetailsTable",
            "isResponsive",
            "col-3",
            "standardDeposit"
          )}
          style={{ marginBottom: "2em" }}
        >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!isTransactionStarts ? (
        <Button
          text={submitButtonText().buttonText}
          title={i18("Submit", "poolCardTable.submitBtn.title")}
          isFullWidth={true}
          btnColor="gradient"
          onClick={handleSubmit}
          disabled={submitButtonText().disabled}
        />
      ) : (
        <CustomLoader text={i18("Processing...", "poolCardTable.loader.text")} size="xs" />
      )}
    </section>
  );
}

export default PoolCardHybridDepositTable;
