import { createAsyncThunk } from "@reduxjs/toolkit";
import parseWalletErrorMessage from "@axvdex/utils/parseWalletErrorMessage";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
import { postTransactions } from "@axvdex/api/user";
import { AsyncThunkConfig } from "..";
import { executeContract, fetchUserInfo, updateNativeBalance, updateTokenBalance } from "../wallet/walletThunks";
import { sendToast, setSalesBids, updateNicknames, updateUser } from "../wallet/walletSlice";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "../persist";

export const executeBidOnSaleAction = createAsyncThunk<
  { incentives: any[] },
  {
    walletAddress: string;
    saleAddress: string;
    address?: string;
    denom?: string;
    amount: string;
    i18: any;
  },
  AsyncThunkConfig
>(
  "wallet/executeBidOnSaleAction",
  async ({ walletAddress, saleAddress, address, denom, amount, i18 }, { dispatch, getState }) => {
    const state = getState().wallet;
    let isSuccessful = false;
    let msg = "";
    let txLink = null;
    let res = null;
    try {
      if (denom) {
        res = await executeContract(
          walletAddress,
          saleAddress,
          {
            bid: {},
          },
          [
            {
              denom,
              amount,
            },
          ]
        );
      }
      if (address) {
        res = await executeContract(
          walletAddress,
          address,
          {
            send: {
              contract: saleAddress,
              amount,
              msg: Buffer.from(
                JSON.stringify({
                  bid: {},
                })
              ).toString("base64"),
            },
          },
          []
        );
      }

      txLink = `${process.env.REACT_APP_EXPLORER_URL}/${res.transactionHash}`;
      isSuccessful = true;
      msg = `Bid successful`;
    } catch (e) {
      console.log(e.message);
      msg = `Bid failed. Error: ${parseWalletErrorMessage(e.message, i18)}`;
      isSuccessful = false;
    }

    const toastType = isSuccessful ? "tx-success" : "tx-fail";

    // update native asset as it is used for gas fees
    dispatch(
      updateNativeBalance({
        denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM,
        userAddress: walletAddress,
      })
    );

    // update user bids
    await dispatch(updateUserSaleBids({ walletAddress, saleAddress, withDetails: true }));
    const userInfo = await fetchUserInfo(null, walletAddress, true);
    dispatch(updateUser(userInfo));

    // update balances of the bid asset
    if (address)
      dispatch(
        updateTokenBalance({
          tokenAddress: address,
          userAddress: walletAddress,
        })
      );
    if (denom)
      dispatch(
        updateNativeBalance({
          denom,
          userAddress: walletAddress,
        })
      );

    // send notification
    dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));

    // send tx to BE so its updated for this user on the DB and trigger update User info
    const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
    if (permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress]) {
      postTransactions(
        { txHash: res.transactionHash },
        {
          address: walletAddress,
          signature: permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress],
        }
      );
    }

    // a bid can have an incentive on it, so we check if there is an incentive and return it to then be displayed to the user
    const bid_event = res?.logs?.[0]?.events?.find(e => e.type === "wasm-astrovault-sale-bid")?.attributes;

    const incentives = [];

    if (bid_event) {
      bid_event.forEach(e => {
        if (e.key === "incentive") {
          incentives.push(e.value);

          const incentive = JSON.parse(e.value);
          // update balances of incentives
          if (incentive[0] === "asset" && state.assets[incentive[1]]?.address)
            dispatch(
              updateTokenBalance({
                tokenAddress: incentive[1],
                userAddress: walletAddress,
              })
            );
          if (incentive[0] === "asset" && state.assets[incentive[1]]?.denom)
            dispatch(
              updateNativeBalance({
                denom: incentive[1],
                userAddress: walletAddress,
              })
            );
        }
      });
    }

    return {
      incentives,
    };
  }
);

export const updateUserSaleBids = createAsyncThunk<
  string,
  { walletAddress: string; saleAddress: string; withDetails: boolean },
  AsyncThunkConfig
>("wallet/updateUserSaleBids", async ({ walletAddress, saleAddress, withDetails }, { dispatch }) => {
  const response = await rpcClientQuerySmartContractWrapper(saleAddress, {
    user_bids: {
      address: walletAddress,
      with_details: withDetails,
    },
  });
  dispatch(setSalesBids({ saleAddress, saleUserBids: response }));
  return response.locked;
});

export const redeemFromVesting = createAsyncThunk<
  void,
  {
    walletAddress: string;
    vestingAddress: string;
    sale_id: number;
    i18: any;
    targetAssetAddr?: string;
  },
  AsyncThunkConfig
>(
  "wallet/redeemFromVesting",
  async ({ walletAddress, vestingAddress, sale_id, targetAssetAddr, i18 }, { dispatch }) => {
    let isSuccessful = false;
    let msg = "";
    let txLink = null;
    let res = null;
    try {
      res = await executeContract(
        walletAddress,
        vestingAddress,
        {
          redeem_assets_from_vesting: {
            sale_ids: [sale_id],
          },
        },
        []
      );

      txLink = `${process.env.REACT_APP_EXPLORER_URL}/${res.transactionHash}`;
      isSuccessful = true;
      msg = `Redeem successful`;
    } catch (e) {
      console.log(e.message);
      msg = `Redeem failed. Error: ${parseWalletErrorMessage(e.message, i18)}`;
      isSuccessful = false;
    }

    const toastType = isSuccessful ? "tx-success" : "tx-fail";

    // update native asset as it is used for gas fees
    dispatch(
      updateNativeBalance({
        denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM,
        userAddress: walletAddress,
      })
    );

    // update balances of the target asset
    if (targetAssetAddr)
      dispatch(
        updateTokenBalance({
          tokenAddress: targetAssetAddr,
          userAddress: walletAddress,
        })
      );

    // send notification
    dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));
  }
);

export const updateNicknamesFromBlockchain = createAsyncThunk<string, { addresses: string[] }, AsyncThunkConfig>(
  "wallet/updateNicknamesFromBlockchain",
  async ({ addresses }, { dispatch, getState }) => {
    const state = getState();
    try {
      const response = await rpcClientQuerySmartContractWrapper(state.wallet.contracts.nicknames.address, {
        nicknames: {
          addresses,
        },
      });

      dispatch(updateNicknames(response));
      return response;
    } catch (e) {
      console.error(e);
    }
  }
);

export const updateNickname = createAsyncThunk<
  void,
  {
    payment: any;
    nickname: string;
    target_address: string;
    i18: any;
  },
  AsyncThunkConfig
>("wallet/updateNickname", async ({ payment, nickname, target_address, i18 }, { dispatch, getState }) => {
  const state = getState();
  let isSuccessful = false;
  let msg = "";
  let txLink = null;
  let res = null;
  try {
    res = await executeContract(state.wallet.walletInfo.walletAddress, payment.info.token.contract_addr, {
      send: {
        contract: state.wallet.contracts.nicknames.address,
        amount: payment.amount,
        msg: Buffer.from(
          JSON.stringify({
            set_nickname: {
              nickname,
              target_address,
            },
          })
        ).toString("base64"),
      },
    });

    txLink = `${process.env.REACT_APP_EXPLORER_URL}/${res.transactionHash}`;
    isSuccessful = true;
    msg = `Update nickname successful`;
  } catch (e) {
    console.log(e.message);
    msg = `Update nickname error. Error: ${parseWalletErrorMessage(e.message, i18)}`;
    isSuccessful = false;
  }

  const toastType = isSuccessful ? "tx-success" : "tx-fail";

  // update native asset as it is used for gas fees
  dispatch(
    updateNativeBalance({
      denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM,
      userAddress: state.wallet.walletInfo.walletAddress,
    })
  );

  // update balances of the payment
  dispatch(
    updateTokenBalance({
      tokenAddress: payment.info.token.contract_addr,
      userAddress: state.wallet.walletInfo.walletAddress,
    })
  );

  // update nickname
  dispatch(
    updateNicknamesFromBlockchain({
      addresses: [state.wallet.walletInfo.walletAddress],
    })
  );

  // send notification
  dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));
});

export const getNicknameProtection = createAsyncThunk<
  void,
  {
    payment: any;
    i18: any;
  },
  AsyncThunkConfig
>("wallet/getNicknameProtection", async ({ payment, i18 }, { dispatch, getState }) => {
  const state = getState();
  let isSuccessful = false;
  let msg = "";
  let txLink = null;
  let res = null;
  try {
    res = await executeContract(state.wallet.walletInfo.walletAddress, payment.info.token.contract_addr, {
      send: {
        contract: state.wallet.contracts.nicknames.address,
        amount: payment.amount,
        msg: Buffer.from(
          JSON.stringify({
            get_protection: {},
          })
        ).toString("base64"),
      },
    });

    txLink = `${process.env.REACT_APP_EXPLORER_URL}/${res.transactionHash}`;
    isSuccessful = true;
    msg = `Get nickname protection successful`;
  } catch (e) {
    console.log(e.message);
    msg = `Get nickname protection error. Error: ${parseWalletErrorMessage(e.message, i18)}`;
    isSuccessful = false;
  }

  const toastType = isSuccessful ? "tx-success" : "tx-fail";

  // update native asset as it is used for gas fees
  dispatch(
    updateNativeBalance({
      denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM,
      userAddress: state.wallet.walletInfo.walletAddress,
    })
  );

  // update balances of the payment
  dispatch(
    updateTokenBalance({
      tokenAddress: payment.info.token.contract_addr,
      userAddress: state.wallet.walletInfo.walletAddress,
    })
  );

  // update nickname
  dispatch(
    updateNicknamesFromBlockchain({
      addresses: [state.wallet.walletInfo.walletAddress],
    })
  );

  // send notification
  dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));
});
