import { JSX, useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Search } from "react-feather";
import { delay, findIndex, includes, isEqual } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { compareArrayOfObject } from "@axvdex/utils/wallet";
import { ReactComponent as IcnSortAsc } from "@axvdex/assets/icons/icn-sort-arrow-down-short-wide-solid.svg";
import { ReactComponent as IcnSortDesc } from "@axvdex/assets/icons/icn-sort-arrow-down-wide-short-solid.svg";
import { ReactComponent as IcnUnsorted } from "@axvdex/assets/icons/icn-sort-unsorted.svg";
import { loadState, persistState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";
import useLanguage from "@axvdex/hooks/useLanguage";
import { useQuery } from "@axvdex/hooks/useQuery";
import MainLayout from "@axvdex/layouts/MainLayout";
import { getTokenList } from "../tokenListForSelect";
import CustomLoader from "../common/CustomLoader";
import StatusMessages from "../StatusMessages";
import CustomInputButton from "components/form-element/CustomInputButton";
import CustomSelect from "components/form-element/CustomSelect";
import PoolCardDetailsModal from "components/modals/PoolCardDetailsModal";
import PoolCard from "components/pools/PoolCard";
import styles from "styles/Pool.module.scss";

import { useAppSelector } from "state";
import { selectAssets, selectFavouritePools, selectPools, selectPoolsWithBalance } from "state/wallet/walletSelectors";

const SortOrderIcon = ({ sortOrder, isDate }: { sortOrder: "desc" | "asc" | "unsorted"; isDate: boolean }) => {
  const icon = (): [JSX.Element, string] => {
    const { i18 } = useLanguage();

    let title = i18("Click to sort high to low", "pools.filter.sortOrder.title.toDesc");
    if (isDate) {
      title = i18("Click to sort latest to oldest", "pools.filter.sortOrder.title.date.toDesc");
    }
    let ariaLabel = i18("Not sorted", "pools.filter.sortOrder.ariaLabel.unsorted");
    let iconEl = <IcnUnsorted data-tooltip-id="tooltip" aria-label={ariaLabel} />;

    if ("desc" === sortOrder) {
      title = i18("Click to sort low to high", "pools.filter.sortOrder.title.toAsc");
      ariaLabel = i18("Sorted high to low", "pools.filter.sortOrder.ariaLabel.desc");
      if (isDate) {
        title = i18("Click to sort oldest to latest", "pools.filter.sortOrder.title.date.toAsc");
        ariaLabel = i18("Sorted latest to oldest", "pools.filter.sortOrder.ariaLabel.date.desc");
      }
      iconEl = <IcnSortDesc data-tooltip-id="tooltip" aria-label={ariaLabel} />;
    } else if ("asc" === sortOrder) {
      title = i18("Click to remove sort direction", "pools.filter.sortOrder.title.toUnsorted");
      ariaLabel = i18("Sorted low to high", "pools.filter.sortOrder.ariaLabel.asc");
      if (isDate) {
        ariaLabel = i18("Sorted oldest to latest", "pools.filter.sortOrder.ariaLabel.date.asc");
      }
      iconEl = <IcnSortAsc data-tooltip-id="tooltip" aria-label={ariaLabel} />;
    }

    return [iconEl, title];
  };

  return icon();
};

const processPools = (pools, assets) => {
  return Object.keys(pools)
    ?.filter(poolKey => {
      if (pools[poolKey].isHidden) return false;
      pools[poolKey].poolAssets?.forEach(asset => {
        const assetId = asset.info?.token?.contract_addr || asset.info?.native_token?.denom;
        const poolAssetDetails = assets[assetId];
        if (!poolAssetDetails) return false;
      });
      return true;
    })
    .map(pool => {
      let tokenPair = "";
      const assetIDs = [];
      const poolAssets = [];
      let pool_tvl = 0;
      pools[pool].poolAssets?.forEach(asset => {
        const assetId = asset.info?.token?.contract_addr || asset.info?.native_token?.denom;
        const poolAssetDetails = assets[assetId];
        if (!poolAssetDetails) return;
        const price = poolAssetDetails.price || 0;
        const decimals = poolAssetDetails.decimals;
        pool_tvl += price * (Number(asset.amount) / Math.pow(10, decimals));
        if (!tokenPair) {
          tokenPair = `${poolAssetDetails.symbol}`;
        } else {
          tokenPair = `${tokenPair} / ${poolAssetDetails.symbol}`;
        }
        assetIDs.push(assetId);
        poolAssets.push(poolAssetDetails);
      });
      return {
        ...pools[pool],
        tokenPair,
        assetIDs,
        pool_tvl,
        assets: poolAssets,
        apr: pools[pool].percentageAPRs ? pools[pool].percentageAPRs.reduce((sum, source) => sum + source, 0) || 0 : 0,
      };
    });
};

function PoolsTable() {
  const { i18 } = useLanguage();
  const query = useQuery();
  const FilterTypeList = useMemo(
    () => [
      { label: i18("Stable pools", "pools.filter.filterByType.stable.label"), value: "stable" },
      { label: i18("Standard pools", "pools.filter.filterByType.standard.label"), value: "standard" },
      { label: i18("Hybrid pools", "pools.filter.filterByType.hybrid.label"), value: "hybrid" },
      { label: i18("Favourite pools", "pools.filter.filterByType.starred.label"), value: "starred" },
    ],
    []
  );

  const routeParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [loadPool, setLoadPool] = useState(true);
  const [tokenList, setTokenList] = useState([]);
  const [poolsData, setPoolsData] = useState([]);
  const [topFilter, setTopFilter] = useState(() => {
    const tf = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.poolsTable).topFilter;
    return tf ? tf : "all";
  });
  const [poolTypeFilter, setPoolTypeFilter] = useState<{ value: string; label: string }>(() => {
    const ptf = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.poolsTable).poolTypeFilter;
    return ptf ? ptf : null;
  });
  const [selectedSort, setSelectedSort] = useState(() => {
    const ss = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.poolsTable).selectedSort;
    return ss ? ss : null;
  });
  const [sortOrder, setSortOrder] = useState(() => {
    const so = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.poolsTable).sortOrder;
    return so ? so : "desc";
  });

  const [iconEl, title] = SortOrderIcon({ sortOrder: sortOrder, isDate: "createdAt" === selectedSort?.value });
  const SortByList = [
    {
      label: i18("TVL", "pools.filter.sortBy.tvl.label"),
      value: "pool_tvl",
      optionPrefix: selectedSort?.value === "pool_tvl" ? iconEl : null,
      tooltip:
        selectedSort?.value === "pool_tvl"
          ? title
          : i18("Sort high to low by TVL", "pools.filter.sortBy.tvl.tooltip.default"),
      /* Uncomment this to force open tooltip
      openTooltip: true,
      /**/
    },
    {
      label: i18("APR", "pools.filter.sortBy.apr.label"),
      value: "apr",
      optionPrefix: selectedSort?.value === "apr" ? iconEl : null,
      tooltip:
        selectedSort?.value === "apr"
          ? title
          : i18("Sort high to low by APR", "pools.filter.sortBy.apr.tooltip.default"),
      /* Uncomment this to force open tooltip
      openTooltip: true,
      /**/
    },
    {
      label: i18("Added", "pools.filter.sortBy.added.label"),
      value: "createdAt",
      optionPrefix: "createdAt" === selectedSort?.value ? iconEl : null,
      tooltip:
        "createdAt" === selectedSort?.value
          ? title
          : i18("Sort latest to oldest added date", "pools.filter.sortBy.added.tooltip.default"),
      /* Uncomment this to force open tooltip
      openTooltip: true,
      /**/
    },
  ];

  const poolsStored = useAppSelector(selectPools, isEqual);
  const assetsStored = useAppSelector(selectAssets, isEqual);
  const poolsWithBalance = useAppSelector(selectPoolsWithBalance, isEqual);
  const favouriteList = useAppSelector(selectFavouritePools, isEqual);

  //const isSmallBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);

  // opens menu by default if search param is found on the URL to do the "hacky" select of the token provided on the query string
  const searchParam = "search-filter";
  const searchParamValue = query.get(searchParam);
  const isSearchParam = !!searchParamValue;
  const [poolSearchMenuIsOpen, setPoolSearchMenuIsOpen] = useState(isSearchParam);
  const [searchString, setSearchString] = useState("");
  const [fuzzySearchString, setFuzzySearchString] = useState("");
  const [assetSearchOption, setAssetSearchOption] = useState("");

  const [selectedPool, setSelectedPool] = useState<string>();

  const handleTogglePoolModal = useCallback((poolId: string) => {
    setSelectedPool(poolId);
  }, []);

  const handleClosePoolModal = useCallback(() => {
    navigate("/pool", { replace: true });
    setSelectedPool("");
  }, []);

  useEffect(() => {
    const globalPools = { ...poolsStored };
    const globalAssets = { ...assetsStored };
    setTokenList(getTokenList(globalAssets));
    const poolsData = processPools(globalPools, globalAssets);
    setPoolsData(poolsData);
    // eslint-disable-next-line
  }, [poolsStored, assetsStored]);

  useEffect(() => {
    // handle sending poolID from other pages to open it directly
    if (location?.state?.poolId) {
      const { poolId } = location.state;
      navigate(location.pathname, { replace: true });
      delay(() => setSelectedPool(poolId), 50);
    }
  }, []);

  // when having search url param, hide the list of the search
  const elListbox = document.getElementById("react-select-7-listbox");
  if (isSearchParam && "" === searchString && "" === fuzzySearchString && "" === assetSearchOption && elListbox) {
    elListbox.style.display = "none";
  }

  useEffect(() => {
    // handle url coming in with params
    const searchParamValue = query.get(searchParam);
    if (searchParamValue && "" === searchString && "" === fuzzySearchString && "" === assetSearchOption) {
      const tokenIndex = tokenList.findIndex(
        (token: any) => token.value.toLowerCase() === searchParamValue.toLowerCase()
      );
      // const elSelect = document.getElementById("react-select-7-option-" + tokenIndex);
      const elSelect = Array.from(document.getElementsByClassName("customSelectOptionLabel")).find(
        el => el.textContent.toLowerCase() === searchParamValue.toLowerCase()
      ) as HTMLElement;

      if (-1 !== tokenIndex && elSelect) {
        elSelect.click();
        setAssetSearchOption(tokenList[tokenIndex].value);
        setFuzzySearchString("");
        setPoolSearchMenuIsOpen(false);
      }
    }
  }, [tokenList]);

  useEffect(() => {
    if (poolsData?.length && routeParams?.poolId) {
      const { poolId } = routeParams;
      const tokenIds = poolId.split("-");
      const pool = poolsData.find(pool =>
        isEqual(pool.poolAssetSymbols.map(i => i.toLowerCase()).sort(), tokenIds.map(i => i.toLowerCase()).sort())
      );
      if (pool?.id) {
        setSelectedPool(pool?.id);
      }
    }
  }, [poolsData]);

  const handleFilterTypeChange = e => {
    const poolsTable = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.poolsTable);
    poolsTable.poolTypeFilter = e;
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.poolsTable, poolsTable);
    setPoolTypeFilter(e);
  };

  const handleSortChange = e => {
    if (selectedSort?.value === e?.value) {
      const poolsTable = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.poolsTable);
      const newSortOrder = sortOrder === "unsorted" ? "desc" : sortOrder === "desc" ? "asc" : "unsorted";
      poolsTable.sortOrder = newSortOrder;
      persistState(WHITE_LIST_PERSISTED_STATE_KEYS.poolsTable, poolsTable);
      setSortOrder(newSortOrder);
      return;
    }
    setSortOrder("desc");
    const poolsTable = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.poolsTable);
    poolsTable.selectedSort = e;
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.poolsTable, poolsTable);
    setSelectedSort(e);
  };

  const sortPools = poolResults => {
    if (!selectedSort || !sortOrder || "unsorted" === sortOrder) {
      return compareArrayOfObject(poolResults, "tokenPair", true, false);
    }
    if ("createdAt" === selectedSort.value) {
      return compareArrayOfObject(poolResults, selectedSort.value, "desc" === sortOrder, false, true);
    }
    return compareArrayOfObject(poolResults, selectedSort.value, "desc" === sortOrder);
  };

  const displayPools = useMemo(() => {
    const poolsDataByAssetName = poolsData.reduce((acc, pool) => {
      let isAssetInSearchField = false;
      if (assetSearchOption) {
        isAssetInSearchField = pool.assets.some(({ symbol }) => {
          return symbol === assetSearchOption;
        });
      } else if (fuzzySearchString) {
        isAssetInSearchField = pool.assets.some(({ label }) => {
          return includes(label.toLowerCase(), fuzzySearchString.toLowerCase());
        });
      }
      if (isAssetInSearchField) {
        return [...acc, pool];
      }

      return acc;
    }, []);

    const transformedPoolDetails = poolsDataByAssetName.length ? [...poolsDataByAssetName] : [...poolsData];

    let transformedPools = [...transformedPoolDetails];

    if (topFilter === "my_pools") {
      transformedPools = [
        ...transformedPools.filter(pool => {
          return poolsWithBalance.find(({ contract_addr }) => contract_addr === pool.address);
        }),
      ];
    } else if (topFilter === "favourites") {
      transformedPools = [...transformedPools.filter(pool => favouriteList.includes(pool.address))];
    } else {
      transformedPools = [...transformedPools];
    }

    let poolResults;

    if (poolTypeFilter?.value === "standard") {
      poolResults = [
        ...transformedPools.filter(pool => {
          return pool.type === "standard";
        }),
      ];
    } else if (poolTypeFilter?.value === "hybrid") {
      poolResults = [
        ...transformedPools.filter(pool => {
          return pool.type === "hybrid";
        }),
      ];
    } else if (poolTypeFilter?.value === "stable") {
      poolResults = [
        ...transformedPools.filter(pool => {
          return pool.type === "stable";
        }),
      ];
    } else if (poolTypeFilter?.value === "starred") {
      poolResults = [
        ...transformedPools.filter(pool => {
          return favouriteList.includes(pool.address);
        }),
      ];
    } else {
      poolResults = [...transformedPools];
    }

    const sortedPools = sortPools(poolResults);

    const poolCards = [];
    const featuredPoolCards = [];

    sortedPools.forEach((currentPool, index) => {
      if (currentPool.featured) {
        featuredPoolCards.push(
          <PoolCard
            key={index}
            pool={currentPool}
            poolId={currentPool.id}
            onClick={() => handleTogglePoolModal(currentPool.id)}
            isSelected={selectedPool === currentPool.id}
            favourite={favouriteList.includes(currentPool.address)}
          />
        );
      } else {
        poolCards.push(
          <PoolCard
            key={index}
            pool={currentPool}
            poolId={currentPool.id}
            onClick={() => handleTogglePoolModal(currentPool.id)}
            isSelected={selectedPool === currentPool.id}
            favourite={favouriteList.includes(currentPool.address)}
          />
        );
      }
    });
    setLoadPool(false);

    if (
      0 === poolCards.length &&
      0 === featuredPoolCards.length &&
      ("my_pools" === topFilter || "favourites" === topFilter)
    ) {
      const pType = "favourites" === topFilter ? "favourite" : "active";
      return {
        poolCards: [i18(`Your ${pType} pools will show here...`, `pools.topFilter.myPools.${pType}.placeholder`)],
        featuredPoolCards: [],
      };
    }
    return {
      poolCards,
      featuredPoolCards,
    };
  }, [
    poolsData,
    poolTypeFilter,
    selectedSort,
    sortOrder,
    favouriteList,
    poolsWithBalance,
    selectedPool,
    topFilter,
    fuzzySearchString,
    assetSearchOption,
  ]);

  const onInputChange = (value: string) => {
    setSearchString(value);
    setPoolSearchMenuIsOpen(!!value);
  };

  const handleSearchKeyDown = event => {
    if (event.key === "Enter") {
      setFuzzySearchString(event.target.value);
      setPoolSearchMenuIsOpen(false);
      event.preventDefault();
    }
  };

  const handleSearchOnChange = (option, { action }) => {
    if (action === "select-option") {
      setAssetSearchOption(option.value);
      setFuzzySearchString("");
    }
    if (action === "clear") {
      setAssetSearchOption("");
    }
  };

  const handleTopFilterChange = e => {
    const filterValue = e.target.value;
    if (filterValue === "all") {
      setFuzzySearchString("");
      setAssetSearchOption("");
      handleFilterTypeChange(null);
    }
    updateTopFilter(filterValue);
  };

  const updateTopFilter = value => {
    const poolsTable = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.poolsTable);
    poolsTable.topFilter = value;
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.poolsTable, poolsTable);
    setTopFilter(value);
  };

  return (
    <>
      <MainLayout
        pageClass={clsx(styles.pool, "pool")}
        headTitle={i18("Pool", "pools.headTitle")}
        headDescription={i18("Pools of Astrovault", "pools.headDescription")}
      >
        <StatusMessages />
        <section className="poolSectionHeader withGradientBorderBottom">
          <div className="poolSectionHeaderTitle flexbox">
            <h1>{i18("Pool and Earn", "pools.title")}</h1>
            <fieldset>
              <legend className="visuallyHidden">{i18("Display", "pools.topFilter.legend")}</legend>
              <div className="btnGroup">
                <CustomInputButton
                  type="radio"
                  name="pool_sort"
                  labelText={i18("All", "pools.topFilter.all")}
                  id="all"
                  value="all"
                  checked={topFilter === "all"}
                  onChange={() => {}}
                  onClick={handleTopFilterChange}
                />
                <CustomInputButton
                  type="radio"
                  id="favourites"
                  name="pool_sort"
                  labelText={i18("Favourites", "pools.topFilter.favourites")}
                  value="favourites"
                  checked={topFilter === "favourites"}
                  onChange={() => {}}
                  onClick={() => updateTopFilter("favourites")}
                />
                <CustomInputButton
                  type="radio"
                  id="my_pools"
                  name="pool_sort"
                  labelText={i18("My Pools", "pools.topFilter.myPools")}
                  value="my_pools"
                  checked={topFilter === "my_pools"}
                  onChange={() => {}}
                  onClick={() => updateTopFilter("my_pools")}
                />
              </div>
            </fieldset>
          </div>

          <div className="poolSectionHeaderFilters gridBox">
            {
              // <CustomSelect
              //   name="pool_filter_by_status"
              //   labelText={i18("Filter by status", "pools.filter.filterByStatus.label")}
              //   hiddenLabel={true}
              //   placeholder={i18("Status", "pools.filter.filterByStatus.placeholder")}
              //   items={DummyFilterList}
              //   isClearable
              // />
              //
            }
            <CustomSelect
              name="pool_filter_by_type"
              labelText={i18("Filter by type", "pools.filter.filterByType.label")}
              hiddenLabel={true}
              placeholder={i18("Type", "pools.filter.filterByType.placeholder")}
              items={FilterTypeList}
              defaultValue={poolTypeFilter}
              value={[poolTypeFilter]}
              onChange={handleFilterTypeChange}
              isClearable
            />

            <CustomSelect
              name="pool_sort"
              labelText={i18("Sort", "pools.filter.sort.label")}
              hiddenLabel={true}
              placeholder={i18("Sort", "pools.filter.sort.placeholder")}
              items={SortByList}
              value={
                selectedSort?.value
                  ? SortByList[findIndex(SortByList, item => item.value === selectedSort?.value)]
                  : null
              }
              onChange={handleSortChange}
              isClearable
            />

            <CustomSelect
              name="pool_search"
              labelText={i18("Search", "pools.filter.search.label")}
              hiddenLabel={true}
              placeholder={i18("Search", "pools.filter.search.placeholder")}
              items={tokenList}
              customSelectIcon={<Search />}
              hideChevronIcon={true}
              onInputChange={onInputChange}
              inputValue={searchString}
              onChange={handleSearchOnChange}
              onKeyDown={handleSearchKeyDown}
              isClearable
              menuIsOpen={poolSearchMenuIsOpen}
            />
          </div>
        </section>
        <FeaturedPools featuredPools={displayPools?.featuredPoolCards} />
        <section className="poolSectionCardGrid gridBox">
          {loadPool ? <CustomLoader size="sm" /> : displayPools.poolCards}
        </section>
        {poolsData.length !== 0 && (
          <PoolCardDetailsModal
            openModal={!!selectedPool}
            onCloseModal={handleClosePoolModal}
            poolId={selectedPool}
            pools={poolsData}
          />
        )}
      </MainLayout>
    </>
  );
}

const FeaturedPools = ({ featuredPools }: any) => {
  const { i18 } = useLanguage();

  if (!featuredPools || !featuredPools.length) return null;
  return (
    <div style={{ paddingBottom: "2em" }}>
      <div style={{ paddingBottom: "2em" }} className="withGradientBorderBottom">
        <section
          className="poolSectionHeader "
          style={{ paddingTop: "0em", paddingBottom: "0em", marginBottom: "1em" }}
        >
          <div className="poolSectionHeaderTitle flexbox">
            <h1>{i18("Featured", "pools.featuredTitle")}</h1>
          </div>
        </section>
        <section className="poolSectionCardGrid gridBox">{featuredPools}</section>
      </div>
    </div>
  );
};

export default PoolsTable;
