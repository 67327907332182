export default assetSymbol => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  let assetImg = require(`../assets/tokens/logo-noimage.svg`).default;

  if ("string" === typeof assetSymbol) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      assetImg = require(`../assets/tokens/logo-${assetSymbol.toLowerCase()}.svg`);
    } catch {
      /* empty */
    }
  }

  return assetImg;
};
