import clsx from "clsx";
import { Star } from "react-feather";
import useLanguage from "@axvdex/hooks/useLanguage";
import { ReactComponent as FullStar } from "../../assets/icons/icn-star-full.svg";
import Button from "./Button";

interface ButtonFavouriteProps {
  btnColor?: "purple";
  onToggleFavourite: () => void;
  isFavourite: boolean;
}

const ButtonFavourite = ({ onToggleFavourite, isFavourite, btnColor }: ButtonFavouriteProps) => {
  const { i18 } = useLanguage();
  return (
    <Button
      extraClassName={clsx("btnFavourite", isFavourite && "isFavourite")}
      title={
        isFavourite
          ? i18("Added to favourites", "common.button.favourite.added")
          : i18("Add to favourites", "common.button.favourite.add")
      }
      btnVariant="icon"
      btnColor={btnColor}
      onClick={onToggleFavourite}
    >
      {isFavourite ? <FullStar /> : <Star />}
    </Button>
  );
};

export default ButtonFavourite;
