import useLanguage from "@axvdex/hooks/useLanguage";
import MainLayout from "../layouts/MainLayout";

function Vote() {
  const { i18 } = useLanguage();

  return (
    <MainLayout
      pageClass="vote"
      headTitle={i18("Vote", "vote.headTitle")}
      headDescription={i18("Vote your favourites on Astrovault", "vote.headDescription")}
    >
      {i18("Vote page", "vote.title")}
    </MainLayout>
  );
}

export default Vote;
