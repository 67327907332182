import { ReactNode, useEffect, useMemo, useRef } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { CheckCircle, AlertCircle, AlertTriangle, Info } from "react-feather";
import useLanguage from "@axvdex/hooks/useLanguage";
import Button from "./Button";

interface ToastProps {
  extraClassName?: string;
  type: "success" | "warning" | "info" | "error";
  hideTypeIcon?: boolean;
  children: ReactNode;
  contentIsBordered?: boolean;
  extraButton?: ReactNode;
  closeButtonText?: string;
  openToast: boolean;
  containerId: string;
  toastId: string;
  disableAutoClose?: boolean;
  closeOnClick?: boolean;
}

function switchToastIcons(type: string) {
  switch (type) {
    case "success":
      return <CheckCircle />;
    case "error":
      return <AlertCircle />;
    case "info":
      return <Info />;
    case "warning":
      return <AlertTriangle />;
    default:
      return "";
  }
}

function CustomToast({
  type,
  hideTypeIcon,
  children,
  contentIsBordered,
  extraButton,
  openToast,
  closeButtonText,
  containerId,
  toastId,
  disableAutoClose,
  closeOnClick = true,
}: ToastProps) {
  const { i18 } = useLanguage();
  const toastIdinternal = useRef(toastId);

  const ToastContent = useMemo(() => {
    return (
      <div className="customToastInner">
        <div className={clsx("customToastContent", contentIsBordered && "isBordered")}>{children}</div>

        <div className="buttonContainer customToastButtonContainer">
          <Button
            btnColor="gradientText"
            text={closeButtonText ? closeButtonText : i18("Dismiss", "customToast.btnDismiss.text")}
            onClick={() => toast.dismiss(toastIdinternal.current)}
          />

          {extraButton && extraButton}
        </div>
      </div>
    );
  }, [children, extraButton, closeButtonText]);

  useEffect(() => {
    if (openToast && !toast.isActive(toastId)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      toastIdinternal.current = toast(ToastContent, {
        type: type,
        toastId: toastId,
        closeButton: false,
        containerId: containerId,
        icon: hideTypeIcon ? false : switchToastIcons(type),
        autoClose: disableAutoClose ? false : 10000,
        closeOnClick: closeOnClick,
      });
    }
  }, [openToast]);

  return <> </>;
}

export default CustomToast;
