import clsx from "clsx";
import useLanguage from "@axvdex/hooks/useLanguage";
import styles from "../../styles/SubFooterContent.module.scss";

function SubFooterLegalContent() {
  const { i18 } = useLanguage();
  const currentYear = new Date().getFullYear();

  return (
    <div className={clsx(styles.subFooterContent, "subFooterContent")}>
      <small className="copyright">
        Astrovault©{currentYear} | {i18("All rights reserved.", "subFooterContent.copyRight.allRightsReserved")}
      </small>

      <small className="subFooterLegal">
        {i18(
          "The AXV token will not be offered in the United States or to U.S. persons or to residents of certain other prohibited jurisdictions. The information provided on this website is for general informational purposes only. It does not constitute, and should not be considered, a formal offer to sell or a solicitation of an offer to buy any security in any jurisdiction, legal advice, investment advice, or tax advice. If you are in need of legal advice, investment advice, or tax advice, please consult with a professional adviser. The Astrovault protocol is under development and is subject to change. As such, the protocol documentation and contents of this website may not reflect the current state of the protocol at any given time. The protocol documentation and website content are not final and are subject to change.",
          "subFooterContent.subFooterLegal.text"
        )}
      </small>
    </div>
  );
}

export default SubFooterLegalContent;
