import useLanguage from "@axvdex/hooks/useLanguage";
import CustomToast from "../common/CustomToast";
import Button from "../common/Button";

interface ToastSuccessIbcTransferProps {
  openToast: boolean;
  isSuccess: boolean;
  msg: string;
  sendLink: string | null;
  receiveLink: string | null;
  timeoutLink: string | null;
  toastID: string;
  closeOnClick?: boolean;
}

function ToastIbcTransfer({
  openToast,
  isSuccess,
  msg,
  sendLink,
  receiveLink,
  timeoutLink,
  toastID,
  closeOnClick = false,
}: ToastSuccessIbcTransferProps) {
  const { i18 } = useLanguage();

  return (
    <CustomToast
      key={msg + toastID}
      type={isSuccess ? "success" : "error"}
      containerId="ibcTransferContainerId"
      toastId={msg + toastID}
      contentIsBordered={true}
      openToast={openToast}
      closeOnClick={closeOnClick}
    >
      <h1>{i18("IBC Transfer", "toast.ibcTransfer.title")}</h1>

      <p className="textGrey">{msg}</p>

      <div className="buttonContainer column">
        {sendLink && (
          <Button
            btnColor="gradient"
            isFullWidth={true}
            btnSize="sm"
            text={i18("Send Tx", "toast.ibcTransfer.sendTx")}
            onClick={() => {
              window.open(sendLink, "_blank", "noopener,noreferrer");
            }}
          />
        )}
        {timeoutLink && (
          <Button
            btnColor="gradient"
            isFullWidth={true}
            btnSize="sm"
            text={i18("Timeout Tx", "toast.ibcTransfer.timeoutTx")}
            onClick={() => {
              window.open(timeoutLink, "_blank", "noopener,noreferrer");
            }}
          />
        )}
        {receiveLink && (
          <Button
            btnColor="gradient"
            isFullWidth={true}
            btnSize="sm"
            text={i18("Receive Tx", "toast.ibcTransfer.receiveTx")}
            onClick={() => {
              window.open(receiveLink, "_blank", "noopener,noreferrer");
            }}
          />
        )}
      </div>
    </CustomToast>
  );
}

export default ToastIbcTransfer;
