import { div, gt, lte, minus, times } from "../math";

export const computeStandardSwap = (pool: any, hop: any, amount: bigint) => {
  const offerPool = pool.poolAssets.find((asset: any) => {
    if (asset.info.token) {
      return asset.info.token.contract_addr === hop.x;
    }
    return asset.info.native_token.denom === hop.x;
  });
  const offerPoolAmount = BigInt(offerPool.amount);

  const askPool = pool.poolAssets.find((asset: any) => {
    if (asset.info.token) {
      return asset.info.token.contract_addr === hop.y;
    }
    return asset.info.native_token.denom === hop.y;
  });
  const askPoolAmount = BigInt(askPool.amount);

  // if either side of the assets of this pool is 0, the swap is not possible and we return 0
  if (offerPoolAmount <= BigInt(0) || askPoolAmount === BigInt(0)) {
    return {
      returnAmount: BigInt(0),
      spreadAmount: BigInt(0),
      feeAmount: BigInt(0),
      commissionAmount: BigInt(0),
      buybackburnAmount: BigInt(0),
    };
  }

  const precision = 10 ** 18;
  const commissionRate = pool.settings.swap_fee;
  const buybackburnRate = pool.settings.buybackburn_fee;

  // offer => ask
  // ask_amount = (ask_pool - cp / (offer_pool + offer_amount)) * (1 - commission_rate)
  const cp = offerPoolAmount * askPoolAmount;
  let returnAmount = askPoolAmount - cp / (offerPoolAmount + amount);

  // calculate spread & commission
  const spreadAmount = amount * (askPoolAmount / offerPoolAmount) - returnAmount;

  let commissionAmount = BigInt(0);
  if (commissionRate) {
    commissionAmount = (returnAmount * BigInt(parseFloat(commissionRate) * precision)) / BigInt(precision);
  }

  let buybackburnAmount = BigInt(0);
  if (buybackburnRate) {
    buybackburnAmount = (returnAmount * BigInt(parseFloat(buybackburnRate.fee) * precision)) / BigInt(precision);
  }

  returnAmount = returnAmount - commissionAmount - buybackburnAmount;

  return {
    returnAmount,
    feeAmount: commissionAmount + buybackburnAmount,
    spreadAmount,
    commissionAmount,
    buybackburnAmount,
  };
};

export const computeStandardOfferAmount = (pool: any, hop: any, amount: bigint) => {
  const offerPool = pool.poolAssets.find((asset: any) => {
    if (asset.info.token) {
      return asset.info.token.contract_addr === hop.x;
    }
    return asset.info.native_token.denom === hop.x;
  });
  const offerPoolAmount = offerPool.amount;

  const askPool = pool.poolAssets.find((asset: any) => {
    if (asset.info.token) {
      return asset.info.token.contract_addr === hop.y;
    }
    return asset.info.native_token.denom === hop.y;
  });
  const askPoolAmount = askPool.amount;

  // if either side of the assets of this pool is 0, the swap is not possible and we return 0
  if (lte(offerPoolAmount, "0") || askPoolAmount === "0") {
    return {
      returnAmount: BigInt(0),
      spreadAmount: BigInt(0),
      feeAmount: BigInt(0),
      commissionAmount: BigInt(0),
      buybackburnAmount: BigInt(0),
    };
  }

  const commissionRate = pool.settings.swap_fee;
  const buybackburnRate = pool.settings.buybackburn_fee;

  let commissionRateNormalized = "0";
  if (commissionRate) {
    commissionRateNormalized = commissionRate;
  }

  let buybackburnRateNormalized = "0";
  if (buybackburnRate) {
    buybackburnRateNormalized = buybackburnRate.fee;
  }

  // ask => offer
  // offer_amount = cp / (ask_pool - ask_amount / (1 - commission_rate - buybackburn_rate)) - offer_pool
  const cp = times(offerPoolAmount, askPoolAmount);
  const one_minus_commission = minus(minus("1", commissionRateNormalized), buybackburnRateNormalized);

  const inv_one_minus_commission = div("1", one_minus_commission);

  const offerAmount = minus(
    div(cp, minus(askPoolAmount, times(amount.toString(), inv_one_minus_commission))),
    offerPoolAmount
  );

  const before_commission_deduction = times(amount.toString(), inv_one_minus_commission);
  const before_spread_deduction = div(times(offerAmount, askPoolAmount), offerPoolAmount);

  let spreadAmount = "0";
  if (gt(before_spread_deduction, before_commission_deduction)) {
    spreadAmount = minus(before_spread_deduction, before_commission_deduction);
  }

  const commissionAmount = times(before_commission_deduction, commissionRateNormalized);

  const buybackburnAmount = times(before_commission_deduction, buybackburnRateNormalized);

  if (BigInt(parseInt(offerAmount)) < BigInt(0)) {
    throw new Error("Insuficient liquidity for this trade");
  }

  return {
    offerAmount: BigInt(parseInt(offerAmount)),
    feeAmount: BigInt(parseInt(commissionAmount) + parseInt(buybackburnAmount)),
    spreadAmount: BigInt(parseInt(spreadAmount)),
    commissionAmount: BigInt(parseInt(commissionAmount)),
    buybackburnAmount: BigInt(parseInt(buybackburnAmount)),
  };
};
