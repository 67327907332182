import { Link } from "react-router-dom";
import Logo from "@axvdex/components/common/Logo";
import useLanguage from "@axvdex/hooks/useLanguage";
import ErrorPageLayout from "../layouts/ErrorPageLayout";

function Error404() {
  const { i18 } = useLanguage();

  const random404 = [
    "Oops! Looks like the page you were looking for rugged",
    "Oops! Page rugged",
    "Oops! Page left the blockchain party",
    "Oops! Page no longer captures value",
    "Oops! Page lost its devenue",
    "Oops! Page not a stable",
    "Oops! Page no longer in the pool",
    "Oops! Page is burnt",
    "Oops! No moon for that page",
    "Oops! No gas for page",
    "Oops! Couldn't state sync that page",
    "Oops! Everything pruned of page",
    "Oops! Page node found",
    "Oops! Page finality not found",
    "Oops! Page not validated",
    "Oops! Page not able to catch up",
    "Oops! Page lost in a consensus battle",
    "Oops! Page redacted by lawyers",
    "Oops! Page not in mempool",
    "Oops! Page suffered impermanent loss",
    "Oops! Page non fungible",
    "Oops! Page cannot be queried",
    "Oops! Page has no message handler",
    "Oops! Page is Rusty",
    "Oops! Page in Tarantinos closet",
    "Oops! Page stolen by crypto opponents",
    "Oops! Page is wrongly typed",
    "Oops! Page is Guru meditation",
    "Oops! Page is lost to a 68K #4e71",
    "Oops! Page is lost to a 68K #4e75",
    "Oops! Page is lost to a Z80 #c9",
    "Oops! Page not Almost Intelligent",
    "Oops! Page locked in escrow",
    "Oops! Page withdrawn",
    "Oops! Page not staked",
    "Oops! Page not deposited",
  ];

  const index = Math.floor(Math.random() * random404.length);

  return (
    <ErrorPageLayout errorNumber="404">
      <h1 className="errorPageText">{i18(random404[index], `errorPage.msg.${index}`)}</h1>

      <Link
        to="/"
        className="btn"
        data-color="gradient"
        title={i18("Go Back to Homepage", "errorPage.btnBackToHomepage")}
      >
        {i18("Back to Homepage", "errorPage.backToHomepage")}
      </Link>

      <div className="errorPageLogoFooter">
        <Logo unlink={true} hideMonogram={false} />
      </div>
    </ErrorPageLayout>
  );
}

export default Error404;
