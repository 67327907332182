import clsx from "clsx";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalModalContext } from "@axvdex/providers/GlobalModalProvider";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import Button from "@axvdex/components/common/Button";
import { setNavOpen } from "@axvdex/state/app/appSlice";
import { selectNavOpen } from "@axvdex/state/app/appSelectors";
import WelcomeBackground from "@axvdex/assets/images/default-welcome-dialog-bg.webp";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import useLanguage from "@axvdex/hooks/useLanguage";
import styles from "../styles/DashboardWelcomeDialog.module.scss";

function DashboardWelcomeDialog() {
  const dispatch = useAppDispatch();
  const isNavMenuOpen = useAppSelector(selectNavOpen);
  const { i18 } = useLanguage();
  const navigate = useNavigate();
  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    if (!isNavMenuOpen) dispatch(setNavOpen(true));
  }, []);

  const handleConnectWallet = useCallback(async () => {
    if (!loadState(WHITE_LIST_PERSISTED_STATE_KEYS.agreedCompliance)) {
      showModal("complianceModal", {
        connectWalletAfter: true,
      });
    } else {
      showModal("walletSelectionModal");
    }
  }, []);

  return (
    <div className={clsx(styles.dashboardWelcomeDialog, "dashboardWelcomeDialogOverlay")}>
      <aside className="dashboardWelcomeDialog">
        <img src={WelcomeBackground} alt={i18("Connect your wallet to start", "dashboard.welcome.dialog.img.alt")} />

        <div className="dashboardWelcomeDialogBody">
          <h2 className="h2">{i18("Welcome to Astrovault", "dashboard.welcome.dialog.title")}</h2>

          <p className="textGrey">
            {i18(
              "Before you continue on your interchain journey, take a moment to connect a wallet or learn about Astrovault",
              "dashboard.welcome.dialog.text"
            )}
          </p>

          <div className="buttonContainer">
            <Button
              btnColor="gradientText"
              text={i18("Connect your wallet to start", "dashboard.welcome.dialog.button.text")}
              title={i18("Connect Your Wallet", "dashboard.welcome.dialog.button.title")}
              onClick={handleConnectWallet}
            />
            <Button
              btnColor="gradient"
              text={i18("Learn more", "dashboard.welcome.dialog.link.about.text")}
              title={i18("See more about Astrovault", "dashboard.welcome.dialog.link.about.title")}
              style={{ marginLeft: "auto" }}
              onClick={() => {
                navigate("/about");
              }}
            />
          </div>
        </div>
      </aside>
    </div>
  );
}

export default DashboardWelcomeDialog;
