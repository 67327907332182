import BigNumber from "bignumber.js";
import { EventsEntity, IAsset, IContract, IPool, RawLogEntity } from "./interfaces";

export default (
  blockHeight: number | null,
  rawLog: RawLogEntity,
  assets: { [key: string]: IAsset },
  pools: { [key: string]: IPool },
  contracts: { [key: string]: IContract }
) => {
  try {
    const result = [];

    // parse logs depending on their log version (wasm only is old way)
    if (rawLog.events.find(({ type }) => type.includes("wasm-astrovault-"))) {
      // current log structure

      // with cosmos-sdk v0.47.0 version, the logs are structured in a different way so we need to check the log block height
      // attribute logs with the same event type are now separated instead of all grouped together inside the same array
      // archway updated to cosmos-sdk v0.47.0 on block height 3554500         // TODO: prepare this for multichain

      let sdk_0_47_0 = true;
      // if block height is not available, we assume it's the new version
      if (blockHeight && blockHeight < 3554500) {
        sdk_0_47_0 = false;
      }

      // check if its a routed swap
      const routerEvent = rawLog.events.find(({ type }) => type.includes("wasm-astrovault-router-handle_route"));
      const cashbackMint = rawLog.events.find(({ type }) => type.includes("wasm-astrovault-cashback-mint"));
      const mintEvent = rawLog.events.find(({ type }) =>
        type.includes("wasm-astrovault-staking_derivative-mint_derivative")
      );
      const standardSwap = rawLog.events.find(({ type }) => type.includes("wasm-astrovault-standard_pool-swap"));
      const stableSwap = rawLog.events.find(({ type }) => type.includes("wasm-astrovault-stable_pool-swap"));
      const hybridSwap = rawLog.events.find(({ type }) => type.includes("wasm-astrovault-ratio_pool-swap"));

      if (routerEvent) {
        if (sdk_0_47_0) {
          // as all events are in sequence and not duplicated, we can just loop through the relevant events and parse them
          for (const [i, event] of rawLog.events.entries()) {
            if (event.type === "wasm-astrovault-staking_derivative-mint_derivative") {
              const mintParsed = mintDerivativeParse(assets, contracts, event, 0);
              result.push({
                actionType: "mint",
                fromAssetID: mintParsed.firstAsset.id,
                toAssetID: mintParsed.secondAsset.id,
                fromAssetAmount: mintParsed.offerAmount,
                toAssetAmount: mintParsed.returnAmount,
                toAssetFeesAmount: mintParsed.feesAmount,
                gvt8Minted: mintParsed.gvt8Minted,
                poolAssetIDs: null,
              });
            }
            if (event.type === "wasm-astrovault-standard_pool-swap") {
              // find the next cashback mint event right after the currect event in the loop, so it is related to this specific swap
              const cashbackEvent = rawLog.events.find(
                (cashbackEvent, j) => j > i && cashbackEvent.type === "wasm-astrovault-cashback-mint"
              );
              const swapParsed = standardPoolSwapParse(assets, event, cashbackEvent, 0);
              result.push({
                actionType: "standard",
                fromAssetID: swapParsed.firstAsset.id,
                toAssetID: swapParsed.secondAsset.id,
                fromAssetAmount: swapParsed.offerAmount,
                toAssetAmount: swapParsed.returnAmount,
                toAssetFeesAmount: swapParsed.feesAmount,
                gvt8Minted: swapParsed.gvt8Minted,
                poolAssetIDs: pools[findOnSpecificEntry(event.attributes, "_contract_address", 0)].poolAssets.map(
                  poolAsset => poolAsset.info?.token?.contract_addr || poolAsset.info?.native_token?.denom
                ),
              });
            }
            if (event.type === "wasm-astrovault-stable_pool-swap") {
              // find the next cashback mint event right after the currect event in the loop, so it is related to this specific swap
              const cashbackEvent = rawLog.events.find(
                (cashbackEvent, j) => j > i && cashbackEvent.type === "wasm-astrovault-cashback-mint"
              );
              const swapParsed = stableORhybridPoolSwapParse(assets, pools, event, cashbackEvent, 0);
              result.push({
                actionType: "stable",
                fromAssetID: swapParsed.firstAsset.id,
                toAssetID: swapParsed.secondAsset.id,
                fromAssetAmount: swapParsed.offerAmount,
                toAssetAmount: swapParsed.returnAmount,
                toAssetFeesAmount: swapParsed.feesAmount,
                gvt8Minted: swapParsed.gvt8Minted,
                poolAssetIDs: pools[findOnSpecificEntry(event.attributes, "_contract_address", 0)].poolAssets.map(
                  poolAsset => poolAsset.info?.token?.contract_addr || poolAsset.info?.native_token?.denom
                ),
              });
            }
            if (event.type === "wasm-astrovault-ratio_pool-swap") {
              // find the next cashback mint event right after the currect event in the loop, so it is related to this specific swap
              const cashbackEvent = rawLog.events.find(
                (cashbackEvent, j) => j > i && cashbackEvent.type === "wasm-astrovault-cashback-mint"
              );
              const swapParsed = stableORhybridPoolSwapParse(assets, pools, event, cashbackEvent, 0);
              result.push({
                actionType: "hybrid",
                fromAssetID: swapParsed.firstAsset.id,
                toAssetID: swapParsed.secondAsset.id,
                fromAssetAmount: swapParsed.offerAmount,
                toAssetAmount: swapParsed.returnAmount,
                toAssetFeesAmount: swapParsed.feesAmount,
                gvt8Minted: swapParsed.gvt8Minted,
                poolAssetIDs: pools[findOnSpecificEntry(event.attributes, "_contract_address", 0)].poolAssets.map(
                  poolAsset => poolAsset.info?.token?.contract_addr || poolAsset.info?.native_token?.denom
                ),
              });
            }
          }
        } else {
          // we keep a track of repeat actions because inside the event on the loop we need to always get the next occurency of _contract_addr to get the correct sequence of actions when there are multiple events of the same type
          const repeatCounter = {
            mint_derivative: 0,
            standard_pool: 0,
            stable_pool: 0,
            ratio_pool: 0,
          };
          const actionSequence = routerEvent.attributes.filter(attr => attr.key === "hop").map(attr => attr.value);
          for (const actionType of actionSequence) {
            if (actionType === "mint_derivative") {
              const mintParsed = mintDerivativeParse(assets, contracts, mintEvent, repeatCounter.mint_derivative);
              result.push({
                actionType: "mint",
                fromAssetID: mintParsed.firstAsset.id,
                toAssetID: mintParsed.secondAsset.id,
                fromAssetAmount: mintParsed.offerAmount,
                toAssetAmount: mintParsed.returnAmount,
                toAssetFeesAmount: mintParsed.feesAmount,
                gvt8Minted: mintParsed.gvt8Minted,
                poolAssetIDs: null,
              });
              repeatCounter.mint_derivative += 1;
            }
            if (actionType === "standard_pool") {
              const swapParsed = standardPoolSwapParse(assets, standardSwap, cashbackMint, repeatCounter.standard_pool);
              result.push({
                actionType: "standard",
                fromAssetID: swapParsed.firstAsset.id,
                toAssetID: swapParsed.secondAsset.id,
                fromAssetAmount: swapParsed.offerAmount,
                toAssetAmount: swapParsed.returnAmount,
                toAssetFeesAmount: swapParsed.feesAmount,
                gvt8Minted: swapParsed.gvt8Minted,
                poolAssetIDs: pools[
                  findOnSpecificEntry(standardSwap.attributes, "_contract_address", repeatCounter.standard_pool)
                ].poolAssets.map(
                  poolAsset => poolAsset.info?.token?.contract_addr || poolAsset.info?.native_token?.denom
                ),
              });
              repeatCounter.standard_pool += 1;
            }
            if (actionType === "stable_pool") {
              const swapParsed = stableORhybridPoolSwapParse(
                assets,
                pools,
                stableSwap,
                cashbackMint,
                repeatCounter.stable_pool
              );
              result.push({
                actionType: "stable",
                fromAssetID: swapParsed.firstAsset.id,
                toAssetID: swapParsed.secondAsset.id,
                fromAssetAmount: swapParsed.offerAmount,
                toAssetAmount: swapParsed.returnAmount,
                toAssetFeesAmount: swapParsed.feesAmount,
                gvt8Minted: swapParsed.gvt8Minted,
                poolAssetIDs: pools[
                  findOnSpecificEntry(stableSwap.attributes, "_contract_address", repeatCounter.stable_pool)
                ].poolAssets.map(
                  poolAsset => poolAsset.info?.token?.contract_addr || poolAsset.info?.native_token?.denom
                ),
              });
              repeatCounter.stable_pool += 1;
            }
            if (actionType === "ratio_pool") {
              const swapParsed = stableORhybridPoolSwapParse(
                assets,
                pools,
                hybridSwap,
                cashbackMint,
                repeatCounter.ratio_pool
              );
              result.push({
                actionType: "hybrid",
                fromAssetID: swapParsed.firstAsset.id,
                toAssetID: swapParsed.secondAsset.id,
                fromAssetAmount: swapParsed.offerAmount,
                toAssetAmount: swapParsed.returnAmount,
                toAssetFeesAmount: swapParsed.feesAmount,
                gvt8Minted: swapParsed.gvt8Minted,
                poolAssetIDs: pools[
                  findOnSpecificEntry(hybridSwap.attributes, "_contract_address", repeatCounter.ratio_pool)
                ].poolAssets.map(
                  poolAsset => poolAsset.info?.token?.contract_addr || poolAsset.info?.native_token?.denom
                ),
              });
              repeatCounter.ratio_pool += 1;
            }
          }
        }
        return result;
      }

      // check individual mint or swap

      if (mintEvent) {
        const mintParsed = mintDerivativeParse(assets, contracts, mintEvent);
        result.push({
          actionType: "mint",
          fromAssetID: mintParsed.firstAsset.id,
          toAssetID: mintParsed.secondAsset.id,
          fromAssetAmount: mintParsed.offerAmount,
          toAssetAmount: mintParsed.returnAmount,
          toAssetFeesAmount: mintParsed.feesAmount,
          gvt8Minted: mintParsed.gvt8Minted,
          poolAssetIDs: null,
        });
        return result;
      }

      if (standardSwap) {
        const swapParsed = standardPoolSwapParse(assets, standardSwap, cashbackMint);
        result.push({
          actionType: "standard",
          fromAssetID: swapParsed.firstAsset.id,
          toAssetID: swapParsed.secondAsset.id,
          fromAssetAmount: swapParsed.offerAmount,
          toAssetAmount: swapParsed.returnAmount,
          toAssetFeesAmount: swapParsed.feesAmount,
          gvt8Minted: swapParsed.gvt8Minted,
          poolAssetIDs: pools[standardSwap.attributes[0].value].poolAssets.map(
            poolAsset => poolAsset.info?.token?.contract_addr || poolAsset.info?.native_token?.denom
          ),
        });
        return result;
      }

      if (stableSwap) {
        const swapParsed = stableORhybridPoolSwapParse(assets, pools, stableSwap, cashbackMint);
        result.push({
          actionType: "stable",
          fromAssetID: swapParsed.firstAsset.id,
          toAssetID: swapParsed.secondAsset.id,
          fromAssetAmount: swapParsed.offerAmount,
          toAssetAmount: swapParsed.returnAmount,
          toAssetFeesAmount: swapParsed.feesAmount,
          gvt8Minted: swapParsed.gvt8Minted,
          poolAssetIDs: pools[stableSwap.attributes[0].value].poolAssets.map(
            poolAsset => poolAsset.info?.token?.contract_addr || poolAsset.info?.native_token?.denom
          ),
        });
        return result;
      }

      if (hybridSwap) {
        const swapParsed = stableORhybridPoolSwapParse(assets, pools, hybridSwap, cashbackMint);
        result.push({
          actionType: "hybrid",
          fromAssetID: swapParsed.firstAsset.id,
          toAssetID: swapParsed.secondAsset.id,
          fromAssetAmount: swapParsed.offerAmount,
          toAssetAmount: swapParsed.returnAmount,
          toAssetFeesAmount: swapParsed.feesAmount,
          gvt8Minted: swapParsed.gvt8Minted,
          poolAssetIDs: pools[hybridSwap.attributes[0].value].poolAssets.map(
            poolAsset => poolAsset.info?.token?.contract_addr || poolAsset.info?.native_token?.denom
          ),
        });
        return result;
      }
    } else {
      // OLD WAY (backwards compatible)
      const wasmObject = rawLog.events.find(({ type }) => type === "wasm");

      let currentWasmEntries = [...wasmObject.attributes];
      // eslint-disable-next-line no-constant-condition
      while (true) {
        // get first action
        const indexAction = currentWasmEntries.findIndex(
          wasmData => (wasmData.key === "action" && wasmData.value === "swap") || wasmData.value === "mint_derivative"
        );
        if (indexAction === -1) break;
        // next action index
        const nextIndexAction = currentWasmEntries.findIndex(
          (wasmData, i) =>
            (i > indexAction && wasmData.key === "action" && wasmData.value === "swap") ||
            (i > indexAction && wasmData.key === "action" && wasmData.value === "mint_derivative")
        );

        const actionType = currentWasmEntries[indexAction].value;
        const hopObject = {
          actionType: null,
          fromAssetID: null,
          toAssetID: null,
          fromAssetAmount: null,
          toAssetAmount: null,
          toAssetFeesAmount: null,
          gvt8Minted: null,
          poolAssetIDs: null,
        };
        if (actionType === "mint_derivative") {
          const derivativeContractID = Object.keys(contracts).find(
            contractKey => contracts[contractKey].address === currentWasmEntries[indexAction - 1].value
          );
          const assetID = contracts[derivativeContractID].config.network_settings.native_asset_denom;
          const assetAmount =
            currentWasmEntries[indexAction + 4].key === "amount"
              ? currentWasmEntries[indexAction + 4].value // this is for arch derivative (source when having rewards)
              : currentWasmEntries[indexAction + 3].key === "amount"
              ? currentWasmEntries[indexAction + 3].value // this happens on external derivatives
              : currentWasmEntries[indexAction + 1].value; // last resort
          hopObject.fromAssetID = assetID;
          hopObject.fromAssetAmount = assetAmount;
          hopObject.toAssetID = contracts[derivativeContractID].config.dx_token;
          hopObject.toAssetAmount = assetAmount;
          hopObject.toAssetFeesAmount = "0";
          hopObject.gvt8Minted = "0";
          hopObject.poolAssetIDs = null;
          hopObject.actionType = "mint";
        }
        if (actionType === "swap" && pools[currentWasmEntries[indexAction - 1].value]) {
          const pool = pools[currentWasmEntries[indexAction - 1].value];
          if (pool.type === "standard") {
            const gvt8Index = currentWasmEntries.findIndex(
              wasmData => wasmData.key === "action" && wasmData.value === "mint"
            );
            hopObject.actionType = "standard";
            hopObject.fromAssetID = currentWasmEntries.find(wasmData => wasmData.key === "offer_asset").value;
            hopObject.fromAssetAmount = currentWasmEntries.find(wasmData => wasmData.key === "offer_amount").value;
            hopObject.toAssetID = currentWasmEntries.find(wasmData => wasmData.key === "ask_asset").value;
            hopObject.toAssetAmount = currentWasmEntries.find(wasmData => wasmData.key === "return_amount").value;
            hopObject.toAssetFeesAmount = BigNumber(
              currentWasmEntries.find(wasmData => wasmData.key === "buybackburn_amount").value
            )
              .plus(currentWasmEntries.find(wasmData => wasmData.key === "commission_amount").value)
              .toString(10);
            // this is to protect to get gvt8 amounts from next hops, so we constrain the indexes possible
            hopObject.gvt8Minted =
              nextIndexAction === -1
                ? currentWasmEntries[gvt8Index + 1].value
                : gvt8Index < nextIndexAction
                ? currentWasmEntries[gvt8Index + 1].value
                : "0";
            hopObject.poolAssetIDs = pool.poolAssets.map(
              poolAsset => poolAsset.info?.token?.contract_addr || poolAsset.info?.native_token?.denom
            );
          } else if (pool.type === "stable") {
            const fromAssetAmounts = JSON.parse(
              wasmObject.attributes.find(wasmData => wasmData.key === "from_assets_amount").value
            );
            const fromAssetIndex = fromAssetAmounts.findIndex(asset => BigNumber(asset).gt(0));
            const fromAssetID =
              pool.poolAssets[fromAssetIndex].info?.token?.contract_addr ||
              pool.poolAssets[fromAssetIndex].info?.native_token?.denom;
            const fromAssetAmount = fromAssetAmounts[fromAssetIndex];

            const toAssetAmounts = JSON.parse(
              wasmObject.attributes.find(wasmData => wasmData.key === "to_assets_amount").value
            );
            const toAssetIndex = toAssetAmounts.findIndex(asset => BigNumber(asset).gt(0));
            const toAssetID =
              pool.poolAssets[toAssetIndex].info?.token?.contract_addr ||
              pool.poolAssets[toAssetIndex].info?.native_token?.denom;
            const toAssetAmount = toAssetAmounts[toAssetIndex];

            const toAssetFeesAmounts = JSON.parse(
              wasmObject.attributes.find(wasmData => wasmData.key === "assets_fee_amount").value
            );
            const toAssetFeesIndex = toAssetFeesAmounts.findIndex(asset => BigNumber(asset).gt(0));
            const toAssetFeesAmount = toAssetFeesAmounts[toAssetFeesIndex];

            const gvt8Index = currentWasmEntries.findIndex(
              wasmData => wasmData.key === "action" && wasmData.value === "mint"
            );

            hopObject.actionType = "stable";
            hopObject.fromAssetID = fromAssetID;
            hopObject.fromAssetAmount = fromAssetAmount;
            hopObject.toAssetID = toAssetID;
            hopObject.toAssetAmount = toAssetAmount;
            hopObject.toAssetFeesAmount = toAssetFeesAmount || "0";
            hopObject.gvt8Minted =
              nextIndexAction === -1
                ? currentWasmEntries[gvt8Index + 1].value
                : gvt8Index < nextIndexAction
                ? currentWasmEntries[gvt8Index + 1].value
                : "0";
            hopObject.poolAssetIDs = pool.poolAssets.map(
              poolAsset => poolAsset.info?.token?.contract_addr || poolAsset.info?.native_token?.denom
            );
          } else if (pool.type === "hybrid") {
            const fromAssetAmounts = JSON.parse(
              wasmObject.attributes.find(wasmData => wasmData.key === "from_assets_amount").value
            );
            const fromAssetIndex = fromAssetAmounts.findIndex(asset => BigNumber(asset).gt(0));
            const fromAssetID =
              pool.poolAssets[fromAssetIndex].info?.token?.contract_addr ||
              pool.poolAssets[fromAssetIndex].info?.native_token?.denom;
            const fromAssetAmount = fromAssetAmounts[fromAssetIndex];

            const toAssetAmounts = JSON.parse(
              wasmObject.attributes.find(wasmData => wasmData.key === "to_assets_amount").value
            );
            const toAssetIndex = toAssetAmounts.findIndex(asset => BigNumber(asset).gt(0));
            const toAssetID =
              pool.poolAssets[toAssetIndex].info?.token?.contract_addr ||
              pool.poolAssets[toAssetIndex].info?.native_token?.denom;
            const toAssetAmount = toAssetAmounts[toAssetIndex];

            const toAssetFeesAmounts = JSON.parse(
              wasmObject.attributes.find(wasmData => wasmData.key === "assets_fee_amount").value
            );
            const toAssetFeesIndex = toAssetFeesAmounts.findIndex(asset => BigNumber(asset).gt(0));
            const toAssetFeesAmount = toAssetFeesAmounts[toAssetFeesIndex];

            const gvt8Index = currentWasmEntries.findIndex(
              wasmData => wasmData.key === "action" && wasmData.value === "mint"
            );

            hopObject.actionType = "hybrid";
            hopObject.fromAssetID = fromAssetID;
            hopObject.fromAssetAmount = fromAssetAmount;
            hopObject.toAssetID = toAssetID;
            hopObject.toAssetAmount = toAssetAmount;
            hopObject.toAssetFeesAmount = toAssetFeesAmount || "0";
            hopObject.gvt8Minted =
              nextIndexAction === -1
                ? currentWasmEntries[gvt8Index + 1].value
                : gvt8Index < nextIndexAction
                ? currentWasmEntries[gvt8Index + 1].value
                : "0";
            hopObject.poolAssetIDs = pool.poolAssets.map(
              poolAsset => poolAsset.info?.token?.contract_addr || poolAsset.info?.native_token?.denom
            );
          }
        }
        result.push(hopObject);
        if (nextIndexAction !== -1) {
          // nextIndexAction - 1 because we need to accommodate for the next loop to find the contract address that is on the prev index of the action
          currentWasmEntries = currentWasmEntries.slice(nextIndexAction - 1, currentWasmEntries.length - 1);
        } else {
          break;
        }
      }
    }
    return result;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const simulatedTradeConverterToVisualizer = (simulatedValues, pools) => {
  return simulatedValues.route.map(hop => {
    return {
      actionType:
        hop.derivativeOperation === "mint"
          ? "mint"
          : pools[hop.p].type === "standard"
          ? "standard"
          : pools[hop.p].type === "stable"
          ? "stable"
          : pools[hop.p].type === "hybrid"
          ? "hybrid"
          : "-",
      fromAssetID: hop.x,
      toAssetID: hop.y,
      fromAssetAmount: hop.offerAmount.toString(),
      toAssetAmount: hop.askAmount.toString(),
      toAssetFeesAmount: hop.feeAmount.toString(),
      gvt8Minted: hop.expectedCashbackMinted.toString(),
      poolAssetIDs: pools[hop.p].poolAssets.map(
        poolAsset => poolAsset.info?.token?.contract_addr || poolAsset.info?.native_token?.denom
      ),
    };
  });
};

const findOnSpecificEntry = (array: any, key_to_find: string, entryCount: number): string | undefined => {
  if (entryCount === 0) {
    return array.find(attr => attr.key === key_to_find)?.value as string;
  }
  if (entryCount === -1) {
    return array.findLast(attr => attr.key === key_to_find)?.value as string;
  }

  const filteredEntries = array.filter(attr => attr.key === key_to_find);
  return filteredEntries.length > entryCount ? (filteredEntries[entryCount].value as string) : undefined;
};

export const mintDerivativeParse = (
  assets: { [key: string]: IAsset },
  contracts: { [key: string]: IContract },
  event: EventsEntity,
  entryCount = 0
) => {
  const derivativeContract = findOnSpecificEntry(event.attributes, "_contract_address", entryCount);
  const derivativeState = Object.values(contracts).find(contract => contract.address === derivativeContract);
  const firstAssetAmount = findOnSpecificEntry(event.attributes, "amount", entryCount);

  const secondAssetAmount = firstAssetAmount;
  if (!derivativeState) {
    return null;
  }
  const firstAssetID = derivativeState.config!.network_settings.native_asset_denom;
  const secondAssetID = derivativeState.config.dx_token;
  const firstAsset = assets[firstAssetID];
  const secondAsset = assets[secondAssetID];
  const assetIdentification = `${firstAssetID}_${secondAssetID}`;

  return {
    assetIdentification,
    firstAsset,
    offerAmount: firstAssetAmount,
    secondAsset,
    returnAmount: secondAssetAmount,
    feesAmount: "0",
    gvt8Minted: "0",
  };
};

export const standardPoolSwapParse = (
  assets: { [key: string]: IAsset },
  standardPoolSwap: EventsEntity,
  cashbackMint: EventsEntity,
  entryCount = 0
) => {
  const offerAmount = findOnSpecificEntry(standardPoolSwap.attributes, "offer_amount", entryCount);
  const returnAmount = findOnSpecificEntry(standardPoolSwap.attributes, "return_amount", entryCount);
  const firstAssetID = findOnSpecificEntry(standardPoolSwap.attributes, "offer_asset", entryCount);
  const secondAssetID = findOnSpecificEntry(standardPoolSwap.attributes, "ask_asset", entryCount);

  const firstAsset = assets[firstAssetID];
  const secondAsset = assets[secondAssetID];
  const assetIdentification = `${firstAssetID}_${secondAssetID}`;

  return {
    assetIdentification,
    firstAsset,
    offerAmount,
    secondAsset,
    returnAmount,
    feesAmount: BigNumber(findOnSpecificEntry(standardPoolSwap.attributes, "buybackburn_amount", entryCount) ?? "0")
      .plus(findOnSpecificEntry(standardPoolSwap.attributes, "commission_amount", entryCount) ?? "0")
      .toString(10),
    gvt8Minted: cashbackMint ? findOnSpecificEntry(cashbackMint.attributes, "amount", entryCount) : "0",
  };
};

export const stableORhybridPoolSwapParse = (
  assets: { [key: string]: IAsset },
  pools: { [key: string]: IPool },
  event: EventsEntity,
  cashbackMint: EventsEntity,
  entryCount = 0
) => {
  const firstAssetAddressAmount = findOnSpecificEntry(event.attributes, "from_assets_amount", entryCount);
  const transformFirstAssetAddress = JSON.parse(firstAssetAddressAmount);
  const firstAssetIndex = transformFirstAssetAddress.findIndex(firstAsset => Number(firstAsset) > 0);
  const offerAmount = transformFirstAssetAddress.find(firstAsset => Number(firstAsset) > 0);

  const poolAssets = pools[findOnSpecificEntry(event.attributes, "_contract_address", entryCount)].poolAssets;

  const firstPoolAsset = poolAssets[firstAssetIndex];
  const firstAssetID = firstPoolAsset?.info?.token?.contract_addr || firstPoolAsset?.info?.native_token?.denom;
  const firstAsset = assets[firstAssetID];

  const secondAssetAddressValue = findOnSpecificEntry(event.attributes, "to_assets_amount", entryCount);

  const transformSecondAssetAddress = JSON.parse(secondAssetAddressValue);

  const secondAssetIndex = transformSecondAssetAddress.findIndex(firstAsset => Number(firstAsset) > 0);
  const returnAmount = transformSecondAssetAddress.find(firstAsset => Number(firstAsset) > 0);

  const secondPoolAsset = poolAssets[secondAssetIndex];
  const secondAssetID = secondPoolAsset?.info?.token?.contract_addr || secondPoolAsset?.info?.native_token?.denom;
  const secondAsset = assets[secondAssetID];

  const assetIdentification = `${firstAssetID}_${secondAssetID}`;

  const toAssetFeesAmounts = JSON.parse(findOnSpecificEntry(event.attributes, "assets_fee_amount", entryCount));
  const toAssetFeesIndex = toAssetFeesAmounts.findIndex(asset => BigNumber(asset).gt(0));
  const toAssetFeesAmount = toAssetFeesAmounts[toAssetFeesIndex];

  return {
    assetIdentification,
    firstAsset,
    offerAmount,
    secondAsset,
    returnAmount,
    feesAmount: toAssetFeesAmount || "0",
    gvt8Minted: cashbackMint ? findOnSpecificEntry(cashbackMint.attributes, "amount", entryCount) : "0",
  };
};
