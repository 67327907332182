import FarmsTable from "../components/farms/FarmsTable";

function Farm() {
  return (
    <>
      <FarmsTable />
    </>
  );
}

export default Farm;
