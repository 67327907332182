import { AlertTriangle } from "react-feather";
import { useEffect, useState } from "react";
import { useAppSelector } from "@axvdex/state";
import { selectStatusCounters } from "@axvdex/state/wallet/walletSelectors";
import useLanguage from "@axvdex/hooks/useLanguage";

const testStatusMsg = !!0; // set to !!1 to enable, !!0 to disable

function StatusMessages() {
  const { i18 } = useLanguage();
  const statusCounters = useAppSelector(selectStatusCounters);
  const [statusMessages, setStatusMessages] = useState(null);

  useEffect(() => {
    if (testStatusMsg || (statusCounters && statusCounters.rpc >= 15)) {
      // alert appears if there are 15 messages of error between current window and prev
      setStatusMessages(
        <div className="flexbox">
          <span className="warning tokenIcon">
            <AlertTriangle />
          </span>
          <span className="tokenName">
            {/* good msg but too techie, toned it down but kept an indicator of context for tech support *RPC nodes* */}
            {/*Status Report: RPC nodes are currently unstable. Onchain requests can be affected by this...*/}
            {i18(
              "Network service issues detected that can disrupt Astrovault onchain requests with RPC nodes",
              "statusMessages.rpcNodes.text"
            )}
          </span>
        </div>
      );

      /* output a warning to the console to prevent this test mode being accidentally left on */
      console.log("WARNING: Status 'testStatusMsg' mode is active");
    }
  }, [statusCounters]);

  if (!statusMessages) return <></>;
  return <section className="seviceStatus withGradientBorder">{statusMessages}</section>;
}

export default StatusMessages;
