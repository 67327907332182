import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../contexts/LanguageContext";

const useLanguage = () => {
  const { language, changeLanguage } = useContext(LanguageContext);

  const { t, i18n } = useTranslation();

  const i18 = useCallback(
    (defaultValue = "", translationKey: string, replacements = {}) => {
      /*
        replacements is an optional k, v object where k is a string to replace, and v is the replacement value
        for example; if; replacements={"find", "replace"}
        and translationKey or defaultValue is "hello {{find}}"
        then "hello replace" is returned
      */

      const useDefault = !0; // (def: !0) If false, the translation file has priority over the default value

      let result;
      if (useDefault && ("en" === language.toLowerCase() || (!i18n.exists(translationKey) && defaultValue))) {
        if ("MAINNET" !== process.env.REACT_APP_MODE && !i18n.exists(translationKey)) {
          console.log(`WARN: Missing translationKey: "${translationKey}" defaultValue: "${defaultValue}"`);
        }
        result = defaultValue;
      } else {
        result = t(translationKey, replacements);
      }

      // document.title needs to be passed string type not String type
      // therefore, unwrap this value to a string as there is probably
      // no need for String type
      return result.valueOf();
    },
    [language, i18n, t]
  );

  return { language, changeLanguage, i18 };
};

export default useLanguage;
