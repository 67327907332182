import axios from "axios";

export default async (chain: any) => {
  const mainRPC = chain.rpc;
  const fallbackRPCs = chain.fallbackRPCs ?? [];
  for (const rpc of [mainRPC, ...fallbackRPCs]) {
    try {
      await axios.get(rpc + "/abci_info", {
        timeout: 5000,
      });
      return rpc;
    } catch (err) {
      /* empty */
    }
  }
  return mainRPC;
};
