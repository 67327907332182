export const responsiveBreakpoints = {
  mobileSm: "(max-width: 575px)",
  mobile: "(max-width: 1024px)",
};

export const chainConfig =
  process.env.REACT_APP_ARCHWAY_NETWORK === "constantine-3"
    ? {
        bech32Config: {
          bech32PrefixAccAddr: "archway",
          bech32PrefixAccPub: "archwaypub",
          bech32PrefixConsAddr: "archwayvalcons",
          bech32PrefixConsPub: "archwayvalconspub",
          bech32PrefixValAddr: "archwayvaloper",
          bech32PrefixValPub: "archwayvaloperpub",
        },
        bip44: {
          coinType: 118,
        },
        chainId: "constantine-3",
        chainName: "Archway (Testnet)",
        chainSymbolImageUrl:
          "https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/constantine/chain.png",
        currencies: [
          {
            coinDecimals: 18,
            coinDenom: "CONST",
            coinGeckoId: "constantine-network",
            coinMinimalDenom: "aconst",
          },
        ],
        features: ["cosmwasm"],
        feeCurrencies: [
          {
            coinDecimals: 18,
            coinDenom: "CONST",
            coinGeckoId: "constantine-network",
            coinMinimalDenom: "aconst",
            gasPriceStep: {
              low: 1000000000000,
              average: 1500000000000,
              high: 2000000000000,
            },
          },
        ],
        rest: "https://api.constantine.archway.io",
        rpc: "https://rpc.constantine.archway.io",
        stakeCurrency: {
          coinDecimals: 18,
          coinDenom: "CONST",
          coinGeckoId: "constantine-network",
          coinMinimalDenom: "aconst",
        },
        nodeProvider: {
          name: "Phi Labs",
          email: "support@philabs.xyz",
          website: "https://philabs.xyz",
        },
      }
    : process.env.REACT_APP_ARCHWAY_NETWORK === "arch-local"
    ? {
        bech32Config: {
          bech32PrefixAccAddr: "archway",
          bech32PrefixAccPub: "archwaypub",
          bech32PrefixConsAddr: "archwayvalcons",
          bech32PrefixConsPub: "archwayvalconspub",
          bech32PrefixValAddr: "archwayvaloper",
          bech32PrefixValPub: "archwayvaloperpub",
        },
        bip44: {
          coinType: 118,
        },
        chainId: "arch-local",
        chainName: "Archway",
        chainSymbolImageUrl:
          "https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/archway/chain.png",
        currencies: [
          {
            coinDecimals: 18,
            coinDenom: "ARCH",
            coinGeckoId: "archway",
            coinMinimalDenom: "aarch",
          },
        ],
        features: ["cosmwasm"],
        feeCurrencies: [
          {
            coinDecimals: 18,
            coinDenom: "ARCH",
            coinGeckoId: "archway",
            coinMinimalDenom: "aarch",
            gasPriceStep: {
              low: 1000000000000,
              average: 1500000000000,
              high: 2000000000000,
            },
          },
        ],
        rest: "http://localhost:1317",
        rpc: "http://localhost:26657",
        stakeCurrency: {
          coinDecimals: 18,
          coinDenom: "ARCH",
          coinGeckoId: "archway",
          coinMinimalDenom: "aarch",
        },
      }
    : {
        bech32Config: {
          bech32PrefixAccAddr: "archway",
          bech32PrefixAccPub: "archwaypub",
          bech32PrefixConsAddr: "archwayvalcons",
          bech32PrefixConsPub: "archwayvalconspub",
          bech32PrefixValAddr: "archwayvaloper",
          bech32PrefixValPub: "archwayvaloperpub",
        },
        bip44: {
          coinType: 118,
        },
        chainId: "archway-1",
        chainName: "Archway",
        chainSymbolImageUrl:
          "https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/archway/chain.png",
        currencies: [
          {
            coinDecimals: 18,
            coinDenom: "ARCH",
            coinGeckoId: "archway",
            coinMinimalDenom: "aarch",
          },
        ],
        features: ["cosmwasm"],
        feeCurrencies: [
          {
            coinDecimals: 18,
            coinDenom: "ARCH",
            coinGeckoId: "archway",
            coinMinimalDenom: "aarch",
            gasPriceStep: {
              low: 1000000000000,
              average: 1500000000000,
              high: 2000000000000,
            },
          },
        ],
        rest: "https://api.mainnet.archway.io",
        rpc: "https://rpc.mainnet.archway.io",
        stakeCurrency: {
          coinDecimals: 18,
          coinDenom: "ARCH",
          coinGeckoId: "archway",
          coinMinimalDenom: "aarch",
        },
        nodeProvider: {
          name: "Phi Labs",
          email: "support@philabs.xyz",
          website: "https://philabs.xyz",
        },
        walletUrlForStaking: "https://wallet.keplr.app/chains/archway",
      };
