import BigNumber from "bignumber.js";
import { loadState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";

export default (message: string, i18: any) => {
  let parsedMessage = message;

  const replacement =
    (message.includes("Request rejected") && "Request rejected") ||
    (message.includes("User rejected the request") && "User rejected the request") ||
    (message.includes("Transaction declined") && "Transaction declined") ||
    false;
  if (replacement) {
    let wallet = null;
    if (loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet)) {
      wallet =
        loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet).charAt(0).toUpperCase() +
        loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet).slice(1);
    }
    parsedMessage = message.replace(
      replacement,
      wallet
        ? i18(`Action cancelled in ${wallet}`, "parseWallet.error.ActionCancelledParam", { wallet })
        : i18(`Action cancelled`, "parseWallet.error.ActionCancelled")
    );
  }

  if (message.includes(process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM)) {
    parsedMessage = divideNumbersInString(message, 18, process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM);
  }

  if (message.includes("Minimum receive assertion failed.")) {
    try {
      const minAmount = message.split("minimum amount: ")[1].split(" ")[0];
      const endSwapAmount = message.split("end swap amount: ")[1].split(" ")[0];
      const txHash = message.split("broadcasting tx ")[1].split(" ")[0];

      parsedMessage = i18(
        `Slippage tolerance protection triggered.\nMinimum amount: ${minAmount}\nReturn amount: ${endSwapAmount}\nTx Hash: ${txHash}`,
        "parseWallet.error.SlippageParam",
        { minAmount: minAmount, endSwapAmount: endSwapAmount, txHash: txHash }
      );
    } catch (e) {
      parsedMessage = i18("Slippage tolerance protection triggered.", "parseWallet.error.Slippage") + "\n" + message;
    }
  }

  // Outbid related messages
  if (message.includes("NotOnWhitelist")) {
    parsedMessage = i18("Wallet is not on the whitelist", "parseWallet.error.NotOnWhitelist");
  }
  if (message.includes("SaleWaitingToStart")) {
    parsedMessage = i18("Sale is waiting to start", "parseWallet.error.SaleWaitingToStart");
  }
  if (message.includes("SaleEnded")) {
    parsedMessage = i18("Sale has ended", "parseWallet.error.SaleEnded");
  }
  if (message.includes("MinTickets")) {
    parsedMessage = i18("Minimum tickets not met", "parseWallet.error.MinTickets");
  }
  if (message.includes("MaxTickets")) {
    parsedMessage = i18("Maximum tickets exceeded", "parseWallet.error.MaxTickets");
  }
  if (message.includes("WalletTicketCap")) {
    parsedMessage = i18("Wallet ticket cap exceeded", "parseWallet.error.WalletTicketCap");
  }

  if (message.includes("NicknameAlreadyUsed")) {
    parsedMessage = i18("Nickname is already in use, choose another!", "parseWallet.error.NicknameAlreadyUsed");
  }

  return parsedMessage;
};

function divideNumbersInString(inputText, decimals, delimiter) {
  const words = inputText.split(" ");
  const resultWords = [];

  for (const word of words) {
    if (word.includes(delimiter)) {
      const result = BigNumber(word.replace(/[a-zA-Z:]/g, ""))
        .div(Math.pow(10, decimals))
        .toString(10);

      resultWords.push(result + delimiter.slice(1));
    } else {
      resultWords.push(word);
    }
  }

  // modified text
  return resultWords.join(" ");
}
