import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";
import { responsiveBreakpoints } from "@axvdex/constants";
import { selectGlobalSettings } from "@axvdex/state/wallet/walletSelectors";
import { useAppSelector } from "@axvdex/state";
import styles from "../styles/HeaderNavItems.module.scss";
import HeaderUtilitiesMenu from "./HeaderUtilitiesMenu";

function HeaderNavItems() {
  const globalSettings = useAppSelector(selectGlobalSettings);

  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);

  return (
    <>
      <ul className={clsx(styles.headerNavItemsList, "headerNavItemsList")}>
        {!isMobileBreakpoint && globalSettings?.menuPosition === "top" && (
          <li className="headerNavItem utilities">
            <HeaderUtilitiesMenu />
          </li>
        )}
      </ul>
    </>
  );
}

export default HeaderNavItems;
