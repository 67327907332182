import { createSlice } from "@reduxjs/toolkit";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "../persist";

const savedNavOpen = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.isSideMenuOpen);
const connectedWallet = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);

export interface AppState {
  navOpen: boolean;
}

const initialState: AppState = {
  navOpen: !connectedWallet ? true : savedNavOpen,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setNavOpen: (state, { payload }) => {
      state.navOpen = payload;
    },
  },
});

export const { setNavOpen } = appSlice.actions;

export default appSlice.reducer;
