// import { useMediaQuery } from "usehooks-ts";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import useLanguage from "@axvdex/hooks/useLanguage";
// import { responsiveBreakpoints } from "@axvdex/constants";
import { useAppSelector } from "@axvdex/state";
import { selectAssets } from "@axvdex/state/wallet/walletSelectors";
import imgSanitize from "@axvdex/utils/imgSanitize";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
import Button from "../common/Button";
import { ReactComponent as IcnTwitter } from "../../assets/icons/icn-twitterx.svg";
import CustomModal from "../common/CustomModal";

function SaleIncentiveCongratsModal({
  saleIncentiveCongratsModalOpen,
  setSaleIncentiveCongratsModalOpen,
  outbidSale,
}: any) {
  const { i18 } = useLanguage();
  const assets = useAppSelector(selectAssets);
  const [nftImages, setNftImages] = useState<any>([]);

  useEffect(() => {
    if (saleIncentiveCongratsModalOpen.open) {
      const nfts = saleIncentiveCongratsModalOpen.incentives.filter((incentive: any) => "nft" === incentive[0]);
      getNftDetails(nfts);
    }
  }, [saleIncentiveCongratsModalOpen]);

  const getNftDetails = async nfts => {
    const nftsImages = [];
    for (const nft of nfts) {
      if ("nft" !== nft[0]) continue;
      const res = await rpcClientQuerySmartContractWrapper(nft[1], {
        all_nft_info: { token_id: nft[2] },
      });
      if (res?.info?.extension.image) nftsImages.push(res.info.extension.image);
    }

    setNftImages(nftsImages);
  };

  return (
    <>
      <CustomModal
        isOpen={saleIncentiveCongratsModalOpen.open}
        onClose={() => {
          setNftImages([]);
          setSaleIncentiveCongratsModalOpen({
            open: false,
            incentives: [],
          });
        }}
        modalSize="sm"
      >
        <section className="sectionModalHeader">
          <h2>{i18("Outbid Reward", "sale.outbid.reward")}</h2>
        </section>
        <div style={{ fontSize: "1em" }}>
          <p style={{ fontSize: "1em" }}>
            {i18("Congratulations! You've won the following rewards:", "sale.outbid.congrats")}
          </p>
          <div style={{ display: "flex", placeContent: "space-evenly" }}>
            {saleIncentiveCongratsModalOpen.incentives.map((incentive: any, index: number) => (
              <>
                {"asset" === incentive[0] && (
                  <div className="inlineFlexbox">
                    <span style={{ fontSize: "2em", marginRight: ".25em" }}>
                      {BigNumber(incentive[2]).div(Math.pow(10, assets[incentive[1]].decimals)).toString(10)}
                    </span>
                    <img
                      className="tokenIcon"
                      src={imgSanitize(assets[incentive[1]]?.symbol)}
                      alt={assets[incentive[1]]?.symbol}
                    />
                  </div>
                )}

                {"nft" === incentive[0] &&
                  nftImages.map((nftImage: any, index: number) => (
                    <img
                      key={nftImage}
                      src={nftImage}
                      alt={i18("NFT Image", "sale.nft.image")}
                      style={{ maxWidth: "10em" }}
                    />
                  ))}
              </>
            ))}
          </div>
        </div>
        <div style={{ textAlign: "right", marginTop: "1.5em" }}>
          <Button
            title={i18("Share on X", "sale.share.x")}
            style={{ padding: "1em", margin: ".5em", marginTop: "1em" }}
            btnColor="gradient"
            onClick={() => {
              const winString = saleIncentiveCongratsModalOpen.incentives
                .map(incentive => {
                  if ("asset" === incentive[0]) {
                    return `${BigNumber(incentive[2]).div(Math.pow(10, assets[incentive[1]].decimals)).toString(10)} ${
                      assets[incentive[1]].symbol
                    }`;
                  }
                  if ("nft" === incentive[0]) {
                    return i18("an NFT", "sale.an.nft");
                  }
                })
                .join(", ");

              window.open(
                "https://twitter.com/intent/tweet?text=" +
                  i18(
                    `I won ${winString} in the ${outbidSale.project} campaign on @outbid_launch where every bid is a winner%21`,
                    "sale.share.x.post",
                    { prize: winString, project: outbidSale.project }
                  ).replace(/(%20|\s)+/g, "+")
              );
            }}
          >
            {i18("Share on", "sale.share.on")}
            <IcnTwitter style={{ width: "1em", height: "1em" }} />
          </Button>
        </div>
      </CustomModal>
    </>
  );
}

export default SaleIncentiveCongratsModal;
