const handleResponse = async (response: Response) => {
  if (response.status === 401) {
    throw new Error("Unauthorized");
  }

  if (response.status === 504) {
    throw new Error("Timeout: Request failed to complete in time.");
  }

  const res = await response.json();

  if (response.status === 400) {
    throw new Error(res.message);
  }

  if (response.status === 403) {
    throw new Error(res.message);
  }

  if (response.status === 404) {
    throw new Error(res.message);
  }

  if (response.status < 200 || response.status >= 300) {
    throw new Error(`There has been an error. Response status: ${response.status}`);
  }

  return res;
};

export const fetchWrapper = (arg1?: string, url?: string, body?, additionalOptions?, headers?) => {
  // if called with one argument, default to 'GET' method
  const _method = url ? arg1.toUpperCase() : "GET";
  // if called without method arg, the first
  const _url = url || arg1;

  const options = {
    method: _method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Cache: "no-cache",
      ...headers,
    },
    credentials: "include",
    body: body && JSON.stringify(body), // body can be undefined, that's ok
    ...additionalOptions,
  };

  return fetch(`${process.env.REACT_APP_BACKEND_URL}${_url}`, options).then(handleResponse);
};
