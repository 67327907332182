import { initWalletInfo } from "./walletSlice";
import { updateNativeBalance, updateTokenBalance } from "./walletThunks";

//https://github.com/eslint-community/eslint-plugin-promise/issues/111#issuecomment-663824152
// eslint-disable-next-line promise/avoid-new
export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const initWalletInfoEffect = {
  actionCreator: initWalletInfo,
  effect: async (_action, { dispatch, getState }) => {
    const { assets, ...wallet } = getState().wallet;
    const allAssets = Object.keys(assets);
    for (const currentAsset of allAssets) {
      if (wallet.assetBalances[currentAsset]) continue;
      const asset = assets[currentAsset];
      if (asset.isNative) {
        await dispatch(updateNativeBalance({ userAddress: wallet.walletInfo.walletAddress, denom: asset.denom }));
      } else {
        await dispatch(
          updateTokenBalance({ userAddress: wallet.walletInfo.walletAddress, tokenAddress: asset.address })
        );
      }
    }
  },
};
