import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { NumericFormat } from "react-number-format";
import { AlertTriangle, Settings, X } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import BigNumber from "bignumber.js";
import { findIndex } from "lodash";
import { getRouterHops } from "@axvdex/utils/swapScripts/preComputedRouterHops";
import {
  executeTrade,
  updateNativeBalance,
  updateTokenBalance,
  updateUserGlobalSettingsFields,
} from "@axvdex/state/wallet/walletThunks";
import { getFeeGrantOptions } from "@axvdex/api/user";
import { sendToast } from "@axvdex/state/wallet/walletSlice";
import { useQuery } from "@axvdex/hooks/useQuery";

import swapSimulation, { ISwapSimulationReturn } from "@axvdex/utils/swapScripts/swapSimulation";
import estimatedFees, {
  GAS_ESTIMATION_STATIC_MINT_DERIVATIVE,
  GAS_ESTIMATION_STATIC_TRADE_1_HOP_STABLE,
  GAS_ESTIMATION_STATIC_TRADE_1_HOP_STANDARD,
  GAS_ESTIMATION_STATIC_TRADE_1_HOP_HYBRID,
  GAS_ESTIMATION_STATIC_TRADE_ROUTER_OVERHEAD,
} from "@axvdex/utils/estimatedFees";
import { fromHumanAmountToTokenBalance } from "@axvdex/utils/formatNumber";
import { simulatedTradeConverterToVisualizer } from "@axvdex/utils/getTradeSequence";

import useLanguage from "@axvdex/hooks/useLanguage";
import TradeVisualiserModal from "../modals/TradeVisualiserModal";
import Button from "../common/Button";
import CustomSelect, { ListItemsProps } from "../form-element/CustomSelect";
import CustomNumericInput from "../form-element/CustomNumericInput";
import CustomInputButton from "../form-element/CustomInputButton";
import CustomSwitch from "../form-element/CustomSwitch";
import UserBalance from "../user/UserBalance";
import UserRewardsAmount from "../user/UserRewardsAmount";
import { ReactComponent as IcnTrade } from "../../assets/icons/icn-exchange-vertical.svg";
import { Asset, getTokenList, initialTradeInfo, TradeInfo } from "./tradeHelpers";
import {
  selectAssetBalances,
  selectAssets,
  selectContracts,
  selectFavouriteAssets,
  selectGlobalSettings,
  selectPools,
  selectStatusCounters,
  selectUserLoading,
  selectWalletInfo,
} from "state/wallet/walletSelectors";
import { useAppDispatch, useAppSelector } from "state";

function TradeForm({ feeGrantAssetsToTrade }: any) {
  const highFeeConfirm = 15;

  const { i18 } = useLanguage();

  const TradeFromTokenSlippageList = useMemo(
    () => [
      { id: "2", name: "trade_from_token_slippage", label: "2%" },
      { id: "3", name: "trade_from_token_slippage", label: "3%" },
      { id: "4", name: "trade_from_token_slippage", label: "4%" },
      { id: "custom", name: "trade_from_token_slippage", label: i18("Custom", "trade.form.slippage.custom") },
    ],
    [i18]
  );

  const dispatch = useAppDispatch();
  const poolsStored = useAppSelector(selectPools);
  const assetsStored = useAppSelector(selectAssets);
  const walletInfo = useAppSelector(selectWalletInfo);
  const contracts = useAppSelector(selectContracts);
  const assetBalances = useAppSelector(selectAssetBalances);
  const favouriteAssets = useAppSelector(selectFavouriteAssets);
  const isUpdatingUser = useAppSelector(selectUserLoading);
  const statusCounters = useAppSelector(selectStatusCounters);
  const globalSettings = useAppSelector(selectGlobalSettings);

  // const [activeRow, setActiveRow] = useState("from");
  const [isTransactionStarts, setIsTransactionStarts] = useState(false);
  const [assets, setAssets] = useState({});
  const [shouldValueBeInUSD, setShouldValueBeInUSD] = useState(false);
  const [simulationValues, setSimulationValues] = useState<ISwapSimulationReturn>(null);
  const [doesRouteExist, setDoesRouteExist] = useState(true);
  const [exchangeRateSwitchFrom, setExchangeRateSwitchFrom] = useState(true);
  const [marketImpactSwitch, setMarketImpactSwitch] = useState(true);
  const [tradeInfo, setTradeInfo] = useState<TradeInfo>(initialTradeInfo);

  const [openSlippage, setOpenSlippage] = useState(false);
  const [slippage, setSlippage] = useState("2");
  const [slippageType, setSlippageType] = useState("hardcoded");
  const [isolatedPoolTrading, setIsolatedPoolTrading] = useState(false);

  const handleToggleSlippage = useCallback(() => {
    setOpenSlippage(openSlippage => !openSlippage);
  }, []);

  const { control, handleSubmit, setValue } = useForm();
  const [tokenList, setTokenList] = useState([]);
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [fromSelectedToken, setFromSelectedToken] = useState<ListItemsProps>({
    label: "",
    value: "",
    optionPrefix: "",
    optionSuffix: "",
  });
  const [toSelectedToken, setToSelectedToken] = useState<ListItemsProps>({
    label: "",
    value: "",
    optionPrefix: "",
    optionSuffix: "",
  });

  const [fromTokenAmount, setFromTokenAmount] = useState("");
  const [toTokenAmount, setToTokenAmount] = useState("0");
  const [largeFeeComponent, setLargeFeeComponent] = useState({
    visible: false,
    activated: false,
  });

  const query = useQuery();

  useEffect(() => {
    // when globalSettings change we update the slippage tolerance defaults with it if configured
    if (globalSettings && globalSettings.slippageTolerance) {
      setSlippage(globalSettings.slippageTolerance.amount);
      setSlippageType(globalSettings.slippageTolerance.type);
    }
    if (globalSettings && globalSettings.tradeIsolatedPoolTrading) {
      setIsolatedPoolTrading(globalSettings.tradeIsolatedPoolTrading);
    }
  }, [globalSettings]);

  // if there is feeGrantAssets coming in the state...
  useEffect(() => {
    if (feeGrantAssetsToTrade) {
      setFromSelectedToken(feeGrantAssetsToTrade.fromAsset);
      setToSelectedToken(feeGrantAssetsToTrade.toAsset);
      setFromTokenAmount(feeGrantAssetsToTrade.fromAmount);
    }
  }, [feeGrantAssetsToTrade]);

  useEffect(() => {
    // if (Object.keys(assetsStored)?.length !== Object.keys(assets)?.length) {
    const [list, tokenList] = getTokenList(assetsStored, assetBalances, favouriteAssets);
    setAssets(list);
    setTokenList(tokenList);
    // }
    // eslint-disable-next-line
  }, [assetsStored, assetBalances, favouriteAssets]);

  const updateCurrentAssetBalance = async currentAsset => {
    if (assetBalances[currentAsset.id]) return;
    if (currentAsset.isNative) {
      await dispatch(updateNativeBalance({ userAddress: walletInfo.walletAddress, denom: currentAsset.denom }));
    } else {
      await dispatch(updateTokenBalance({ tokenAddress: currentAsset.address, userAddress: walletInfo.walletAddress }));
    }
  };

  useEffect(() => {
    if (tokenList.length && !isUpdatingUser && !fromSelectedToken.value && !toSelectedToken.value) {
      const from = query.get("from");
      const to = query.get("to");
      const value = query.get("amount");
      // const retrade = query.get("retrade");
      const [firstFav, secondeFav] = favouriteAssets;
      // console.log("favouriteAssets", { firstFav, secondFav });
      let fromTokenIdx;
      if (from) {
        fromTokenIdx = tokenList.findIndex(token => token.value === from);
      } else if (firstFav) {
        fromTokenIdx = tokenList.findIndex(token => token.id === firstFav);
      } else {
        fromTokenIdx = 0;
      }
      const fromToken = tokenList[fromTokenIdx >= 0 ? fromTokenIdx : 0];

      const newTokenList = tokenList.filter(t => t.id !== fromToken.id);
      let toTokenIdx;
      if (to) {
        toTokenIdx = newTokenList.findIndex(token => token.value === to);
      } else if (secondeFav) {
        toTokenIdx = newTokenList.findIndex(token => token.id === secondeFav);
      } else {
        //This will be indexed in the tokenList after filtering out the fromToken
        toTokenIdx = 0;
      }
      const toToken = newTokenList.filter(t => t.id !== fromToken.id)[toTokenIdx >= 0 ? toTokenIdx : 0];

      const firstAsset = getAssetByNameFromStored(fromToken.value);
      const secondAsset = getAssetByNameFromStored(toToken.value);
      if (firstAsset) {
        updateCurrentAssetBalance(firstAsset);
      }
      if (secondAsset) {
        updateCurrentAssetBalance(secondAsset);
      }
      // const favouriteDetails = firstAsset && secondAsset && retrade;
      // if (retrade && firstAsset && walletInfo.isConnected && favouriteDetails) {
      //   setFromTokenAmount(favouriteDetails.fromToken.amount);
      // }
      setFromSelectedToken(fromToken);
      setToSelectedToken(toToken);
      setFromTokenAmount(value || "");
      setValue("trade_from_token", fromToken);
      setValue("trade_to_token", toToken);
    }
  }, [tokenList, favouriteAssets, walletInfo.isConnected]);

  useEffect(() => {
    setFromOptions(tokenList.filter(token => token.value !== toSelectedToken?.value));
    setToOptions(tokenList.filter(token => token.value !== fromSelectedToken?.value));
  }, [assets, fromSelectedToken, toSelectedToken]);

  useEffect(() => {
    if (fromSelectedToken.label !== "" && toSelectedToken.label !== "" && fromTokenAmount !== "") {
      let fromAmount;
      fromAmount = fromTokenAmount.match(/[\d,.]+/);
      if (Array.isArray(fromAmount) && fromAmount.length > 0) {
        fromAmount = fromAmount[0].replace(/,/g, "");
      } else {
        fromAmount = "0";
      }
      if (Number.isNaN(fromAmount) || Number(fromAmount) <= 0) fromAmount = "0";
      const amount = Number(fromAmount) || 0;
      const slippageTolerance = Number(slippage);
      const fromAsset = getAssetByNameFromStored(fromSelectedToken.value);
      const toAsset = getAssetByNameFromStored(toSelectedToken.value);
      const amountForSwap = shouldValueBeInUSD
        ? BigNumber(amount / fromAsset.price)
            .decimalPlaces(fromAsset.decimals)
            .toNumber()
        : BigNumber(amount).decimalPlaces(fromAsset.decimals).toNumber();

      // console.log(amount);
      try {
        const res = swapSimulation(
          poolsStored,
          assetsStored,
          contracts,
          statusCounters,
          getRouterHops(),
          fromAsset.id,
          amountForSwap.toString(),
          toAsset.id,
          null,
          slippageTolerance,
          // if coming from fee grant it should be max of 3 hops
          // if isolated pool trading only 1 hop is possible
          feeGrantAssetsToTrade ? 3 : isolatedPoolTrading ? 1 : null
        );
        const askAmount = BigNumber(res.askAmount).decimalPlaces(6, BigNumber.ROUND_FLOOR).toString(10);
        setSimulationValues(res);
        setToTokenAmount(askAmount || "0");
        setTradeInfo((prevState: TradeInfo) => {
          return {
            ...prevState,
            fromAsset,
            toAsset,
            fromFieldValue: amountForSwap,
            toFieldValue: Number(askAmount || 0),
            fromFieldValueInUSD: shouldValueBeInUSD
              ? amount / prevState.fromAsset.price
              : amount * prevState.fromAsset.price,
            toFieldValueInUSD: (Number(askAmount) || 0) * prevState?.toAsset?.price || 0,
          };
        });
        setDoesRouteExist(true);
      } catch (err) {
        if (err?.message === "No paths found") {
          setDoesRouteExist(false);
        } else {
          console.error(err);
        }
        setSimulationValues(null);
        setToTokenAmount("0");
        setTradeInfo((prevState: TradeInfo) => {
          return {
            ...prevState,
            fromAsset,
            toAsset,
            fromFieldValue: amountForSwap,
            toFieldValue: 0,
            fromFieldValueInUSD: shouldValueBeInUSD
              ? amount / prevState.fromAsset.price
              : amount * prevState.fromAsset.price,
            toFieldValueInUSD: 0,
          };
        });
      }
    }
  }, [
    fromTokenAmount,
    fromSelectedToken,
    toSelectedToken,
    shouldValueBeInUSD,
    slippage,
    poolsStored,
    isolatedPoolTrading,
  ]);

  const handleFromTokenChange = (selectedOption: ListItemsProps) => {
    setFromSelectedToken(selectedOption);
    tokenSelected(selectedOption);
  };

  const handleToTokenChange = (selectedOption: ListItemsProps) => {
    setToSelectedToken(selectedOption);
    tokenSelected(selectedOption);
  };

  const tokenSelected = async (selectedOption: ListItemsProps) => {
    const asset = getAssetByNameFromStored(selectedOption.value);
    if (assetBalances[asset.id]) return;
    if (asset.isNative) {
      await dispatch(updateNativeBalance({ userAddress: walletInfo.walletAddress, denom: asset.denom }));
    } else {
      await dispatch(updateTokenBalance({ tokenAddress: asset.address, userAddress: walletInfo.walletAddress }));
    }
  };

  const getAssetByName = (assetName: string) => {
    const asset = Object.keys(assets).find(asset => {
      if (assets[asset]?.isDerivative) {
        return assets[asset]?.label === assetName;
      }
      return assets[asset]?.symbol === assetName;
    });

    return assets[asset];
  };

  const getAssetByNameFromStored = (assetName: string): Asset => {
    const asset = Object.keys(assetsStored).find(asset => {
      if (assetsStored[asset]?.isDerivative) {
        return assetsStored[asset]?.label === assetName;
      }
      return assetsStored[asset]?.symbol === assetName;
    });
    const storedAsset = assetsStored[asset];
    //Convert stored asset to Asset type to avoid type errors
    return {
      id: storedAsset.id,
      isNative: storedAsset.isNative,
      denom: storedAsset.denom,
      address: storedAsset.address,
      decimals: storedAsset.decimals,
      isDerivative: storedAsset.isDerivative,
      symbol: storedAsset.symbol,
      label: storedAsset.label,
      price: storedAsset.price,
    };
  };

  const handleFromTokenAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const amountForSwap = e.target.value;
    setFromTokenAmount(amountForSwap);
    setLargeFeeComponent({ visible: false, activated: false });
  };

  const handleTradeSubmit = async _ => {
    // when isolated trade is enabled and there is no route, clicking on the button would disable this option
    if ((!doesRouteExist || !tradeInfo.toFieldValue) && isolatedPoolTrading) {
      setIsolatedPoolTrading(false);
      dispatch(
        updateUserGlobalSettingsFields({
          tradeIsolatedPoolTrading: false,
        })
      );
      return;
    }

    if (!largeFeeComponent.visible && parseFloat(simulationValues?.feePer) >= highFeeConfirm) {
      setLargeFeeComponent({ visible: true, activated: false });
      return;
    }
    if (
      BigNumber(tradeInfo.fromFieldValue).gt(assetBalances[tradeInfo.fromAsset.address || tradeInfo.fromAsset.denom])
    ) {
      // snackbar.error(`You do not have enough ${tradeInfo.fromAsset.label || tradeInfo.fromAsset.symbol}`);
      return;
    }
    setIsTransactionStarts(true);
    try {
      // console.log({
      //   walletAddress: walletInfo.walletAddress,
      //   simulationValues,
      //   assets: assetsStored,
      //   contracts,
      //   fromAsset: tradeInfo.fromAsset,
      //   toAsset: tradeInfo.toAsset,
      // });

      let granterAddress = undefined;
      if (
        (walletInfo.walletConnectSequence === 0 || walletInfo.walletConnectSequence === null) &&
        assetBalances[process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM] === "0" &&
        feeGrantAssetsToTrade
      ) {
        const options = await getFeeGrantOptions();
        if (options.data.feeGrants.feeGranters.length === 0) {
          dispatch(
            sendToast({
              type: "tx-fail",
              info: { msg: "All Fee granters are busy, try again later...", toastID: "" + new Date().getTime() },
            })
          );
          setIsTransactionStarts(false);
          return;
        }
        granterAddress =
          options.data.feeGrants.feeGranters[Math.floor(Math.random() * options.data.feeGrants.feeGranters.length)]
            .granterAddress;
      }

      await dispatch(
        executeTrade({
          walletAddress: walletInfo.walletAddress,
          simulationValues,
          assets: assetsStored,
          pools: poolsStored,
          contracts,
          fromAsset: tradeInfo.fromAsset,
          toAsset: tradeInfo.toAsset,
          granterAddress,
          walletSignerType: walletInfo.walletSignerType,
          i18,
        })
      );
      //setTradeInfo(initialTradeInfo);
    } catch (err) {
      console.error(i18("Failed transaction. Please try later", "trade.form.failedTxn"), err);
    }
    setIsTransactionStarts(false);
    setLargeFeeComponent({ visible: false, activated: false });
  };

  const handleMaxButton = async () => {
    if (fromSelectedToken?.value) {
      const assetPrice = getAssetByNameFromStored(fromSelectedToken.value).price;
      const tokenAmount = BigNumber(getAssetByName(fromSelectedToken.value)?.balance || "0").decimalPlaces(
        6,
        BigNumber.ROUND_FLOOR
      );
      setFromTokenAmount((shouldValueBeInUSD ? tokenAmount.times(assetPrice).toString() : tokenAmount).toString());
    }
  };

  const handleAssetValueSwitch = shouldValueBeInUSD => {
    setShouldValueBeInUSD(shouldValueBeInUSD);
    const assetPrice = getAssetByNameFromStored(fromSelectedToken.value).price;
    const fromTokenValue = Number(fromTokenAmount.replace(/[^0-9.]/g, "")) || 0;
    setFromTokenAmount((shouldValueBeInUSD ? fromTokenValue * assetPrice : fromTokenValue / assetPrice).toString());
  };

  const switchTokens = () => {
    setFromSelectedToken(toSelectedToken);
    setToSelectedToken(fromSelectedToken);
    setToTokenAmount(fromTokenAmount);
    setFromTokenAmount(toTokenAmount);
    setShouldValueBeInUSD(false);
    setExchangeRateSwitchFrom(true);
  };

  const submitButtonText = () => {
    const disabledConfig = { disabled: true, extraClass: "" };
    const action =
      simulationValues?.route.length > 1
        ? i18("Trade", "trade.action.trade")
        : simulationValues?.route[0]?.derivativeOperation === "mint"
        ? i18("Mint", "trade.action.mint")
        : i18("Trade", "trade.action.trade");

    if (!walletInfo.isConnected) {
      return {
        buttonText: i18("Connect wallet", "trade.submitBtn.text.connectWallet"),
        ...disabledConfig,
      };
    }

    if (!tradeInfo.fromFieldValue) {
      return {
        buttonText: i18("Enter Amount", "trade.submitBtn.text.enterAmount"),
        ...disabledConfig,
      };
    }

    if (
      tradeInfo.fromAsset &&
      BigNumber(tradeInfo.fromFieldValue)
        .times(Math.pow(10, tradeInfo.fromAsset.decimals))
        .gt(BigNumber(assetBalances[tradeInfo.fromAsset.id]))
    ) {
      return {
        buttonText: i18(`Not enough ${tradeInfo.fromAsset.symbol} balance`, "trade.submitBtn.text.notEnoughBalance", {
          symbol: tradeInfo.fromAsset.symbol,
        }),
        ...disabledConfig,
      };
    }

    if (isTransactionStarts) {
      return {
        buttonText: i18("Executing...", "trade.submitBtn.text.executing"),
        ...disabledConfig,
      };
    }

    if ((!doesRouteExist || !tradeInfo.toFieldValue) && isolatedPoolTrading) {
      return {
        disabled: false,
        buttonText: i18("Disable isolated pool trading", "trade.submitBtn.text.disableIsolatedPoolTrading"),
        extraClass: "",
      };
    }

    if (!doesRouteExist || !tradeInfo.toFieldValue) {
      return {
        buttonText: i18("No route for this trade", "trade.submitBtn.text.noRoute"),
        ...disabledConfig,
      };
    }

    if (Number(simulationValues?.marketImpactPer) > 100) {
      return {
        buttonText: i18("Market impact > 100%", "trade.submitBtn.text.marketImpact"),
        ...disabledConfig,
      };
    }

    if (Number(simulationValues?.priceImpactPer) > 100) {
      return {
        buttonText: i18("Price impact > 100%", "trade.submitBtn.text.priceImpact"),
        ...disabledConfig,
      };
    }

    // check if user has sufficient gas fees to cover this

    const calcEstimatedFees = estimatedFees(
      simulationValues?.route.length === 1 && simulationValues?.route[0]?.derivativeOperation === "mint"
        ? GAS_ESTIMATION_STATIC_MINT_DERIVATIVE
        : simulationValues?.route.reduce((acc, obj) => {
            if (poolsStored[obj.p].type === "stable") {
              return acc + GAS_ESTIMATION_STATIC_TRADE_1_HOP_STABLE;
            }
            if (poolsStored[obj.p].type === "standard") {
              return acc + GAS_ESTIMATION_STATIC_TRADE_1_HOP_STANDARD;
            }
            if (poolsStored[obj.p].type === "hybrid") {
              return acc + GAS_ESTIMATION_STATIC_TRADE_1_HOP_HYBRID;
            }
            return acc + 0;
          }, 0) +
            (simulationValues?.route.length > 1
              ? GAS_ESTIMATION_STATIC_TRADE_ROUTER_OVERHEAD * simulationValues?.route.length
              : 0),
      statusCounters.estimatedFeesReference
    );
    if (
      !feeGrantAssetsToTrade &&
      assetBalances[process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM] &&
      BigNumber(assetBalances[process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM]).lt(calcEstimatedFees)
    ) {
      return {
        buttonText: i18(`Wallet needs ARCH for ${action}`, "trade.submitBtn.text.walletNoFunds", { action }),
        ...disabledConfig,
      };
    }

    // if the input is the source denom we need to check if it will consume more fees
    // current balance - input amount < estimated fees
    try {
      let fromAmount;
      fromAmount = fromTokenAmount.match(/[\d.]+/);
      if (Array.isArray(fromAmount) && fromAmount.length > 0) {
        fromAmount = fromAmount[0].replace(/,/g, "");
      } else {
        fromAmount = "0";
      }
      if (Number.isNaN(fromAmount) || Number(fromAmount) <= 0) fromAmount = "0";
      if (
        !feeGrantAssetsToTrade &&
        tradeInfo.fromAsset.id === process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM &&
        assetBalances[process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM] &&
        BigNumber(assetBalances[process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM])
          .minus(
            fromHumanAmountToTokenBalance(
              fromAmount.replaceAll(",", ""),
              assetsStored[process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM].decimals
            )
          )
          .lt(calcEstimatedFees)
      ) {
        return {
          buttonText: i18(`Wallet needs ARCH for ${action}`, "trade.submitBtn.text.walletNoFunds", { action }),
          ...disabledConfig,
        };
      }
    } catch (e) {
      console.error("Failed to calculate fees", e);
      /* empty */
    }

    if (largeFeeComponent.visible) {
      if (!largeFeeComponent.activated) {
        return {
          buttonText: i18(`Confirm HIGH FEE ${action}`, "trade.submitBtn.text.highFeeConfirm", { action }),
          ...disabledConfig,
        };
      }

      return {
        buttonText: i18(`Yes, make this HIGH FEE ${action}`, "trade.submitBtn.text.highFeeAgree", { action }),
        extraClass: "highFeeButton",
        disabled: false,
      };
    }

    return {
      buttonText: action,
      extraClass: "",
      disabled: false,
    };
  };

  const buttonConfig = submitButtonText();
  return (
    <form className="tradeForm" onSubmit={handleSubmit(handleTradeSubmit)}>
      <fieldset className="tradeFromTokenFieldset withGradientBorder">
        <legend className="visuallyHidden">{i18("Trade from token", "trade.form.fromToken.section.legend")}</legend>

        <fieldset className="selectTokenSubFieldset tradeFromToken">
          <legend className="visuallyHidden">{i18("Select token", "trade.form.fromToken.legend")}</legend>

          <div className="flexbox">
            <Controller
              name="trade_from_token"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ...field } }) => {
                return (
                  <CustomSelect
                    {...field}
                    extraClassName="tradeFromTokenFormGroupSelect"
                    labelText={i18("From", "trade.form.fromToken.select.label")}
                    hiddenLabel={true}
                    items={fromOptions}
                    placeholder={i18("Select token", "trade.form.fromToken.select.placeholder")}
                    value={fromOptions[findIndex(fromOptions, item => item.value === fromSelectedToken.value)]}
                    onChange={(val: ListItemsProps) => {
                      handleFromTokenChange(val);
                      onChange(val);
                    }}
                  />
                );
              }}
            />

            <CustomNumericInput
              extraClassName="tradeFromTokenUsdFormGroup"
              name="trade_from_usd"
              labelText={i18("Choose amount", "trade.form.fromToken.input.label")}
              value={fromTokenAmount}
              onChange={handleFromTokenAmountChange}
              hiddenLabel={true}
              placeholder={"0"}
              decimalScale={6}
              suffix={` ${shouldValueBeInUSD ? i18("USD", "trade.form.amountInUSD") : fromSelectedToken.label}`}
            />
          </div>

          <div className="helpText selectTokenSubFieldsetHelpText">
            <Button btnColor="gradientText" onClick={() => handleAssetValueSwitch(!shouldValueBeInUSD)} title="">
              <span className="textGradient">
                <NumericFormat
                  className="balanceUsdValue"
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  decimalScale={6}
                  value={
                    (!shouldValueBeInUSD
                      ? BigNumber(simulationValues?.offerAmountInUSD || 0)
                          .decimalPlaces(6, BigNumber.ROUND_FLOOR)
                          .toNumber()
                      : tradeInfo.fromFieldValue) || 0
                  }
                  prefix={i18("Modify as ", "trade.form.fromToken.usd.prefix")}
                  suffix={` ${!shouldValueBeInUSD ? i18("USD", "trade.form.amountInUSD") : fromSelectedToken.value}`}
                />
              </span>
            </Button>
          </div>
        </fieldset>

        <div className="selectTokenBalanceAmount">
          <UserBalance
            balanceValue={getAssetByName(fromSelectedToken.value)?.balance || 0}
            balanceUsdValue={getAssetByName(fromSelectedToken.value)?.usdBalance || 0}
          />

          <div className="buttonContainer">
            <Button
              btnColor="dark-medium"
              text={i18("Max", "trade.form.fromToken.maxBtn.text")}
              title={i18("Select Max Value", "trade.form.fromToken.maxBtn.title")}
              onClick={handleMaxButton}
            />

            <Button
              extraClassName={clsx("btnTradeOpenSlippage", openSlippage && "isOpen")}
              btnColor="dark-medium"
              btnVariant="icon"
              title={i18("Set slippage tolerance", "trade.form.slippageBtn.title")}
              icon={openSlippage ? <X /> : <Settings />}
              onClick={handleToggleSlippage}
            />
          </div>
        </div>

        <div className={clsx("tradeFomTokenSlippageFieldsetWrapper", openSlippage && "isOpen")}>
          <fieldset className="tradeFomTokenSlippageFieldset">
            <legend>{i18("Slippage tolerance", "trade.form.slippage.legend")}</legend>

            <div className="btnGroup">
              {TradeFromTokenSlippageList.map((input, idx) => (
                <CustomInputButton
                  key={idx}
                  checked={slippageType === "hardcoded" ? input.id === slippage : input.id === "custom"}
                  type="radio"
                  id={input.id}
                  name="slippage"
                  labelText={input.label}
                  value={input.id}
                  onChange={() => {
                    if (input.id === "custom") {
                      setSlippage(slippage);
                      setSlippageType("custom");
                      dispatch(
                        updateUserGlobalSettingsFields({
                          slippageTolerance: { type: "custom", amount: slippage },
                        })
                      );
                    } else {
                      setSlippage(input.id);
                      setSlippageType("hardcoded");
                      dispatch(
                        updateUserGlobalSettingsFields({
                          slippageTolerance: { type: "hardcoded", amount: input.id },
                        })
                      );
                    }
                  }}
                />
              ))}
            </div>

            {slippageType === "custom" && (
              <CustomNumericInput
                extraClassName="tradeCustomSlippageFormGroup"
                name="trade_custom_slippage_amount"
                labelText={i18("Custom slippage amount", "trade.form.slippage.custom.label")}
                value={slippage}
                onChange={e => {
                  setSlippage(e.target.value);
                  dispatch(
                    updateUserGlobalSettingsFields({
                      slippageTolerance: { type: "custom", amount: e.target.value },
                    })
                  );
                }}
              />
            )}
            <div style={{ marginTop: "1em" }}>
              <CustomSwitch
                extraClassName="StableWithdrawalCustomSwitch"
                name="isolatedPoolTrading"
                labelText={
                  <>
                    <p>{i18("Isolated pool trading (Advanced)", "trade.form.isolatedPoolTrading.label")}</p>
                  </>
                }
                isToggled={isolatedPoolTrading}
                onToggle={e => {
                  setIsolatedPoolTrading(e.target.checked);
                  dispatch(
                    updateUserGlobalSettingsFields({
                      tradeIsolatedPoolTrading: e.target.checked,
                    })
                  );
                }}
              />
            </div>
          </fieldset>
        </div>

        <Button
          extraClassName="switchIcon"
          btnColor="dark-medium"
          btnVariant="icon"
          title={i18("Switch trade tokens", "trade.form.switchBtn.title")}
          icon={<IcnTrade />}
          onClick={switchTokens}
        />
      </fieldset>

      <fieldset className="withGradientBorder">
        <legend className="visuallyHidden">{i18("Trade to token", "trade.form.toToken.section.legend")}</legend>

        <div className="selectTokenSubFieldset tradeToToken">
          <div className="flexbox">
            <Controller
              name="trade_to_token"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ...field } }) => (
                <CustomSelect
                  {...field}
                  extraClassName="tradeToTokenFormGroupSelect"
                  labelText={i18("To", "trade.form.toToken.select.label")}
                  hiddenLabel={true}
                  placeholder={i18("Select token", "trade.form.toToken.select.placeholder")}
                  items={toOptions}
                  value={toOptions[findIndex(toOptions, item => item.value === toSelectedToken.value)]}
                  onChange={(val: ListItemsProps) => {
                    handleToTokenChange(val);
                    onChange(val);
                  }}
                />
              )}
            />

            <CustomNumericInput
              extraClassName="tradeToTokenUsdFormGroup gradientText"
              name="trade_to_usd"
              labelText={i18("USD trade amount", "trade.form.toToken.input.label")}
              hiddenLabel={true}
              placeholder={"0"}
              value={
                shouldValueBeInUSD && simulationValues
                  ? BigNumber(simulationValues.askAmountInUSD).decimalPlaces(6, BigNumber.ROUND_FLOOR).toNumber()
                  : toTokenAmount
              }
              //decimalScale={6}
              suffix={shouldValueBeInUSD && simulationValues ? " " + i18("USD", "trade.form.amountInUSD") : ""}
              disabled={true}
            />
          </div>
        </div>

        <div className="flexbox visualiser">
          <UserBalance
            balanceValue={getAssetByName(toSelectedToken.value)?.balance || 0}
            balanceUsdValue={getAssetByName(toSelectedToken.value)?.usdBalance || 0}
          />

          {simulationValues && (
            <TradeVisualiserModal
              source={"trade"}
              tradeSequenceRaw={simulatedTradeConverterToVisualizer(simulationValues, poolsStored)}
              gasFees={estimatedFees(
                simulationValues?.route.length === 1 && simulationValues?.route[0]?.derivativeOperation === "mint"
                  ? GAS_ESTIMATION_STATIC_MINT_DERIVATIVE
                  : simulationValues?.route.reduce((acc, obj) => {
                      if (poolsStored[obj.p].type === "stable") {
                        return acc + GAS_ESTIMATION_STATIC_TRADE_1_HOP_STABLE;
                      }
                      if (poolsStored[obj.p].type === "standard") {
                        return acc + GAS_ESTIMATION_STATIC_TRADE_1_HOP_STANDARD;
                      }
                      if (poolsStored[obj.p].type === "hybrid") {
                        return acc + GAS_ESTIMATION_STATIC_TRADE_1_HOP_HYBRID;
                      }
                      return acc + 0;
                    }, 0) +
                      (simulationValues?.route.length > 1
                        ? GAS_ESTIMATION_STATIC_TRADE_ROUTER_OVERHEAD * simulationValues?.route.length
                        : 0),
                statusCounters.estimatedFeesReference
              )}
            />
          )}
        </div>
      </fieldset>

      <ul className="tradeFormList">
        <li>
          <em className="tradeListName">
            {i18(`Minimum received (${slippage}%)`, "trade.form.minimumReceived.em", { slippage: slippage })}
          </em>
          <NumericFormat
            className="tradeListValue"
            displayType="text"
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={6}
            suffix={` ${tradeInfo.toAsset.symbol} ($${
              simulationValues?.minimumReceivedInUSD >= 0.01
                ? Math.round(simulationValues?.minimumReceivedInUSD * 100) / 100
                : "0.00"
            })`}
            value={
              simulationValues && simulationValues?.minimumReceivedInUSD >= 0.000001
                ? parseFloat(simulationValues?.minimumReceived) < 0
                  ? "0"
                  : simulationValues?.minimumReceived
                : "0"
            }
          />
        </li>
        <li>
          <div className="inlineFlexbox">
            <em className="tradeListName">
              {marketImpactSwitch
                ? i18("Price impact", "trade.form.priceImpact.em")
                : i18("Market impact", "trade.form.marketImpact.em")}
            </em>

            {simulationValues && (
              <Button
                btnColor="gradientText"
                btnVariant="icon"
                title={
                  marketImpactSwitch
                    ? i18("See market impact", "trade.form.marketImpact.btn.title")
                    : i18("See price impact", "trade.form.priceImpact.btn.title")
                }
                icon={<i className="feather icon-repeat" />}
                onClick={() => setMarketImpactSwitch(!marketImpactSwitch)}
              />
            )}
          </div>

          {marketImpactSwitch ? (
            <div className="inlineFlexbox">
              {Number(simulationValues?.priceImpactPer) > 5 && (
                <span className="tradeListValuePreIcon">
                  <AlertTriangle />
                </span>
              )}
              <NumericFormat
                className={clsx(
                  "tradeListValue",
                  Number(simulationValues?.priceImpactPer) > 3 && "bold",
                  Number(simulationValues?.priceImpactPer) > 5 && "red"
                )}
                displayType="text"
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={2}
                suffix="%"
                prefix={Number(simulationValues?.priceImpactPer) > 100 ? ">" : ""}
                value={Number(simulationValues?.priceImpactPer) > 100 ? 100 : simulationValues?.priceImpactPer}
              />
            </div>
          ) : (
            <div className="inlineFlexbox">
              {Number(simulationValues?.marketImpactPer) > 5 && (
                <span className="tradeListValuePreIcon">
                  <AlertTriangle />
                </span>
              )}
              <NumericFormat
                className={clsx(
                  "tradeListValue",
                  Number(simulationValues?.marketImpactPer) > 3 && "bold",
                  Number(simulationValues?.marketImpactPer) > 5 && "red",
                  Number(simulationValues?.marketImpactPer) < 0 && "green"
                )}
                displayType="text"
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={2}
                suffix="%"
                prefix={Number(simulationValues?.marketImpactPer) > 100 ? ">" : ""}
                value={Number(simulationValues?.marketImpactPer) > 100 ? 100 : simulationValues?.marketImpactPer}
              />
            </div>
          )}
        </li>
        <li>
          <em className="tradeListName">{i18("Fees", "trade.form.fees.em")}</em>
          <div className="inlineFlexbox">
            {parseFloat(simulationValues?.feePer) > 5 && (
              <span className="tradeListValuePreIcon">
                <AlertTriangle />
              </span>
            )}
            <NumericFormat
              className={clsx(
                "tradeListValue",
                parseFloat(simulationValues?.feePer) > 3 && "bold",
                parseFloat(simulationValues?.feePer) > 5 && "red"
              )}
              displayType="text"
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              suffix={`% ($${simulationValues ? Math.round(simulationValues?.feeInUSD * 100) / 100 || 0 : 0})`}
              value={
                simulationValues ? (parseFloat(simulationValues?.feePer) > 100 ? 100 : simulationValues?.feePer) : "0"
              }
            />
          </div>
        </li>
        <li>
          <div className="inlineFlexbox">
            <em className="tradeListName">{i18("Exchange rate", "trade.form.exchangeRate.em")}</em>
            {simulationValues && (
              <Button
                btnColor="gradientText"
                btnVariant="icon"
                title={i18("Reverse", "trade.form.exchangeRate.btn.title")}
                icon={<i className="feather icon-repeat" />}
                onClick={() => setExchangeRateSwitchFrom(!exchangeRateSwitchFrom)}
              />
            )}
          </div>

          {simulationValues ? (
            <span className="inlineFlexbox">
              <span className="tradeListValue">
                {exchangeRateSwitchFrom
                  ? `1 ${tradeInfo.fromAsset.symbol} = ${
                      simulationValues?.offerToAskRatio === "-"
                        ? "-"
                        : BigNumber(simulationValues?.offerToAskRatio).decimalPlaces(6, BigNumber.ROUND_FLOOR) || "-"
                    } ${tradeInfo.toAsset.symbol}`
                  : `1 ${tradeInfo.toAsset.symbol} = ${
                      simulationValues?.askToOfferRatio === "-" || Number(simulationValues?.marketImpactPer) > 100
                        ? "-"
                        : BigNumber(simulationValues?.askToOfferRatio).decimalPlaces(6, BigNumber.ROUND_FLOOR) || "-"
                    } ${tradeInfo.fromAsset.symbol}`}
              </span>
            </span>
          ) : (
            <span className="tradeListValue">-</span>
          )}

          {/* <NumericFormat
            className="tradeListValue"
            displayType="text"
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={3}
            suffix=""
            value={simulationValues?.offerToAskRatio || "-"}
          /> */}
        </li>

        <li>
          <em className="tradeListName">{i18("GRVT8 Rewards", "trade.grvt8rewards")}</em>
          <UserRewardsAmount
            rewardsValueProps={{
              value: Math.round(Number(simulationValues?.expectedCashbackAmount) * 1000000) / 1000000 || 0,
            }}
          />
        </li>
      </ul>

      <Button
        type="submit"
        btnColor="gradient"
        isFullWidth={true}
        text={buttonConfig.buttonText}
        title={i18("Submit", "trade.submitBtn.title")}
        disabled={buttonConfig.disabled}
        extraClassName={buttonConfig.extraClass}
      />

      {largeFeeComponent.visible && (
        <section className="highFee withGradientBorder">
          <CustomSwitch
            name="feeConfirm"
            labelText={
              <>
                <div className={clsx("highFeeText", "inlineFlexbox")}>
                  <small>
                    <NumericFormat
                      displayType="text"
                      thousandSeparator=","
                      decimalSeparator="."
                      decimalScale={2}
                      prefix={i18("Warning HIGH FEE", "trade.form.highFee") + ": "}
                      suffix={`% ($${simulationValues ? Math.round(simulationValues?.feeInUSD * 100) / 100 || 0 : 0})`}
                      value={
                        simulationValues
                          ? parseFloat(simulationValues?.feePer) > 100
                            ? 100
                            : simulationValues?.feePer
                          : "0"
                      }
                    />
                  </small>
                </div>

                <div>
                  <small className="textGrey">
                    {i18("Do you REALLY want to make this HIGH FEE trade?", "trade.form.highFeeConfirm")}
                  </small>
                </div>
              </>
            }
            isToggled={largeFeeComponent.activated}
            onToggle={e => setLargeFeeComponent({ activated: e.target.checked, visible: true })}
          />
        </section>
      )}
    </form>
  );
}

export default TradeForm;
