import { createAsyncThunk } from "@reduxjs/toolkit";
import { postFarmFavourite } from "@axvdex/api/user";
// import { postPoolFavourite } from "@axvdex/api/user";
import { loadState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";
import { AsyncThunkConfig } from "..";
import { addFavouriteFarm, deleteFavouriteFarm } from "../wallet/walletSlice";
// import { addFavouritePool, deleteFavouritePool } from "../wallet/walletSlice";

export const triggerFarmFavouriteChange = createAsyncThunk<
  void,
  { isFavourite: boolean; farmAddress: string },
  AsyncThunkConfig
>("pool/triggerFarmFavouriteChange", async ({ isFavourite, farmAddress }, { dispatch, getState }) => {
  const { walletAddress } = getState().wallet.walletInfo;
  const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
  if (permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress]) {
    const method = isFavourite ? "POST" : "DELETE";

    try {
      await postFarmFavourite(
        method,
        { favoriteAddress: farmAddress },
        {
          address: walletAddress,
          signature: permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress],
        }
      );

      // snackbar.success(i18("header.saveStarred", "Success saving starred"));

      if (isFavourite) {
        dispatch(addFavouriteFarm({ favoriteFarm: farmAddress }));
      } else {
        dispatch(deleteFavouriteFarm({ favoriteFarm: farmAddress }));
      }
    } catch (err) {
      console.error(err);
      // snackbar.error(i18("header.errorStarred", "Starred not saved. Please try later"));
    }
  }
});
