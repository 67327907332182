import {
  // IAsset,
  IFarm,
  // IPool
} from "@axvdex/utils/interfaces";
import { fetchWrapper } from "./index";

export const getListOfFarms = (): Promise<{ data?: Array<IFarm>; ok: boolean }> => {
  return fetchWrapper(
    "/farm" + (process.env.REACT_APP_SUB_MODE ? "?env=" + process.env.REACT_APP_SUB_MODE.toLowerCase() : "")
  );
};
