import { ReactNode } from "react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import useLanguage from "@axvdex/hooks/useLanguage";

interface ActiveLinkProps {
  extraClassName?: string;
  href: string;
  text?: string;
  title?: string;
  icon?: ReactNode;
  iconPlacement?: "left" | "right";
  isDisabled?: boolean;
  children?: ReactNode;
}

function ActiveLink({ extraClassName, href, text, title, icon, iconPlacement, isDisabled, children }: ActiveLinkProps) {
  const { i18 } = useLanguage();

  return (
    <NavLink
      to={href}
      className={({ isActive }) =>
        clsx("navLink", isActive && "active", extraClassName && extraClassName, isDisabled && "isDisabled")
      }
      title={i18(title ?? text ?? "Button Title", "navlink.title")}
      data-icon-placement={iconPlacement && iconPlacement}
      aria-label={i18(title ?? text ?? "aria-label", "navlink.aria-label")}
    >
      <span className="navLinkWrapper">
        <div style={{ display: "flex", alignItems: "center", gap: ".3em" }}>
          {icon && <span className="navLinkIcon">{icon}</span>}

          {text ? <span className="navLinkText">{text}</span> : children}
        </div>
      </span>
    </NavLink>
  );
}

export default ActiveLink;
