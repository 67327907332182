import clsx from "clsx";
import useLanguage from "@axvdex/hooks/useLanguage";
import { ReactComponent as IcnHandle } from "../../assets/icons/icn-handle-spacing.svg";

interface DragHandleProps {
  extraClassName?: string;
}

function DragHandle({ extraClassName, ...props }: DragHandleProps) {
  const { i18 } = useLanguage();
  return (
    <span
      className={clsx("btn", "dragHandle", extraClassName && extraClassName)}
      data-variant="icon"
      data-color="dark-medium"
      title={i18("Drag", "dragHandle.title")}
      {...props}
    >
      <IcnHandle />
    </span>
  );
}

export default DragHandle;
