/* eslint-disable @typescript-eslint/no-var-requires */
import clsx from "clsx";
import { Lightbox } from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { Trans } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import { findIndex } from "lodash";
import { useMemo, useState } from "react";
import HelpSubPageSidebar from "@axvdex/components/HelpSubPageSidebar";
import HelpSubPageSection from "@axvdex/components/HelpSubPageSection";
import useLanguage from "@axvdex/hooks/useLanguage";
import MainLayout from "../layouts/MainLayout";
import styles from "../styles/Help.module.scss";

function HelpWallet() {
  const { i18 } = useLanguage();

  const sectionSummary = [
    {
      name: i18("Getting started", "help.wallet.sectionSummary.gettingStarted.name"),
      url: "#gettingStarted",
    },
    {
      name: i18("Connect a wallet", "help.wallet.sectionSummary.connectWallet.name"),
      url: "#connectWallet",
    },
  ];

  const [index, setIndex] = useState(-1);

  const slides = useMemo(() => {
    return [
      // add images with id passed to onImageClick function
      {
        src: require(`../assets/images/help/wallet/Picture2.webp`),
        id: "Picture2",
        title: i18("Connect Wallet view", "help.wallet.slides.picture2.title"),
      },
      {
        src: require(`../assets/images/help/wallet/Picture3.webp`),
        id: "Picture3",
        title: i18("Connect Wallet at sidebar", "help.wallet.slides.picture3.title"),
      },
      {
        src: require(`../assets/images/help/wallet/Picture4.webp`),
        id: "Picture4",
        title: i18("Wallet approve", "help.wallet.slides.picture4.title"),
      },
      {
        src: require(`../assets/images/help/wallet/Picture5.webp`),
        id: "Picture5",
        title: i18("Wallet confirm transaction", "help.wallet.slides.picture5.title"),
      },
      {
        src: require(`../assets/images/help/wallet/Picture6.webp`),
        id: "Picture6",
        title: i18("Connected Wallet", "help.wallet.slides.picture6.title"),
      },
    ];
  }, []);

  const onImageClick = (imageName: string) => {
    const index_ = findIndex(slides, { id: imageName });
    setIndex(index_);
  };

  return (
    <MainLayout
      pageClass={clsx(styles.help, "help", "helpWallet")}
      headTitle={i18("Help :: Wallet", "help.wallet.headTitle")}
      headDescription={i18("Help using a wallet on Astrovault", "help.wallet.headDescription")}
    >
      <div className="helpPageWrapper">
        <div className="sectionWrapperLg">
          <section className="helpSectionHeader withGradientBorderBottom">
            <h1>{i18("Wallet", "help.wallet.section.title")}</h1>

            <p>
              <Trans i18nKey="help.wallet.section.text.0">
                Astrovault is a powerful DEX (Decentralised Exchange) using the innovative Archway System to trade
                tokens from across the Cosmos Ecosytem.
              </Trans>
            </p>

            <p>
              <Trans i18nKey="help.wallet.section.text.1">
                With access to unparalleled liquidity and quick transaction times, Astrovault is an incredible evolution
                of the traditional DEX.
              </Trans>
            </p>
          </section>

          <div className="helpSubPageGrid">
            <HelpSubPageSidebar summaryTitle={i18("Wallet", "help.wallet.sidebar.title")} items={sectionSummary} />

            <div className="helpSubPageContent">
              <HelpSubPageSection
                sectionId="gettingStarted"
                sectionTitle={i18("Getting started", "help.wallet.subpage.section.gettingStarted.title")}
              >
                <p>
                  <Trans i18nKey="help.wallet.gettingStarted.text.0">
                    Astrovault requires a wallet that can connect to the greater Cosmos ecosystem. The following wallets
                    are supported.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/wallet/Picture2.webp`)}
                  onClick={() => onImageClick("Picture2")}
                  alt={i18("Connect Wallet Pop Up", "help.wallet.picture2.alt")}
                  width={785}
                  height={606}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.wallet.gettingStarted.text.1">
                    Astrovault provides useful links to make it easy to install a wallet. Simply click a download link
                    and follow the instructions.
                  </Trans>
                </p>
              </HelpSubPageSection>

              <HelpSubPageSection
                sectionId="connectWallet"
                sectionTitle={i18("Connect a wallet", "help.wallet.subpage.section.connectWallet.title")}
              >
                <p>
                  <Trans i18nKey="help.wallet.subpage.section.connectWallet.text.0">
                    With a wallet{" "}
                    <HashLink
                      to={"/help/wallet#gettingStarted"}
                      rel="noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      installed
                    </HashLink>
                    , connect it to Astrovault, on the left side of your view you should see this:
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/wallet/Picture3.webp`)}
                  onClick={() => onImageClick("Picture3")}
                  alt={i18("Connect Wallet through Sidebar", "help.wallet.picture3.alt")}
                  width={324}
                  height={954}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey="help.wallet.subpage.section.connectWallet.text.1">
                    Click &quot;Connect Wallet&quot; and choose your options. This guide will use Keplr, but feel free
                    to use any, the steps will be the same.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.wallet.subpage.section.connectWallet.text.2">
                    Upon selecting your wallet, your wallet will create a pop-up asking you to accept the Archway
                    network.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/wallet/Picture4.webp`)}
                  onClick={() => onImageClick("Picture4")}
                  alt={i18("Wallet approve", "help.wallet.picture4.alt")}
                  width={341}
                  height={652}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey="help.wallet.subpage.section.connectWallet.text.3">
                    After approving the Archway network your wallet will ask you to approve a connection to Astrovault.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/wallet/Picture5.webp`)}
                  onClick={() => onImageClick("Picture5")}
                  alt={i18("Wallet confirm transaction", "help.wallet.picture5.alt")}
                  width={347}
                  height={663}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey="help.wallet.subpage.section.connectWallet.text.4">
                    Click &quot;Approve&quot; to complete the connection to Astrovault and experience innovation in
                    cross chain exchange.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.wallet.subpage.section.connectWallet.text.5">
                    Your view should now look similar to this:
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/wallet/Picture6.webp`)}
                  onClick={() => onImageClick("Picture6")}
                  alt={i18("Connected Wallet", "help.wallet.picture6.alt")}
                  width={1544}
                  height={773}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />
              </HelpSubPageSection>
            </div>
          </div>
        </div>
      </div>
      <Lightbox
        index={index}
        slides={slides}
        open={index >= 0}
        close={() => setIndex(-1)}
        plugins={[Captions, Thumbnails]}
        className={styles.lightbox}
      />
    </MainLayout>
  );
}

export default HelpWallet;
