import { useEffect, useState } from "react";
import clsx from "clsx";
import { SigningStargateClient } from "@cosmjs/stargate";
import BigNumber from "bignumber.js";
import { findIndex } from "lodash";
import { ExternalLink } from "react-feather";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { selectAssets, selectChains, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { formatBalance, fromHumanAmountToTokenBalance } from "@axvdex/utils/formatNumber";
import { executeIbcTransfer, getIbcDepositClientSigner } from "@axvdex/state/wallet/walletThunks";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import imgSanitize from "@axvdex/utils/imgSanitize";
import useLanguage from "@axvdex/hooks/useLanguage";
import { IAsset } from "@axvdex/utils/interfaces";
import rpcAliveCheck from "@axvdex/utils/rpcAliveCheck";
import { sendToast } from "@axvdex/state/wallet/walletSlice";
import CustomSelect, { ListItemsProps } from "../form-element/CustomSelect";
import Button from "../common/Button";
import CustomNumericInput from "../form-element/CustomNumericInput";
import CustomInput from "../form-element/CustomInput";
import UserBalance from "../user/UserBalance";
import { TableAssetsProps } from "../DashboardMyAssets";
import CustomLoader from "../common/CustomLoader";

interface MyAssetsManageTokenDepositFormProps {
  asset: TableAssetsProps;
  onCloseModal: () => void;
}

function MyAssetsManageTokenDepositForm({ asset, onCloseModal }: MyAssetsManageTokenDepositFormProps) {
  const { i18 } = useLanguage();
  const walletInfo = useAppSelector(selectWalletInfo);
  const chains = useAppSelector(selectChains);
  const dispatch = useAppDispatch();
  const assets = useAppSelector(selectAssets);

  let origChain = null;
  if (asset && asset.isSourceDenom) {
    origChain = chains.find(chain => !chain.currencies.find(currency => currency.coinMinimalDenom === asset.id));
  } else if (asset && asset.denom_trace) {
    origChain = IBCAssetToChain(asset, chains);
  }

  const [chainOptions, setChainOptions] = useState([]);
  const [tokenOptions, setTokenOptions] = useState([]);

  const [selectedToken, setSelectedToken] = useState<ListItemsProps>(
    asset
      ? {
          label: asset.symbol,
          value: asset.id,
          optionPrefix: (
            <img
              src={imgSanitize(asset.symbol)}
              alt={i18(`${asset.symbol} - Token`, "managetokens.select.options.prefix.alt", {
                symbol: asset.symbol,
              })}
            />
          ),
        }
      : null
  );

  useEffect(() => {
    setTokenOptions(
      Object.entries(assets)
        .filter(asset => asset[1].isNative)
        .map(asset => {
          return {
            label: asset[1].symbol,
            value: asset[1].id,
            optionPrefix: (
              <img
                src={imgSanitize(asset[1].symbol)}
                alt={i18(`${asset[1].symbol} - Token`, "managetokens.select.options.prefix.alt", {
                  symbol: asset[1].symbol,
                })}
              />
            ),
          };
        })
        .sort(function (a, b) {
          if ("ARCH" === a.label.toUpperCase()) return 0;
          return ("" + a.label).localeCompare(b.label);
        })
    );
  }, [assets]);

  useEffect(() => {
    setChainOptions(
      chains
        .filter(chain => chain.displayName !== "Archway")
        .map(chain => {
          return { label: chain.displayName, value: chain.chainId };
        })
        .sort(function (a, b) {
          return ("" + a.label).localeCompare(b.label);
        })
    );
  }, [chains]);

  const handleTokenChange = (selectedOption: ListItemsProps) => {
    setSelectedToken(selectedOption);
    const asset = assets[selectedOption.value];
    let chain = null;
    if (asset && !asset.isSourceDenom) {
      chain = IBCAssetToChain(asset, chains);
    }
    setSelectedChain(chain ? { label: chain.displayName, value: chain.chainId } : selectedChain);
  };

  const [selectedChain, setSelectedChain] = useState<ListItemsProps>(
    origChain ? { label: origChain.displayName, value: origChain.chainId } : null
  );

  const handleChainChange = (selectedOption: ListItemsProps) => {
    setSelectedChain(selectedOption);
    const chain = chains.find(chain => chain.chainId === selectedOption.value);
    const asset = IBCChainToAsset(assets, chain);
    setSelectedToken(
      asset
        ? {
            label: asset[1].symbol,
            value: asset[0],
            optionPrefix: (
              <img
                src={imgSanitize(asset[1].symbol)}
                alt={i18(`${asset[1].symbol} - Token`, "managetokens.select.options.prefix.alt", {
                  symbol: asset[1].symbol,
                })}
              />
            ),
          }
        : null
    );
  };

  const [sendingAddrInput, setSendingAddrInput] = useState("");
  const [sendingBalance, setSendingBalance] = useState("");
  const [sendingClient, setSendingClient] = useState<SigningStargateClient | null>(null);

  const forceSuggestChain = async chainInfo => {
    const chain = chains.find(chain => chain.chainId === chainInfo.value);
    const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
    let walletExtensionClient = window[walletExtension];

    if (walletExtension === "cosmostation") {
      walletExtensionClient = window.cosmostation.providers.keplr;
    }

    await walletExtensionClient.experimentalSuggestChain(chain);
    setAmountInput("");
    setSendingAddrInput("");
    setSendingBalance("");
    handleFillSendingAddress();
  };
  useEffect(() => {
    selectedChain && forceSuggestChain(selectedChain);
  }, [selectedChain, selectedToken]);

  const handleRefreshBalance = async () => {
    if (selectedChain && selectedToken) {
      const chain = chains.find(chain => chain.chainId === selectedChain.value);
      const denom = assets[selectedToken.value].isSourceDenom
        ? chain.ibcDenoms.find(denom => selectedToken.value == denom.base_denom).denom
        : assets[selectedToken.value].denom_trace.base_denom;
      const { acc_address, balance, client, isSecretNetworkViewingKeyError } = await getIbcDepositClientSigner(
        chain.chainId,
        chain.rpc,
        denom,
        chain
      );

      setSendingBalance(!isSecretNetworkViewingKeyError ? balance : "secretViewingKeyError");
    }
  };

  const handleFillSendingAddress = async () => {
    setIsLoadingDepositTrigger(true);
    if (selectedChain && selectedToken) {
      const chain = chains.find(chain => chain.chainId === selectedChain.value);
      const denom = assets[selectedToken.value].isSourceDenom
        ? chain.ibcDenoms.find(denom => selectedToken.value == denom.base_denom).denom
        : assets[selectedToken.value].denom_trace.base_denom;

      const { acc_address, balance, client, isSecretNetworkViewingKeyError } = await getIbcDepositClientSigner(
        chain.chainId,
        await rpcAliveCheck(chain),
        denom,
        chain
      );
      setSendingAddrInput(acc_address);
      setSendingBalance(!isSecretNetworkViewingKeyError ? balance : "secretViewingKeyError");
      setSendingClient(client);
    }
    setIsLoadingDepositTrigger(false);
  };

  const [amountInput, setAmountInput] = useState("");

  const [isLoadingDepositTrigger, setIsLoadingDepositTrigger] = useState(false);

  const handleDepositTrigger = async () => {
    if (!sendingClient && amountInput === "0") return;
    setIsLoadingDepositTrigger(true);
    try {
      const srcChain = chains.find(chain => chain.chainId === selectedChain.value);
      // on deposit the destination chain is always archway
      const destChain = chains.find(chain => chain.chainId === process.env.REACT_APP_ARCHWAY_NETWORK);
      const denom = assets[selectedToken.value].isSourceDenom
        ? srcChain.ibcDenoms.find(denom => selectedToken.value == denom.base_denom).denom
        : assets[selectedToken.value].denom_trace.base_denom;

      await dispatch(
        executeIbcTransfer({
          sendingClient,
          sendingAddrInput,
          // for deposit the destination is the wallet in archway
          destUserAddress: walletInfo.walletAddress,
          denomToSend: denom,
          amount: fromHumanAmountToTokenBalance(amountInput.replaceAll(",", ""), assets[selectedToken.value].decimals),
          channel: srcChain.ibcRecvChannel,
          fee: {
            denom: srcChain.feeCurrencies[0].coinMinimalDenom,
            amount: srcChain.defaultIBCTransferFee ?? "5000",
          },
          // on deposit, we want to check if the packet has timed out on the from chain
          srcChain: {
            chainId: srcChain.chainId,
            restURL: srcChain.rest,
            explorerURL: srcChain.explorerURL,
            rpcURL: srcChain.rpc,
            isEVM: srcChain.isEVM,
          },
          // on deposit, we want to check if the packet has arrived to archway
          dstChain: {
            chainId: destChain.chainId,
            restURL: destChain.rest,
            explorerURL: destChain.explorerURL,
            rpcURL: destChain.rpc,
            isEVM: destChain.isEVM,
          },
          // on deposit, we need to only update the balance of the destination asset, with the denom of it on archway chain
          assetBalancesToUpdate: {
            userAddress: walletInfo.walletAddress,
            tokens: [],
            natives: [selectedToken.value],
          },
          // cw20_ics20 assets
          cw20_ics20: assets[selectedToken.value]?.denom_trace?.cw20_ics20,
          i18,
        })
      );

      await handleFillSendingAddress();
    } catch (e) {
      console.log(e);
    }
    setIsLoadingDepositTrigger(false);
  };

  const submitButtonText = () => {
    if (amountInput === "0" || !amountInput || amountInput === "") {
      return {
        buttonText: i18("Enter Amount", "managetokens.deposit.submitBtn.text.enterAmount"),
        disabled: true,
      };
    }

    if (
      BigNumber(
        fromHumanAmountToTokenBalance(amountInput.replaceAll(",", ""), assets[selectedToken.value].decimals)
      ).gt(BigNumber(sendingBalance))
    ) {
      return {
        buttonText: i18(
          "Not enough " + assets[selectedToken.value].symbol + " balance",
          "managetokens.deposit.submitBtn.text.notEnoughBalance",
          { symbol: assets[selectedToken.value].symbol }
        ),
        disabled: true,
      };
    }

    if (
      BigNumber(sendingBalance)
        .minus(
          BigNumber(
            fromHumanAmountToTokenBalance(amountInput.replaceAll(",", ""), assets[selectedToken.value].decimals)
          )
        )
        .lt("5000")
    ) {
      const t_ = i18(`Low funds for ibc-deposit`, "managetokens.deposit.submitBtn.text.lowFunds");
      return {
        buttonText: t_,
        disabled: true,
      };
    }

    return {
      buttonText: i18("Deposit", "managetokens.deposit.submitBtn.text.deposit"),
      disabled: false,
    };
  };

  const chainExtraButton = () => {
    const chain = chains.find(chain => chain.chainId === selectedChain?.value);
    if (chain.extraBridgeURL) {
      return (
        <ExternalLink
          style={{ width: "1.5em", height: "1.5em", cursor: "pointer" }}
          onClick={() => window.open(chain.extraBridgeURL, "_blank").focus()}
        />
      );
    }
    return null;
  };

  return (
    <form className={clsx("dashboardMyAssetsForm", "deposit")}>
      <fieldset className="depositToFieldset">
        <legend className="visuallyHidden">{i18("Select token", "managetokens.deposit.from.token.legend")}</legend>

        <div className="selectTokenSubFieldset depositTo">
          <div className="flexbox">
            <CustomSelect
              name="deposit_to_token"
              labelText={i18("To", "managetokens.deposit.from.token.select.label")}
              hiddenLabel={true}
              placeholder={i18("Select token", "managetokens.deposit.from.token.select.placeholder")}
              items={tokenOptions}
              value={tokenOptions[findIndex(tokenOptions, item => item.value === selectedToken?.value) || 0]}
              onChange={handleTokenChange}
              required
            />

            <CustomNumericInput
              extraClassName="depositFromUsdFormGroup"
              name="deposit_to_usd"
              labelText={i18("Enter amount", "managetokens.deposit.from.token.input.label")}
              hiddenLabel={true}
              placeholder={"0"}
              value={amountInput}
              onChange={e => setAmountInput(e.target.value)}
              disabled={!selectedToken}
            />
          </div>

          <div className="helpText selectTokenSubFieldsetHelpText">
            <span className="textGrey">
              {selectedToken &&
                formatBalance(
                  fromHumanAmountToTokenBalance(amountInput.replaceAll(",", ""), assets[selectedToken.value].decimals),
                  assets[selectedToken.value].price,
                  assets[selectedToken.value].decimals
                ).usdConversion}
              &nbsp;USD&nbsp;
            </span>
            {/* <span className="textGrey">0&nbsp;USD&nbsp;-&nbsp;</span> */}
            {/* <span className="textGradient">{i18("Use USD instead", "managetokens.useUsd")}</span> */}
          </div>
        </div>

        <div className="selectTokenBalanceAmount">
          <UserBalance
            balanceValue={
              selectedToken
                ? formatBalance(sendingBalance, assets[selectedToken.value].price, assets[selectedToken.value].decimals)
                    .amount
                : 0
            }
            balanceUsdValue={
              selectedToken
                ? formatBalance(sendingBalance, assets[selectedToken.value].price, assets[selectedToken.value].decimals)
                    .usdConversion
                : 0
            }
            invalidAmount={sendingBalance === "-" || sendingBalance === "secretViewingKeyError"}
            secretViewingKey={
              selectedChain &&
              selectedToken &&
              assets[selectedToken.value].denom_trace?.cw20_ics20 && // if cw20 on origin chain and secretViewiwingKeyError => secret network viewing keys special case
              sendingBalance === "secretViewingKeyError"
                ? {
                    restURL: chains.find(chain => chain.chainId === selectedChain.value).rest,
                    chainId: chains.find(chain => chain.chainId === selectedChain.value).chainId,
                    sendingAddr: sendingAddrInput,
                    contract_address: assets[selectedToken.value].denom_trace.cw20_ics20.cw20Address,
                    refreshBalance: () => handleRefreshBalance(),
                  }
                : null
            }
          />
          <Button
            btnColor="dark-medium"
            text={i18("Max", "managetokens.deposit.maxBtn.text")}
            title={i18("Set Max Value", "managetokens.deposit.maxBtn.title")}
            disabled={selectedChain?.value.includes("secret") && "secretViewingKeyError" === sendingBalance}
            onClick={() =>
              setAmountInput(
                selectedToken
                  ? formatBalance(
                      BigNumber(sendingBalance).gt("5000") ? BigNumber(sendingBalance).minus("5000").toString(10) : "0", // leave 0.005 due to fees
                      assets[selectedToken.value].price,
                      assets[selectedToken.value].decimals
                    ).amount.toString()
                  : "0"
              )
            }
          />
        </div>
      </fieldset>

      <fieldset className="depositFromFieldset">
        <legend className="visuallyHidden">{i18("Select chain", "managetokens.deposit.from.chain.legend")}</legend>

        <CustomSelect
          name="deposit_from_chain"
          labelText={i18("From", "managetokens.deposit.from.chain.select.label")}
          placeholder={i18("Select chain", "managetokens.deposit.from.chain.select.placeholder")}
          items={chainOptions}
          value={
            selectedChain
              ? chainOptions[findIndex(chainOptions, item => selectedChain.value === item.value) || 0]
              : null
          }
          onChange={handleChainChange}
          required
          extraButton={selectedChain ? chainExtraButton() : null}
        />

        <CustomInput
          style={{ cursor: "pointer" }}
          name="deposit_from_chain_address"
          labelText={i18("Sending address", "managetokens.deposit.from.address.label")}
          title={i18(`Copy address to clipboard`, "managetokens.deposit.from.address.copyToClipboard.title")}
          value={sendingAddrInput}
          onChange={e => setSendingAddrInput(e.target.value)}
          readOnly
          onClick={() => {
            if (sendingAddrInput !== "") {
              // copy to clipboard
              navigator.clipboard.writeText(sendingAddrInput);
              dispatch(
                sendToast({
                  type: "info",
                  noStore: true,
                  info: {
                    msg: i18("Address copied to clipboard", "toast.addressCopied.text"),
                    toastID: "" + new Date().getTime(),
                  },
                })
              );
            }
          }}
        />
      </fieldset>

      <p className="infoText">
        {i18("Additional fees may apply when you're confirming the transaction", "managetokens.deposit.infoText")}
      </p>

      <div className="buttonContainer">
        <Button
          btnColor="gradientText"
          text={i18("Cancel", "managetokens.cancelBtn.text")}
          title={i18("Cancel and close", "managetokens.cancelBtn.title")}
          onClick={onCloseModal}
        />
        {isLoadingDepositTrigger ? (
          <CustomLoader size="xs" />
        ) : (
          <Button
            btnColor="gradient"
            title={i18("Submit", "managetokens.deposit.submitBtn.title")}
            text={submitButtonText().buttonText}
            onClick={async () => await handleDepositTrigger()}
            disabled={submitButtonText().disabled}
          />
        )}
      </div>
    </form>
  );
}

export const IBCChainToAsset = (assets: { [p: string]: IAsset }, chain) => {
  return Object.entries(assets).find(asset => {
    const assetInChain = chain.currencies.find(
      (chainCurrencies: any) => chainCurrencies.coinMinimalDenom === asset[1].denom_trace?.base_denom
    );

    // return true as found if association between asset and chain, or
    // asset is a cw20 on its original chain, and therefore has its own properties
    // otherwise false to iterate next item
    return (
      (assetInChain && chain.ibcSendChannel === asset[1].denom_trace.path.split("/")[1]) ||
      (asset[1].denom_trace?.cw20_ics20 && chain.chainId === asset[1].denom_trace.cw20_ics20.chainId)
    );
  });
};

export const IBCAssetToChain = (asset: IAsset | TableAssetsProps, chains: any[]) => {
  return asset && asset.denom_trace
    ? // an asset with .denom_trace is an ibc deposited asset, so try to find the respective chain
      chains.find(chain => {
        // check currencies array of the chain for NATIVE assets to that chain
        const chainDetected = chain.currencies.find(
          currency =>
            currency.coinMinimalDenom === asset.denom_trace.base_denom &&
            asset.denom_trace.path.split("/")[1] === chain.ibcSendChannel
        );

        // return true as found if chain is detected via either currencies array or cw20_ics20
        // otherwise false to iterate next item
        return !!(
          chainDetected ||
          (asset.denom_trace.cw20_ics20 && chain.chainId === asset.denom_trace.cw20_ics20.chainId)
        );
      })
    : null;
};

export default MyAssetsManageTokenDepositForm;
