import { useCallback, useState, useMemo, useEffect } from "react";
import clsx from "clsx";
import { isEqual } from "lodash";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { selectAssets, selectFavouritePools, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { triggerFavouriteChange } from "@axvdex/state/pool/poolThunks";
import { selectAssetBalances } from "@axvdex/state/wallet/walletSelectors";
import { abbreviateNumber, formatBalance } from "@axvdex/utils/formatNumber";
import imgSanitize from "@axvdex/utils/imgSanitize";
import useLanguage from "@axvdex/hooks/useLanguage";
import CustomModal from "../common/CustomModal";
import Button from "../common/Button";
import ButtonFavourite from "../common/ButtonFavourite";
import styles from "../../styles/PoolCardDetailsModal.module.scss";

import PoolCardStandardDepositTable from "../pools/PoolCardStandardDepositTable";
import PoolCardLockupsTable from "../pools/PoolCardLockupsTable";
import PoolCardStableDepositTable from "../pools/PoolCardStableDepositTable";
import PoolCardStandardWithdrawalTable from "../pools/PoolCardStandardWithdrawalTable";
import PoolCardStableWithdrawalTable from "../pools/PoolCardStableWithdrawalTable";
import PoolCardStableWithdrawalXAssetModeTable from "../pools/PoolCardStableWithdrawalXAssetModeTable";
import PoolCardHybridDepositTable from "../pools/PoolCardHybridDepositTable";
import PoolCardHybridWithdrawalTable from "../pools/PoolCardHybridWithdrawalTable";
import CollectRewardModal from "./CollectRewardModal";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";

interface PoolCardDetailsModalProps {
  openModal: boolean;
  onCloseModal: () => void;
  poolId: string;
  pools: any[];
}

function PoolCardDetailsModal({ openModal, onCloseModal, poolId, pools }: PoolCardDetailsModalProps) {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const walletInfo = useAppSelector(selectWalletInfo);
  const favouritePools = useAppSelector(selectFavouritePools, isEqual);
  const assets = useAppSelector(selectAssets, isEqual);
  const [lockupsState, setLockupsState] = useState({
    lockups: null,
    latestBlock: null,
  });
  const assetBalances = useAppSelector(selectAssetBalances);

  const deposit = useMemo(() => {
    return { name: i18("Deposit", "poolCardDetails.deposit") };
  }, []);
  const withdraw = useMemo(() => {
    return { name: i18("Withdraw", "poolCardDetails.withdraw") };
  }, []);
  const lockups = useMemo(() => {
    return { name: i18("Lockups", "poolCardDetails.lockups") };
  }, []);

  const poolData = useMemo(() => pools?.find(pool => pool.id === poolId) || null, [poolId, pools]);
  const [poolModalTabList, setPoolModalTabList] = useState([deposit, withdraw]);

  useEffect(() => {
    (async () => {
      if (openModal) {
        if (poolData?.type === "stable" || poolData?.type === "hybrid") {
          const lockupsData = await querySoftLockups(poolData, walletInfo);
          if (lockupsData.length > 0) {
            setPoolModalTabList([deposit, withdraw, lockups]);
          } else {
            setPoolModalTabList([deposit, withdraw]);
          }
        }
        if (poolData?.type === "standard") {
          setPoolModalTabList([deposit, withdraw]);
        }
      } else {
        setPoolModalTabList([deposit, withdraw]);
      }
    })();
  }, [openModal, poolData]);

  const [isFavourite, setIsFavourite] = useState(favouritePools.includes(poolData?.address));

  useEffect(() => {
    setIsFavourite(favouritePools.includes(poolData?.address));
  }, [poolData?.address, favouritePools]);

  const toggleFavourite = async () => {
    setIsFavourite(isFavourite => !isFavourite);
    await dispatch(triggerFavouriteChange({ isFavourite: !isFavourite, poolAddress: poolData?.address }));
  };

  const [activeTab, setActiveTab] = useState(0);
  const HandleTabClick = useCallback((index: number) => {
    setActiveTab(index);
  }, []);

  const tokenList = useMemo(() => {
    return poolData?.assetIDs?.map((assetID, index) => {
      const asset = assets[assetID];
      return {
        icon: <img src={imgSanitize(assets[assetID]?.symbol)} alt={""} />,
        name: asset?.symbol,
        amount: formatBalance(poolData?.poolAssets[index].amount, asset?.price, asset?.decimals).amount,
      };
    });
  }, [poolData]);

  /*
  const { showModal } = useGlobalModalContext();

  const handleOpenRewardsModal = () => {
    showModal("collectRewardModal");
    onCloseModal();
  };
  */

  const querySoftLockups = async (poolData, walletInfo) => {
    const [lockups, latestBlock] = await Promise.all([
      rpcClientQuerySmartContractWrapper(poolData.lockups, {
        acc_lockups: { address: walletInfo.walletAddress },
      }),
      window.client.getBlock(),
    ]);

    setLockupsState({ lockups, latestBlock });
    return lockups;
  };

  return (
    <CustomModal
      isOpen={openModal}
      onClose={onCloseModal}
      extraClassName={clsx(styles.poolCardDetailsModal, "poolCardDetailsModal")}
      modalType="right"
    >
      {poolData && (
        <>
          <section className="poolCardModalSectionHeader withGradientBorderBottom">
            <div className="poolCardModalTokenListIconWrapper">
              <ul className="tokenGridList poolCardModalTokenList icons">
                {tokenList?.map((item, idx: number) => (
                  <li key={idx}>
                    <span title={item.name}>{item.icon}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="flexbox">
              <div className="poolCardModalTokenListNameWrapper">
                <ul className="poolCardModalTokenList name">
                  {tokenList?.map((item, idx: number) => (
                    <li key={idx}>
                      <span>{item.name}</span>
                    </li>
                  ))}
                </ul>

                <div className="poolCardModalChain">
                  <span>
                    {i18(
                      poolData.type.charAt(0).toUpperCase() + poolData.type.slice(1) + " Pool",
                      "poolCardDetails.body.poolType.text",
                      { poolType: poolData.type.charAt(0).toUpperCase() + poolData.type.slice(1) }
                    )}{" "}
                    (
                    {`${i18("APR", "poolCard.body.apr.text")}: ${
                      poolData && poolData.percentageAPRs && poolData.percentageAPRs.length > 0
                        ? poolData.percentageAPRs.reduce((sum, source) => sum + source, 0) || 0
                        : 0
                    }%`}
                    ){" "}
                  </span>
                </div>
              </div>

              <div className="buttonContainer">
                {
                  // only show this if the user have lp balance > 0 on this pool
                  assetBalances[poolData.lp_staking] &&
                    assetBalances[poolData.lp_staking] !== "0" &&
                    poolData.lp_staking_info.reward_sources.length > 0 && (
                      <CollectRewardModal poolsToShow={[poolData]} farmsToShow={[]} triggerElement="icon" />
                    )
                }
                <ButtonFavourite btnColor="purple" onToggleFavourite={toggleFavourite} isFavourite={isFavourite} />
              </div>
            </div>
          </section>

          <section className="poolCardModalSectionRewards withGradientBorderBottom">
            <ul className="poolCardModalSectionRewardsList">
              {tokenList?.map((item, idx: number) => (
                <li key={idx}>
                  <span>
                    {abbreviateNumber(item.amount)}&nbsp;{item.name}
                  </span>
                </li>
              ))}
            </ul>
          </section>

          <section className="poolCardModalSectionTabs">
            <ul className="btnGroup navTabs">
              {poolModalTabList.map((item, idx) => (
                <li key={idx}>
                  <Button
                    text={item.name}
                    title={i18(`View ${item.name}`, "poolCardDetails.body.tabs.title", { tabName: item.name })}
                    extraClassName={clsx(idx === activeTab && "isActive")}
                    onClick={() => HandleTabClick(idx)}
                  />
                </li>
              ))}
            </ul>

            <section className="poolCardModalSectionTabsContent">
              {activeTab === 0 && poolData.type === "standard" && <PoolCardStandardDepositTable poolData={poolData} />}
              {activeTab === 0 && poolData.type === "stable" && <PoolCardStableDepositTable poolData={poolData} />}
              {activeTab === 0 && poolData.type === "hybrid" && <PoolCardHybridDepositTable poolData={poolData} />}
              {activeTab === 1 && poolData.type === "standard" && (
                <PoolCardStandardWithdrawalTable poolData={poolData} />
              )}
              {activeTab === 1 && poolData.type === "stable" && !poolData.settings.xasset_mode_minter && (
                <PoolCardStableWithdrawalTable poolData={poolData} />
              )}
              {activeTab === 1 && poolData.type === "stable" && poolData.settings.xasset_mode_minter && (
                <PoolCardStableWithdrawalXAssetModeTable poolData={poolData} />
              )}
              {activeTab === 1 && poolData.type === "hybrid" && <PoolCardHybridWithdrawalTable poolData={poolData} />}
              {activeTab === 2 &&
                poolData.type === "stable" &&
                poolModalTabList.findIndex(tab => tab.name === "Lockups") !== -1 && (
                  <PoolCardLockupsTable
                    poolData={poolData}
                    querySoftLockups={querySoftLockups}
                    lockups={lockupsState.lockups}
                    latestBlock={lockupsState.latestBlock}
                  />
                )}
              {activeTab === 2 &&
                poolData.type === "hybrid" &&
                poolModalTabList.findIndex(tab => tab.name === "Lockups") !== -1 && (
                  <PoolCardLockupsTable
                    poolData={poolData}
                    querySoftLockups={querySoftLockups}
                    lockups={lockupsState.lockups}
                    latestBlock={lockupsState.latestBlock}
                  />
                )}
            </section>
          </section>
        </>
      )}

      {/* <section className="poolCardModalSectionRewards withGradientBorder">
        <div className="flexbox">
          <div className="poolCardModalRewards">
            <p>{i18("Your Rewards", "poolCardDetails.reward.title")}</p>

            <UserRewardsAmount />
          </div>
          <Button text=i18("Collect" , "poolCardDetails.reward.button.text") btnColor="gradient" onClick={handleOpenRewardsModal} />
        </div>
      </section> */}
    </CustomModal>
  );
}

export default PoolCardDetailsModal;
