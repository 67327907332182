import { ToastMessage } from "@axvdex/components/BackgroundToastSender";
import {
  IAsset,
  IContract,
  IPool,
  IFarm,
  ISwapLog,
  ILpBalance,
  IOutbidSale,
  INicknameInfo,
} from "../../utils/interfaces";

export interface IWalletInfo {
  isConnected: boolean;
  walletAddress: string;
  walletOwnerName: string;
  walletConnectAccountNumber: number | null;
  walletConnectSequence: number | null;
  walletSignerType: string;
  connectionStatus: string;
  nativeCoin: string;
}

export interface IState {
  isLoadingWallet: boolean;
  walletInfo: IWalletInfo;
  user: {
    favoriteSwaps: Array<any>;
    favoritePools: Array<any>;
    favoriteFarms: Array<any>;
    priceWatch: Array<any>;
    poolsWithBalance: Array<any>;
    farmsWithBalance: Array<any>;
    swapLogs: Array<ISwapLog>;
    myAssets: Array<any>;
    isUserDetailsLoad: boolean;
    favoriteAssets: Array<string>;
    grvt8Balance: string | null;
    salesBids: { [key: string]: any } | null;
    myDashboardGrid: any | null;
    myToastMessages: Array<ToastMessage>;
    myGlobalSettings?: any;
    vestingLockedAmount: string | null;
    myAssetUnmints: {
      [key: string]: {
        unmints: string[];
        readyUnmints: string[];
      };
    };
    mySoftLockups: {
      [key: string]: {
        start_timestamp: string; // timestamp in string
        assets_amount: string[];
      }[];
    };
    notifications: {
      timestamp: number;
      type: string;
      msg: string;
      extraDetails?: any;
      isNew: boolean;
    }[];
    surveys: {
      [key: number]: any;
    };
    //myPoolUnlocks: { [key: string]: { unlocks: number; readyUnlocks: number } }; // for softlockups
  };
  contracts: { [key: string]: IContract };
  outbid: { [key: string]: IOutbidSale };
  outbidServerTime: number | null;
  pools: { [key: string]: IPool };
  farms: { [key: string]: IFarm };
  poolsLpBalance: { [key: string]: ILpBalance };
  farmsLpBalance: { [key: string]: ILpBalance };
  assets: { [key: string]: IAsset };
  chains: Array<any>;
  statusCounters: any;
  assetBalances: object;
  toast: { type: string; info: any; noStore?: boolean } | null;
  updatingUser: boolean;
  nicknames: { [key: string]: INicknameInfo };
}

const state: IState = {
  isLoadingWallet: true,
  walletInfo: {
    isConnected: false,
    walletAddress: "",
    walletOwnerName: "",
    connectionStatus: "",
    nativeCoin: "",
    walletConnectAccountNumber: null,
    walletConnectSequence: null,
    walletSignerType: "",
  },
  user: {
    favoriteSwaps: [],
    favoritePools: [],
    favoriteFarms: [],
    priceWatch: [],
    poolsWithBalance: [],
    farmsWithBalance: [],
    swapLogs: [],
    myAssets: [],
    favoriteAssets: [],
    isUserDetailsLoad: false,
    grvt8Balance: null,
    salesBids: null,
    myDashboardGrid: null,
    myToastMessages: [],
    myGlobalSettings: null,
    // loadState(WHITE_LIST_PERSISTED_STATE_KEYS.globalSettings) === "undefined"
    //   ? {}
    //   : loadState(WHITE_LIST_PERSISTED_STATE_KEYS.globalSettings),
    vestingLockedAmount: null,
    myAssetUnmints: {},
    mySoftLockups: {},
    notifications: [],
    surveys: null,
  },
  contracts: {},
  outbid: {},
  outbidServerTime: null,
  pools: {},
  farms: {},
  poolsLpBalance: {},
  farmsLpBalance: {},
  assets: {},
  chains: [],
  statusCounters: null,
  assetBalances: {},
  toast: null,
  updatingUser: false,
  nicknames: {},
};

export default state;
