import { fetchWrapper } from ".";

export const getOutbidSales = headers =>
  fetchWrapper(
    "GET",
    "/outbid" + (process.env.REACT_APP_SUB_MODE ? "?env=" + process.env.REACT_APP_SUB_MODE.toLowerCase() : ""),
    undefined,
    {},
    headers
  );

export const getOutbidSalesWalletSalesWithBids = headers =>
  fetchWrapper("GET", "/outbid/walletSalesWithBids", undefined, {}, headers);

export const getOutbidSaleBidTable = (outbidAddress, saleAddress) => {
  let queryString = "";
  if (outbidAddress) queryString += "?outbidAddress=" + outbidAddress;
  if (saleAddress) queryString += "&saleAddress=" + saleAddress;
  return fetchWrapper("GET", "/outbid/saleBidTable" + queryString, undefined, {});
};
