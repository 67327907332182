import clsx from "clsx";
import { Trans } from "react-i18next";
import useLanguage from "@axvdex/hooks/useLanguage";
import MainLayout from "../layouts/MainLayout";
import styles from "../styles/Legal.module.scss";

function Legal() {
  const { i18 } = useLanguage();
  return (
    <MainLayout
      pageClass={clsx(styles.legal, "legal")}
      headTitle={i18("RISKS AND DISCLAIMERS", "legal.headTitle")}
      headDescription={i18("Astrovault Risks and Disclaimers", "legal.headDescription")}
    >
      <h1>{i18("RISKS AND DISCLAIMERS", "legal.risksAndDisclaimers")}</h1>

      <p>
        <Trans i18nKey="legal.risksAndDisclaimers.text.0">
          This website is operated by <strong>Autogenesis Limited</strong> (the &quot;<strong>Company</strong>&quot;,
          &quot;
          <strong>we</strong>&quot; or &quot;<strong>us</strong>&quot;). This website is for informational purposes only
          and therefore, the information and descriptions contained in it are not to be construed as an offering
          memorandum, advertisement or prospectus. Accordingly, this information is not intended to be a complete
          description of all terms, exclusions and conditions applicable to the services described in this website.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.risksAndDisclaimers.text.1">
          This website and any information or materials contained in it do not constitute the distribution, an offer or
          solicitation of any kind to purchase or sell any product, security or instrument whatsoever nor should they be
          construed as providing any type of investment or other advice or recommendations by us, any of our affiliates
          or third parties to any person in any jurisdiction where such distribution, offer, solicitation, purchase or
          sale would be unlawful under the laws of such jurisdiction. Moreover, the Company does not give investment
          advice, endorsement, analysis or recommendations with respect to any cryptocurrencies, digital assets, tokens
          or securities (&quot;<strong>cryptocurrencies</strong>&quot;) or provide any financial, tax, legal advice or
          consultancy services of any kind. We are not your broker, intermediary, agent, or advisor and has no fiduciary
          relationship or obligation to you in connection with any trades or other decisions or activities effected by
          you using this website.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.risksAndDisclaimers.text.2">
          This website has not been approved by any regulator. This website is for &quot;professional investors&quot;,
          &quot;accredited investors&quot; or &quot;sophisticated investors&quot; only and not for retail use. We
          encourage you to consult your professional advisers in respect of any decisions or activities made on the
          website.
        </Trans>
      </p>

      <h2>{i18("Disclaimer of Warranties", "legal.disclaimerOfWarranties")}</h2>

      {"MAINNET" !== process.env.REACT_APP_MODE && (
        <p>
          <Trans i18nKey="legal.disclaimerOfWarranties.text.0">
            [Please note that our protocol is in Beta which is in the process of being tested before its full release.
            The sole purpose of this Beta version is to conduct testing and obtain feedback. We do not give any
            warranties, whether express or implied, as to the suitability or usability of the protocol, our Services,
            the website, its software or any of its content. We will not be liable for any loss, whether such loss is
            direct, indirect, special or consequential, suffered by you or any party as a result of the use of the Beta
            protocol, its content and functionalities.]
          </Trans>
        </p>
      )}
      <p>
        <Trans i18nKey="legal.disclaimerOfWarranties.text.1">
          Information contained in this website is current as at the date of publication, may be superseded by
          subsequent market events or for other reasons, and is subject to change without any notice.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.disclaimerOfWarranties.text.2">
          Neither the Company, its affiliates nor any of its or their officers, directors, agents, employees or
          representatives makes any warranty, express or implied, of any kind whatsoever related to the adequacy,
          accuracy or completeness of any information on this website or the use of information on this website. We do
          not make any investment recommendations, and no communication, through this website or in any other medium, is
          intended as or should be construed as advice or recommendation for any cryptocurrencies offered on or off this
          website or any other sort of advice.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.disclaimerOfWarranties.text.3">
          In addition, we do not warrant that this website will meet your needs, or that it will be uninterrupted,
          timely, secure or error-free, that defects will be corrected, or that this website or the server that makes it
          available are free of viruses or other harmful components. Accordingly, we and our affiliates expressly
          disclaim any liability, whether in contract, tort, strict liability of otherwise, for any direct, indirect,
          consequential, punitive or special damages arising out of, or in any way connected with, any person’s access
          to or use of this website. This is true even if we or our affiliates have been advised of the possibility of
          such damages or losses. In addition, we will not be liable to any person for any loss resulting from a cause
          over which we do not have control.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.disclaimerOfWarranties.text.4">
          We may terminate your access to our website for any reason, without prior notice.
        </Trans>
      </p>

      <h2>{i18("Risks", "legal.risks")}</h2>

      <p>
        <Trans i18nKey="legal.risks.text.0">
          Investing in cryptocurrencies is highly risky and may lead to a total loss of investment. You must have
          sufficient understanding of cryptographic tokens, token storage mechanisms (such as token wallets), and
          blockchain technology to appreciate the risks involved in investing in cryptocurrencies.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.risks.text.0.1">
          Don&apos;t invest unless you&apos;re prepared to lose all the money you invest. This is a high-risk investment
          and you should not expect to be protected if something goes wrong.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.risks.text.1">
          You should conduct your own due diligence of any issuer or cryptocurrencies and consult your advisors prior to
          making any investment decision. You are recommended to exercise prudence and trade and invest responsibly
          within your own capabilities. You are solely responsible for determining whether any investment, investment
          strategy or related transaction is appropriate for you according to your personal investment objectives,
          financial circumstances and risk tolerance, and you shall be solely responsible for any loss or liability
          therefrom. You should consult legal or tax professionals regarding your specific situation.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.risks.text.2">
          We do not recommend that any cryptocurrencies should be bought, earned, sold, or held by you and we will not
          be held responsible for the decisions you make based on the information provided by us on this website.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.risks.text.3">
          AS WITH ANY ASSET, THE VALUES OF CRYPTOCURRENCES ARE VOLATILE AND MAY FLUCTUATE SIGNIFICANTLY AND THERE IS A
          SUBSTANTIAL RISK OF ECONOMIC LOSS WHEN PURCHASING, HOLDING OR INVESTING IN CRYPTOCURRENCIES. BY ACCESSING THE
          WEBSITE AND USING OUR SERVICES, YOU ACKNOWLEDGE AND AGREE THAT: (A) YOU ARE AWARE OF THE RISKS ASSOCIATED WITH
          TRANSACTIONS OF CRYPTOCURRENCIES THAT ARE BASED ON BLOCKCHAIN AND CRYPTOGRAPHY TECHNOLOGIES AND ARE ISSUED AND
          MANAGED IN A DECENTRALIZED FORM; (B) YOU SHALL ASSUME ALL RISKS RELATED TO THE USE OF THIS WEBSITE AND
          TRANSACTIONS OF CRYPTOCURRENCIES; AND (C) THE COMPANY SHALL NOT BE LIABLE FOR ANY LOSS OR ADVERSE OUTCOMES.
        </Trans>
      </p>

      <h2 id="restricted-countries">{i18("Restricted countries", "legal.restrictedCountries")}</h2>

      <p>
        <Trans i18nKey="legal.restrictedCountries.text.0">
          By using this website, you assume all liability for your use and certify the veracity of the following
          statement; that you are not a resident of any country, territory, or location otherwise restricted from using
          this platform in the following list;
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.restrictedCountries.text.1">
          Afghanistan, American Samoa, Angola, Armenia, Azerbaijan, Balkans, Belarus, Bosnia and Herzegovina, Botswana,
          Burma, Burundi, Cambodia, Cameroon, Canada, Central African Republic, Chad, China , Crimea of Ukraine, Cuba,
          Democratic People&apos;s Republic of Korea, Democratic Republic of the Congo, Eritrea, Ethiopia, Ghana, Guam,
          Guinea, Guinea-Bissau, Haiti, Iran, Iraq, Laos, Lebanon, Liberia, Libya, Japan, Madagascar, Mali, Mozambique,
          Myanmar, Nicaragua, Northern Mariana Islands, Pakistan, Puerto Rico, Republic of the Congo, Russia, Somalia,
          South Sudan, Sri Lanka, Sudan, Syrian Arab Republic, Tajikistan, Trinidad and Tobago, Turkmenistan, Uganda,
          United Kingdom, United States, Uzbekistan, Vanuatu, Venezuela, Virgin Islands (U.S.), Yemen, and Zimbabwe
        </Trans>
      </p>
    </MainLayout>
  );
}

export default Legal;
