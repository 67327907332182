import BigNumber from "bignumber.js";
import { IPool } from "./interfaces";
import { div, gt } from "./math";
import { denormalize_amount, lp_token_from_18_to_6, lp_token_from_6_to_18, normalize_amount } from "./formatNumber";

const unstakeSimulation = (
  pool: IPool,
  lpAmount: string,
  stablePoolInputs: {
    amounts: string[];
    is_instant_withdrawal: boolean;
  } | null = null,
  hybridPoolInputs: {
    is_instant_withdrawal: boolean;
  } | null = null,
  walletAddress: string,
  walletLPBalance: string
) => {
  try {
    // if this pool is standard, no need for amounts
    if (pool.type === "standard") {
      const lpAmountParsed = BigNumber(lpAmount).decimalPlaces(0, BigNumber.ROUND_FLOOR).toString(10);
      const { lpAmountWithoutDecimals } = standardLPTokenRepresentation(lpAmountParsed, pool);

      // TO SEND THIS TO THE lp_staking of this pool
      const asset1Amount = gt(pool.total_share, "0")
        ? BigNumber(lpAmountWithoutDecimals)
            .div(pool.total_share)
            .times(pool.poolAssets[0].amount)
            .div(Math.pow(10, pool.assetDecimals[0]))
            .toString(10)
        : "0";
      const asset2Amount = gt(pool.total_share, "0")
        ? BigNumber(lpAmountWithoutDecimals)
            .div(pool.total_share)
            .times(pool.poolAssets[1].amount)
            .div(Math.pow(10, pool.assetDecimals[1]))
            .toString(10)
        : "0";

      return {
        refund_assets: [asset1Amount, asset2Amount],
        lpTokensAmount: lpAmountParsed,
        tx: {
          withdrawal: {
            amount: lpAmountWithoutDecimals,
            direct_pool_withdrawal: {
              standard: {
                to: walletAddress,
              },
            },
            not_claim_rewards: walletLPBalance !== lpAmountWithoutDecimals ? true : false,
          },
        },
        funds: [],
      };
    }

    // if this pool is standard, no need for amounts
    if (pool.type === "hybrid") {
      const lpAmountParsed = BigNumber(lpAmount).decimalPlaces(0, BigNumber.ROUND_FLOOR).toString(10);
      const { lpAmountWithoutDecimals, refund_assets } = hybridLPTokenRepresentation(lpAmountParsed, pool);

      // TO SEND THIS TO THE lp_staking of this pool
      const asset1Amount = refund_assets[0];
      const asset2Amount = refund_assets[1];

      const assets_lockup_fee_amount = Array(2).fill(BigInt(0));
      const final_withdrawal_assets_amount_lockup = [asset1Amount, asset2Amount];
      let lockupFeePer = "0";

      if (hybridPoolInputs.is_instant_withdrawal) {
        const calcs = calcLockup(pool, final_withdrawal_assets_amount_lockup, BigInt(0));

        for (const [index, calc] of calcs.entries()) {
          assets_lockup_fee_amount[index] = calc.fee_amount;
          final_withdrawal_assets_amount_lockup[index] = calc.final_amount.toString();
          lockupFeePer = div(calc.fee_per.toString(), calc.unit_precision.toString());
        }
      }
      return {
        refund_assets: [
          BigNumber(asset1Amount).div(Math.pow(10, pool.assetDecimals[0])).toString(10),
          BigNumber(asset2Amount).div(Math.pow(10, pool.assetDecimals[1])).toString(10),
        ],
        lpTokensAmount: lpAmountParsed,
        lockupFeePer,
        assets_lockup_fee_amount: assets_lockup_fee_amount.map((a, i) =>
          BigNumber(a).div(Math.pow(10, pool.assetDecimals[i])).decimalPlaces(6, BigNumber.ROUND_FLOOR).toString(10)
        ),
        final_withdrawal_assets_amount_lockup: final_withdrawal_assets_amount_lockup.map((a, i) =>
          BigNumber(a).div(Math.pow(10, pool.assetDecimals[i])).decimalPlaces(6, BigNumber.ROUND_FLOOR).toString(10)
        ),
        tx: {
          withdrawal: {
            amount: lpAmountWithoutDecimals,
            direct_pool_withdrawal: {
              ratio: {
                to: walletAddress,
                is_instant_withdrawal: hybridPoolInputs.is_instant_withdrawal,
              },
            },
            not_claim_rewards: walletLPBalance !== lpAmountWithoutDecimals ? true : false,
          },
        },
        funds: [],
      };
    }

    if (pool.type === "stable") {
      if (!stablePoolInputs) throw new Error("stablePoolInputs need to be provided for stable pools");
      stablePoolInputs.amounts = stablePoolInputs.amounts.map((amount, i) =>
        BigNumber(amount)
          .times(Math.pow(10, pool.assetDecimals[i]))
          .decimalPlaces(0, BigNumber.ROUND_FLOOR)
          .toString(10)
      );

      const new_asset_pools_amount: any = [];

      let total_withdrawal_amount = BigInt(0);
      let new_asset_pools_total_amount = BigInt(0);

      for (const [index, asset_amount] of stablePoolInputs.amounts.entries()) {
        const asset_pool_amount = BigInt(pool.poolAssets[index].amount);
        if (asset_pool_amount > BigInt(0)) {
          let new_asset_pool_amount = asset_pool_amount - BigInt(asset_amount);
          if (new_asset_pool_amount < BigInt(0)) {
            new_asset_pool_amount = BigInt(0);
            // console.log("Underflow calc");
          }
          const new_asset_pool_normalized_amount: any = normalize_amount(
            new_asset_pool_amount.toString(),
            pool.assetDecimals[index]
          );
          new_asset_pools_amount.push(new_asset_pool_normalized_amount);
          total_withdrawal_amount += normalize_amount(asset_amount, pool.assetDecimals[index]);
          new_asset_pools_total_amount += new_asset_pool_normalized_amount;
        } else {
          const asset_pool_normalized_amount = normalize_amount(
            asset_pool_amount.toString(),
            pool.assetDecimals[index]
          );
          new_asset_pools_amount.push(asset_pool_normalized_amount);
          new_asset_pools_total_amount += asset_pool_normalized_amount;
        }
      }
      const final_withdrawal_assets_amount_lockup = [...stablePoolInputs.amounts];
      const assets_fee_amount = Array(stablePoolInputs.amounts.length).fill(BigInt(0));
      const unit_precision = BigInt(10000);
      const N = pool.poolAssets.length;
      const withdrawalFeePer: any = [];

      for (const [index, asset_amount] of stablePoolInputs.amounts.entries()) {
        if (BigInt(asset_amount) > BigInt(0)) {
          const E = new_asset_pools_amount[index];

          let fee_per = BigInt(0);

          // if E is 0 it means we are depleting the pool completly so the fee is infinity => 100%
          if (E > BigInt(0)) {
            const S = new_asset_pools_total_amount - E;

            // S/(N-1)
            const a = BigInt(S) / (BigInt(N) - BigInt(1));

            // (S/(N-1))/E
            const b = (a * unit_precision) / E;

            // ((S/(N-1))/E)^3
            // let c =
            //   // @ts-ignore: Unreachable code error
            //   (b ** BigInt(3) * unit_precision) / unit_precision ** BigInt(3);

            const c = (b * b * b * unit_precision) / (unit_precision * unit_precision * unit_precision);

            // 0.023 x ((S/(N-1))/E)^3
            const d = BigInt(pool.settings.withdrawal_to_lockup.a_fee_param) * c; // 0.023 => 230 with precision is now 8!

            fee_per = d / unit_precision; // back to precision of 4

            // % is higher than 100%
            if (fee_per > BigInt(1000000)) {
              fee_per = BigInt(1000000);
            }
          } else {
            fee_per = BigInt(1000000);
          }

          let fee_amount = ((BigInt(asset_amount) / unit_precision) * fee_per) / BigInt(100);

          // if < 0.25% with precision of 4 the fee is ignored
          if (fee_per < pool.settings.withdrawal_to_lockup.ignore_fee_param) {
            fee_amount = BigInt(0);
          }

          final_withdrawal_assets_amount_lockup[index] = BigInt(BigInt(asset_amount) - fee_amount).toString(10);
          assets_fee_amount[index] = BigInt(fee_amount);

          withdrawalFeePer.push(div(fee_per.toString(), unit_precision.toString()));
        } else {
          withdrawalFeePer.push("0");
        }
      }

      const assets_lockup_fee_amount = Array(stablePoolInputs.amounts.length).fill(BigInt(0));

      let lockupFeePer = "0";

      if (stablePoolInputs.is_instant_withdrawal) {
        const calcs = calcLockup(pool, final_withdrawal_assets_amount_lockup, BigInt(0));

        for (const [index, calc] of calcs.entries()) {
          assets_lockup_fee_amount[index] = calc.fee_amount;
          final_withdrawal_assets_amount_lockup[index] = calc.final_amount.toString();
          lockupFeePer = div(calc.fee_per.toString(), calc.unit_precision.toString());
        }
      }

      // TO SEND THIS TO THE lp_staking of this pool
      return {
        refund_assets: null,
        lpTokens: div(lp_token_from_18_to_6(total_withdrawal_amount.toString()).toString(), Math.pow(10, 6)),
        withdrawalFeePer,
        assets_fee_amount: assets_fee_amount.map((a, i) => div(a.toString(), Math.pow(10, pool.assetDecimals[i]))),
        lockupFeePer,
        assets_lockup_fee_amount: assets_lockup_fee_amount.map((a, i) =>
          div(a.toString(), Math.pow(10, pool.assetDecimals[i]))
        ),
        final_withdrawal_assets_amount_lockup: final_withdrawal_assets_amount_lockup.map((a, i) =>
          div(a.toString(), Math.pow(10, pool.assetDecimals[i]))
        ),
        tx: {
          withdrawal: {
            amount: lp_token_from_18_to_6(total_withdrawal_amount.toString()).toString(),
            direct_pool_withdrawal: {
              stable: {
                withdrawal_lockup_assets_amount: stablePoolInputs.amounts.map(a => a.toString()),
                is_instant_withdrawal: stablePoolInputs.is_instant_withdrawal,
                to: walletAddress,
              },
            },
            not_claim_rewards:
              walletLPBalance !== lp_token_from_18_to_6(total_withdrawal_amount.toString()).toString() ? true : false,
          },
        },
        funds: [],
      };
    }
  } catch (e) {
    console.error(e);
  }
};

export const unstakeSimulationStablePoolXAssetMode = (
  lpAmount: string,
  pool: IPool,
  walletAddress: string,
  walletLPBalance: string
) => {
  const { refund_assets } = stableLPTokenRepresentationXAssetMode(lpAmount, pool);

  console.log(walletLPBalance);
  return {
    refund_assets: [
      BigNumber(refund_assets[0]).div(Math.pow(10, pool.assetDecimals[0])).toString(10),
      BigNumber(refund_assets[1]).div(Math.pow(10, pool.assetDecimals[1])).toString(10),
    ],
    lpTokens: div(lpAmount, Math.pow(10, 6)),
    tx: {
      withdrawal: {
        amount: BigNumber(lpAmount).decimalPlaces(0, BigNumber.ROUND_FLOOR).toString(10),
        direct_pool_withdrawal: {
          stable_xasset_mode: {
            to: walletAddress,
          },
        },
        not_claim_rewards:
          walletLPBalance !== BigNumber(lpAmount).decimalPlaces(0, BigNumber.ROUND_FLOOR).toString(10) ? true : false,
      },
    },
    funds: [],
  };
};

export const standardLPTokenRepresentation = (lpAmount: string, pool: IPool) => {
  const lpAmountWithoutDecimals = BigNumber(lpAmount)
    .times(Math.pow(10, 0))
    .decimalPlaces(0, BigNumber.ROUND_FLOOR)
    .toString(10);
  if (!lpAmount || lpAmountWithoutDecimals === "0") {
    return {
      lpAmountWithoutDecimals: "0",
      share_ratio: "0",
      refund_assets: pool.poolAssets.map(() => "0"),
    };
  }
  const share_ratio = div(lpAmountWithoutDecimals, pool.total_share);

  const refund_assets = pool.poolAssets.map((asset, _) =>
    BigNumber(asset.amount).times(share_ratio).decimalPlaces(0, BigNumber.ROUND_FLOOR).toString(10)
  );

  return {
    lpAmountWithoutDecimals,
    share_ratio,
    refund_assets,
  };
};

export const stableLPTokenRepresentationXAssetMode = (lpAmount: string, pool: IPool) => {
  const lpAmountWithoutDecimals = BigNumber(lpAmount)
    .times(Math.pow(10, 0))
    .decimalPlaces(0, BigNumber.ROUND_FLOOR)
    .toString(10);

  if (lpAmountWithoutDecimals === "0") {
    return {
      lpAmountWithoutDecimals: "0",
      refund_assets: pool.poolAssets.map(() => "0"),
    };
  }

  const amount = lp_token_from_6_to_18(lpAmountWithoutDecimals);

  const pool_assets = pool.poolAssets;
  let total_pool_amount = BigInt(0);
  const pools_amount_normalized = [];
  // assuming this is a pool with just 2 assets (native and its xAsset token)
  // if in the futute there are more than 2 assets on this pool with the xAsset Mode on, this needs to change!
  let xasset_index = null;
  let native_side_index = null;

  for (const [i, pool_asset] of pool_assets.entries()) {
    const normalized_amount = normalize_amount(pool_asset.amount, pool.assetDecimals[i]);
    total_pool_amount += normalized_amount;
    pools_amount_normalized.push(normalized_amount);
    if (pool_asset.info.native_token) {
      native_side_index = i;
    } else {
      xasset_index = i;
    }
  }

  if (BigInt(amount) > total_pool_amount) {
    throw Error("Not enough pool assets to fulfill this request");
  }

  if (xasset_index === null || native_side_index === null) {
    throw Error("Error getting assets index on the pool");
  }

  // safe unwraps as we check them above.
  // this should NEVER error out as these types of pools with XAsset Mode on always will be setup with a native side and a token side
  // let xasset_index = xasset_index.unwrap();
  // let native_side_index = native_side_index.unwrap();

  // assuming this is a pool with just 2 assets (native and its xAsset token)
  // The perfect balance is 100% native asset and 0% xAsset
  // we get the side of the xAsset token and try to fulfill the request
  // if the amount of xAsset tokens is not enought to fulfill all the withdrawal requested by the user we go to the native side to get the rest
  const refund_amounts = [BigInt("0"), BigInt("0")];

  // if xAsset Pool amount is >= Amount withdrawal request, it will be 100% xAsset side
  if (pools_amount_normalized[xasset_index] >= BigInt(amount)) {
    refund_amounts[xasset_index] = denormalize_amount(amount, pool.assetDecimals[xasset_index]);
  } else {
    // if xAsset Pool amount is not enough we take first from the xAsset part 100%, then the rest from the native side
    refund_amounts[xasset_index] = denormalize_amount(
      pools_amount_normalized[xasset_index].toString(),
      pool.assetDecimals[xasset_index]
    );

    const amount_withdrawal_remaining = BigInt(amount) - pools_amount_normalized[xasset_index];

    refund_amounts[native_side_index] = denormalize_amount(
      amount_withdrawal_remaining.toString(),
      pool.assetDecimals[native_side_index]
    );
  }

  const refund_assets = [];
  for (const [i, _] of pool_assets.entries()) {
    refund_assets.push({
      info: pool.poolAssets[i].info,
      amount: refund_amounts[i],
    });
  }

  return {
    lpAmountWithoutDecimals,
    refund_assets: refund_assets.map(asset => asset.amount),
  };
};

export const hybridLPTokenRepresentation = (lpAmount: string, pool: IPool) => {
  const lpAmountWithoutDecimals = BigNumber(lpAmount)
    .times(Math.pow(10, 0))
    .decimalPlaces(0, BigNumber.ROUND_FLOOR)
    .toString(10);
  if (!lpAmount || lpAmountWithoutDecimals === "0" || !pool.hybridRatioDetails.ratio) {
    return {
      lpAmountWithoutDecimals: "0",
      share_ratio: "0",
      refund_assets: pool.poolAssets.map(() => "0"),
    };
  }

  // Try to convert exactly the rust code to TS
  const PRECISION = 1000000;
  const current_real_ratio = { ratio: pool.hybridRatioDetails.ratio };
  const pools_amount_normalized = [
    normalize_amount(pool.poolAssets[0].amount, pool.assetDecimals[0]).toString(),
    normalize_amount(pool.poolAssets[1].amount, pool.assetDecimals[1]).toString(),
  ];

  const pool0_real_value = BigNumber(pools_amount_normalized[0]);
  const pool1_real_value = BigNumber(pools_amount_normalized[1]).times(current_real_ratio.ratio).div(PRECISION);
  const pools_total_real_value = pool0_real_value.plus(pool1_real_value);

  const total_share = pool.total_share;
  const withdraw_ratio = BigNumber(lpAmountWithoutDecimals).div(total_share);

  const pool0_real_per = pool0_real_value.div(pool0_real_value.plus(pool1_real_value));
  const pool1_real_per = pool1_real_value.div(pool0_real_value.plus(pool1_real_value));

  let refund_amounts: any = ["0", "0"];

  try {
    if (pool0_real_per.gte(pool1_real_per)) {
      if (pool0_real_per.minus(withdraw_ratio).gte(0) && pool0_real_per.minus(withdraw_ratio).gte(pool1_real_per)) {
        // 100% token0
        refund_amounts = [
          denormalize_amount(
            pools_total_real_value.times(withdraw_ratio).decimalPlaces(0, BigNumber.ROUND_FLOOR).toString(10),
            pool.assetDecimals[0]
          ).toString(10),
          "0",
        ];
      } else {
        // fixed token0 % + split token 0 and token 1 %s
        const real_amount_target = pool0_real_value.plus(pool1_real_value).times(withdraw_ratio);
        const first_phase_per = pool0_real_per.minus(pool1_real_per);
        const first_phase_pool0_real_amount = pools_total_real_value.times(first_phase_per);
        const first_phase_pool1_real_amount = BigNumber("0");

        const after_phase1_target = real_amount_target
          .minus(first_phase_pool0_real_amount)
          .minus(first_phase_pool1_real_amount);

        const after_phase1_pool0_real_amount = pool0_real_value.minus(first_phase_pool0_real_amount);
        const after_phase1_pool1_real_amount = pool1_real_value.minus(first_phase_pool1_real_amount);

        const pool0_split_per = after_phase1_target.div(2).div(after_phase1_pool0_real_amount);
        const pool1_split_per = after_phase1_target.div(2).div(after_phase1_pool1_real_amount);

        refund_amounts = [
          denormalize_amount(
            first_phase_pool0_real_amount
              .plus(pool0_real_value.minus(first_phase_pool0_real_amount).times(pool0_split_per))
              .decimalPlaces(0, BigNumber.ROUND_FLOOR)
              .toString(10),
            pool.assetDecimals[0]
          ).toString(),
          denormalize_amount(
            first_phase_pool1_real_amount
              .plus(pool1_real_value.minus(first_phase_pool1_real_amount).times(pool1_split_per))
              .times(PRECISION)
              .div(current_real_ratio.ratio)
              .decimalPlaces(0, BigNumber.ROUND_FLOOR)
              .toString(10),
            pool.assetDecimals[1]
          ).toString(),
        ];
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (pool1_real_per.minus(withdraw_ratio).gte(0) && pool1_real_per.minus(withdraw_ratio).gte(pool0_real_per)) {
        // 100% token1
        refund_amounts = [
          "0",
          denormalize_amount(
            pools_total_real_value
              .times(withdraw_ratio)
              .times(PRECISION)
              .div(current_real_ratio.ratio)
              .decimalPlaces(0, BigNumber.ROUND_FLOOR)
              .toString(10),
            pool.assetDecimals[1]
          ).toString(),
        ];
      } else {
        // fixed token1 % + split token 0 and token 1 %s
        const real_amount_target = pool0_real_value.plus(pool1_real_value).times(withdraw_ratio);
        const first_phase_per = pool1_real_per.minus(pool0_real_per);
        const first_phase_pool0_real_amount = BigNumber("0");
        const first_phase_pool1_real_amount = pools_total_real_value.times(first_phase_per);

        const after_phase1_target = real_amount_target
          .minus(first_phase_pool0_real_amount)
          .minus(first_phase_pool1_real_amount);

        const after_phase1_pool0_real_amount = pool0_real_value.minus(first_phase_pool0_real_amount);
        const after_phase1_pool1_real_amount = pool1_real_value.minus(first_phase_pool1_real_amount);

        const pool0_split_per = after_phase1_target.div(2).div(after_phase1_pool0_real_amount);
        const pool1_split_per = after_phase1_target.div(2).div(after_phase1_pool1_real_amount);

        refund_amounts = [
          denormalize_amount(
            first_phase_pool0_real_amount
              .plus(pool0_real_value.minus(first_phase_pool0_real_amount).times(pool0_split_per))
              .decimalPlaces(0, BigNumber.ROUND_FLOOR)
              .toString(10),
            pool.assetDecimals[0]
          ).toString(),
          denormalize_amount(
            first_phase_pool1_real_amount
              .plus(pool1_real_value.minus(first_phase_pool1_real_amount).times(pool1_split_per))
              .times(PRECISION)
              .div(current_real_ratio.ratio)
              .decimalPlaces(0, BigNumber.ROUND_FLOOR)
              .toString(10),
            pool.assetDecimals[1]
          ).toString(),
        ];
      }
    }

    return {
      lpAmountWithoutDecimals,
      share_ratio: pool.hybridRatioDetails.ratio,
      refund_assets: refund_amounts,
    };
  } catch (e) {
    console.log(e);
    return {
      lpAmountWithoutDecimals: "0",
      share_ratio: "0",
      refund_assets: pool.poolAssets.map(() => "0"),
    };
  }
};

export const calcLockup = (pool: IPool, amounts: string[], elapsed_time: bigint) => {
  const unit_precision = BigInt(10000);
  const res = [];
  for (const amount of amounts) {
    const d = unit_precision * (elapsed_time / BigInt(pool.settings.lockup.fee_decay_step_duration));
    const multiplier_nom = BigInt(pool.settings.lockup.fee_decay_multiplier_nom);
    const multiplier_denom = BigInt(pool.settings.lockup.fee_decay_multiplier_denom);

    let fee_per = unit_precision - (d * multiplier_nom) / multiplier_denom;

    if (fee_per < BigInt(0)) fee_per = BigInt(0);

    const fee_amount = (BigInt(amount) * fee_per) / unit_precision / BigInt(100);

    let final_amount = BigInt(amount) - fee_amount;

    if (final_amount < BigInt("0")) final_amount = BigInt("0");

    res.push({
      fee_per,
      fee_amount,
      final_amount,
      unit_precision,
    });
  }

  return res;
};

export default unstakeSimulation;
