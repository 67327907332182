const MAX_PATH_LENGTH = 7; // The longest path this function will find.  Complexity is O(n!)

export const getAllPaths = (
  graph: { [assetX: string]: { [assetY: string]: string } },
  assetX: string,
  assetY: string
) => {
  const currentAssetPath: string[] = [];
  const currentHopPath: string[] = [];
  const pathList: {
    p: string;
    x: string;
    y: string;
  }[][] = [];

  dfs(assetX, assetY, null); // Call recursive function

  return pathList.sort(function (a, b) {
    return a.length - b.length;
  });

  // Depth-first-search
  function dfs(assetX: string, assetY: string, pool: string | null) {
    if (currentAssetPath.includes(assetX)) {
      return;
    }

    // Push this token onto the end of the currentPath
    currentAssetPath.push(assetX);
    if (pool) {
      currentHopPath.push(pool);
    }

    // If tokenA === tokenB, we have reached tokenDest and completed a path
    if (assetX === assetY) {
      let count = 0;
      const path: {
        p: string;
        x: string;
        y: string;
      }[] = [];

      for (const hopPath of currentHopPath) {
        path.push({
          p: hopPath,
          x: currentAssetPath[count],
          y: currentAssetPath[count + 1],
        });
        count++;
      }
      pathList.push(path); // Add the currentPath to pathList
      currentAssetPath.pop(); // Take the current node off the path and rewind
      currentHopPath.pop(); // Take the current node off the path and rewind
      return;
    }

    // Recur for all the vertices adjacent to current vertex
    if (currentAssetPath.length < MAX_PATH_LENGTH) {
      for (const i in graph[assetX]) {
        dfs(i, assetY, graph[assetX][i]);
      }
    }

    currentAssetPath.pop(); // Take the current node off the path and rewind
    currentHopPath.pop(); // Take the current node off the path and rewind
  }
};
