import clsx from "clsx";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import AstrovaultTokenIcon from "../common/AstrovaultTokenIcon";

interface UserRewardsAmountProps {
  extraClassName?: string;
  isGradientBorder?: boolean;
  rewardsValueProps?: NumericFormatProps;
}

function UserRewardsAmount({ extraClassName, isGradientBorder = true, rewardsValueProps }: UserRewardsAmountProps) {
  return (
    <div className={clsx("userRewardsAmount", "inlineFlexbox", extraClassName && extraClassName)}>
      <NumericFormat
        className="userRewardsAmountValue"
        displayType="text"
        thousandSeparator=","
        decimalSeparator="."
        decimalScale={6}
        {...rewardsValueProps}
      />
      <AstrovaultTokenIcon isGradientBorder={isGradientBorder} />
    </div>
  );
}

export default UserRewardsAmount;
