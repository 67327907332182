import { Tooltip, ITooltip } from "react-tooltip";
import clsx from "clsx";
import styles from "../../styles/CustomToolTip.module.scss";

export interface CustomToolTipProps extends ITooltip {
  extraClassName?: string;
  open?: boolean;
}

const CustomToolTip = ({ extraClassName, open, ...props }: CustomToolTipProps) => {
  return (
    <Tooltip
      {...props}
      className={clsx(styles.customTooltip, "customTooltip", extraClassName && extraClassName)}
      isOpen={open}
    ></Tooltip>
  );
};

export default CustomToolTip;
