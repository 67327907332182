import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { ChevronLeft, ChevronRight, ArrowUpRight, Loader } from "react-feather";

import { map } from "lodash";
import BigNumber from "bignumber.js";
import { dashboardAssetsList, dashboardMyPoolsList } from "@axvdex/mocks/DummyData";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import { hasPermitStored } from "@axvdex/utils/localStorage";
import imgSanitize from "@axvdex/utils/imgSanitize";

import useLanguage from "@axvdex/hooks/useLanguage";
import { updateFarmsLpBalance, updatePoolsLpBalance } from "@axvdex/state/wallet/walletSlice";
import { ILpBalance } from "@axvdex/utils/interfaces";
import { collectRewardsAction } from "@axvdex/state/wallet/walletThunks";
import { useGlobalModalContext } from "../providers/GlobalModalProvider";
import styles from "../styles/DashboardMyPools.module.scss";
import Button from "./common/Button";
import UserRewardsAmount from "./user/UserRewardsAmount";
import CustomDropdown from "./common/CustomDropdown";
import DragHandle from "./common/DragHandle";
import CustomLoader from "./common/CustomLoader";
import AstrovaultTokenIcon from "./common/AstrovaultTokenIcon";
import CollectRewardModal from "./modals/CollectRewardModal";
import { abbreviateNumber, abbreviateUSD, formatBalance, humanizeAmount } from "utils/formatNumber";
import { hybridLPTokenRepresentation, standardLPTokenRepresentation } from "utils/unstakeSimulation";
import { TransformedPools } from "types/pools";
import { useAppDispatch, useAppSelector } from "state";
import {
  selectAssetBalances,
  selectAssets,
  selectFarms,
  selectFarmsLpBalance,
  selectFarmsWithBalance,
  selectIsWalletLoading,
  selectPools,
  selectPoolsLpBalance,
  selectPoolsWithBalance,
  selectUserLoading,
  selectWalletInfo,
} from "state/wallet/walletSelectors";
import { FarmRepresentation } from "./common/FarmRepresentation";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";

function DashboardMyPoolsAndFarms() {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const [loadPool, setLoadPool] = useState(true);
  const [showNotConnectedData, setShowNotConnectedData] = useState(false);

  const pools = useAppSelector(selectPools);
  const farms = useAppSelector(selectFarms);
  const poolsLpBalance = useAppSelector(selectPoolsLpBalance);
  const farmsLpBalance = useAppSelector(selectFarmsLpBalance);
  const assets = useAppSelector(selectAssets);
  const poolsWithBalance = useAppSelector(selectPoolsWithBalance);
  const farmsWithBalance = useAppSelector(selectFarmsWithBalance);
  const assetBalances = useAppSelector(selectAssetBalances);
  const isLoadingWallet = useAppSelector(selectIsWalletLoading);
  const walletInfo = useAppSelector(selectWalletInfo);
  const userLoading = useAppSelector(selectUserLoading);

  useEffect(() => {
    if (!isLoadingWallet && !walletInfo.isConnected) {
      setLoadPool(false);
      setShowNotConnectedData(true);
    } else {
      setShowNotConnectedData(false);
      setLoadPool(false);
    }
  }, [walletInfo.isConnected, isLoadingWallet]);

  const getStableDepositedBalanceDollarPrice = poolData => {
    let price = 0;
    poolData.assets.forEach(asset => {
      // in case there is no wallet connect, we still need to calc mocked prices
      if (
        (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) ||
        !hasPermitStored(walletInfo)
      ) {
        price += dashboardAssetsList[asset.id]?.price ?? 0;
      } else {
        price += assets[asset.id]?.price ?? 0;
      }
    });

    return price / poolData.assets.length;
  };

  const getPoolsWithBalanceRewards = async (transformedPools: any) => {
    const lpBalanceRequests = [];
    transformedPools.forEach(pool =>
      !poolsLpBalance[pool.lp_staking]
        ? lpBalanceRequests.push(
            rpcClientQuerySmartContractWrapper(pool.lp_staking, {
              balance: { address: walletInfo.walletAddress },
            })
          )
        : Promise.resolve()
    );

    dispatch(
      updatePoolsLpBalance(
        (await Promise.all(lpBalanceRequests)).reduce((a, v, i) => {
          if (v) {
            return {
              ...a,
              [transformedPools[i].lp_staking]: v,
            };
          }
          return { ...a };
        }, {})
      )
    );
  };

  const getFarmsWithBalanceRewards = async (transformedFarms: any) => {
    const lpBalanceRequests = [];
    transformedFarms.forEach(farm =>
      lpBalanceRequests.push(
        !farmsLpBalance[farm.address]
          ? rpcClientQuerySmartContractWrapper(farm.address, {
              balance: { address: walletInfo.walletAddress },
            })
          : Promise.resolve()
      )
    );

    dispatch(
      updateFarmsLpBalance(
        (await Promise.all(lpBalanceRequests)).reduce((a, v, i) => {
          if (v) {
            return {
              ...a,
              [transformedFarms[i].address]: v,
            };
          }
          return { ...a };
        }, {})
      )
    );
  };

  const poolsData = useMemo(() => {
    const transformedPools =
      pools &&
      Object.keys(pools)
        ?.filter(poolAddress => poolsWithBalance.find(poolWithBalance => poolWithBalance.contract_addr === poolAddress))
        .map((poolAddress: any) => {
          let allAssets = [];
          pools[poolAddress].poolAssets?.forEach(asset => {
            allAssets = [
              ...allAssets,
              {
                ...(assets[asset.info?.token?.contract_addr] || assets[asset.info?.native_token?.denom]),
              },
            ];
          });

          return {
            ...pools[poolAddress],
            assets: allAssets,
            userBalanceTotalInDollars:
              "stable" === pools[poolAddress].type
                ? formatBalance(
                    assetBalances[poolAddress],
                    getStableDepositedBalanceDollarPrice({
                      assets: allAssets,
                    }),
                    6
                  ).usdConversion
                : "standard" === pools[poolAddress].type
                ? allAssets
                    .map(
                      (asset, index) =>
                        formatBalance(
                          standardLPTokenRepresentation(assetBalances[poolAddress], pools[poolAddress]).refund_assets[
                            index
                          ],
                          asset.price,
                          asset.decimals
                        ).usdConversion
                    )
                    .reduce((partialSum, a) => partialSum + a, 0)
                : "hybrid" === pools[poolAddress].type
                ? allAssets
                    .map(
                      (asset, index) =>
                        formatBalance(
                          hybridLPTokenRepresentation(assetBalances[poolAddress], pools[poolAddress]).refund_assets[
                            index
                          ],
                          asset.price,
                          asset.decimals
                        ).usdConversion
                    )
                    .reduce((partialSum, a) => partialSum + a, 0)
                : 0,
          };
        });

    getPoolsWithBalanceRewards(transformedPools);

    return Object.keys(transformedPools)
      .map(item => {
        return transformedPools[item];
      })
      .sort((a, b) => b.userBalanceTotalInDollars - a.userBalanceTotalInDollars);
    // eslint-disable-next-line
  }, [pools, poolsWithBalance]);

  const farmsData = useMemo(() => {
    const transformedFarms =
      farms &&
      Object.keys(farms)
        ?.filter(farmAddress => farmsWithBalance.find(farmWithBalance => farmWithBalance.contract_addr === farmAddress))
        .map((farmAddress: any) => {
          const allAssets = [assets[farms[farmAddress].config.inc_token]];

          return {
            ...farms[farmAddress],
            assets: allAssets,
            userBalanceTotalInDollars: formatBalance(
              assetBalances[farmAddress],
              assets[farms[farmAddress].config.inc_token].price,
              assets[farms[farmAddress].config.inc_token].decimals
            ).usdConversion,
          };
        });

    getFarmsWithBalanceRewards(transformedFarms);

    return Object.keys(transformedFarms)
      .map(item => {
        return transformedFarms[item];
      })
      .sort((a, b) => b.userBalanceTotalInDollars - a.userBalanceTotalInDollars);
    // eslint-disable-next-line
  }, [farms, farmsWithBalance]);

  return (
    <section className={clsx(styles.dashboardGridItemMyPools, "dashboardGridItemMyPools")}>
      <div className="dashboardGridItemTitle">
        <h2>{i18("My Pools/Farms", "dashboard.pools.title")}</h2>
        <div
          style={
            showNotConnectedData ||
            (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet))
              ? { pointerEvents: "none", filter: "blur(0.3rem)" }
              : {}
          }
        >
          <DragHandle extraClassName="ghostDragHandle" />
        </div>
      </div>

      <section className="dashboardSectionPools withGradientBorder">
        {showNotConnectedData ||
        (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) ||
        !hasPermitStored(walletInfo) ? (
          <div style={{ pointerEvents: "none", filter: "blur(0.3rem)" }}>
            <MyPoolsFarmsComponent
              poolsData={dashboardMyPoolsList}
              farmsData={[]}
              assets={dashboardAssetsList}
              assetBalances={assetBalances}
              pools={pools}
              farms={farms}
              getStableDepositedBalanceDollarPrice={getStableDepositedBalanceDollarPrice}
              poolsLpBalance={poolsLpBalance}
              farmsLpBalance={farmsLpBalance}
            />
          </div>
        ) : loadPool ? (
          <CustomLoader size="xs" />
        ) : (
          <MyPoolsFarmsComponent
            poolsData={poolsData}
            farmsData={farmsData}
            assets={assets}
            assetBalances={assetBalances}
            pools={pools}
            farms={farms}
            getStableDepositedBalanceDollarPrice={getStableDepositedBalanceDollarPrice}
            poolsLpBalance={poolsLpBalance}
            farmsLpBalance={farmsLpBalance}
          />
        )}
      </section>
    </section>
  );
}

const MyPoolsFarmsComponent = ({
  poolsData,
  farmsData,
  assetBalances,
  pools,
  farms,
  getStableDepositedBalanceDollarPrice,
  poolsLpBalance,
  farmsLpBalance,
}: any) => {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const walletInfo = useAppSelector(selectWalletInfo);
  const assets = useAppSelector(selectAssets);
  const [loadedForCollectRewards, setLoadedForCollectRewards] = useState(false);

  const calcTotalRewardsDollar = () => {
    const rewardAssets = [];
    let accumulatedRewards = BigNumber(0);

    Object.values({ ...poolsLpBalance, ...farmsLpBalance }).forEach((balance: ILpBalance) => {
      balance.rewards.forEach(reward => {
        if ("0" !== reward.rewards) {
          const rewardAsset = reward.asset.token?.contract_addr || reward.asset.native_token?.denom;
          accumulatedRewards = accumulatedRewards.plus(
            BigNumber(reward.rewards).div(Math.pow(10, assets[rewardAsset].decimals)).times(assets[rewardAsset].price)
          );
          rewardAssets.push(assets[rewardAsset]);
        }
      });
    });

    return (
      <>
        <span>{abbreviateUSD(accumulatedRewards)}</span>
        <ul className="tokenGridList">
          {[...new Set(rewardAssets)].map((token, idx) => {
            return (
              <li key={idx}>
                <span title={token.tokenName} aria-label={token.tokenName}>
                  <img className="tokenIcon" src={imgSanitize(token.symbol)} alt={token.tokenName} />
                </span>
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  const renderRewards = (itemType, itemState, rewardSource) => {
    const rewardSourceAsset =
      rewardSource.reward_asset.token?.contract_addr || rewardSource.reward_asset.native_token?.denom;
    let rewardSourceBalance = null;
    if ("pool" === itemType) {
      rewardSourceBalance =
        poolsLpBalance[itemState.lp_staking] &&
        poolsLpBalance[itemState.lp_staking].rewards.find((reward: any) => {
          if (reward.asset.token && reward.asset.token.contract_addr === rewardSourceAsset) {
            return true;
          }
          if (reward.asset.native_token && reward.asset.native_token.denom === rewardSourceAsset) {
            return true;
          }
          return false;
        });
    }
    if ("farm" === itemType) {
      rewardSourceBalance =
        farmsLpBalance[itemState.address] &&
        farmsLpBalance[itemState.address].rewards.find((reward: any) => {
          if (reward.asset.token && reward.asset.token.contract_addr === rewardSourceAsset) {
            return true;
          }
          if (reward.asset.native_token && reward.asset.native_token.denom === rewardSourceAsset) {
            return true;
          }
          return false;
        });
    }

    if (rewardSourceBalance) {
      const formatRewardAmount = BigNumber(rewardSourceBalance.rewards)
        .div(Math.pow(10, assets[rewardSourceAsset].decimals))
        .decimalPlaces(4)
        .toString(10);
      return (
        <span className={"swiper-no-swiping inlineFlexbox"}>
          &nbsp;
          {formatRewardAmount}{" "}
          <small>
            (
            {abbreviateUSD(
              formatBalance(
                rewardSourceBalance.rewards,
                assets[rewardSourceAsset].price,
                assets[rewardSourceAsset].decimals
              ).usdConversion
            )}
            )
          </small>
          <span
            className="rewardAssetIconWrapper"
            title={assets[rewardSourceAsset].symbol}
            aria-label={assets[rewardSourceAsset].symbol}
          >
            <img
              src={imgSanitize(assets[rewardSourceAsset].symbol)}
              alt={assets[rewardSourceAsset].symbol}
              className="rewardAssetIcon"
            />
          </span>
        </span>
      );
    }
    return <span className={"swiper-no-swiping"}></span>;
  };

  return (
    <>
      {poolsData.length === 0 && farmsData.lenght === 0 ? (
        <span>{i18("Your active pools/farms will show here...", "dashboard.pools.placeholder")}</span>
      ) : (
        <>
          <div className="dashboardSectionPoolsHeader">
            <div className="dashboardSectionPoolsRewards">
              <h3 className="h6">{i18("Your Rewards", "dashboard.pools.rewards")}</h3>
              <div className={clsx("userRewardsAmount", "inlineFlexbox")}>{calcTotalRewardsDollar()}</div>
            </div>

            <CollectRewardModal poolsToShow={poolsData} farmsToShow={farmsData} triggerElement="text" />
          </div>

          <section className="dashboardSectionPoolsSlider">
            <Swiper
              slidesPerView={1}
              slidesPerGroup={1}
              spaceBetween={16}
              centeredSlides={false}
              slidesPerGroupSkip={1}
              modules={[Navigation, Pagination]}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                992: {
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                },
                1400: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
              }}
              navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
              }}
              pagination={{
                el: ".swiper-pagination",
                clickable: true,
              }}
            >
              {map([...poolsData, ...farmsData], (item, idx) => {
                const itemType = pools[item.address] ? "pool" : farms[item.address] ? "farm" : null;
                if (!itemType) return <></>;
                return (
                  <SwiperSlide key={idx}>
                    <article className="dashboardSectionPoolsSliderItem swiperCustom">
                      <div className="dashboardSectionPoolsSliderItemDropdown">
                        <CustomDropdown
                          sticky={true}
                          appendTo={"parent"}
                          placement="bottom-start"
                          zIndex={99}
                          extraClassName="dashboardSectionPoolsCustomDropdown"
                          customDropdownToggle={{
                            btnVariant: "icon",
                            btnColor: "purple",
                            btnTitle: i18("Select", "dashboard.pools.pool.dropdown.title"),
                          }}
                          content={
                            <>
                              <Link
                                to={"pool" === itemType ? "/pool" : "farm" === itemType ? "/farm" : ""}
                                state={
                                  "pool" === itemType
                                    ? { poolId: item.id }
                                    : "farm" === itemType
                                    ? { farmId: item.address }
                                    : null
                                }
                                className="btn btnMyPoolsCustomDropdown"
                                data-icon-placement="left"
                                title={i18(
                                  "View details in ",
                                  "pool" === itemType
                                    ? "dashboard.pools.pool.viewInFarmsTitle"
                                    : "farm" === itemType
                                    ? "dashboard.pools.pool.viewInFarmsTitle"
                                    : ""
                                )}
                                aria-label={i18(
                                  "View details in ",
                                  "pool" === itemType
                                    ? "dashboard.pools.pool.viewInFarmsTitle"
                                    : "farm" === itemType
                                    ? "dashboard.pools.pool.viewInFarmsTitle"
                                    : ""
                                )}
                              >
                                {i18(
                                  "View in ",
                                  "pool" === itemType
                                    ? "dashboard.pools.pool.viewInPools"
                                    : "farm" === itemType
                                    ? "dashboard.pools.pool.viewInFarms"
                                    : ""
                                )}
                                <ArrowUpRight />
                              </Link>

                              {!loadedForCollectRewards ? (
                                <Button
                                  extraClassName="btnMyPoolsCustomDropdown"
                                  text={i18("Collect Rewards", "dashboard.pools.pool.collectRewardsBtn.text")}
                                  title={i18("Open Collect Rewards", "dashboard.pools.pool.collectRewardsBtn.title")}
                                  aria-label={i18(
                                    "Open Collect Rewards",
                                    "dashboard.pools.pool.collectRewardsBtn.title"
                                  )}
                                  icon={<Loader />}
                                  iconPlacement="left"
                                  onClick={async () => {
                                    setLoadedForCollectRewards(true);
                                    await dispatch(
                                      collectRewardsAction({
                                        walletAddress: walletInfo.walletAddress,
                                        poolsData: "pool" === itemType ? [item] : [],
                                        farmsData: "farm" === itemType ? [item] : [],
                                        i18,
                                      })
                                    );
                                    setLoadedForCollectRewards(false);
                                  }}
                                />
                              ) : (
                                <div className="collectRewardLoader">
                                  <CustomLoader size="xs" />
                                </div>
                              )}
                            </>
                          }
                        />
                      </div>

                      <div className="dashboardSectionPoolsSliderItemHeader">
                        <ul
                          className="tokenGridList dashboardSectionPoolsSliderTokenGridList"
                          style={{
                            display: "pool" !== itemType ? "flex" : undefined,
                          }}
                        >
                          {"pool" === itemType &&
                            item.assets.map((token, idx) => {
                              return (
                                <li key={idx}>
                                  <span title={token.tokenName} aria-label={token.tokenName}>
                                    <img className="tokenIcon" src={imgSanitize(token.symbol)} alt={token.tokenName} />
                                  </span>
                                </li>
                              );
                            })}
                          {"farm" === itemType && (
                            <FarmRepresentation
                              address={item.address}
                              inputAsset={{
                                label: assets[item.config.inc_token]?.label,
                                symbol: assets[item.config.inc_token]?.symbol,
                              }}
                              outputAssets={item.reward_sources.map(rewardSource => {
                                const rewardAsset =
                                  assets[
                                    rewardSource.reward_asset.token?.contract_addr ||
                                      rewardSource.reward_asset.native_token?.denom
                                  ];
                                return {
                                  label: rewardAsset?.label,
                                  symbol: rewardAsset?.symbol,
                                };
                              })}
                            />
                          )}
                        </ul>
                      </div>

                      <ul className="poolsTokenDetailsList">
                        {"pool" === itemType && "stable" === item.type ? (
                          <li className="divider">
                            <em>
                              <span className={"swiper-no-swiping"}>
                                {i18("Pool Token", "dashboard.pools.pool.poolToken")}
                              </span>
                            </em>
                            <span>
                              <span className={"swiper-no-swiping"}>
                                {Number(humanizeAmount(assetBalances[item.address], 6)).toFixed(2) || 0}
                                <small>
                                  &nbsp;(
                                  {abbreviateUSD(
                                    formatBalance(
                                      assetBalances[item.address],
                                      getStableDepositedBalanceDollarPrice(item),
                                      6
                                    ).usdConversion
                                  )}
                                  )
                                </small>
                              </span>
                            </span>
                          </li>
                        ) : "pool" === itemType && "standard" === item.type ? (
                          <div className="divider">
                            {item.poolAssetSymbols.map((assetSymbol, index) => (
                              <li key={assetSymbol}>
                                <em>
                                  <span className={"swiper-no-swiping"}>{assetSymbol}</span>
                                </em>
                                <span>
                                  <span className={"swiper-no-swiping"}>
                                    {abbreviateNumber(
                                      formatBalance(
                                        standardLPTokenRepresentation(assetBalances[item.address], pools[item.address])
                                          .refund_assets[index],
                                        item.assets[index].price,
                                        item.assets[index].decimals
                                      ).amount
                                    )}
                                    <small>
                                      &nbsp;(
                                      {abbreviateUSD(
                                        formatBalance(
                                          standardLPTokenRepresentation(
                                            assetBalances[item.address],
                                            pools[item.address]
                                          ).refund_assets[index],
                                          item.assets[index].price,
                                          item.assets[index].decimals
                                        ).usdConversion
                                      )}
                                      )
                                    </small>
                                  </span>
                                </span>
                              </li>
                            ))}
                          </div>
                        ) : "pool" === itemType && "hybrid" === item.type ? (
                          <div className="divider">
                            {item.poolAssetSymbols.map((assetSymbol, index) => (
                              <li key={assetSymbol}>
                                <em>
                                  <span className={"swiper-no-swiping"}>{assetSymbol}</span>
                                </em>
                                <span>
                                  <span className={"swiper-no-swiping"}>
                                    {abbreviateNumber(
                                      formatBalance(
                                        hybridLPTokenRepresentation(assetBalances[item.address], pools[item.address])
                                          .refund_assets[index],
                                        item.assets[index].price,
                                        item.assets[index].decimals
                                      ).amount
                                    )}
                                    <small>
                                      &nbsp;(
                                      {abbreviateUSD(
                                        formatBalance(
                                          hybridLPTokenRepresentation(assetBalances[item.address], pools[item.address])
                                            .refund_assets[index],
                                          item.assets[index].price,
                                          item.assets[index].decimals
                                        ).usdConversion
                                      )}
                                      )
                                    </small>
                                  </span>
                                </span>
                              </li>
                            ))}
                          </div>
                        ) : "farm" === itemType ? (
                          <div className="divider">
                            {item.assets.map((asset, index) => (
                              <li key={asset.symbol}>
                                <em>
                                  <span className={"swiper-no-swiping"}>{asset.symbol}</span>
                                </em>
                                <span>
                                  <span className={"swiper-no-swiping"}>
                                    {abbreviateNumber(
                                      formatBalance(farmsLpBalance[item.address]?.locked, asset.price, asset.decimals)
                                        .amount
                                    )}
                                    <small>
                                      &nbsp;(
                                      {abbreviateUSD(
                                        formatBalance(farmsLpBalance[item.address]?.locked, asset.price, asset.decimals)
                                          .usdConversion
                                      )}
                                      )
                                    </small>
                                  </span>
                                </span>
                              </li>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}

                        {"pool" === itemType && poolsLpBalance && item.lp_staking_info?.reward_sources.length > 0 ? (
                          item.lp_staking_info?.reward_sources.map((rewardSource, i) => (
                            <li key={i}>
                              <em>
                                {i === 0 && (
                                  <span className={"swiper-no-swiping"}>
                                    {i18("Rewards", "dashboard.pools.pool.rewards")}
                                  </span>
                                )}
                              </em>
                              <span>{renderRewards(itemType, item, rewardSource)}</span>
                            </li>
                          ))
                        ) : "farm" === itemType && farmsLpBalance && item.reward_sources.length > 0 ? (
                          item.reward_sources.map((rewardSource, i) => (
                            <li key={i}>
                              <em>
                                {i === 0 && (
                                  <span className={"swiper-no-swiping"}>
                                    {i18("Rewards", "dashboard.pools.pool.rewards")}
                                  </span>
                                )}
                              </em>
                              <span>{renderRewards(itemType, item, rewardSource)}</span>
                            </li>
                          ))
                        ) : (
                          <li>
                            <em>
                              {" "}
                              <span className={"swiper-no-swiping"}>
                                {i18("Rewards", "dashboard.pools.pool.rewards")}
                              </span>
                            </em>
                            <span>
                              <span className={"swiper-no-swiping"}>-</span>
                            </span>
                          </li>
                        )}
                      </ul>
                    </article>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <div className="buttonContainer dashboardSectionPoolsSliderButtonContainer">
              <Button
                title={i18("Previous", "dashboard.pools.slider.previous")}
                btnVariant="icon"
                btnColor="dark-medium"
                extraClassName="swiper-button-prev"
                icon={<ChevronLeft />}
              />

              <div className="swiper-pagination" />

              <Button
                title={i18("Next", "dashboard.pools.slider.next")}
                btnVariant="icon"
                btnColor="dark-medium"
                extraClassName="swiper-button-next"
                icon={<ChevronRight />}
              />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default DashboardMyPoolsAndFarms;
