import { SigningArchwayClient } from "@archwayhq/arch3.js";
import { ChainInfo, Coin, StdSignature } from "@keplr-wallet/types";
import { TxRaw } from "cosmjs-types/cosmos/tx/v1beta1/tx";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AminoTypes,
  createProtobufRpcClient,
  QueryClient,
  setupAuthExtension,
  SigningStargateClient,
  StargateClient,
} from "@cosmjs/stargate";
import BigNumber from "bignumber.js";
import { MsgExecuteContract } from "cosmjs-types/cosmwasm/wasm/v1/tx";
import { PeriodicVestingAccount } from "cosmjs-types/cosmos/vesting/v1beta1/vesting";
import { QueryClientImpl } from "cosmjs-types/cosmos/auth/v1beta1/query";
import { Tendermint34Client } from "@cosmjs/tendermint-rpc";
import {
  CosmWasmClient,
  createWasmAminoConverters,
  MsgExecuteContractEncodeObject,
  SigningCosmWasmClient,
} from "@cosmjs/cosmwasm-stargate";
import { SecretNetworkClient } from "secretjs";
import Long from "long";
import { MsgTransfer } from "cosmjs-types/ibc/applications/transfer/v1/tx";
import { IContract, IFarm, IPool } from "@axvdex/utils/interfaces";
import { chainConfig } from "@axvdex/constants";
import { signHackedAmino } from "@axvdex/utils/aminoFeeGrantHack";
import parseWalletErrorMessage from "@axvdex/utils/parseWalletErrorMessage";
import rpcClientQuerySmartContractWrapper, { sendLogError } from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
import signAndBroadcastEVM from "@axvdex/utils/signAndBroadcastEVM";
import { delay } from "./walletEffects";
import { getListOfPools } from "api/pools";
import { AsyncThunkConfig } from "state";
import { loadState, persistState, removeState, WHITE_LIST_PERSISTED_STATE_KEYS } from "state/persist";
import {
  clearWallet,
  initWalletInfo,
  sendToast,
  setGRVT8Balance,
  setLoadingWallet,
  setMyDashboardGrid,
  setMyGlobalSettings,
  setPoolInfo,
  setStateBalances,
  setStateFarmBalances,
  setStateLpBalances,
  setStateUser,
  setVestingLockedAmount,
  updateAssetBalances,
  updateAssetsUnmints,
  updateConnectionStatus,
  updateFarmsLpBalance,
  updateMyAssets,
  updatePools,
  updatePoolsLpBalance,
  updatePriceWatch,
  updateUser,
  updatingUser,
} from "state/wallet/walletSlice";
import {
  getUser,
  getUserLogs,
  postFeeGrantTx,
  postSignature,
  postTransactions,
  updateUserAssets,
  updateUserDashboardGrid,
  updateUserGlobalSettings,
  updateUserPriceWatch,
} from "api/user";

export interface Wallet {
  name: string;
  address: string;
  connectAccountNumber: number;
  connectSequence: number;
  signerType: string;
}

let connectingWallet = false;
let changingAccount = false;

export const connectWallet = async (dispatch, showModal) => {
  if (connectingWallet) throw Error("Already connecting wallet...");
  connectingWallet = true;
  try {
    const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
    const chainId = process.env.REACT_APP_ARCHWAY_NETWORK as string;
    const coinDenom = process.env.REACT_APP_ARCHWAY_DENOM as string;
    const coinMinimalDenom = process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM as string;
    const chainName = process.env.REACT_APP_ARCHWAY_NETWORK as string;
    const rpc = process.env.REACT_APP_RPC_URL as string;
    const rest = process.env.REACT_APP_REST_URL as string;

    //if any of the env variables are missing, don't connect
    if (!chainId || !coinDenom || !coinMinimalDenom || !chainName || !rpc || !rest) {
      console.error("Missing environment variables");
      throw new Error("Missing environment variables");
    }
    if (!window[walletExtension]) {
      throw new Error("Please install " + walletExtension + " extension");
    }
    console.debug("Connecting to " + walletExtension + "...");

    let walletExtensionClient = window[walletExtension];

    if ("cosmostation" === walletExtension) {
      walletExtensionClient = window.cosmostation.providers.keplr;
    }
    if ("leap" !== walletExtension) {
      // leap mobile seems to block on this function
      await walletExtensionClient.experimentalSuggestChain(chainConfig);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // get supported chains by leap, if it already has the chain we are trying to add, it skips the experimentalSuggestChain
      const suportedChains = await window.leap.getSupportedChains();
      if (!suportedChains.includes(chainConfig.chainName.toLocaleLowerCase())) {
        await walletExtensionClient.experimentalSuggestChain(chainConfig);
      }
    }
    await walletExtensionClient.enable(chainId);
    walletExtensionClient.defaultOptions = {
      sign: {
        preferNoSetFee: true,
        preferNoSetMemo: true,
      },
    };
    const accountRaw = await walletExtensionClient.getKey(chainId);
    const offlineSigner = await walletExtensionClient.getOfflineSignerAuto(chainId);
    const account = (await offlineSigner.getAccounts())[0];
    let wallet: Wallet;
    let walletNativeBalances: Coin[] = [];
    try {
      const [client, starClient] = await Promise.all([
        SigningArchwayClient.connectWithSignerAndBatchClient(rpc, offlineSigner, {
          broadcastPollIntervalMs: process.env.REACT_APP_BROADCAST_POLL_INTERVAL
            ? parseInt(process.env.REACT_APP_BROADCAST_POLL_INTERVAL)
            : undefined,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          batchClientOptions: {
            dispatchInterval: 250,
            batchSizeLimit: 25,
          },
        }),
        // [ONCHAIN_REQUEST] 3 requests to check status of mainnet-apis-sentry-XXXX
        StargateClient.connect(rpc),
      ]);
      try {
        // if this fails we know this acc does not exist onchain yet, so it can be eligible for fee grant
        const [accDetails, nativeLockedAmount] = await Promise.all([
          // [ONCHAIN_REQUEST] 1 request to get acc info from chain
          starClient.getSequence(account.address),
          // [ONCHAIN_REQUEST] 2 requests to connect to node + get account info with more details
          tryGetLockedNativeDenom(account.address),
        ]);
        if ("0" !== nativeLockedAmount) {
          dispatch(setVestingLockedAmount(nativeLockedAmount));
        }

        wallet = {
          name: accountRaw.name,
          address: account.address,
          connectAccountNumber: accDetails.accountNumber,
          connectSequence: accDetails.sequence,
          signerType: "undefined" !== typeof offlineSigner.signDirect ? "direct" : "amino",
        };
      } catch (e) {
        wallet = {
          name: accountRaw.name,
          address: account.address,
          connectAccountNumber: null,
          connectSequence: null,
          signerType: "undefined" !== typeof offlineSigner.signDirect ? "direct" : "amino",
        };
      }
      // [ONCHAIN_REQUEST] 1 request to get acc all native balances
      walletNativeBalances = [...(await starClient.getAllBalances(wallet.address))];
      window.client = client;
    } catch (e) {
      sendLogError(e.message);
      console.error(e);
      throw e;
    }
    if ("cosmostation" === walletExtension) {
      window.cosmostation.cosmos.on("accountChanged", async () => {
        if (changingAccount) return;
        changingAccount = true;
        // console.log("Key store changed. Refetching account info...");
        await handleAccountChange(dispatch, showModal);
        changingAccount = false;
      });
    } else {
      window.removeEventListener(walletExtension + "_keystorechange", () => {});
      window.addEventListener(walletExtension + "_keystorechange", async () => {
        if (changingAccount) return;
        changingAccount = true;
        // console.log("Key store changed. Refetching account info...");
        await handleAccountChange(dispatch, showModal);
        changingAccount = false;
      });
    }

    connectingWallet = false;
    return { wallet, walletNativeBalances };
  } catch (e) {
    connectingWallet = false;
    sendLogError(e.message);
    console.error(e);
    throw e;
  }
};

export const tryGetLockedNativeDenom = async (address: string) => {
  const tmClient = await Tendermint34Client.connect(process.env.REACT_APP_RPC_URL as string);
  const queryClient = QueryClient.withExtensions(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    tmClient!,
    setupAuthExtension
  );

  // locked at 2023-09-03
  // archway1sz96l6kzjax8fy7qywqgn0qa65c4u77397muny { denom: 'aarch', amount: '72033282984325328092' } = 2625
  // archway1t96nhcc9uuvkr9dlmuqkvg0myqkaut9p599ddk { denom: 'aarch', amount: '3501000000000000000000' } = 2625
  // archway1zewgssdsgdyqzwm0ckzhh20azju4hlp6ukyfju { denom: 'aarch', amount: '5001000000000000000000' } = 3750
  // archway1rugdvgs6vttk55dcj7xa3ptv9p532y7gjef3wz { denom: 'aarch', amount: '2501000000000000000000' } = 1875
  // archway1lkdcfz9ukhcmhvcfrzxy357ysp2u6txzsvqdkc { denom: 'aarch', amount: '2768816100000000000' } = 375
  // archway1a92wa7xvrvr8gkvr8mct2hjnsv4r8rphhnjpc0 { denom: 'aarch', amount: '501000000000000000000' } = 375
  // archway1yfss2hezldzxxs8qp83azrenlwdx3tyfsscuck { denom: 'aarch', amount: '1013257900000000000' } = 750
  // archway1whq6ktvgvlk63nj5qz7dfhz6zfhcadhfeh53p8 { denom: 'aarch', amount: '1001000000000000000000' } = 750
  // archway1ggg5klf4e4wxgc4f8z0vmc9pcxh5s5nvjw28tp { denom: 'aarch', amount: '501000000000000000000' } = 375
  // Uncomment to test one mainnet wallet that has locked up assets:
  // const [acc] = await Promise.all([queryClient.auth.account("archway1t96nhcc9uuvkr9dlmuqkvg0myqkaut9p599ddk")]);
  const [acc] = await Promise.all([queryClient.auth.account(address)]);

  if ("/cosmos.vesting.v1beta1.PeriodicVestingAccount" === acc.typeUrl) {
    // the wallets that had NOT delegated, have bad balance with available + locked
    // we need to get the amount of locked and subtract to the balance of native denom
    const accDetails = PeriodicVestingAccount.decode(acc.value);

    const delegatedVesting = accDetails.baseVestingAccount.delegatedVesting
      .filter(coin => coin.denom === process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM)
      .reduce((a, b) => a.plus(b.amount), BigNumber(0));

    // if user has delegatedVesting amount, it means it delegated its locked tokens so the balance showing is correct
    if (delegatedVesting.gt(0)) {
      return "0";
    }

    // get periods that have not yet passed
    const availableVesting = [];
    let accumulator = 0;
    accDetails.vestingPeriods.forEach(period => {
      if (accDetails.startTime.toNumber() + accumulator + period.length.toNumber() > new Date().getTime() / 1000)
        availableVesting.push(
          period.amount
            .filter(coin => coin.denom === process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM)
            .reduce((a, b) => a.plus(b.amount), BigNumber(0))
        );
      accumulator += period.length.toNumber();
    });

    const lockedAmount = availableVesting.reduce((a, b) => a.plus(b), BigNumber(0));

    return lockedAmount.gt(0) ? lockedAmount.toString(10) : "0";
  }
  return "0";
};

export const getFeeGrantSigners = async () => {
  const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
  const chainId = process.env.REACT_APP_ARCHWAY_NETWORK as string;
  const rpc = process.env.REACT_APP_RPC_URL as string;
  let walletExtensionClient = window[walletExtension];

  if ("cosmostation" === walletExtension) {
    walletExtensionClient = window.cosmostation.providers.keplr;
  }
  const offlineSigner = await walletExtensionClient.getOfflineSignerAuto(chainId);
  const signer = await SigningStargateClient.connectWithSigner(rpc, offlineSigner, {
    aminoTypes: new AminoTypes({
      ...createWasmAminoConverters(),
    }),
    broadcastPollIntervalMs: process.env.REACT_APP_BROADCAST_POLL_INTERVAL
      ? parseInt(process.env.REACT_APP_BROADCAST_POLL_INTERVAL)
      : undefined,
  });

  signer.registry.register("/cosmwasm.wasm.v1.MsgExecuteContract", MsgExecuteContract);
  const tmClient = await Tendermint34Client.connect(process.env.REACT_APP_RPC_URL as string);
  const queryClient = QueryClient.withExtensions(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    tmClient!,
    setupAuthExtension
  );

  const queryService = new QueryClientImpl(createProtobufRpcClient(queryClient));

  if (!walletExtensionClient?.signAmino) {
    throw new Error("Please install wallet extension");
  }

  const signerOff = await StargateClient.connect(rpc);

  return {
    stargateSigner: signer,
    queryService,
    offlineSigner,
    walletExtensionClient,
    signerOff,
  };
};

export const getIbcDepositClientSigner = async (chainId: string, rpc: string, denom: string, chainInfo: ChainInfo) => {
  const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
  let walletExtensionClient = window[walletExtension];

  if ("cosmostation" === walletExtension) {
    walletExtensionClient = window.cosmostation.providers.keplr;
  }

  await walletExtensionClient.experimentalSuggestChain(chainInfo);
  const offlineSigner = await walletExtensionClient.getOfflineSignerAuto(chainId);
  const acc_address = (await offlineSigner.getAccounts())[0].address;
  const ibcClient = await SigningStargateClient.connectWithSigner(rpc, offlineSigner);

  let balance = null;
  // cw20 denoms can appear here, so we check for if the name of the denom starts with cw20: and query the contract assuming it's a cw20base standard
  if ("cw20:" === denom.substring(0, 5)) {
    // Secret Network is a special case as they have viewing keys to get the balance from their cw20 tokens
    if (chainInfo.chainId.includes("secret")) {
      const client = await new SecretNetworkClient({
        url: chainInfo.rest,
        chainId: chainInfo.chainId,
      });

      // return a isSecretNetworkViewingKeyError flag to identify this error
      let key = null;
      try {
        key = await walletExtensionClient.getSecret20ViewingKey(chainInfo.chainId, denom.split(":")[1]);
      } catch (e) {
        return { acc_address, balance: "0", client: ibcClient, isSecretNetworkViewingKeyError: true };
      }
      if (!key) return { acc_address, balance: "0", client: ibcClient, isSecretNetworkViewingKeyError: true };

      balance = (
        (await client.query.compute.queryContract({
          contract_address: denom.split(":")[1],
          query: { balance: { address: acc_address, key } },
        })) as any
      ).balance;
    } else {
      const client = await CosmWasmClient.connect(rpc);
      balance = await client.queryContractSmart(denom.split(":")[1], { balance: { address: acc_address } });
    }
  } else {
    balance = await ibcClient.getBalance(acc_address, denom);
  }

  return { acc_address, balance: balance.amount, client: ibcClient };
};

export const getIbcWithdrawalAccAddress = async (chainId: string) => {
  const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
  let walletExtensionClient = window[walletExtension];

  if ("cosmostation" === walletExtension) {
    walletExtensionClient = window.cosmostation.providers.keplr;
  }

  const offlineSigner = await walletExtensionClient.getOfflineSignerAuto(chainId);
  const acc_address = (await offlineSigner.getAccounts())[0].address;

  const offlineSignerArch = await walletExtensionClient.getOfflineSignerAuto(process.env.REACT_APP_ARCHWAY_NETWORK);
  const client = await SigningStargateClient.connectWithSigner(process.env.REACT_APP_RPC_URL, offlineSignerArch);
  return { acc_address, client };
};

export const disconnectWallet = createAsyncThunk<void, void, AsyncThunkConfig>(
  "wallet/disconnectWallet",
  async (_, { getState, dispatch }) => {
    const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
    // remove items from localstorage (permit)
    const walletInfo = getState().wallet.walletInfo;
    localStorage.removeItem("connected");
    const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
    delete permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress];
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.permits, permits);
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet, "");
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet, false);
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.agreedCompliance, false);
    removeState(WHITE_LIST_PERSISTED_STATE_KEYS.dashboardGrid);
    // remove wallet enable logging out
    // try catch needed as Leap does not have the .disable() function
    // clean disconnect still works in Leap because of the erase state below
    // but Leap will NOT erase the approved domain as this function is not available
    if (!/undefined/i.test(typeof window[walletExtension].disable)) {
      await window[walletExtension].disable();
    } else if (!/undefined/i.test(typeof window[walletExtension].disconnect)) {
      const chainId = process.env.REACT_APP_ARCHWAY_NETWORK as string;
      await window[walletExtension].disconnect(chainId);
    }

    // erase state
    dispatch(clearWallet());
    window.client = null;
  }
);

const handleAccountChange = async (dispatch, showModal) => {
  try {
    await dispatch(connectWalletWithDispatch({ showModal }));
    await dispatch(
      setStateUser({
        favoriteSwaps: [],
        favoritePools: [],
        favoriteAssets: [],
        priceWatch: [],
        poolsWithBalance: [],
        swapLogs: [],
        myAssets: [],
        isUserDetailsLoad: false,
        myAssetUnmints: {},
        mySoftLockups: {},
        myGlobalSettings: null,
      })
    );
    await dispatch(setStateBalances({}));
    await dispatch(setStateLpBalances({}));
    await dispatch(setStateFarmBalances({}));
    await dispatch(handleAuthenticationOnAccountChange({ showModal }));
  } catch (e) {
    console.error(e);
  }
};

let executingAuthenticationOnAccountChange = false;

export const handleAuthenticationOnAccountChange = createAsyncThunk<void, { showModal }, AsyncThunkConfig>(
  "wallet/handleAuthenticationOnAccountChange",
  async ({ showModal }, { dispatch, getState }) => {
    try {
      if (!executingAuthenticationOnAccountChange) {
        executingAuthenticationOnAccountChange = true;
        dispatch(updatingUser(true));
        const walletInfo = getState().wallet.walletInfo;
        const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
        if (!permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress]) {
          showModal("permitAuthenticationModal");
        }
        const sign = permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress];
        try {
          const userInfo = await fetchUserInfo(sign.signature, walletInfo.walletAddress);
          dispatch(updateUser(userInfo));
        } catch (e) {
          console.error(e);
        }

        executingAuthenticationOnAccountChange = false;
      }

      // snackbar.success(i18("header.permitSaved", "Success saving permit"));
    } catch (err) {
      // snackbar.error(i18("header.permitError", "Failed creating permit"));
      // console.error(err);
      dispatch(updatingUser(false));
      executingAuthenticationOnAccountChange = false;
    }
  }
);

export const connectWalletWithDispatch = createAsyncThunk<Wallet | null, { showModal }, AsyncThunkConfig>(
  "wallet/connectWalletWithDispatch",
  async ({ showModal }, { dispatch }) => {
    try {
      dispatch(setLoadingWallet(true));
      const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
      if (!window[walletExtension]) throw new Error("UNINSTALLED");
      const { wallet, walletNativeBalances } = await connectWallet(dispatch, showModal);
      dispatch(
        initWalletInfo({
          wallet,
          assetBalances: walletNativeBalances.reduce(
            (obj, item) => Object.assign(obj, { [item.denom]: item.amount }),
            {}
          ),
        })
      );
      persistState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet, true);
      // dispatch(loadingWallet(false));
      return wallet;
    } catch (e) {
      if ("Already connecting wallet..." === e.message) return null;
      if ("UNINSTALLED" === e.message) {
        dispatch(
          sendToast({ type: "tx-fail", info: { msg: "Wallet not found!", toastID: "" + new Date().getTime() } })
        );
      }
      persistState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet, false);
      persistState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet, "");
      dispatch(
        updateConnectionStatus({
          isConnected: false,
          walletAddress: "",
          assetBalances: {
            [process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM || ""]: 0,
          },
          connectionStatus:
            "No wallet exists" === e.message
              ? "No wallet exists, please create one..."
              : "UNINSTALLED" === e.message
              ? "Install keplr extension..."
              : "Invalid chain Id" === e.message
              ? "NOT_CONNECTED"
              : "NOT_CONNECTED",
        })
      );
      return null;
    }
  }
);

export const createUserPermit = async (walletAddress: string) => {
  const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
  let walletExtensionClient = window[walletExtension];
  if ("cosmostation" === walletExtension) {
    walletExtensionClient = window.cosmostation.providers.keplr;
  }

  if (!walletExtensionClient?.signAmino) {
    throw new Error("Please install wallet extension");
  }
  const { signature } = await walletExtensionClient.signAmino(
    process.env.REACT_APP_ARCHWAY_NETWORK || "",
    walletAddress,
    {
      account_number: "0", // Must be 0
      chain_id: process.env.REACT_APP_ARCHWAY_NETWORK || "",
      fee: {
        amount: [{ denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM || "", amount: "0" }],
        gas: "0",
      },
      memo: "", // Must be empty
      msgs: [
        {
          type: "permit",
          value: "Astrovault User Permit",
        },
      ],
      sequence: "0", // Must be 0
    },
    {
      preferNoSetFee: true, // Fee must be 0, so hide it from the user
      preferNoSetMemo: true, // Memo must be empty, so hide it from the user
    }
  );

  return signature;
};

export const executeContract = async (walletAddress: string, contractAddress: string, msg, funds?: Coin[]) => {
  return window.client.execute(
    walletAddress,
    contractAddress,
    msg,
    "auto",
    undefined,
    funds ? funds.sort((a, b) => a.denom.localeCompare(b.denom)) : undefined
  );
};

export const updateAssetsUnmint = createAsyncThunk<
  { unmints: string[]; readyUnmints: string[] },
  { assetID: string; userAddress: string; derivativeAddress: string; contracts: { [key: string]: IContract } },
  AsyncThunkConfig
>("wallet/updateAssetsUnmint", async ({ userAddress, derivativeAddress, contracts, assetID }, { dispatch }) => {
  try {
    const res = await rpcClientQuerySmartContractWrapper(derivativeAddress, {
      user_withdrawals: { address: userAddress },
    });
    const notReadyUnmints = [];
    const readyUnmints = res
      .filter(unmint => {
        const endDate = Object.entries(contracts).find(([_, contract]) => contract.address === derivativeAddress)[1]
          .config.is_external_source
          ? unmint.window_details.withdrawal_external_end_timestamp
          : unmint.window_details.withdrawal_estimated_end_timestamp;
        if (!endDate) {
          notReadyUnmints.push(unmint.amount);
          return false;
        }
        if (new Date().getTime() > endDate * 1000) return true;
        notReadyUnmints.push(unmint.amount);
        return false;
      })
      .map(unmint => unmint.amount);

    const unmints = {
      unmints: notReadyUnmints,
      readyUnmints,
    };

    dispatch(
      updateAssetsUnmints({
        [assetID]: unmints,
      })
    );

    return unmints;
  } catch (e) {
    //console.error(e);
  }
});

export const updateNativeBalance = createAsyncThunk<string, { userAddress: string; denom: string }, AsyncThunkConfig>(
  "wallet/updateNativeBalance",
  async ({ userAddress, denom }, { dispatch }) => {
    const balance = await window.client.getBalance(userAddress, denom);
    dispatch(updateAssetBalances({ [balance.denom]: balance.amount }));
    return balance.amount;
  }
);

export const updateTokenBalance = createAsyncThunk<
  string,
  { userAddress: string; tokenAddress: string },
  AsyncThunkConfig
>("wallet/updateTokenBalance", async ({ userAddress, tokenAddress }, { dispatch }) => {
  try {
    const response = await rpcClientQuerySmartContractWrapper(tokenAddress, {
      balance: { address: userAddress },
    });

    dispatch(updateAssetBalances({ [tokenAddress]: response.balance }));
    return response.balance;
  } catch (e) {
    //console.error(e);
  }
});

export const updateLpStakingBalance = createAsyncThunk<
  string,
  { userAddress: string; lpStakingAddress: string },
  AsyncThunkConfig
>("wallet/updateLpStakingBalance", async ({ lpStakingAddress, userAddress }, { dispatch }) => {
  const response = await rpcClientQuerySmartContractWrapper(lpStakingAddress, {
    balance: { address: userAddress },
  });
  dispatch(updateAssetBalances({ [lpStakingAddress]: response.locked }));
  dispatch(updatePoolsLpBalance({ [lpStakingAddress]: response }));
  return response.locked;
});

export const updateFarmLpStakingBalance = createAsyncThunk<
  string,
  { userAddress: string; lpStakingAddress: string },
  AsyncThunkConfig
>("wallet/updateFarmLpStakingBalance", async ({ lpStakingAddress, userAddress }, { dispatch }) => {
  const response = await rpcClientQuerySmartContractWrapper(lpStakingAddress, {
    balance: { address: userAddress },
  });
  dispatch(updateAssetBalances({ [lpStakingAddress]: response.locked }));
  dispatch(updateFarmsLpBalance({ [lpStakingAddress]: response }));
  return response.locked;
});

export const executeIbcTransfer = createAsyncThunk<
  void,
  {
    sendingClient: SigningStargateClient;
    sendingAddrInput: string;
    destUserAddress: string;
    denomToSend: string;
    amount: string;
    channel: string;
    fee: {
      denom: string;
      amount: string;
    };
    srcChain: {
      explorerURL: string;
      chainId: string;
      restURL: string;
      rpcURL: string;
      isEVM: boolean;
    };
    dstChain: {
      explorerURL: string;
      chainId: string;
      restURL: string;
      rpcURL: string;
      isEVM: boolean;
    };
    assetBalancesToUpdate?: { userAddress: string; tokens: string[]; natives: string[] };
    cw20_ics20?: {
      ibcRecvChannel: string;
      ibcSendChannel: string;
      cw20Address: string;
      ics20Address: string;
      chainId: string;
      ics20CodeHash?: string;
    };
    isWithdrawal?: boolean;
    i18: any;
  },
  AsyncThunkConfig
>(
  "wallet/executeIbcTransfer",
  async (
    {
      sendingClient,
      sendingAddrInput,
      destUserAddress,
      denomToSend,
      amount,
      channel,
      fee,
      srcChain,
      dstChain,
      assetBalancesToUpdate,
      cw20_ics20,
      isWithdrawal,
      i18,
    },
    { dispatch }
  ) => {
    let isSuccessful = false;
    let msg = "";
    let sendLink = null;
    let timeoutLink = null;
    let receiveLink = null;
    let packet_sequence = null;
    let packet_src_channel = null;
    let packet_dst_channel = null;

    try {
      let res = null;
      if (!cw20_ics20) {
        const msgTransfer: MsgTransfer = {
          sourcePort: "transfer",
          sourceChannel: channel,
          token: {
            denom: denomToSend,
            amount,
          },
          sender: sendingAddrInput,
          receiver: destUserAddress,
          memo: "",
          timeoutTimestamp: Long.fromString(String((Date.now() + 600000) * 1000000), true),
        };

        const msg = {
          typeUrl: "/ibc.applications.transfer.v1.MsgTransfer",
          value: msgTransfer,
        };

        if (!srcChain.isEVM) {
          res = await sendingClient.signAndBroadcast(sendingAddrInput, [msg], {
            amount: [fee],
            gas: "200000",
          });
        } else {
          console.log("123");
          res = await signAndBroadcastEVM(
            sendingClient,
            {
              amount: [fee],
              gas: "200000",
            },
            {
              chainId: srcChain.chainId,
              restURL: srcChain.restURL,
            },
            sendingAddrInput,
            [msg]
          );
        }
        sendLink = srcChain.explorerURL + res.transactionHash;
        const event = res.events.find(event => "send_packet" === event.type);
        if (!event) throw Error(res.rawLog);
        packet_sequence = event.attributes.find(attribute => "packet_sequence" === attribute.key).value;
        packet_src_channel = event.attributes.find(attribute => "packet_src_channel" === attribute.key).value;
        packet_dst_channel = event.attributes.find(attribute => "packet_dst_channel" === attribute.key).value;
      }
      // for cw20_ics20 DEPOSITS we need to use different messages
      if (cw20_ics20 && !isWithdrawal) {
        // for cw20_ics20 ibc transfers we need to get a signing client for the origin chain and execute a 'send' on the contract...
        const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
        let walletExtensionClient = window[walletExtension];
        if ("cosmostation" === walletExtension) {
          walletExtensionClient = window.cosmostation.providers.keplr;
        }
        const offlineSigner = await walletExtensionClient.getOfflineSignerAuto(cw20_ics20.chainId);
        if (cw20_ics20.chainId.includes("secret")) {
          const client = await new SecretNetworkClient({
            url: srcChain.restURL,
            chainId: cw20_ics20.chainId,
            wallet: offlineSigner,
            walletAddress: sendingAddrInput,
          });

          res = await client.tx.compute.executeContract(
            {
              sender: sendingAddrInput,
              contract_address: cw20_ics20.cw20Address,
              msg: {
                send: {
                  recipient: cw20_ics20.ics20Address,
                  recipient_code_hash: cw20_ics20.ics20CodeHash,
                  amount,
                  msg: Buffer.from(
                    JSON.stringify({
                      channel: cw20_ics20.ibcRecvChannel,
                      remote_address: destUserAddress,
                      timeout: 600,
                    })
                  ).toString("base64"),
                },
              },
            },
            { gasLimit: 100000 }
          );
          sendLink = srcChain.explorerURL + res.transactionHash;
          const logs = res.arrayLog;
          packet_sequence = logs.find(attribute => "packet_sequence" === attribute.key).value;
          packet_src_channel = logs.find(attribute => "packet_src_channel" === attribute.key).value;
          packet_dst_channel = logs.find(attribute => "packet_dst_channel" === attribute.key).value;
        } else {
          const client = await SigningCosmWasmClient.connectWithSigner(srcChain.rpcURL, offlineSigner);

          res = await client.execute(
            sendingAddrInput,
            cw20_ics20.cw20Address,
            {
              send: {
                contract: cw20_ics20.ics20Address,
                amount,
                msg: Buffer.from(
                  JSON.stringify({ channel: cw20_ics20.ibcRecvChannel, remote_address: destUserAddress })
                ).toString("base64"),
              },
            },
            { amount: [fee], gas: "200000" }
          );

          sendLink = srcChain.explorerURL + res.transactionHash;
          const event = res.events.find(event => "send_packet" === event.type);
          if (!event) throw Error(res.rawLog);
          packet_sequence = event.attributes.find(attribute => "packet_sequence" === attribute.key).value;
          packet_src_channel = event.attributes.find(attribute => "packet_src_channel" === attribute.key).value;
          packet_dst_channel = event.attributes.find(attribute => "packet_dst_channel" === attribute.key).value;
        }
      }

      if (cw20_ics20 && isWithdrawal) {
        // in here we are at a cw20-ics20 ibc withdrawal
        const msgTransfer: MsgTransfer = {
          sourcePort: "transfer",
          sourceChannel: cw20_ics20.ibcSendChannel, // we get the channel from the asset specific object
          token: {
            denom: denomToSend,
            amount,
          },
          sender: sendingAddrInput,
          receiver: destUserAddress,
          memo: "",
          timeoutTimestamp: Long.fromString(String((Date.now() + 600000) * 1000000), true),
        };

        const msg = {
          typeUrl: "/ibc.applications.transfer.v1.MsgTransfer",
          value: msgTransfer,
        };

        res = await sendingClient.signAndBroadcast(sendingAddrInput, [msg], {
          amount: [fee],
          gas: "200000",
        });

        sendLink = srcChain.explorerURL + res.transactionHash;
        const event = res.events.find(event => "send_packet" === event.type);
        if (!event) throw Error(res.rawLog);
        packet_sequence = event.attributes.find(attribute => "packet_sequence" === attribute.key).value;
        packet_src_channel = event.attributes.find(attribute => "packet_src_channel" === attribute.key).value;
        packet_dst_channel = event.attributes.find(attribute => "packet_dst_channel" === attribute.key).value;
      }

      // eslint-disable-next-line no-constant-condition
      while (1) {
        const timeoutResponse = await fetch(
          `${srcChain.restURL}/cosmos/tx/v1beta1/txs?events=timeout_packet.packet_src_channel='${packet_src_channel}'&events=timeout_packet.packet_sequence='${packet_sequence}'`
        );
        const timeoutResponseJson = await timeoutResponse.json();

        if (timeoutResponseJson.txs.length > 0) {
          isSuccessful = false;
          timeoutLink = srcChain.explorerURL + timeoutResponseJson.tx_responses[0].txhash;
          msg = "IBC transfer timedout!";
          break;
        }

        const recvResponse = await fetch(
          `${dstChain.restURL}/cosmos/tx/v1beta1/txs?events=recv_packet.packet_dst_channel='${packet_dst_channel}'&events=recv_packet.packet_sequence='${packet_sequence}'`
        );
        const res = await recvResponse.json();
        if (res.txs.length > 0) {
          isSuccessful = true;
          receiveLink = dstChain.explorerURL + res.tx_responses[0].txhash;
          msg = "IBC transfer successful!";
          break;
        }
        await delay(10000);
      }
    } catch (e) {
      console.log(e);
      isSuccessful = false;
      msg = "IBC transfer failed! Error: " + parseWalletErrorMessage(e.message, i18);
    }
    const toastType = isSuccessful ? "ibc-transfer-success" : "ibc-transfer-fail";

    dispatch(
      sendToast({
        type: toastType,
        info: { msg, sendLink, receiveLink, timeoutLink, toastID: "" + new Date().getTime() },
      })
    );
    if (assetBalancesToUpdate) {
      for (const toUpdateToken of assetBalancesToUpdate.tokens) {
        dispatch(
          updateTokenBalance({
            tokenAddress: toUpdateToken,
            userAddress: assetBalancesToUpdate.userAddress,
          })
        );
      }
      for (const toUpdateNative of assetBalancesToUpdate.natives) {
        dispatch(
          updateNativeBalance({
            denom: toUpdateNative,
            userAddress: assetBalancesToUpdate.userAddress,
          })
        );
      }
    }
  }
);

export const myAssetsUpdateAction = createAsyncThunk<void, { updatedData: string[] }, AsyncThunkConfig>(
  "wallet/myAssetsUpdate",
  async ({ updatedData }, { dispatch, getState }) => {
    const { walletInfo } = getState().wallet;
    const res = await updateUserAssets(
      { myAssets: updatedData.filter(data => !data.includes("dummyRow")) },
      {
        address: walletInfo.walletAddress,
        signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[
          process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress
        ],
      }
    );
    dispatch(updateMyAssets(res.data));
  }
);

export const executePoolAction = createAsyncThunk<
  void,
  {
    walletAddress: string;
    poolData: any;
    simulationValues: any;
    isDeposit: boolean;
    i18: any;
  },
  AsyncThunkConfig
>("wallet/executePoolAction", async ({ walletAddress, poolData, simulationValues, isDeposit, i18 }, { dispatch }) => {
  let isSuccessful: boolean;
  let msg: string;
  let txLink = null;
  let res = null;
  try {
    res = await executeContract(
      walletAddress,
      isDeposit ? poolData.address : poolData.lp_staking,
      simulationValues.tx,
      simulationValues.funds
    );
    txLink = `${process.env.REACT_APP_EXPLORER_URL}/${res.transactionHash}`;
    isSuccessful = true;
    msg = `${isDeposit ? "Deposit" : "Withdrawal"} on ${poolData.tokenPair} successful!`;

    try {
      const pools = {};
      const poolList = await getListOfPools();
      poolList.data?.forEach(pool => {
        pools[pool.address] = pool;
      });
      dispatch(updatePools(pools));
    } catch {
      console.log("Failed to update pool list");
    }
  } catch (e) {
    msg = `${isDeposit ? "Deposit" : "Withdrawal"} on ${poolData.tokenPair} failed! Error: ${parseWalletErrorMessage(
      e.message,
      i18
    )}`;
    isSuccessful = false;
  }
  const toastType = isSuccessful ? "tx-success" : "tx-fail";

  let updateSourceAssetBalance = true;

  // update asset balances of this pool
  for (const poolAsset of poolData.poolAssets) {
    if (poolAsset.info.token) {
      dispatch(
        updateTokenBalance({
          tokenAddress: poolAsset.info.token.contract_addr,
          userAddress: walletAddress,
        })
      );
    } else {
      if (poolAsset.info.native_token.denom === process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM)
        updateSourceAssetBalance = false;

      dispatch(
        updateNativeBalance({
          denom: poolAsset.info.native_token.denom,
          userAddress: walletAddress,
        })
      );
    }
  }

  // update native asset as it is used for gas fees
  if (updateSourceAssetBalance) {
    dispatch(
      updateNativeBalance({
        denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM,
        userAddress: walletAddress,
      })
    );
  }
  // update lp staking balance
  dispatch(updateLpStakingBalance({ userAddress: walletAddress, lpStakingAddress: poolData.lp_staking }));

  // update the pool values locally directly from blockchain
  dispatch(updatePoolInfo(poolData.address));

  // send notification
  dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));

  // send tx to BE, so it's updated for this user on the DB and trigger update User info
  const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
  if (permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress]) {
    await postTransactions(
      { txHash: res.transactionHash },
      {
        address: walletAddress,
        signature: permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress],
      }
    );
    const userInfo = await fetchUserInfo(
      permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress],
      walletAddress
    );
    dispatch(updateUser(userInfo));
  }
});

export const executeFarmAction = createAsyncThunk<
  void,
  {
    walletAddress: string;
    farmData: any;
    amount: string;
    isDeposit: boolean;
    i18: any;
    notClaimRewards?: boolean;
  },
  AsyncThunkConfig
>(
  "wallet/executeFarmAction",
  async ({ walletAddress, farmData, amount, isDeposit, i18, notClaimRewards = true }, { dispatch }) => {
    let isSuccessful: boolean;
    let msg: string;
    let txLink = null;
    let res = null;
    try {
      res = await executeContract(
        walletAddress,
        isDeposit ? farmData.config.inc_token : farmData.address,
        isDeposit
          ? {
              send: {
                contract: farmData.address,
                amount,
                msg: Buffer.from(
                  JSON.stringify({
                    deposit: {
                      not_claim_rewards: notClaimRewards,
                    },
                  })
                ).toString("base64"),
              },
            }
          : {
              withdrawal: {
                amount,
                not_claim_rewards: notClaimRewards,
              },
            },
        []
      );
      txLink = `${process.env.REACT_APP_EXPLORER_URL}/${res.transactionHash}`;
      isSuccessful = true;
      msg = `${isDeposit ? "Deposit" : "Withdrawal"} on farm successful!`;

      try {
        const pools = {};
        const poolList = await getListOfPools();
        poolList.data?.forEach(pool => {
          pools[pool.address] = pool;
        });
        dispatch(updatePools(pools));
      } catch {
        console.log("Failed to update pool list");
      }
    } catch (e) {
      msg = `${isDeposit ? "Deposit" : "Withdrawal"} on farm failed! Error: ${parseWalletErrorMessage(e.message, i18)}`;
      isSuccessful = false;
    }
    const toastType = isSuccessful ? "tx-success" : "tx-fail";

    // update asset balances of this pool
    dispatch(
      updateTokenBalance({
        tokenAddress: farmData.config.inc_token,
        userAddress: walletAddress,
      })
    );

    // update native asset as it is used for gas fees

    dispatch(
      updateNativeBalance({
        denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM,
        userAddress: walletAddress,
      })
    );

    // update lp staking balance
    dispatch(updateFarmLpStakingBalance({ userAddress: walletAddress, lpStakingAddress: farmData.address }));

    // send notification
    dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));

    // send tx to BE, so it's updated for this user on the DB and trigger update User info
    const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
    if (permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress]) {
      await postTransactions(
        { txHash: res.transactionHash },
        {
          address: walletAddress,
          signature: permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress],
        }
      );
      const userInfo = await fetchUserInfo(
        permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress],
        walletAddress
      );
      dispatch(updateUser(userInfo));
    }
  }
);

export const collectRewardsAction = createAsyncThunk<
  void,
  {
    walletAddress: string;
    poolsData: IPool[];
    farmsData: IFarm[];
    i18: any;
  },
  AsyncThunkConfig
>("wallet/collectRewardsAction", async ({ walletAddress, poolsData, farmsData, i18 }, { dispatch }) => {
  let isSuccessful: boolean;
  let msg: string;
  let txLink = null;
  let res = null;
  try {
    res = await window.client.executeMultiple(
      walletAddress,
      [
        ...poolsData.map(poolData => {
          return {
            contractAddress: poolData.lp_staking,
            msg: {
              withdrawal: {
                amount: "0",
              },
            },
          };
        }),
        ...farmsData.map(farmData => {
          return {
            contractAddress: farmData.address,
            msg: {
              withdrawal: {
                amount: "0",
              },
            },
          };
        }),
      ],
      "auto",
      undefined
    );
    txLink = `${process.env.REACT_APP_EXPLORER_URL}/${res.transactionHash}`;
    isSuccessful = true;
    msg = `Collect Rewards from pools/farms successful!`;
  } catch (e) {
    msg = `Collect Rewards from pools/farms failed! Error: ${parseWalletErrorMessage(e.message, i18)}`;
    isSuccessful = false;
  }
  const toastType = isSuccessful ? "tx-success" : "tx-fail";

  // update assets that should receive rewards
  let updateSourceAssetBalance = true;
  for (const poolData of poolsData) {
    for (const reward_source of poolData.lp_staking_info.reward_sources) {
      if (reward_source.reward_asset.token) {
        dispatch(
          updateTokenBalance({
            tokenAddress: reward_source.reward_asset.token.contract_addr,
            userAddress: walletAddress,
          })
        );
      } else {
        if (reward_source.reward_asset.native_token.denom === process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM)
          updateSourceAssetBalance = false;

        dispatch(
          updateNativeBalance({
            denom: reward_source.reward_asset.native_token.denom,
            userAddress: walletAddress,
          })
        );
      }
    }
  }

  for (const farmData of farmsData) {
    for (const reward_source of farmData.reward_sources) {
      if (reward_source.reward_asset.token) {
        dispatch(
          updateTokenBalance({
            tokenAddress: reward_source.reward_asset.token.contract_addr,
            userAddress: walletAddress,
          })
        );
      } else {
        if (reward_source.reward_asset.native_token.denom === process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM)
          updateSourceAssetBalance = false;

        dispatch(
          updateNativeBalance({
            denom: reward_source.reward_asset.native_token.denom,
            userAddress: walletAddress,
          })
        );
      }
    }
  }

  // update native asset as it is used for gas fees
  if (updateSourceAssetBalance) {
    dispatch(
      updateNativeBalance({
        denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM,
        userAddress: walletAddress,
      })
    );
  }

  // update lp staking balance
  for (const poolData of poolsData) {
    dispatch(updateLpStakingBalance({ userAddress: walletAddress, lpStakingAddress: poolData.lp_staking }));
  }
  for (const farmData of farmsData) {
    dispatch(updateFarmLpStakingBalance({ userAddress: walletAddress, lpStakingAddress: farmData.address }));
  }

  // send notification
  dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));

  // send tx to BE, so it's updated for this user on the DB and trigger update User info
  const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
  if (permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress]) {
    await postTransactions(
      { txHash: res.transactionHash },
      {
        address: walletAddress,
        signature: permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress],
      }
    );
    const userInfo = await fetchUserInfo(
      permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress],
      walletAddress
    );
    dispatch(updateUser(userInfo));
  }
});

export const executeGRVT8BurnAction = createAsyncThunk<
  void,
  {
    walletAddress: string;
    cashbackAddr: string;
    axvAddr: string;
    amount: string;
    i18: any;
  },
  AsyncThunkConfig
>("wallet/executeGRVT8BurnAction", async ({ walletAddress, cashbackAddr, axvAddr, amount, i18 }, { dispatch }) => {
  let isSuccessful: boolean;
  let msg: string;
  let txLink = null;
  let res = null;
  try {
    res = await executeContract(walletAddress, cashbackAddr, { burn: { amount } });
    txLink = `${process.env.REACT_APP_EXPLORER_URL}/${res.transactionHash}`;
    isSuccessful = true;
    msg = `GRVT8 burn successful!`;
  } catch (e) {
    msg = `GRVT8 burn failed! Error: ${parseWalletErrorMessage(e.message, i18)}`;
    isSuccessful = false;
  }
  const toastType = isSuccessful ? "tx-success" : "tx-fail";

  // update source denom due to gas fees payed
  dispatch(
    updateNativeBalance({
      denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM,
      userAddress: walletAddress,
    })
  );

  // update Cashback and AXV balances as these are the assets affected by this tx
  dispatch(
    updateTokenBalance({
      tokenAddress: cashbackAddr,
      userAddress: walletAddress,
    })
  );
  dispatch(
    updateTokenBalance({
      tokenAddress: axvAddr,
      userAddress: walletAddress,
    })
  );

  // send notification
  dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));

  // send tx to BE, so it's updated for this user on the DB and trigger update User info
  const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
  if (permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress]) {
    await postTransactions(
      { txHash: res.transactionHash },
      {
        address: walletAddress,
        signature: permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress],
      }
    );
    const userInfo = await fetchUserInfo(
      permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress],
      walletAddress
    );
    dispatch(updateUser(userInfo));
  }
});

export const executeClaimAirdrop = createAsyncThunk<
  void,
  {
    walletAddress: string;
    airdropAddr: string;
    axvAddr: string;
    exchangeAsset?: any;
    exchangeAssetAmount?: any;
    i18: any;
  },
  AsyncThunkConfig
>(
  "wallet/executeClaimAirdrop",
  async ({ walletAddress, airdropAddr, axvAddr, exchangeAsset, exchangeAssetAmount, i18 }, { dispatch }) => {
    let isSuccessful: boolean;
    let msg: string;
    let txLink = null;
    let res = null;
    try {
      if (!exchangeAsset) {
        res = await executeContract(walletAddress, airdropAddr, { wallet_claim: {} });
      } else if (exchangeAsset.native_token) {
        res = await executeContract(walletAddress, airdropAddr, { exchange_asset_claim: {} }, [
          { denom: exchangeAsset.native_token.denom, amount: exchangeAssetAmount },
        ]);
      }
      txLink = `${process.env.REACT_APP_EXPLORER_URL}/${res.transactionHash}`;
      isSuccessful = true;
      msg = `Airdrop claim successful!`;
    } catch (e) {
      msg = `Airdrop claim failed! Error: ${parseWalletErrorMessage(e.message, i18)}`;
      isSuccessful = false;
    }
    const toastType = isSuccessful ? "tx-success" : "tx-fail";

    // update source denom due to gas fees payed
    dispatch(
      updateNativeBalance({
        denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM,
        userAddress: walletAddress,
      })
    );

    // update AXV balances as these are the assets affected by this tx
    dispatch(
      updateTokenBalance({
        tokenAddress: axvAddr,
        userAddress: walletAddress,
      })
    );

    // send notification
    dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));
  }
);

export const executeTrade = createAsyncThunk<
  void,
  {
    walletAddress: string;
    simulationValues: any;
    pools: any;
    assets: any;
    contracts: any;
    fromAsset: any;
    toAsset: any;
    granterAddress?: string;
    walletSignerType?: string;
    i18: any;
  },
  AsyncThunkConfig
>(
  "wallet/executeTrade",
  async (
    {
      walletAddress,
      simulationValues,
      pools,
      assets,
      contracts,
      fromAsset,
      toAsset,
      granterAddress,
      walletSignerType,
      i18,
    },
    { dispatch }
  ) => {
    let isSuccessful: boolean;
    let msg: string;
    let txLink = null;
    let res = null;
    try {
      let toExecuteContract = null;
      let msgBody = null;

      if (0 === simulationValues.route?.length) {
        throw Error("No routes found...");
      } else if (1 === simulationValues.route?.length) {
        const route = simulationValues.route[0];
        if (route.hopTx.mint_staking_derivative) {
          toExecuteContract = route.hopTx.mint_staking_derivative.contract_addr;
          msgBody = {
            mint_derivative: {},
          };
        } else if (route.hopTx.standard_hop_info) {
          if (assets[route.x].isNative) {
            toExecuteContract = route.p;
            msgBody = {
              swap: {
                offer_asset: {
                  info: pools[route.p].poolAssets[route.hopTx.standard_hop_info.from_asset_index].info,
                  amount: route.offerAmount.toString(),
                },
                expected_return: BigNumber(simulationValues.minimumReceived)
                  .times(Math.pow(10, assets[route.y].decimals))
                  .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                  .toString(10),
              },
            };
          } else {
            toExecuteContract = route.x;
            msgBody = {
              send: {
                contract: route.p,
                amount: route.offerAmount.toString(),
                msg: Buffer.from(
                  JSON.stringify({
                    swap: {
                      expected_return: BigNumber(simulationValues.minimumReceived)
                        .times(Math.pow(10, assets[route.y].decimals))
                        .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                        .toString(10),
                    },
                  })
                ).toString("base64"),
              },
            };
          }
        } else if (route.hopTx.stable_hop_info) {
          if (assets[route.x].isNative) {
            toExecuteContract = route.p;
            msgBody = {
              swap: {
                swap_to_asset_index: route.hopTx.stable_hop_info.to_asset_index,
                expected_return: BigNumber(simulationValues.minimumReceived)
                  .times(Math.pow(10, assets[route.y].decimals))
                  .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                  .toString(10),
              },
            };
          } else {
            toExecuteContract = route.x;
            msgBody = {
              send: {
                contract: route.p,
                amount: route.offerAmount.toString(),
                msg: Buffer.from(
                  JSON.stringify({
                    swap: {
                      swap_to_asset_index: route.hopTx.stable_hop_info.to_asset_index,
                      expected_return: BigNumber(simulationValues.minimumReceived)
                        .times(Math.pow(10, assets[route.y].decimals))
                        .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                        .toString(10),
                    },
                  })
                ).toString("base64"),
              },
            };
          }
        } else if (route.hopTx.ratio_hop_info) {
          if (assets[route.x].isNative) {
            toExecuteContract = route.p;
            msgBody = {
              swap: {
                expected_return: BigNumber(simulationValues.minimumReceived)
                  .times(Math.pow(10, assets[route.y].decimals))
                  .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                  .toString(10),
              },
            };
          } else {
            toExecuteContract = route.x;
            msgBody = {
              send: {
                contract: route.p,
                amount: route.offerAmount.toString(),
                msg: Buffer.from(
                  JSON.stringify({
                    swap: {
                      expected_return: BigNumber(simulationValues.minimumReceived)
                        .times(Math.pow(10, assets[route.y].decimals))
                        .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                        .toString(10),
                    },
                  })
                ).toString("base64"),
              },
            };
          }
        } else {
          throw Error("Invalid route parameter");
        }
      } else {
        const router = contracts.router;

        if (assets[simulationValues.route[0].x].isNative) {
          toExecuteContract = router.address;
          msgBody = {
            receive: {
              sender: walletAddress,
              amount: simulationValues.route[0].offerAmount.toString(),
              msg: Buffer.from(
                JSON.stringify({
                  route_v2: {
                    hops: simulationValues.route.map(r => r.hopTx),
                    minimum_receive: BigNumber(simulationValues.minimumReceived)
                      .times(
                        Math.pow(10, assets[simulationValues.route[simulationValues.route?.length - 1].y].decimals)
                      )
                      .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                      .toString(10),
                  },
                })
              ).toString("base64"),
            },
          };
        } else {
          toExecuteContract = simulationValues.route[0].x;
          msgBody = {
            send: {
              contract: router.address,
              amount: simulationValues.route[0].offerAmount.toString(),
              msg: Buffer.from(
                JSON.stringify({
                  route_v2: {
                    hops: simulationValues.route.map(r => r.hopTx),
                    minimum_receive: BigNumber(simulationValues.minimumReceived)
                      .times(
                        Math.pow(10, assets[simulationValues.route[simulationValues.route?.length - 1].y].decimals)
                      )
                      .decimalPlaces(0, BigNumber.ROUND_FLOOR)
                      .toString(10),
                  },
                })
              ).toString("base64"),
            },
          };
        }
      }

      // here we need to verify if it will be a fee grant or not!
      if (!granterAddress) {
        res = await executeContract(walletAddress, toExecuteContract, msgBody, simulationValues.route[0].funds);
      } else {
        res = await executeFeeGrantTx(
          walletAddress,
          toExecuteContract,
          msgBody,
          granterAddress,
          walletSignerType,
          simulationValues.route[0].funds
        );
      }

      txLink = `${process.env.REACT_APP_EXPLORER_URL}/${res.transactionHash}`;
      isSuccessful = true;
      msg = `${
        1 === simulationValues.route?.length && simulationValues.route[0].hopTx.mint_staking_derivative
          ? "Mint"
          : "Trade"
      } from ${fromAsset.symbol} to ${toAsset.symbol} successful!`;
    } catch (e) {
      msg = `${
        1 === simulationValues.route?.length && simulationValues.route[0].hopTx.mint_staking_derivative
          ? "Mint"
          : "Trade"
      } from ${fromAsset.symbol} to ${toAsset.symbol} failed! Error: ${parseWalletErrorMessage(e.message, i18)}`;
      isSuccessful = false;
    }
    const toastType = isSuccessful ? "tx-success" : "tx-fail";

    let updateSourceAssetBalance = true;

    // update balance on both assets
    for (const asset of [fromAsset, toAsset]) {
      if (asset.isNative) {
        if (asset.denom === process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM) updateSourceAssetBalance = false;
        dispatch(updateNativeBalance({ userAddress: walletAddress, denom: asset.denom }));
      } else {
        dispatch(updateTokenBalance({ tokenAddress: asset.address, userAddress: walletAddress }));
      }
    }

    // update native asset as it is used for gas fees
    if (updateSourceAssetBalance) {
      dispatch(
        updateNativeBalance({
          denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM,
          userAddress: walletAddress,
        })
      );
    }

    // update the state of the pools where this swap was executed
    for (const route of simulationValues.route) {
      dispatch(updatePoolInfo(route.p));
    }

    // send notification
    dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));

    // send tx to BE, so it's updated for this user on the DB and trigger update User info
    const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
    if (permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress]) {
      await postTransactions(
        { txHash: res.transactionHash },
        {
          address: walletAddress,
          signature: permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress],
        }
      );
      const userInfo = await fetchUserInfo(
        permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress],
        walletAddress
      );
      dispatch(updateUser(userInfo));
    }
  }
);

export const increasePoolAllowance = createAsyncThunk<
  { allowance: string } | null,
  {
    walletAddress: string;
    poolData: any;
    tokenAddress: string;
    i18: any;
  },
  AsyncThunkConfig
>("wallet/increasePoolAllowance", async ({ walletAddress, poolData, tokenAddress, i18 }, { dispatch }) => {
  let isSuccessful: boolean;
  let msg: string;
  let txLink = null;
  try {
    const res = await executeContract(walletAddress, tokenAddress, {
      increase_allowance: {
        spender: poolData.address,
        amount: "340282366920938463463374607431768211454",
      },
    });
    txLink = `${process.env.REACT_APP_EXPLORER_URL}/${res.transactionHash}`;
    // if (res.rawLog) throw Error(res.rawLog);
    msg = "";
    isSuccessful = true;
  } catch (e) {
    msg = parseWalletErrorMessage(e.message, i18);
    isSuccessful = false;
  }
  const toastType = isSuccessful ? "tx-success" : "tx-fail";

  // update native asset as it is used for gas fees
  dispatch(
    updateNativeBalance({
      denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM,
      userAddress: walletAddress,
    })
  );

  // send notification
  dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));

  return isSuccessful ? { allowance: "340282366920938463463374607431768211454" } : { allowance: "0" };
});

export const fetchUserInfo = async (signature = "", walletAddress: string, noLogsFetch = false) => {
  try {
    // setIsUserLoading(true);
    const userSignature =
      signature ||
      loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress];
    if (!userSignature) return;
    const common = {
      address: walletAddress,
      signature: userSignature,
    };

    if (noLogsFetch) {
      const { data } = await getUser(common);
      return data;
    }

    const [{ data }, userLogs] = await Promise.all([getUser(common), getUserLogs(common)]);
    return { ...data, swapLogs: userLogs.data };
  } catch (err) {
    // snackbar.error(i18("header.userDataFailed", "Failed fetching details"));
  } finally {
    // setIsUserLoading(false);
  }
};

export const handleAuthentication = createAsyncThunk<void, void, AsyncThunkConfig>(
  "wallet/handleAuthentication",
  async (_, { dispatch, getState }) => {
    try {
      // setShouldOpenPermitModal(false);
      // TODO: there should be a user action that triggers this authentication
      // the user also should know WHY he is signing a permit
      // this should only trigger one time, and permit is stored on localstorage of the browser
      const walletInfo = getState().wallet.walletInfo;
      const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
      let sign: { signature: string };
      if (!permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress]) {
        const signature = await createUserPermit(walletInfo.walletAddress);
        const data = await postSignature(signature);

        if (data.ok) {
          persistState(WHITE_LIST_PERSISTED_STATE_KEYS.permits, {
            ...loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits),
            [process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress]: signature.signature,
          });
        }
        sign = signature;
      } else {
        sign = permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress];
      }

      const userInfo = await fetchUserInfo(sign.signature, walletInfo.walletAddress);
      dispatch(updateUser(userInfo));
      // snackbar.success(i18("header.permitSaved", "Success saving permit"));
    } catch (err) {
      // snackbar.error(i18("header.permitError", "Failed creating permit"));
      console.error(err);
    }
  }
);

export const updatePoolInfo = createAsyncThunk<void, string, AsyncThunkConfig>(
  "wallet/updatePoolInfo",
  async (poolAddress, { dispatch }) => {
    const info = await window?.client?.queryContractSmart(poolAddress, {
      pool: {},
    });

    dispatch(
      setPoolInfo({
        poolAddress: poolAddress,
        amounts: info.assets.map(asset => asset.amount),
        total_share: info.total_share,
      })
    );
  }
);

export const updateUserPriceOrder = createAsyncThunk<void, { priceWatchList: any[] }, AsyncThunkConfig>(
  "wallet/onPriceWatchDragEnd",
  async ({ priceWatchList }, { dispatch, getState }) => {
    const { walletAddress } = getState().wallet.walletInfo;
    const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
    if (permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress]) {
      try {
        const watchList = [...priceWatchList];

        await updateUserPriceWatch(
          { priceWatch: watchList },
          {
            address: walletAddress,
            signature: permits[process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletAddress],
          }
        );

        // snackbar.success(i18("dashboard.priceWatch.order.saved", "Success saving price order"));

        dispatch(updatePriceWatch(watchList));
      } catch (err) {
        console.error(err);
        // snackbar.error(i18("dashboard.priceWatch.not.order.saved", "Price order not saved. Please try later"));
      }
    }
  }
);
export const removePriceWatch = createAsyncThunk<void, string, AsyncThunkConfig>(
  "wallet/removePriceWatch",
  async (assetId, { dispatch, getState }) => {
    const { walletAddress } = getState().wallet.walletInfo;
    const { priceWatch } = getState().wallet.user;
    if (!priceWatch?.length) return;
    const priceWatchList = priceWatch.filter(item => item.assetId !== assetId);
    // console.log("priceWatch", { assetId, priceWatchList });
    let signature: StdSignature = null;
    if (!loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[walletAddress]) {
      try {
        signature = await createUserPermit(walletAddress);
        const data = await postSignature(signature);
        if (data.ok) {
          persistState(WHITE_LIST_PERSISTED_STATE_KEYS.permits, {
            ...loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits),
            [walletAddress]: signature,
          });

          // snackbar.success(i18("header.permitSaved", "Success saving permit"));
        }
      } catch (err) {
        // snackbar.error(i18("header.permitError", "Failed creating permit"));
        return;
      }
    }
    try {
      const watchList = [...priceWatchList];

      await updateUserPriceWatch(
        { priceWatch: watchList },
        {
          address: walletAddress,
          signature: signature || loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[walletAddress],
        }
      );

      // snackbar.success(i18("dashboard.priceWatch.order.saved", "Success saving price order"));

      dispatch(updatePriceWatch(watchList));
    } catch (err) {
      console.error(err);
      // snackbar.error(i18("dashboard.priceWatch.not.order.saved", "Price order not saved. Please try later"));
    }
  }
);

export const updateGRVT8Balance = createAsyncThunk<
  string,
  { grvt8Contract: IContract; walletAddress: string },
  AsyncThunkConfig
>("wallet/updateGRVT8Balance", async ({ grvt8Contract, walletAddress }, { dispatch }) => {
  const res = await rpcClientQuerySmartContractWrapper(grvt8Contract.address, {
    balance: { address: walletAddress },
  });

  const balance = BigNumber(res.balance).div(Math.pow(10, grvt8Contract.config.decimals)).toString(10);

  dispatch(setGRVT8Balance(balance));

  return balance;
});

export const executeFeeGrantTx = async (
  walletAddress: string,
  contractAddress: string,
  executeMsg,
  granterAddress: string,
  walletSignerType?: string,
  funds?: Coin[]
) => {
  const msgSend = MsgExecuteContract.fromPartial({
    msg: Buffer.from(JSON.stringify(executeMsg)),
    sender: walletAddress,
    contract: contractAddress,
    funds,
  });

  const { stargateSigner, queryService } = await getFeeGrantSigners();

  const estimatedGasFee = 3200000;
  const { estimatedFee } = await window.client.getEstimateTxFees(estimatedGasFee);
  let accNumber: number;

  const acc = await stargateSigner.getAccount(walletAddress);
  if (null === acc) {
    const accounts = await queryService.Accounts({
      pagination: {
        countTotal: true,
        limit: Long.fromNumber(1),
        key: new Uint8Array(),
        offset: Long.fromNumber(0),
        reverse: false,
      },
    });
    accNumber = accounts.pagination.total.toNumber() - 1;
  } else {
    accNumber = acc.accountNumber;
  }

  const msg: MsgExecuteContractEncodeObject = {
    typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
    value: msgSend,
  };

  let txRaw: TxRaw;
  const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
  let walletExtensionClient = window[walletExtension];

  if ("cosmostation" === walletExtension) {
    walletExtensionClient = window.cosmostation.providers.keplr;
  }

  walletExtensionClient.defaultOptions = {
    sign: {
      preferNoSetFee: true,
      preferNoSetMemo: true,
      disableBalanceCheck: true,
    },
  };
  if (!walletSignerType || "direct" === walletSignerType) {
    txRaw = await stargateSigner.sign(
      walletAddress,
      [msg],
      {
        ...estimatedFee,
        granter: granterAddress,
        payer: "",
      },
      "",
      {
        accountNumber: accNumber,
        sequence: 0,
        chainId: process.env.REACT_APP_ARCHWAY_NETWORK,
      }
    );
  } else {
    // AMINO hack sign fix
    const aminoOfflineSigner = walletExtensionClient.getOfflineSignerOnlyAmino(process.env.REACT_APP_ARCHWAY_NETWORK);
    txRaw = await signHackedAmino(
      stargateSigner,
      aminoOfflineSigner,
      walletAddress,
      [msg],
      {
        ...estimatedFee,
        granter: granterAddress,
        payer: "",
      },
      "",
      {
        accountNumber: accNumber,
        sequence: 0,
        chainId: process.env.REACT_APP_ARCHWAY_NETWORK,
      }
    );
  }

  walletExtensionClient.defaultOptions = {
    sign: {
      preferNoSetFee: true,
      preferNoSetMemo: true,
      disableBalanceCheck: false,
    },
  };

  return await postFeeGrantTx({
    txRaw: {
      bodyBytes: Array.from(txRaw.bodyBytes),
      authInfoBytes: Array.from(txRaw.authInfoBytes),
      signatures: [Array.from(txRaw.signatures[0])],
    },
  });
};

export const updateUserGlobalSettingsFields = createAsyncThunk<
  void,
  {
    noDisplayNicknames?: boolean;
    menuPosition?: string;
    dashboardMyAssetsActiveFilters?: { display: string; sorting: string };
    dashboardPortfolioActiveFilters?: { display: string; filter: string; xMerge: boolean };
    slippageTolerance?: {
      type: string;
      amount: string;
    };
    tradeIsolatedPoolTrading?: boolean;
  },
  AsyncThunkConfig
>("wallet/updateUserGlobalSettingsFields", async (toUpdateFields = {}, { dispatch, getState }) => {
  const { walletInfo, user } = getState().wallet;
  const globalSettings = { ...user.myGlobalSettings, ...toUpdateFields };
  try {
    await updateUserGlobalSettings(
      { myGlobalSettings: globalSettings },
      {
        address: walletInfo.walletAddress,
        signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[
          process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress
        ],
      }
    );
  } catch (e) {
    console.log(e);
  }
  dispatch(setMyGlobalSettings(globalSettings));
});

export const deleteMyDashboardGrid = createAsyncThunk<void, void, AsyncThunkConfig>(
  "wallet/deleteMyDashboardGrid",
  async (_, { dispatch, getState }) => {
    const { walletInfo } = getState().wallet;
    localStorage.removeItem(WHITE_LIST_PERSISTED_STATE_KEYS.dashboardGrid);
    try {
      updateUserDashboardGrid(
        { myDashboardGrid: null },
        {
          address: walletInfo.walletAddress,
          signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[
            process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress
          ],
        }
      );
    } catch (e) {
      console.log(e);
    }
    dispatch(setMyDashboardGrid(null));
  }
);

export const secretNetworkCreateViewingKey = async (secretViewingKey: {
  restURL: string;
  chainId: string;
  sendingAddr: string;
  contract_address: string;
}) => {
  const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
  let walletExtensionClient = window[walletExtension];
  if ("cosmostation" === walletExtension) {
    walletExtensionClient = window.cosmostation.providers.keplr;
  }
  await walletExtensionClient.suggestToken(secretViewingKey.chainId, secretViewingKey.contract_address);

  // const offlineSigner = await walletExtensionClient.getOfflineSignerAuto(secretViewingKey.chainId);
  // const client = await new SecretNetworkClient({
  //   url: secretViewingKey.restURL,
  //   chainId: secretViewingKey.chainId,
  //   wallet: offlineSigner,
  //   walletAddress: secretViewingKey.sendingAddr,
  // });
  // const random = new Uint8Array(32);
  // crypto.getRandomValues(random);
  // const key = Buffer.from(random).toString("hex");

  // const res = await client.tx.compute.executeContract(
  //   {
  //     sender: secretViewingKey.sendingAddr,
  //     contract_address: secretViewingKey.contract_address,
  //     msg: {
  //       set_viewing_key: {
  //         key,
  //       },
  //     },
  //   },
  //   { gasLimit: 150000 }
  // );

  // console.log(res);
};
