// import { Box, Plus, X } from "react-feather";
import { Calendar, Sliders, Trash, Trash2 } from "react-feather";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import clsx from "clsx";
// import DateTimePicker from "react-datetime-picker";
import { useAppSelector } from "@axvdex/state";
import { selectAssets, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
// import { IOutbidSale } from "@axvdex/utils/interfaces";
import useLanguage from "@axvdex/hooks/useLanguage";
import imgSanitize from "@axvdex/utils/imgSanitize";
import Button from "../common/Button";
import CustomModal from "../common/CustomModal";
// import CustomInputButton from "../form-element/CustomInputButton";
import CustomSelect from "../form-element/CustomSelect";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const SaleOperatorControls = ({ outbidSale }: any) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const { i18 } = useLanguage();
  const assets = useAppSelector(selectAssets);
  const walletInfo = useAppSelector(selectWalletInfo);

  const [incentiveType, setIncentiveType] = useState(null);
  const [incentiveAsset, setIncentiveAsset] = useState(null);
  const [tokenIncentive, setTokenIncentive] = useState(null);
  const [specificBidIncentive, setSpecificBidIncentive] = useState("");
  const [tokenIncentiveAmount, setTokenIncentiveAmount] = useState("");
  const [nftIncentiveImgURL, setNftIncentiveImgURL] = useState("");
  const [selectedSale, setSelectedSale] = useState(null);
  const [targetAsset, setTargetAsset] = useState(null);

  useEffect(() => {
    setActiveTab(null);
  }, [modalOpen]);

  useEffect(() => {
    setSelectedSale(getSaleRunning());
  }, [modalOpen]);

  const getSaleRunning = () => {
    for (const saleAddress of Object.keys(outbidSale.salesState)) {
      if (
        "WaitingToStart" === outbidSale.salesState[saleAddress].sale_state?.status ||
        "WhitelistBidding" === outbidSale.salesState[saleAddress].sale_state?.status ||
        "Bidding" === outbidSale.salesState[saleAddress].sale_state?.status ||
        "BonusBidding" === outbidSale.salesState[saleAddress].sale_state?.status
      ) {
        return saleAddress;
      }
    }

    return Object.keys(outbidSale.salesState).pop();
  };

  const isActionDisabled = (actionID: string) => {
    if ("createSale" === actionID) {
      return !!Object.values(outbidSale.salesState).find(
        (sale: any) =>
          "WaitingToStart" === sale.sale_state.status ||
          "WhitelistBidding" === sale.sale_state.status ||
          "Bidding" === sale.sale_state.status ||
          "BonusBidding" === sale.sale_state.status
      );
    }

    if ("addSaleIncentive" === actionID) {
      return 0 >= Object.keys(outbidSale.salesState).length;
    }

    if ("end" === actionID) {
      return !!(
        0 === Object.keys(outbidSale.salesState).length ||
        Object.values(outbidSale.salesState).find(
          (sale: any) =>
            "WaitingToStart" === sale.sale_state.status ||
            "WhitelistBidding" === sale.sale_state.status ||
            "Bidding" === sale.sale_state.status ||
            "BonusBidding" === sale.sale_state.status
        )
      );
    }

    if ("claim" === actionID) {
      if (outbidSale.config.sales_process_ended) return false;
    }

    return true;
  };

  return (
    <>
      <Button btnVariant="icon" style={{ margin: "1em" }} onClick={() => setModalOpen(true)}>
        <Sliders style={{ fontSize: "1em" }} />
      </Button>

      <CustomModal isOpen={modalOpen} onClose={() => setModalOpen(false)} modalSize="md">
        <section className="sectionModalHeader">
          <h2 className="h2">{"Sale Operator Panel"}</h2>
        </section>
        <div style={{ textAlign: "center" }}>
          <ul className="btnGroup navTabs">
            {[
              {
                label: "Create Sale",
                id: "createSale",
              },
              {
                label: "Add Sale Incentive",
                id: "addSaleIncentive",
              },
              //   {
              //     label: "Cancel Sale",
              //     id: "cancelSale",
              //   },
              {
                label: "End Process",
                id: "end",
              },
              {
                label: "Claim Funds",
                id: "claim",
              },
            ].map(item => (
              <li key={item.id}>
                <Button
                  text={item.label}
                  extraClassName={item.id === activeTab && "isActive"}
                  onClick={() => setActiveTab(item.id)}
                  disabled={isActionDisabled(item.id)}
                />
              </li>
            ))}
          </ul>

          {"createSale" === activeTab && <CreateSale outbidSale={outbidSale} />}

          {"addSaleIncentive" === activeTab && (
            <div style={{ marginTop: "1em" }}>
              {selectedSale && (
                <h2>
                  <Button
                    onClick={() => {
                      const prevAddress = Object.entries(outbidSale.salesState).find(
                        ([key, value]) => (value as any).config.id === outbidSale.salesState[selectedSale].config.id - 1
                      );
                      if (prevAddress) setSelectedSale(prevAddress[0]);
                    }}
                  >
                    {"<"}
                  </Button>
                  Round {outbidSale.salesState[selectedSale].config.id}
                  <Button
                    onClick={() => {
                      const nextAddress = Object.entries(outbidSale.salesState).find(
                        ([key, value]) => (value as any).config.id === outbidSale.salesState[selectedSale].config.id + 1
                      );
                      if (nextAddress) setSelectedSale(nextAddress[0]);
                    }}
                  >
                    {">"}
                  </Button>
                </h2>
              )}

              {("WaitingToStart" === outbidSale.salesState[selectedSale].sale_state?.status ||
                "WhitelistBidding" === outbidSale.salesState[selectedSale].sale_state?.status ||
                "Bidding" === outbidSale.salesState[selectedSale].sale_state?.status ||
                "BonusBidding" === outbidSale.salesState[selectedSale].sale_state?.status) && (
                <>
                  <div style={{ marginBottom: "1em" }}>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: ".5em" }}>
                      <input
                        type="radio"
                        id="incentive"
                        name="incentive"
                        style={{ marginRight: ".75em" }}
                        onClick={() => setIncentiveType("specific")}
                      />{" "}
                      Specific Bid Incentive
                      <input
                        style={{ maxWidth: "10em", marginLeft: "1em" }}
                        value={specificBidIncentive}
                        onChange={e => setSpecificBidIncentive(e.target.value)}
                      />
                    </div>
                    <div
                      className="withGradientBorderBottom"
                      style={{ display: "flex", alignItems: "center", paddingBottom: "1em" }}
                    >
                      <input
                        type="radio"
                        id="incentive"
                        name="incentive"
                        style={{ marginRight: ".75em" }}
                        onClick={() => setIncentiveType("soldOut")}
                      />{" "}
                      Sold Out Incentive
                    </div>

                    <div style={{ marginTop: "1em" }}>
                      <div style={{ display: "flex", alignItems: "center", marginBottom: ".5em" }}>
                        <input
                          type="radio"
                          id="incentive_asset"
                          name="incentive_asset"
                          style={{ marginRight: ".75em" }}
                          onClick={() => setIncentiveAsset("token")}
                        />{" "}
                        Token
                        <div style={{ marginLeft: ".75em", display: "flex", width: "100%" }}>
                          <CustomSelect
                            labelText={""}
                            hiddenLabel={true}
                            items={Object.values(assets)
                              .map((asset: any) => {
                                return {
                                  value: asset.id,
                                  label: asset.symbol,
                                  id: asset.id,
                                  optionPrefix: <img src={imgSanitize(asset.symbol)} alt={""} />,
                                };
                              })
                              .filter(asset => asset)}
                            placeholder={i18("Select token", "trade.form.fromToken.select.placeholder")}
                            value={tokenIncentive}
                            onChange={val => setTokenIncentive(val)}
                            name={""}
                          />
                          <input
                            style={{ maxWidth: "10em", marginLeft: "1em" }}
                            onChange={e => setTokenIncentiveAmount(e.target.value)}
                            value={tokenIncentiveAmount}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center", marginBottom: ".5em" }}>
                        <input
                          type="radio"
                          id="incentive_asset"
                          name="incentive_asset"
                          style={{ marginRight: ".75em" }}
                          onClick={() => setIncentiveAsset("nft")}
                        />{" "}
                        NFT
                        <input
                          style={{ maxWidth: "100%", marginLeft: "1em" }}
                          onChange={e => setNftIncentiveImgURL(e.target.value)}
                          value={nftIncentiveImgURL}
                        />
                      </div>
                    </div>
                  </div>

                  <Button
                    btnColor="gradient"
                    onClick={async () => {
                      if ("specific" === incentiveType && "" !== specificBidIncentive) {
                        if ("token" === incentiveAsset && tokenIncentive != null && "" !== tokenIncentiveAmount) {
                          const asset = assets[tokenIncentive.id];
                          if (asset.isNative) {
                            await window.client.execute(
                              walletInfo.walletAddress,
                              selectedSale,
                              {
                                add_specific_bids_incentive: {
                                  bids_incentive: [
                                    {
                                      bid_id: parseInt(specificBidIncentive),
                                      incentives: [
                                        {
                                          asset: {
                                            info: {
                                              native_token: {
                                                denom: asset.denom,
                                              },
                                            },
                                            amount: BigNumber(tokenIncentiveAmount)
                                              .times(Math.pow(10, asset.decimals))
                                              .toString(10),
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              },

                              "auto",
                              undefined,
                              [
                                {
                                  denom: asset.denom,
                                  amount: BigNumber(tokenIncentiveAmount)
                                    .times(Math.pow(10, asset.decimals))
                                    .toString(10),
                                },
                              ]
                            );
                          } else {
                            await window.client.execute(
                              walletInfo.walletAddress,
                              asset.address,
                              {
                                send: {
                                  contract: selectedSale,
                                  amount: BigNumber(tokenIncentiveAmount)
                                    .times(Math.pow(10, asset.decimals))
                                    .toString(10),
                                  msg: Buffer.from(
                                    JSON.stringify({
                                      add_specific_bids_incentive: {
                                        bids_incentive: [
                                          {
                                            bid_id: parseInt(specificBidIncentive),
                                            incentives: [
                                              {
                                                asset: {
                                                  info: {
                                                    token: {
                                                      contract_addr: asset.address,
                                                    },
                                                  },
                                                  amount: BigNumber(tokenIncentiveAmount)
                                                    .times(Math.pow(10, asset.decimals))
                                                    .toString(10),
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    })
                                  ).toString("base64"),
                                },
                              },
                              "auto"
                            );
                          }
                        }
                        if ("nft" === incentiveAsset && "" !== nftIncentiveImgURL) {
                          const tokenID =
                            outbidSale.salesState[selectedSale].config.id + "-" + parseInt(specificBidIncentive);

                          await window.client.executeMultiple(
                            walletInfo.walletAddress,
                            [
                              {
                                contractAddress: outbidSale.config.cw721_incentives,
                                msg: {
                                  mint: {
                                    token_id: tokenID,
                                    owner: walletInfo.walletAddress,
                                    extension: {
                                      image: nftIncentiveImgURL,
                                      description:
                                        "Incentive for bidder " +
                                        parseInt(specificBidIncentive) +
                                        " on sale " +
                                        selectedSale,
                                      name: "Incentive " + selectedSale + "-" + parseInt(specificBidIncentive),
                                    },
                                  },
                                },
                              },
                              {
                                contractAddress: outbidSale.config.cw721_incentives,
                                msg: {
                                  send_nft: {
                                    contract: selectedSale,
                                    token_id: tokenID,
                                    msg: Buffer.from(
                                      JSON.stringify({
                                        add_specific_bids_incentive: {
                                          bid_incentive: {
                                            bid_id: parseInt(specificBidIncentive),
                                            incentives: [
                                              {
                                                nft: {
                                                  contract_addr: outbidSale.config.cw721_incentives,
                                                  token_id: tokenID,
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      })
                                    ).toString("base64"),
                                  },
                                },
                              },
                            ],
                            "auto"
                          );
                        }
                      }
                      if ("soldOut" === incentiveType) {
                        if ("token" === incentiveAsset && tokenIncentive != null && "" !== tokenIncentiveAmount) {
                          const asset = assets[tokenIncentive.id];
                          if (asset.isNative) {
                            await window.client.execute(
                              walletInfo.walletAddress,
                              selectedSale,
                              {
                                add_sold_out_bidder_incentive: {},
                              },
                              "auto",
                              undefined,
                              [
                                {
                                  denom: asset.denom,
                                  amount: BigNumber(tokenIncentiveAmount)
                                    .times(Math.pow(10, asset.decimals))
                                    .toString(10),
                                },
                              ]
                            );
                          } else {
                            await window.client.execute(
                              walletInfo.walletAddress,
                              asset.address,
                              {
                                send: {
                                  contract: selectedSale,
                                  amount: BigNumber(tokenIncentiveAmount)
                                    .times(Math.pow(10, asset.decimals))
                                    .toString(10),
                                  msg: Buffer.from(
                                    JSON.stringify({
                                      add_sold_out_bidder_incentive: {},
                                    })
                                  ).toString("base64"),
                                },
                              },
                              "auto"
                            );
                          }
                        }
                        if ("nft" === incentiveAsset && "" !== nftIncentiveImgURL) {
                          const tokenID =
                            outbidSale.salesState[selectedSale].config.id + "-" + parseInt(specificBidIncentive);
                          await window.client.executeMultiple(
                            walletInfo.walletAddress,
                            [
                              {
                                contractAddress: outbidSale.config.cw721_incentives,
                                msg: {
                                  mint: {
                                    token_id: tokenID,
                                    owner: walletInfo.walletAddress,
                                    extension: {
                                      image: nftIncentiveImgURL,
                                      description:
                                        "Incentive for bidder " +
                                        parseInt(specificBidIncentive) +
                                        " on sale " +
                                        selectedSale,
                                      name: "Incentive " + selectedSale + "-" + parseInt(specificBidIncentive),
                                    },
                                  },
                                },
                              },
                              {
                                contractAddress: outbidSale.config.cw721_incentives,
                                msg: {
                                  send_nft: {
                                    contract: selectedSale,
                                    token_id: tokenID,
                                    msg: Buffer.from(
                                      JSON.stringify({
                                        add_sold_out_bidder_incentive: {},
                                      })
                                    ).toString("base64"),
                                  },
                                },
                              },
                            ],
                            "auto"
                          );
                        }
                      }
                    }}
                  >
                    Add
                  </Button>
                  <div className="withGradientBorderBottom" style={{ marginTop: "1em" }}></div>
                </>
              )}

              <div style={{ marginTop: "1em" }}>
                <h2 style={{ marginBottom: "0em" }}>Existing Incentives</h2>
                <small>Current Bid: {outbidSale.salesState[selectedSale].sale_state?.state.bids_count}</small>
                <table className={clsx("table")}>
                  <thead>
                    <tr>
                      <th style={{ width: "5em", textAlign: "center" }}>-</th>
                      <th style={{ textAlign: "center" }}>Bid</th>
                      <th style={{ textAlign: "center" }}>Assets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {outbidSale.salesState[selectedSale].incentives.specific_bids_incentives?.map(
                      (incentive: any, index: number) => (
                        <tr key={index}>
                          <td style={{ width: "5em" }}>
                            <Button
                              icon={<Trash />}
                              onClick={async () => {
                                await window.client.execute(
                                  walletInfo.walletAddress,
                                  selectedSale,
                                  {
                                    remove_incentive: {
                                      specific_bids_incentive_id: incentive.bid_id,
                                    },
                                  },
                                  "auto"
                                );
                              }}
                            ></Button>
                          </td>
                          <td>{incentive.bid_id}</td>
                          <td>
                            {incentive.incentives.map((incentive: any, index: number) => (
                              <div key={index} className="inlineFlexbox" style={{ marginRight: "1em" }}>
                                {incentive.asset && (
                                  <>
                                    <span>
                                      {BigNumber(incentive.asset.amount)
                                        .div(
                                          Math.pow(
                                            10,
                                            assets[
                                              incentive.asset.info.native_token?.denom ||
                                                incentive.asset.info.token?.contract_addr
                                            ].decimals
                                          )
                                        )
                                        .toString(10)}
                                    </span>
                                    <img
                                      style={{ width: "2em" }}
                                      src={imgSanitize(
                                        assets[
                                          incentive.asset.info.native_token?.denom ||
                                            incentive.asset.info.token?.contract_addr
                                        ].symbol
                                      )}
                                      alt={""}
                                    />
                                  </>
                                )}
                                {incentive.nft && (
                                  <>
                                    <span>NFT</span>
                                  </>
                                )}
                              </div>
                            ))}
                          </td>
                        </tr>
                      )
                    )}
                    {outbidSale.salesState[selectedSale].incentives?.sold_out_bid_incentives &&
                      outbidSale.salesState[selectedSale].incentives.sold_out_bid_incentives.length > 0 && (
                        <tr>
                          <td style={{ width: "5em" }}>
                            <Button
                              icon={<Trash />}
                              onClick={async () => {
                                await window.client.execute(
                                  walletInfo.walletAddress,
                                  selectedSale,
                                  {
                                    remove_incentive: {
                                      sold_out_bidder_incentive: true,
                                    },
                                  },
                                  "auto"
                                );
                              }}
                            ></Button>
                          </td>
                          <td>End of Sale</td>
                          <td>
                            {outbidSale.salesState[selectedSale].incentives.sold_out_bid_incentives.map(
                              (incentive: any, index: number) => (
                                <div key={index} className="inlineFlexbox" style={{ marginRight: "1em" }}>
                                  {incentive.asset && (
                                    <>
                                      <span>
                                        {BigNumber(incentive.asset.amount)
                                          .div(
                                            Math.pow(
                                              10,
                                              assets[
                                                incentive.asset.info.native_token?.denom ||
                                                  incentive.asset.info.token?.contract_addr
                                              ].decimals
                                            )
                                          )
                                          .toString(10)}
                                      </span>
                                      <img
                                        style={{ width: "2em" }}
                                        src={imgSanitize(
                                          assets[
                                            incentive.asset.info.native_token?.denom ||
                                              incentive.asset.info.token?.contract_addr
                                          ].symbol
                                        )}
                                        alt={""}
                                      />
                                    </>
                                  )}
                                  {incentive.nft && (
                                    <>
                                      <span>NFT</span>
                                    </>
                                  )}
                                </div>
                              )
                            )}
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        {"end" === activeTab && (
          <div style={{ marginTop: "1em" }}>
            {outbidSale.config.target_asset_info ? (
              <Button
                btnColor="gradient"
                onClick={async () => {
                  let total_target_allocation = BigNumber(0);
                  for (const saleAddr of Object.keys(outbidSale.salesState)) {
                    const res = await rpcClientQuerySmartContractWrapper(saleAddr, {
                      allocation: {},
                    });

                    const ticket_to_target_ratio = BigNumber(res.target_allocation.target_asset_allocation_amount).div(
                      res.target_allocation.target_ticket_amount
                    );

                    const asset_allocation = BigNumber(res.target_allocation.target_asset_allocation_amount).plus(
                      BigNumber(res.added_bonus_allocation_trigger ?? 0).times(ticket_to_target_ratio)
                    );

                    total_target_allocation = total_target_allocation.plus(asset_allocation);
                  }

                  await window.client.execute(
                    walletInfo.walletAddress,
                    outbidSale.config.target_asset_info.token.contract_addr,
                    {
                      send: {
                        contract: outbidSale.address,
                        amount: total_target_allocation.decimalPlaces(0).toString(10),
                        msg: Buffer.from(
                          JSON.stringify({
                            end_sales_process: {},
                          })
                        ).toString("base64"),
                      },
                    },
                    "auto"
                  );
                }}
              >
                End Process
              </Button>
            ) : walletInfo.walletAddress === outbidSale.config.owner ? (
              <>
                <CustomSelect
                  labelText={""}
                  hiddenLabel={true}
                  items={Object.values(assets)
                    .map((asset: any) => {
                      return {
                        value: asset.id,
                        label: asset.symbol,
                        id: asset.id,
                        optionPrefix: <img src={imgSanitize(asset.symbol)} alt={""} />,
                      };
                    })
                    .filter(asset => asset)}
                  placeholder={i18("Select token", "trade.form.fromToken.select.placeholder")}
                  value={targetAsset}
                  onChange={val => setTargetAsset(val)}
                  name={""}
                />
                {targetAsset && (
                  <Button
                    btnColor="gradient"
                    onClick={async () => {
                      let total_target_allocation = BigNumber(0);
                      for (const saleAddr of Object.keys(outbidSale.salesState)) {
                        const res = await rpcClientQuerySmartContractWrapper(saleAddr, {
                          allocation: {},
                        });

                        const ticket_to_target_ratio = BigNumber(
                          res.target_allocation.target_asset_allocation_amount
                        ).div(res.target_allocation.target_ticket_amount);

                        const asset_allocation = BigNumber(res.target_allocation.target_asset_allocation_amount).plus(
                          BigNumber(res.added_bonus_allocation_trigger ?? 0).times(ticket_to_target_ratio)
                        );

                        total_target_allocation = total_target_allocation.plus(asset_allocation);
                      }

                      await window.client.execute(
                        walletInfo.walletAddress,
                        outbidSale.address,
                        {
                          update_config: {
                            target_asset_info: {
                              token: {
                                contract_addr: assets[targetAsset.id].address,
                              },
                            },
                          },
                        },
                        "auto"
                      );
                    }}
                  >
                    Config Target Info
                  </Button>
                )}
              </>
            ) : (
              "No target asset set. Please contact the owner to set a target asset."
            )}
          </div>
        )}
        {"claim" === activeTab && (
          <Button
            title={"Collect funds"}
            btnColor="gradient"
            onClick={async () => {
              await window.client.executeMultiple(
                walletInfo.walletAddress,
                Object.keys(outbidSale.salesState).map(saleAddr => {
                  return {
                    contractAddress: saleAddr,
                    msg: {
                      collect_bid_assets: {},
                    },
                  };
                }),
                "auto"
              );
            }}
          >
            Claim{" "}
          </Button>
        )}
      </CustomModal>
    </>
  );
};

export default SaleOperatorControls;

const CreateSale = ({ outbidSale }: any) => {
  const { i18 } = useLanguage();
  const [createSaleData, setCreateSaleData] = useState(null);
  const assets = useAppSelector(selectAssets);
  const walletInfo = useAppSelector(selectWalletInfo);

  const [date, setDate] = useState<any>(null);
  const [withDate, setWithDate] = useState<boolean>(false);
  const [bidAssets, setBidAssets] = useState<{ id: string; ratio: string }[]>([]);
  const [bidAssetSelection, setBidAssetSelection] = useState<any>(null);
  const [bidAssetSelectionRatio, setBidAssetSelectionRatio] = useState<string>("");

  const [targetAllocation, setTargetAllocation] = useState<any>({
    target_asset_allocation_amount: "1000000",
    decimals: "6",
    target_ticket_amount: "50000",
  });

  const [saleSettings, setSaleSettings] = useState<any>({
    min_ticket: BigNumber(outbidSale.config.default_sale_settings.min_ticket).div(Math.pow(10, 6)).toString(10),
    max_ticket: BigNumber(outbidSale.config.default_sale_settings.max_ticket).div(Math.pow(10, 6)).toString(10),
    duration: outbidSale.config.default_sale_settings.duration,
    whitelist_duration: outbidSale.config.default_sale_settings.whitelist_duration,
    bonus_duration: outbidSale.config.default_sale_settings.bonus_duration,
    bonus_min_ticket_increase: BigNumber(outbidSale.config.default_sale_settings.bonus_min_ticket_increase)
      .div(Math.pow(10, 6))
      .toString(10),
    bonus_sell_out_elegible_token_per: outbidSale.config.default_sale_settings.bonus_sell_out_elegible_token_per,
    bonus_sell_out_bonus_token_per: outbidSale.config.default_sale_settings.bonus_sell_out_bonus_token_per,
    wallet_max_ticket_cap: BigNumber(outbidSale.config.default_sale_settings.wallet_max_ticket_cap)
      .div(Math.pow(10, 6))
      .toString(10),
  });

  return (
    <div style={{ marginTop: "1em" }}>
      <div className="withGradientBorderBottom"></div>
      <div
        className="inlineFlexbox withGradientBorderBottom"
        style={{ width: "100%", marginTop: "1em", paddingBottom: "1em" }}
      >
        Load from prev round:
        <CustomSelect
          labelText={""}
          hiddenLabel={true}
          items={Object.values(outbidSale.salesState)
            .map((saleState: any) => {
              return {
                value: saleState.config.id,
                label: saleState.config.id,
                id: saleState.config.id,
              };
            })
            .filter(asset => asset)}
          placeholder={i18("Select Round", "trade.form.fromToken.select.placeholder")}
          value={null}
          onChange={val => {
            const saleState = Object.values(outbidSale.salesState).find(
              (saleState: any) => saleState.config.id === (val as any).id
            ) as any;
            setBidAssets(
              saleState.config.bid_assets.map((asset: any) => ({
                id: asset.info.token?.contract_addr || asset.info.native_token?.denom,
                ratio: asset.asset_to_ticket_ratio,
              }))
            );
            setTargetAllocation({
              target_asset_allocation_amount: BigNumber(
                saleState.config.target_allocation.target_asset_allocation_amount
              )
                .div(Math.pow(10, saleState.config.target_allocation.target_asset_decimals))
                .toString(10),
              decimals: saleState.config.target_allocation.target_asset_decimals,
              target_ticket_amount: BigNumber(saleState.config.target_allocation.target_ticket_amount)
                .div(Math.pow(10, 6))
                .toString(10),
            });
            setSaleSettings({
              min_ticket: "100", // hardcode this because prev rounds if they go to bonus this field is changed at contract level
              max_ticket: BigNumber(saleState.config.sale_settings.max_ticket).div(Math.pow(10, 6)).toString(10),
              duration: saleState.config.sale_settings.duration,
              whitelist_duration: saleState.config.sale_settings.whitelist_duration,
              bonus_duration: saleState.config.sale_settings.bonus_duration,
              bonus_min_ticket_increase: BigNumber(saleState.config.sale_settings.bonus_min_ticket_increase)
                .div(Math.pow(10, 6))
                .toString(10),
              bonus_sell_out_elegible_token_per: saleState.config.sale_settings.bonus_sell_out_elegible_token_per,
              bonus_sell_out_bonus_token_per: saleState.config.sale_settings.bonus_sell_out_bonus_token_per,
              wallet_max_ticket_cap: BigNumber(saleState.config.sale_settings.wallet_max_ticket_cap)
                .div(Math.pow(10, 6))
                .toString(10),
            });
            setDate(null);
          }}
          name={""}
        />{" "}
      </div>
      <div
        className="inlineFlexbox withGradientBorderBottom"
        style={{ marginTop: "1em", marginBottom: "1em", width: "100%", paddingBottom: "1em" }}
      >
        <span>Start Date: </span>
        {!withDate && "Now"}
        {!withDate && (
          <Button
            onClick={() => {
              setWithDate(true);
              const date = new Date();
              date.setHours(date.getHours() + 1);
              date.setMinutes(0);
              date.setSeconds(0);
              date.setMilliseconds(0);
              setDate(date.toISOString());
            }}
          >
            <Calendar />
          </Button>
        )}
        {withDate && (
          <>
            <input value={date} onChange={e => setDate(e.target.value)} style={{ maxWidth: "20em" }} />
            <small>
              in {Math.round(((new Date(date).getTime() - new Date().getTime()) / 1000 / 60 / 60) * 100) / 100} hours
            </small>
          </>
        )}

        {/* <Button
          onClick={() =>
            setCreateSaleData(() => {
              const newSaleData = { ...createSaleData };
              newSaleData.create_sale.start_timestamp = Math.round(new Date(date).getTime() / 1000);
              return newSaleData;
            })
          }
        >
          Update On JSON
        </Button> */}
      </div>
      <div
        className="withGradientBorderBottom"
        style={{ marginBottom: "1em", width: "100%", paddingBottom: "1em", textAlign: "left" }}
      >
        <div className="inlineFlexbox" style={{ width: "100%" }}>
          <span>Selected Bid Assets: </span>

          <div style={{ marginLeft: ".75em", display: "flex" }}>
            <input
              type="number"
              style={{ maxWidth: "5em", marginRight: "1em" }}
              onChange={e => setBidAssetSelectionRatio(e.target.value)}
              value={bidAssetSelectionRatio}
            />
            <CustomSelect
              labelText={""}
              hiddenLabel={true}
              items={Object.values(assets)
                .map((asset: any) => {
                  return {
                    value: asset.id,
                    label: asset.symbol,
                    id: asset.id,
                    optionPrefix: <img src={imgSanitize(asset.symbol)} alt={""} />,
                  };
                })
                .filter(asset => asset)}
              placeholder={i18("Select token", "trade.form.fromToken.select.placeholder")}
              value={bidAssetSelection}
              onChange={val => setBidAssetSelection(val)}
              name={""}
            />
            <Button
              disabled={!bidAssetSelection || !bidAssetSelectionRatio || "" === bidAssetSelectionRatio}
              onClick={() => {
                setBidAssets([
                  ...bidAssets,
                  {
                    id: bidAssetSelection.id,
                    ratio: bidAssetSelectionRatio,
                  },
                ]);
                setBidAssetSelection(null);
                setBidAssetSelectionRatio("");
              }}
            >
              Add
            </Button>
          </div>
        </div>
        {bidAssets.length > 0 && (
          <div style={{ marginTop: "1em" }}>
            {bidAssets.map((asset, index) => {
              return (
                <div
                  key={index}
                  className="inlineFlexbox"
                  style={{ marginRight: "1em", border: "solid", borderColor: "grey", padding: ".5em" }}
                >
                  <Trash2
                    style={{ color: "red", width: "1em" }}
                    onClick={() => {
                      const newBidAssets = [...bidAssets];
                      newBidAssets.splice(index, 1);
                      setBidAssets(newBidAssets);
                    }}
                  />
                  <span>Ratio: {asset.ratio}</span>
                  <img style={{ width: "2em" }} src={imgSanitize(assets[asset.id].symbol)} alt={""} />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div
        className="withGradientBorderBottom"
        style={{ marginTop: "1em", marginBottom: "1em", width: "100%", paddingBottom: "1em", textAlign: "left" }}
      >
        <div className="inlineFlexbox" style={{ width: "100%" }}>
          <span>Target Asset allocation amount: </span>
          <input
            style={{ width: "10em" }}
            value={targetAllocation.target_asset_allocation_amount}
            onChange={e => {
              setTargetAllocation({
                ...targetAllocation,
                target_asset_allocation_amount: e.target.value,
              });
            }}
          />
          <span>Decimals: </span>
          <input
            style={{ width: "5em" }}
            value={targetAllocation.decimals}
            onChange={e => {
              setTargetAllocation({
                ...targetAllocation,
                decimals: e.target.value,
              });
            }}
          />
        </div>
        <div className="inlineFlexbox" style={{ width: "100%" }}>
          <span>Target ticket amount: </span>
          $
          <input
            style={{ width: "10em" }}
            value={targetAllocation.target_ticket_amount}
            onChange={e => {
              setTargetAllocation({
                ...targetAllocation,
                target_ticket_amount: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div
        className="withGradientBorderBottom"
        style={{ marginTop: "1em", marginBottom: "1em", width: "100%", paddingBottom: "1em", textAlign: "left" }}
      >
        <div className="inlineFlexbox" style={{ width: "100%" }}>
          <span>Minimum per bid: </span>
          $
          <input
            style={{ width: "10em" }}
            value={saleSettings.min_ticket}
            onChange={e => {
              setSaleSettings({
                ...saleSettings,
                min_ticket: e.target.value,
              });
            }}
          />
          <span>Maximum per bid: </span>
          $
          <input
            style={{ width: "10em" }}
            value={saleSettings.max_ticket}
            onChange={e => {
              setSaleSettings({
                ...saleSettings,
                max_ticket: e.target.value,
              });
            }}
          />
        </div>
        <div className="inlineFlexbox" style={{ width: "100%" }}>
          <span>Durations (in seconds): </span>
          <span>Whitelist: </span>
          <input
            style={{ width: "5em" }}
            value={saleSettings.whitelist_duration}
            onChange={e => {
              setSaleSettings({
                ...saleSettings,
                whitelist_duration: e.target.value,
              });
            }}
          />
          <span>Bonus: </span>
          <input
            style={{ width: "5em" }}
            value={saleSettings.bonus_duration}
            onChange={e => {
              setSaleSettings({
                ...saleSettings,
                bonus_duration: e.target.value,
              });
            }}
          />
          <span>Total: </span>
          <input
            style={{ width: "5em" }}
            value={saleSettings.duration}
            onChange={e => {
              setSaleSettings({
                ...saleSettings,
                duration: e.target.value,
              });
            }}
          />
        </div>
        <div className="inlineFlexbox" style={{ width: "100%" }}>
          <span>Bonus ticket increase: </span>
          <input
            style={{ width: "5em" }}
            value={saleSettings.bonus_min_ticket_increase}
            onChange={e => {
              setSaleSettings({
                ...saleSettings,
                bonus_min_ticket_increase: e.target.value,
              });
            }}
          />
        </div>
        <div className="inlineFlexbox" style={{ width: "100%" }}>
          <span>Bonus sell out elegible per: </span>
          <input
            style={{ width: "5em" }}
            value={saleSettings.bonus_sell_out_elegible_token_per}
            onChange={e => {
              setSaleSettings({
                ...saleSettings,
                bonus_sell_out_elegible_token_per: e.target.value,
              });
            }}
          />
          <span>Bonus sell out bonus per: </span>
          <input
            style={{ width: "5em" }}
            value={saleSettings.bonus_sell_out_bonus_token_per}
            onChange={e => {
              setSaleSettings({
                ...saleSettings,
                bonus_sell_out_bonus_token_per: e.target.value,
              });
            }}
          />
        </div>
        <div className="inlineFlexbox" style={{ width: "100%" }}>
          <span>Wallet max ticket cap: </span>
          $
          <input
            style={{ width: "5em" }}
            value={saleSettings.wallet_max_ticket_cap}
            onChange={e => {
              setSaleSettings({
                ...saleSettings,
                wallet_max_ticket_cap: e.target.value,
              });
            }}
          />
        </div>
      </div>

      <div
        className="withGradientBorderBottom"
        style={{ marginTop: "1em", marginBottom: "1em", width: "100%", paddingBottom: "1em", textAlign: "left" }}
      >
        <div className="inlineFlexbox" style={{ width: "100%", placeContent: "center" }}>
          <Button
            btnColor="gradient"
            onClick={async () => {
              const newSaleData = {
                create_sale: {
                  bid_assets: bidAssets.map(asset => {
                    return {
                      info: assets[asset.id].address
                        ? {
                            token: {
                              contract_addr: assets[asset.id].address,
                            },
                          }
                        : {
                            native_token: {
                              denom: assets[asset.id].denom,
                            },
                          },
                      decimals: assets[asset.id].decimals,
                      asset_to_ticket_ratio: asset.ratio,
                    };
                  }),
                  start_timestamp: date ? Math.round(new Date(date).getTime() / 1000) : null,
                  target_allocation: {
                    target_asset_allocation_amount: BigNumber(targetAllocation.target_asset_allocation_amount)
                      .times(Math.pow(10, targetAllocation.decimals))
                      .toString(10),
                    target_asset_decimals: parseInt(targetAllocation.decimals),
                    target_ticket_amount: BigNumber(targetAllocation.target_ticket_amount)
                      .times(Math.pow(10, 6))
                      .toString(10),
                  },
                  sale_settings: {
                    min_ticket: BigNumber(saleSettings.min_ticket).times(Math.pow(10, 6)).toString(10),
                    max_ticket: BigNumber(saleSettings.max_ticket).times(Math.pow(10, 6)).toString(10),
                    duration: parseInt(saleSettings.duration),
                    whitelist_duration: parseInt(saleSettings.whitelist_duration),
                    bonus_duration: parseInt(saleSettings.bonus_duration),
                    bonus_min_ticket_increase: BigNumber(saleSettings.bonus_min_ticket_increase)
                      .times(Math.pow(10, 6))
                      .toString(10),
                    bonus_sell_out_elegible_token_per: saleSettings.bonus_sell_out_elegible_token_per,
                    bonus_sell_out_bonus_token_per: saleSettings.bonus_sell_out_bonus_token_per,
                    wallet_max_ticket_cap: BigNumber(saleSettings.wallet_max_ticket_cap)
                      .times(Math.pow(10, 6))
                      .toString(10),
                  },
                },
              };
              setCreateSaleData(newSaleData);
            }}
          >
            Update JSON
          </Button>
        </div>
      </div>

      {createSaleData && (
        <>
          <ReactJson
            style={{ width: "100%", textAlign: "left" }}
            src={createSaleData}
            theme={"threezerotwofour"}
            onEdit={a => setCreateSaleData(a.updated_src)}
          />
          <br />
          <Button
            btnColor="gradient"
            onClick={async () => {
              await window.client.execute(walletInfo.walletAddress, outbidSale.address, createSaleData, "auto");
            }}
          >
            Create
          </Button>
        </>
      )}
    </div>
  );
};
