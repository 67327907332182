import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { ArrowRight, Shield } from "react-feather";
// import { Edit } from "react-feather";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import { IOutbidSale } from "@axvdex/utils/interfaces";
import { getOutbidSaleBidTable } from "@axvdex/api/outbid";
import { selectContracts, selectNicknames, selectUser, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { updateNicknamesFromBlockchain } from "@axvdex/state/outbid/saleThunks";
import { noNicknamesCheck, sanitizeNickname } from "@axvdex/utils/nicknamesSanitization";
import useLanguage from "@axvdex/hooks/useLanguage";
import CustomModal from "../common/CustomModal";
// import { ReactComponent as IcnShield } from "../../assets/icons/icn-shield.svg";
import { ReactComponent as IcnSword } from "../../assets/icons/icn-sword.svg";
import CustomLoader from "../common/CustomLoader";
import styles from "../../styles/Outbid.module.scss";
// import Button from "../common/Button";
import SaleNicknameTargetModal from "./SaleNicknameTargetModal";
import { responsiveBreakpoints } from "@axvdex/constants";
import { useMediaQuery } from "usehooks-ts";

interface SaleLeaderboardModalProps {
  saleLeaderboardModalOpen: { open: boolean; outbid: IOutbidSale | null; selectedSale: string };
  setSaleLeaderboardModalOpen: React.Dispatch<
    React.SetStateAction<{ open: boolean; outbid: IOutbidSale | null; selectedSale: string }>
  >;
}

function SaleLeaderboardModal({ saleLeaderboardModalOpen, setSaleLeaderboardModalOpen }: SaleLeaderboardModalProps) {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const isMobileSmBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const contracts = useAppSelector(selectContracts);
  const user = useAppSelector(selectUser);
  const nicknames = useAppSelector(selectNicknames);
  const walletInfo = useAppSelector(selectWalletInfo);

  const outbid = saleLeaderboardModalOpen.outbid;
  const outbidSale = saleLeaderboardModalOpen.outbid?.salesState[saleLeaderboardModalOpen.selectedSale];

  const title =
    i18("Leaderboard", "sale.leaderboard") +
    (outbid ? ": " + outbid.project : "") +
    (outbidSale ? " | " + i18("Sale", "sale.leaderboard.title") + " " + outbidSale.config.id : "");

  useEffect(() => {
    if (saleLeaderboardModalOpen.open) getTable();
  }, [saleLeaderboardModalOpen.open]);

  useEffect(() => {
    if (!saleLeaderboardModalOpen.open) setTableData([]);
  }, [saleLeaderboardModalOpen.open]);

  const getTable = async () => {
    setLoading(true);
    const table = await getOutbidSaleBidTable(outbid.address, saleLeaderboardModalOpen.selectedSale);
    if (contracts.nicknames) {
      await dispatch(
        updateNicknamesFromBlockchain({
          addresses: table.data.map((row: any) => row.address),
        })
      );
    }

    setTableData(table.data);
    setLoading(false);
  };

  return (
    <>
      <CustomModal
        extraClassName="outbidGuide"
        isOpen={saleLeaderboardModalOpen.open}
        onClose={() => setSaleLeaderboardModalOpen({ open: false, selectedSale: null, outbid: null })}
        modalSize="lg"
      >
        <section className="sectionModalHeader">
          <h2 className="h2">{title}</h2>
        </section>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CustomLoader size="sm" />
          </div>
        ) : (
          <div
            className={clsx(styles.sales, "sales")}
            style={{ display: "flex", flexFlow: isMobileSmBreakpoint ? "column" : undefined }}
          >
            <div
              className={"custom-scrollbar"}
              style={{
                fontSize: "1em",
                width: isMobileSmBreakpoint ? "100%" : "50%",
                // borderRight: "1px solid white",
                paddingRight: "1em",
                maxHeight: "30em",
                overflowY: "scroll",
              }}
            >
              <table className={clsx("table")}>
                <thead>
                  <tr>
                    <th style={{ width: "5em", textAlign: "center" }}>Place</th>
                    {/* <th style={{ textAlign: "center" }}>Address</th> */}
                    <th style={{ textAlign: "center" }}>Nickname</th>
                    {/* <th style={{ textAlign: "center" }}>Ticket Count</th> */}
                    <th style={{ textAlign: "center" }}>Ticket Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row: any, index: number) => (
                    <tr
                      key={row.address}
                      style={{
                        textAlign: "center",
                        fontWeight: walletInfo.walletAddress === row.address ? "bold" : undefined,
                        backgroundImage:
                          walletInfo.walletAddress === row.address
                            ? "linear-gradient(92.22deg, #0BF6CC 0.58%, #073DC8 100%)"
                            : undefined,
                        backgroundClip: walletInfo.walletAddress === row.address ? "text" : undefined,
                        WebkitBackgroundClip: walletInfo.walletAddress === row.address ? "text" : undefined,
                        color: walletInfo.walletAddress === row.address ? "transparent" : undefined,
                      }}
                    >
                      <td>{index + 1}</td>
                      {/* <td>...{row.address.slice(-6)}</td> */}
                      <td>
                        {nicknames[row.address] ? (
                          <div className="inlineFlexbox">
                            {!isMobileSmBreakpoint && nicknames[row.address].is_protected && (
                              <Shield style={{ color: "white", width: "1.5em" }} />
                            )}
                            {!isMobileSmBreakpoint && nicknames[row.address].changed_other_at && (
                              <IcnSword style={{ width: "1.4em" }} />
                            )}
                            {noNicknamesCheck(nicknames[row.address].nickname, user)}
                            {!nicknames[row.address].is_protected && row.address !== walletInfo.walletAddress && (
                              <SaleNicknameTargetModal targetAddress={row.address} />
                            )}
                          </div>
                        ) : (
                          <div className="inlineFlexbox">
                            {"------"}
                            {row.address !== walletInfo.walletAddress && (
                              <SaleNicknameTargetModal targetAddress={row.address} />
                            )}
                          </div>
                        )}
                      </td>
                      {/* <td>{parseFloat(row.bidCount).toLocaleString("en-US")}</td> */}
                      <td>{parseFloat(row.ticketTotalAmount).toLocaleString("en-US")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className={"custom-scrollbar"}
              style={{
                fontSize: "1em",
                width: isMobileSmBreakpoint ? "100%" : "50%",
                marginTop: isMobileSmBreakpoint ? "2em" : "0",
                marginLeft: "1em",
                maxHeight: "30em",
                overflowY: "scroll",
              }}
            >
              <h2 style={{ textAlign: "center" }}>Activity</h2>
              {contracts.nicknames &&
                contracts.nicknames.extraFields.activity_logs.map((log: any, i) => {
                  let text = "";
                  let icon = null;
                  let color = "rgba(255,255,255,0.1)";
                  if (log.UpdateNickname) {
                    if (log.UpdateNickname.from_address === log.UpdateNickname.to_address) {
                      text = `${
                        "" !== log.UpdateNickname.from_nickname
                          ? sanitizeNickname(log.UpdateNickname.from_nickname, user)
                          : "------"
                      } self-identifies as ${sanitizeNickname(log.UpdateNickname.to_nickname, user)}`;
                      icon = <ArrowRight style={{ width: "1.5em", marginRight: "1em" }} />;
                    } else {
                      text = `${
                        "" !== log.UpdateNickname.from_address_nickname
                          ? sanitizeNickname(log.UpdateNickname.from_address_nickname, user)
                          : "------"
                      } has dubbed ${
                        "" !== log.UpdateNickname.from_nickname
                          ? sanitizeNickname(log.UpdateNickname.from_nickname, user)
                          : "------"
                      } as ${sanitizeNickname(log.UpdateNickname.to_nickname, user)}`;
                      icon = <IcnSword style={{ width: "1.4em", marginRight: "1em" }} />;
                      color = "#952f03";
                    }
                  }
                  if (log.GetProtection) {
                    text = `${sanitizeNickname(log.GetProtection.address_nickname, user)}  is Unconquerable!`;
                    icon = <Shield style={{ width: "1.5em", marginRight: "1em" }} />;
                    color = "#073DC8";
                  }

                  return (
                    <p
                      key={i}
                      //className={i === 0 ? "topBidderActivityRow" : ""}
                      style={{
                        alignItems: "center",
                        display: "flex",
                        backgroundColor: color,
                        borderRadius: "1em",
                        padding: ".75em",
                        marginTop: ".5em",
                        marginBottom: ".5em",
                        marginRight: ".5em",
                      }}
                    >
                      {icon}
                      {text}
                    </p>
                  );
                })}
            </div>
          </div>
        )}
      </CustomModal>
    </>
  );
}

export default SaleLeaderboardModal;
