import { useEffect } from "react";
import clsx from "clsx";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import { useGlobalModalContext } from "@axvdex/providers/GlobalModalProvider";
import { IWalletInfo } from "@axvdex/state/wallet/initialState";
import useLanguage from "@axvdex/hooks/useLanguage";
import styles from "../styles/NavSidebar.module.scss";
import ButtonMenu from "./common/ButtonMenu";
import Logo from "./common/Logo";
import UserWallet from "./user/UserWallet";
import NavItems from "./NavItems";

import NavDebugItems from "./NavDebugItems";

interface DashboardNavProps {
  isOpen: boolean;
  onClick: () => void;
  walletInfo: IWalletInfo;
}

function NavSidebar({ isOpen, onClick, walletInfo }: DashboardNavProps) {
  const { i18 } = useLanguage();
  const { showModal } = useGlobalModalContext();
  const { modal } = useGlobalModalContext();

  useEffect(() => {
    if (!loadState(WHITE_LIST_PERSISTED_STATE_KEYS.agreedCompliance) && !modal.isOpen)
      showModal("complianceModal", {
        connectWalletAfter: false,
      });
  }, [modal]);

  return (
    <nav className={clsx(styles.navSidebar, "navSidebar", isOpen && "isOpen")}>
      <div className="navSidebarWrapper">
        <div className="navSidebarHeader">
          <ButtonMenu
            extraClassName={clsx(isOpen && "isOpen")}
            onClick={onClick}
            title={isOpen ? i18("Shrink menu", "navSidebar.menu.shrink") : i18("Expand Menu", "navSidebar.menu.expand")}
          />

          <Logo unlink={true} hideMonogram={!isOpen} />
        </div>

        <UserWallet hideInfo={!isOpen} walletInfo={walletInfo} onClick={onClick} />
        <div className="navSidebarMenu">
          <NavItems hideText={!isOpen} />
        </div>

        {process.env.REACT_APP_DEBUG_MODE && <NavDebugItems hideText={!isOpen} />}
      </div>
    </nav>
  );
}

export default NavSidebar;
