import { useEffect, useState } from "react";
import clsx from "clsx";
import { Bell, ExternalLink, Trash } from "react-feather";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import useLanguage from "@axvdex/hooks/useLanguage";
import { selectUser, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import Button from "../common/Button";
import CustomModal from "../common/CustomModal";
import styles from "../../styles/MyAssetsManageTokensModal.module.scss";
import DashboardPaginationTable from "../DashboardPaginationTable";
import { updateUserNotifications, updateUserToastMessages } from "@axvdex/api/user";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import { handleAuthentication } from "@axvdex/state/wallet/walletThunks";

function NotificationsModal({ hideInfo }: any) {
  const { i18 } = useLanguage();
  const user = useAppSelector(selectUser);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div style={{ width: "100%", position: "relative", display: "inline-block" }}>
        <Button
          disabled={!user.notifications || 0 === user.notifications.length}
          extraClassName="btnNotifications"
          btnSize="sm"
          btnColor="gradient"
          isFullWidth={true}
          title={i18("Notifications", "userWallet.notifications.title")}
          icon={
            <span className={"bellIcon"}>
              <Bell />
            </span>
          }
          onClick={() => setIsOpen(true)}
        >
          {!hideInfo && <>{i18("Notifications", "userWallet.notifications.title")}</>}
          {user.notifications && 0 < user.notifications.length && (
            <span className="notificationBadge">{user.notifications.length}</span>
          )}
        </Button>
      </div>

      <CustomModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        extraClassName={clsx(styles.permitAuthenticationModal, "permitAuthenticationModal")}
      >
        <section className="sectionModalHeader">
          <h2 className="h2">{i18("Notifications", "userWallet.notifications.title")}</h2>
        </section>
        <NotificationsSection setIsOpen={setIsOpen} />
      </CustomModal>
    </>
  );
}

const NotificationsSection = ({ setIsOpen }: any) => {
  const { i18 } = useLanguage();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const walletInfo = useAppSelector(selectWalletInfo);
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const [isLoading, setIsLoading] = useState(false);
  const removeNotificationHandler = async (indexToRemove: number) => {
    setIsLoading(true);

    const updatedNotifications = [...user.notifications];
    updatedNotifications.splice(indexToRemove, 1);

    await updateUserNotifications(
      { notifications: updatedNotifications },
      {
        address: walletInfo.walletAddress,
        signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[
          process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress
        ],
      }
    );

    await dispatch(handleAuthentication());
    setIsLoading(false);
  };

  useEffect(() => {
    setData(
      user.notifications
        .map((notification: any, index) => {
          let msg = null;
          // Parse notification msg depending on type
          if (notification.type === "saleVestingComplete") {
            msg = i18(
              `${notification.extraDetails.projectName} Sale ${notification.extraDetails.saleID} vesting complete`,
              "userWallet.notifications.saleVestingCompleteMessage",
              {
                projectName: notification.extraDetails.projectName,
                saleID: notification.extraDetails.saleID,
              }
            );
          }

          return {
            timestamp: notification.timestamp,
            msg,
            extraDetails: notification.extraDetails,
            originalPosition: index,
            isLoading,
            removeNotificationHandler,
          };
        })
        .sort((a, b) => b.timestamp - a.timestamp)
    );
  }, [user, isLoading]);

  const messagesColumns: ColumnDef<{
    timestamp: number;
    msg: string;
    extraDetails: any;
    originalPosition: number;
    isLoading: boolean;
    removeNotificationHandler: any;
  }>[] = [
    {
      accessorKey: "date",
      header: () => <span>{i18("Date", "userWallet.notifications.date")}</span>,
      cell: ({ row }) => (
        <time>
          {new Date(row.original.timestamp).toLocaleDateString()}
          <br />
          {new Date(row.original.timestamp).toLocaleTimeString()}
        </time>
      ),
    },
    {
      id: "message",
      header: () => <span>{i18("Message", "userWallet.notifications.message")}</span>,
      cell: ({ row }) => <span>{row.original.msg}</span>,
    },
    {
      id: "actions",
      header: () => <span>{i18("Actions", "userWallet.notifications.actions")}</span>,
      cell: ({ row }) => (
        <div style={{ display: "flex" }}>
          {row.original.extraDetails ? (
            <Button
              style={{ padding: "1em", marginRight: "1em" }}
              disabled={row.original.isLoading}
              extraClassName="btnLink"
              btnSize="sm"
              btnColor="dark-medium"
              title={i18("Link", "userWallet.notifications.link")}
              icon={
                <span className={"linkIcon"}>
                  <ExternalLink />
                </span>
              }
              onClick={() => {
                navigate(
                  "/outbid/" + row.original.extraDetails.publicSaleLabel + "/" + row.original.extraDetails.saleID
                );
                setIsOpen(false);
              }}
            ></Button>
          ) : null}
          <Button
            style={{ padding: "1em" }}
            extraClassName="btnLink"
            disabled={row.original.isLoading}
            btnSize="sm"
            btnColor="dark-medium"
            title={i18("Delete", "userWallet.notifications.delete")}
            icon={
              <span className={"linkIcon"}>
                <Trash />
              </span>
            }
            onClick={async () => {
              await row.original.removeNotificationHandler(row.original.originalPosition);
            }}
          ></Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      {!user.notifications || 0 === user.notifications.length ? (
        <>
          <span>{i18("No notifications...", "dashboard.history.tabs.messages.placeholder")}</span>
        </>
      ) : (
        <DashboardPaginationTable
          tableId="myNotificationsTable"
          customData={data}
          customColumns={messagesColumns}
          pagination={pagination}
          setPagination={setPagination}
        />
      )}
    </div>
  );
};

export default NotificationsModal;
