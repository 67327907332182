import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "state";

const selectSelf = (state: RootState) => state;
export const selectWallet = createSelector(selectSelf, state => state.wallet);
export const selectIsWalletLoading = createSelector(selectSelf, state => state.wallet.isLoadingWallet);
export const selectWalletInfo = createSelector(selectWallet, wallet => wallet.walletInfo);
export const selectAssets = createSelector(selectWallet, wallet => wallet.assets);
export const selectPools = createSelector(selectWallet, wallet => wallet.pools);
export const selectPoolsLpBalance = createSelector(selectWallet, wallet => wallet.poolsLpBalance);
export const selectFarmsLpBalance = createSelector(selectWallet, wallet => wallet.farmsLpBalance);
export const selectContracts = createSelector(selectWallet, wallet => wallet.contracts);
export const selectFarms = createSelector(selectWallet, wallet => wallet.farms);
export const selectChains = createSelector(selectWallet, wallet => wallet.chains);
export const selectOutbid = createSelector(selectWallet, wallet => wallet.outbid);
export const selectOutbidServerTime = createSelector(selectWallet, wallet => wallet.outbidServerTime);
export const selectStatusCounters = createSelector(selectWallet, wallet => wallet.statusCounters);
export const selectUser = createSelector(selectWallet, wallet => wallet.user);
export const selectUserLoading = createSelector(selectWallet, wallet => wallet.updatingUser);
export const selectAssetBalances = createSelector(selectWallet, wallet => wallet.assetBalances);
export const selectAssetUnmints = createSelector(selectUser, user => user.myAssetUnmints);
export const selectMySoftLockups = createSelector(selectUser, user => user.mySoftLockups);
export const selectUserAssets = createSelector(selectUser, user => user?.myAssets || []);
export const selectUserPriceWatch = createSelector(selectUser, user => user?.priceWatch || []);
export const selectSwapLogs = createSelector(selectUser, user => user?.swapLogs || []);
export const selectFavouritePools = createSelector(selectUser, user => user?.favoritePools || []);
export const selectFavouriteSwaps = createSelector(selectUser, user => user?.favoriteSwaps || []);
export const selectFavoritePools = createSelector(selectUser, user => user?.favoritePools || []);
export const selectFavoriteSwaps = createSelector(selectUser, user => user?.favoriteSwaps || []);
export const selectFavoriteFarms = createSelector(selectUser, user => user?.favoriteFarms || []);
export const selectPoolsWithBalance = createSelector(selectUser, user => user?.poolsWithBalance || []);
export const selectFarmsWithBalance = createSelector(selectUser, user => user?.farmsWithBalance || []);
export const selectToast = createSelector(selectWallet, state => state.toast || null);
export const selectFavouriteAssets = createSelector(selectUser, user => user.favoriteAssets || []);
export const selectGRVT8Balance = createSelector(selectUser, user => user.grvt8Balance || null);
export const selectSalesBids = createSelector(selectUser, user => user.salesBids || null);
export const selectMyDashboardGrid = createSelector(selectUser, user => user.myDashboardGrid || null);
export const selectMyToastMessages = createSelector(selectUser, user => user.myToastMessages || null);
export const selectGlobalSettings = createSelector(selectUser, user => user.myGlobalSettings || null);
export const selectVestingLockedAmount = createSelector(selectUser, user => user.vestingLockedAmount || null);
export const selectNicknames = createSelector(selectWallet, wallet => wallet.nicknames);
