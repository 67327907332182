import { useEffect, useState } from "react";
import clsx from "clsx";
import { SigningStargateClient } from "@cosmjs/stargate";
import BigNumber from "bignumber.js";
import { findIndex } from "lodash";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import {
  selectAssetBalances,
  selectAssets,
  selectChains,
  selectStatusCounters,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import { executeIbcTransfer, getIbcWithdrawalAccAddress, updateNativeBalance } from "@axvdex/state/wallet/walletThunks";
import { formatBalance, fromHumanAmountToTokenBalance } from "@axvdex/utils/formatNumber";
import imgSanitize from "@axvdex/utils/imgSanitize";
import estimatedFees, { GAS_ESTIMATION_STATIC_IBC_WITHDRAWAL } from "@axvdex/utils/estimatedFees";

import useLanguage from "@axvdex/hooks/useLanguage";
import { sendToast } from "@axvdex/state/wallet/walletSlice";
import CustomSelect, { ListItemsProps } from "../form-element/CustomSelect";
import Button from "../common/Button";
import CustomNumericInput from "../form-element/CustomNumericInput";
import CustomInput from "../form-element/CustomInput";
import UserBalance from "../user/UserBalance";
import { TableAssetsProps } from "../DashboardMyAssets";
import CustomLoader from "../common/CustomLoader";
import { IBCAssetToChain, IBCChainToAsset } from "./MyAssetsManageTokenDepositForm";

interface MyAssetsManageTokenWithdrawFormProps {
  asset: TableAssetsProps;
  onCloseModal: () => void;
}

function MyAssetsManageTokenWithdrawForm({ asset, onCloseModal }: MyAssetsManageTokenWithdrawFormProps) {
  const { i18 } = useLanguage();
  const walletInfo = useAppSelector(selectWalletInfo);
  const chains = useAppSelector(selectChains);
  const dispatch = useAppDispatch();
  const assets = useAppSelector(selectAssets);
  const assetBalances = useAppSelector(selectAssetBalances);
  const statusCounters = useAppSelector(selectStatusCounters);

  const [chainOptions, setChainOptions] = useState([]);
  const [tokenOptions, setTokenOptions] = useState([]);
  const [selectedToken, setSelectedToken] = useState<ListItemsProps>(
    asset
      ? {
          label: asset.symbol,
          value: asset.id,
          optionPrefix: (
            <img
              src={imgSanitize(asset.symbol)}
              alt={i18(`${asset.symbol} - Token`, "managetokens.select.options.prefix.alt", {
                symbol: asset.symbol,
              })}
            />
          ),
        }
      : null
  );

  let destChain;

  if (selectedToken && assets[selectedToken.value].isSourceDenom) {
    destChain = chains.find(
      chain => !chain.currencies.find(currency => currency.coinMinimalDenom === selectedToken.value)
    );
  } else {
    destChain = IBCAssetToChain(asset, chains);
  }

  useEffect(() => {
    setChainOptions(
      chains
        .filter(chain => chain.displayName !== "Archway")
        .map(chain => {
          return { label: chain.displayName, value: chain.chainId };
        })
        .sort(function (a, b) {
          return ("" + a.label).localeCompare(b.label);
        })
    );
  }, [chains]);

  useEffect(() => {
    setTokenOptions(
      Object.entries(assets)
        .filter(asset => asset[1].isNative)
        .map(asset => {
          return {
            label: asset[1].symbol,
            value: asset[1].id,
            optionPrefix: (
              <img
                src={imgSanitize(asset[1].symbol)}
                alt={i18(`${asset[1].symbol} - Token`, "managetokens.select.options.prefix.alt", {
                  symbol: asset[1].symbol,
                })}
              />
            ),
          };
        })
        .sort(function (a, b) {
          if ("ARCH" === a.label.toUpperCase()) return 0;
          return ("" + a.label).localeCompare(b.label);
        })
    );
  }, [assets]);

  const handleTokenChange = (selectedOption: ListItemsProps) => {
    setSelectedToken(selectedOption);
    const asset = assets[selectedOption.value];
    let chain = null;
    if (asset && !asset.isSourceDenom) {
      chain = IBCAssetToChain(asset, chains);
    }
    setSelectedChain(chain ? { label: chain.displayName, value: chain.chainId } : selectedChain);
  };

  const [selectedChain, setSelectedChain] = useState<ListItemsProps>(
    destChain ? { label: destChain.displayName, value: destChain.chainId } : null
  );

  const handleChainChange = (selectedOption: ListItemsProps) => {
    setSelectedChain(selectedOption);
    const chain = chains.find(chain => chain.chainId === selectedOption.value);
    const asset = IBCChainToAsset(assets, chain);
    setSelectedToken(
      asset
        ? {
            label: asset[1].symbol,
            value: asset[0],
            optionPrefix: (
              <img
                src={imgSanitize(asset[1].symbol)}
                alt={i18(`${asset[1].symbol} - Token`, "managetokens.select.options.prefix.alt", {
                  symbol: asset[1].symbol,
                })}
              />
            ),
          }
        : null
    );
  };

  const [recvAddrInput, setRecvAddrInput] = useState("");
  const [sendingClient, setSendingClient] = useState<SigningStargateClient | null>(null);
  const [isLoadingFillAddr, setIsLoadingFillAddr] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoadingFillAddr) {
      setAmountInput("");
      setRecvAddrInput("");
      handleFillRecvAddress();
    }
  }, [selectedChain, selectedToken]);

  const handleFillRecvAddress = async () => {
    setIsLoadingWithdrawalTrigger(true);
    if (selectedChain) {
      setIsLoadingFillAddr(true);
      const chain = chains.find(chain => chain.chainId === selectedChain.value);
      if (!assetBalances[selectedToken.value]) {
        dispatch(updateNativeBalance({ userAddress: walletInfo.walletAddress, denom: selectedToken.value }));
      }

      const { acc_address, client } = await getIbcWithdrawalAccAddress(chain.chainId);
      setRecvAddrInput(acc_address);
      setSendingClient(client);
      setIsLoadingFillAddr(false);
    }
    setIsLoadingWithdrawalTrigger(false);
  };

  const [amountInput, setAmountInput] = useState("");
  const [isLoadingWithdrawalTrigger, setIsLoadingWithdrawalTrigger] = useState(false);

  const handleWithdrawalTrigger = async () => {
    if (!sendingClient && amountInput === "0") return;
    setIsLoadingWithdrawalTrigger(true);
    try {
      // on withdrawal the src chain is always archway
      const srcChain = chains.find(chain => chain.chainId === process.env.REACT_APP_ARCHWAY_NETWORK);
      const dstChain = chains.find(chain => chain.chainId === selectedChain.value);
      const denom = selectedToken.value;

      await dispatch(
        executeIbcTransfer({
          sendingClient,
          sendingAddrInput: walletInfo.walletAddress,
          destUserAddress: recvAddrInput,
          denomToSend: denom,
          amount: fromHumanAmountToTokenBalance(amountInput.replaceAll(",", ""), assets[selectedToken.value].decimals),
          channel: dstChain.ibcSendChannel,
          fee: (await window.client.getEstimateTxFees(200000)).estimatedFee.amount[0],
          srcChain: {
            chainId: srcChain.chainId,
            restURL: srcChain.rest,
            explorerURL: srcChain.explorerURL,
            rpcURL: srcChain.rpc,
            isEVM: srcChain.isEVM,
          },
          dstChain: {
            chainId: dstChain.chainId,
            restURL: dstChain.rest,
            explorerURL: dstChain.explorerURL,
            rpcURL: dstChain.rpc,
            isEVM: dstChain.isEVM,
          },
          // on withdrawal, we need to update the balance of native coin for gas fees and the src asset
          assetBalancesToUpdate: {
            userAddress: walletInfo.walletAddress,
            tokens: [],
            natives:
              process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM === selectedToken.value
                ? [selectedToken.value]
                : [selectedToken.value, process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM],
          },
          // cw20_ics20 assets
          cw20_ics20: assets[selectedToken.value]?.denom_trace?.cw20_ics20,
          isWithdrawal: true,
          i18,
        })
      );
    } catch (e) {
      console.log(e);
    }
    setIsLoadingWithdrawalTrigger(false);
  };

  const submitButtonText = () => {
    if (amountInput === "0" || !amountInput || amountInput === "" || BigNumber(amountInput.replaceAll(",", "")).eq(0)) {
      return {
        buttonText: i18("Enter Amount", "managetokens.withdraw.submitBtn.text.enterAmount"),
        disabled: true,
      };
    }

    if (
      BigNumber(
        fromHumanAmountToTokenBalance(amountInput.replaceAll(",", ""), assets[selectedToken.value].decimals)
      ).gt(BigNumber(assetBalances[selectedToken.value]))
    ) {
      return {
        buttonText: i18(
          "Not enough " + assets[selectedToken.value].symbol + " balance",
          "managetokens.withdraw.submitBtn.text.notEnoughBalance",
          {
            symbol: assets[selectedToken.value].symbol,
          }
        ),
        disabled: true,
      };
    }

    // check if user has sufficient gas fees to cover this
    const calcEstimatedFees = estimatedFees(
      GAS_ESTIMATION_STATIC_IBC_WITHDRAWAL,
      statusCounters.estimatedFeesReference
    );
    if (
      assetBalances[process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM] &&
      BigNumber(assetBalances[process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM]).lt(calcEstimatedFees)
    ) {
      return {
        buttonText: i18("Wallet needs ARCH for Withdrawal", "managetokens.withdraw.submitBtn.text.notEnoughGas"),
        disabled: true,
      };
    }

    // if the input is the source denom we need to check if it will consume more fees than the user have balance
    // current balance - input amount < estimated fees
    if (
      assets[selectedToken.value].xDerivativeTarget &&
      selectedToken.value === process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM &&
      assetBalances[process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM] &&
      BigNumber(assetBalances[process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM])
        .minus(fromHumanAmountToTokenBalance(amountInput.replaceAll(",", ""), assets[selectedToken.value].decimals))
        .lt(calcEstimatedFees)
    ) {
      return {
        buttonText: i18("Wallet needs ARCH for Withdrawal", "managetokens.withdraw.submitBtn.text.notEnoughGas"),
        disabled: true,
      };
    }

    return {
      buttonText: i18("Withdrawal", "managetokens.withdraw.submitBtn.text.withdrawal"),
      disabled: false,
    };
  };

  return (
    <form className={clsx("dashboardMyAssetsForm", "withdraw")}>
      <fieldset className="withdrawToFieldset">
        <legend className="visuallyHidden">{i18("Select to chain", "managetokens.withdraw.to.chain.legend")}</legend>

        <CustomSelect
          name="withdraw_to_chain"
          labelText={i18("To", "managetokens.withdraw.to.chain.select.label")}
          placeholder={i18("Select chain", "managetokens.withdraw.to.chain.select.placeholder")}
          items={chainOptions}
          value={
            selectedChain
              ? chainOptions[findIndex(chainOptions, item => item.value === selectedChain.value) || 0]
              : null
          }
          onChange={handleChainChange}
          required
        />

        <CustomInput
          style={{ cursor: "pointer" }}
          name="withdraw_to_chain_address"
          labelText={i18("Receiving address", "managetokens.withdraw.to.address.label")}
          title={i18(`Copy address to clipboard`, "managetokens.withdraw.to.address.copyToClipboard.title")}
          value={recvAddrInput}
          onChange={e => setRecvAddrInput(e.target.value)}
          readOnly
          onClick={() => {
            if (recvAddrInput !== "") {
              // copy to clipboard
              navigator.clipboard.writeText(recvAddrInput);
              dispatch(
                sendToast({
                  type: "info",
                  noStore: true,
                  info: {
                    msg: i18("Address copied to clipboard", "toast.addressCopied.text"),
                    toastID: "" + new Date().getTime(),
                  },
                })
              );
            }
          }}
        />
      </fieldset>

      <fieldset className="withdrawFromFieldset">
        <legend className="visuallyHidden">{i18("Select token", "managetokens.withdraw.from.token.legend")}</legend>

        <div className="selectTokenSubFieldset withdrawFrom">
          <div className="flexbox">
            <CustomSelect
              name="withdraw_from_token"
              labelText={i18("From", "managetokens.withdraw.from.token.select.label")}
              hiddenLabel={true}
              placeholder={i18("Select token", "managetokens.withdraw.from.token.select.placeholder")}
              items={tokenOptions}
              value={tokenOptions[findIndex(tokenOptions, item => item.value === selectedToken?.value) || 0]}
              onChange={handleTokenChange}
              required
            />

            <CustomNumericInput
              extraClassName="withdrawFromUsdFormGroup"
              name="withdraw_from_usd"
              labelText={i18("Enter amount", "managetokens.withdraw.from.token.input.label")}
              hiddenLabel={true}
              placeholder={"0"}
              value={amountInput}
              onChange={e => setAmountInput(e.target.value)}
              disabled={!selectedToken}
            />
          </div>

          <div className="helpText selectTokenSubFieldsetHelpText">
            <span className="textGrey">
              {selectedToken
                ? formatBalance(
                    fromHumanAmountToTokenBalance(
                      amountInput.replaceAll(",", ""),
                      assets[selectedToken.value].decimals
                    ),
                    assets[selectedToken.value].price,
                    assets[selectedToken.value].decimals
                  ).usdConversion
                : 0}{" "}
              USD{" "}
            </span>
            {/* <span className="textGrey">0{" "}USD{" "}-{" "}</span>
            <span className="textGradient">{i18("Use USD instead", "managetokens.useUsd")}</span> */}
          </div>
        </div>

        <div className="selectTokenBalanceAmount">
          {selectedToken && assetBalances[selectedToken.value] && (
            <UserBalance
              balanceValue={
                selectedToken
                  ? formatBalance(
                      assetBalances[selectedToken.value],
                      assets[selectedToken.value].price,
                      assets[selectedToken.value].decimals
                    ).amount
                  : 0
              }
              balanceUsdValue={
                selectedToken
                  ? formatBalance(
                      assetBalances[selectedToken.value],
                      assets[selectedToken.value].price,
                      assets[selectedToken.value].decimals
                    ).usdConversion
                  : 0
              }
            />
          )}

          <Button
            btnColor="dark-medium"
            text={i18("Max", "managetokens.withdraw.maxBtn.text")}
            title={i18("Set Max Value", "managetokens.withdraw.maxBtn.title")}
            onClick={() =>
              setAmountInput(
                selectedToken
                  ? formatBalance(
                      assetBalances[selectedToken.value],
                      assets[selectedToken.value].price,
                      assets[selectedToken.value].decimals
                    ).amount.toString()
                  : "0"
              )
            }
          />
        </div>
      </fieldset>

      <p className="infoText">
        {i18("Additional fees may apply when you're confirming the transaction", "managetokens.withdraw.infoText")}
      </p>

      <div className="buttonContainer">
        <Button
          btnColor="gradientText"
          text={i18("Cancel", "managetokens.cancelBtn.text")}
          title={i18("Cancel and close", "managetokens.cancelBtn.title")}
          onClick={onCloseModal}
        />
        {isLoadingWithdrawalTrigger || (selectedToken && !assetBalances[selectedToken.value]) ? (
          <CustomLoader size="xs" />
        ) : (
          <Button
            btnColor="gradient"
            title={i18("Submit", "managetokens.withdraw.submitBtn.title")}
            text={submitButtonText().buttonText}
            onClick={async () => await handleWithdrawalTrigger()}
            disabled={submitButtonText().disabled}
          />
        )}
      </div>
    </form>
  );
}

export default MyAssetsManageTokenWithdrawForm;
