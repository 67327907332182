import { InputHTMLAttributes, ReactNode } from "react";
import clsx from "clsx";
import useLanguage from "@axvdex/hooks/useLanguage";

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  extraClassName?: string;
  name: string;
  disabled?: boolean;
  labelText: ReactNode;
  labelPlacement?: "left" | "right";
  hiddenLabel?: boolean;
  isToggled: boolean;
  onToggle: (e) => void;
  helpText?: ReactNode;
}

function CustomSwitch({
  extraClassName,
  name,
  disabled,
  labelText,
  //labelPlacement = "left",
  hiddenLabel,
  isToggled,
  onToggle,
  helpText,
  ...props
}: CustomInputProps) {
  const { i18 } = useLanguage();

  return (
    <div className={clsx("formGroup", "formGroupSwitch", extraClassName && extraClassName)}>
      <div className="formGroupSwitchWrapper">
        <div className={clsx("label", disabled && "disabled", hiddenLabel && "visuallyHidden")}>{labelText}</div>

        <label className="customSwitch" htmlFor={`id_${name}`}>
          <span className="visuallyHidden">{i18("Toggle", "customSwitch.label")}</span>
          <input
            className="buttonInput"
            type="checkbox"
            id={`id_${name}`}
            name={name}
            checked={isToggled}
            onChange={onToggle}
            disabled={disabled}
            {...props}
          />

          <span className="switch" />
        </label>
      </div>

      {helpText && <div className="helpText">{helpText}</div>}
    </div>
  );
}

export default CustomSwitch;
