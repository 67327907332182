import React from "react";
import { createRoot } from "react-dom/client";
import "react-tooltip/dist/react-tooltip.css";
import "react-toastify/dist/ReactToastify.css";
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/navigation";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import "tippy.js/animations/shift-toward.css";
import "yet-another-react-lightbox/styles.css";
import { Provider } from "react-redux";
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./state";
import { LanguageContextProvider } from "./contexts/LanguageContext";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <LanguageContextProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </LanguageContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
