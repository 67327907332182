import BigNumber from "bignumber.js";
import { normalize_amount, toUSDAmount } from "@axvdex/utils/formatNumber";
// import checkDerivativeMintOrTrade from "@axvdex/utils/swapScripts/checkDerivativeMintOrTrade";
import { IAsset, IContract, IPool, IPoolAsset } from "../interfaces";
import { div, minus, plus, times } from "../math";
import estimatedFees, {
  GAS_ESTIMATION_STATIC_TRADE_1_HOP_HYBRID,
  GAS_ESTIMATION_STATIC_TRADE_1_HOP_STABLE,
  GAS_ESTIMATION_STATIC_TRADE_1_HOP_STANDARD,
  GAS_ESTIMATION_STATIC_TRADE_ROUTER_OVERHEAD,
} from "../estimatedFees";
import { getAllPaths } from "./depthFirstSearchPathCalc";
import { cashbackToAXV, computeCashback } from "./simulateCashback";
import { computeStableSwap, computeStableSwapXAssetMode } from "./simulateStable";
import { computeStandardSwap } from "./simulateStandard";
import { computeHybridSwap } from "./simulateHybrid";

//Given this function, we can now simulate swaps on any pool
//Give me a return type of this function and I can simulate swaps on any pool
export interface ISwapSimulationReturn {
  offerAmount: string;
  offerAmountInUSD: number;
  askAmount: string;
  askAmountInUSD: number;
  minimumReceived: string;
  minimumReceivedInUSD: number;
  offerToAskRatio: string;
  askToOfferRatio: string;
  feePer: string;
  feeInUSD: number;
  expectedCashbackAmount: string;
  expectedCashbackAXVAmount: string;
  expectedCashbackInUSD: number;
  marketImpactPer: string;
  priceImpactPer: string;
  route: {
    p: string;
    x: string;
    y: string;
    askAmount: bigint;
    offerAmount: bigint;
    feeAmount: bigint;
    expectedCashbackMinted: bigint;
    spreadAmount: bigint;
    derivativeOperation: string;
    hopTx: {
      mint_staking_derivative: {
        contract_addr: string;
        offer_asset: {
          native_token: { denom: string };
        };
        ask_asset: {
          token: {
            contract_addr: string;
          };
        };
      };
    };
    funds: { denom: string; amount: string }[];
  }[];
}

const swapSimulation = (
  allPools: {
    [key: string]: IPool;
  },
  allAssets: {
    [key: string]: IAsset;
  },
  allContracts: {
    [key: string]: IContract;
  },
  statusCounters: any,
  hops: {
    [assetX: string]: {
      [assetY: string]: string;
    };
  },
  offerAsset: string,
  offerAssetAmountRaw: string | null,
  askAsset: string,
  askAssetAmountRaw: string | null,
  slippageTolerance: number,
  maxHops?: number
): ISwapSimulationReturn => {
  if (!offerAssetAmountRaw && !askAssetAmountRaw) {
    throw new Error("No offerAssetAmount || askAssetAmount specified");
  }
  if (offerAssetAmountRaw && askAssetAmountRaw) {
    throw new Error("Only one amount needs to be specified");
  }

  // ignoreMinimumReceiveAmount variable to keep track of amounts that dont have slippage or fees (ex. mint xAssets or stable swap from native => xAsset)
  const offerAssetAmount = offerAssetAmountRaw
    ? BigInt(BigNumber(offerAssetAmountRaw).times(Math.pow(10, allAssets[offerAsset].decimals)).toString(10))
    : null;
  const askAssetAmount = askAssetAmountRaw
    ? BigInt(BigNumber(askAssetAmountRaw).times(Math.pow(10, allAssets[askAsset].decimals)).toString(10))
    : null;

  // find cashback and cashbackMinter on the allContracts object
  const cashback = allContracts.cashback;
  const cashbackMinter = allContracts["cashback-minter"];

  const allPathsSwaps: any = [];
  // 2nd step: go thought all hops and add hops to the end that connect, at the end forming all possible paths starting from a specific hop
  // when user inputs on the top input field (offerAssetAmount has value and askAssetAmount is null)
  if (offerAssetAmount && !askAssetAmount) {
    const allPaths = getAllPaths(hops, offerAsset, askAsset).slice(0, 10); // only get the shortest 10 routes

    // 3rd step: simulate swaps thought all the paths coordinating to the most recent data of pools available
    for (const path of allPaths) {
      const simulatedSwap = simulateSwaps(
        allPools,
        allContracts,
        cashbackMinter,
        allAssets,
        statusCounters,
        cashback,
        offerAssetAmount,
        path
      );

      allPathsSwaps.push(simulatedSwap);
    }
  }
  // if ask amount is inputted we need to simulate the reverse path
  // else if (askAssetAmount && !offerAssetAmount) {
  //   const allPaths = getAllPaths(hops, offerAsset, askAsset).slice(0, 10); // only get the shortest 10 routes

  //   // 3rd step: simulate swaps thought all the paths coordinating to the most recent data of pools available
  //   for (const path of allPaths) {
  //     const pathSwaps: any = [];
  //     // ask amount in each hop will be the amount returned by the previous swap hop
  //     let askAmount = askAssetAmount;

  //     for (const hop of path.reverse()) {
  //       if (pathSwaps.length > 0) {
  //         askAmount = pathSwaps[pathSwaps.length - 1].offerAmount;
  //       }

  //       const pool = allPools[hop.p];
  //       if (pool.type === "standard") {
  //         const { offerAmount, feeAmount } = computeStandardOfferAmount(pool, hop, askAmount);

  //         const expectedCashbackMinted = cashbackMinter
  //           ? computeCashback(allAssets, pool, cashbackMinter, hop.x, offerAmount!)
  //           : BigInt(0);

  //         pathSwaps.push({
  //           ...hop,
  //           offerAmount,
  //           askAmount,
  //           feeAmount,
  //           expectedCashbackMinted,
  //           hopTx: {
  //             standard_hop_info: {
  //               offer_asset_info: pool.poolAssets.find((asset: IPoolAsset) => {
  //                 if (asset.info.token) {
  //                   return asset.info.token.contract_addr === hop.x;
  //                 }
  //                 return asset.info.native_token.denom === hop.x;
  //               })!.info,
  //               ask_asset_info: pool.poolAssets.find((asset: IPoolAsset) => {
  //                 if (asset.info.token) {
  //                   return asset.info.token.contract_addr === hop.y;
  //                 }
  //                 return asset.info.native_token.denom === hop.y;
  //               })!.info,
  //             },
  //           },
  //         });
  //       }
  //       if (pool.type === "stable") {
  //         const { offerAmount, feeAmount } = computeStableOfferAmount(pool, hop, askAmount);

  //         const expectedCashbackMinted = cashbackMinter
  //           ? computeCashback(allAssets, pool, cashbackMinter, hop.x, offerAmount!)
  //           : BigInt(0);

  //         const derivativeOperation = checkDerivativeMintOrTrade(
  //           allAssets,
  //           allContracts,
  //           cashback,
  //           hop,
  //           offerAmount,
  //           askAmount,
  //           expectedCashbackMinted
  //         );

  //         if (derivativeOperation === "mint") {
  //           pathSwaps.push({
  //             ...hop,
  //             offerAmount: askAmount,
  //             askAmount: askAmount,
  //             feeAmount: BigInt(0),
  //             spreadAmount: BigInt(0),
  //             expectedCashbackMinted: BigInt(0),
  //             derivativeOperation,
  //             hopTx: {
  //               mint: {}, // TODO: TBD
  //             },
  //           });
  //         } else {
  //           pathSwaps.push({
  //             ...hop,
  //             offerAmount,
  //             askAmount,
  //             feeAmount,
  //             expectedCashbackMinted,
  //             derivativeOperation,
  //             hopTx: {
  //               stable_hop_info: {
  //                 asset_infos: pool.poolAssets.map((asset: any) => asset.info),
  //                 from_asset_index: pool.poolAssets.findIndex((asset: any) => {
  //                   if (asset.info.token) {
  //                     return asset.info.token.contract_addr === hop.x;
  //                   }
  //                   return asset.info.native_token.denom === hop.x;
  //                 }),
  //                 to_asset_index: pool.poolAssets.findIndex((asset: any) => {
  //                   if (asset.info.token) {
  //                     return asset.info.token.contract_addr === hop.y;
  //                   }
  //                   return asset.info.native_token.denom === hop.y;
  //                 }),
  //               },
  //             },
  //           });
  //         }
  //       }
  //     }
  //     allPathsSwaps.push(pathSwaps.reverse());
  //   }
  // }

  if (allPathsSwaps.length === 0) {
    throw new Error("No paths found");
  }

  // sort all paths by the amount of askAmount, so the 1st one will be the BEST route
  if (offerAssetAmount && !askAssetAmount) {
    allPathsSwaps.sort((a: any, b: any) =>
      BigNumber(b[b.length - 1].estimatedFinalAskAmountUSD_withGasFee).minus(
        a[a.length - 1].estimatedFinalAskAmountUSD_withGasFee
      )
    );
  }

  // parse the best route to the result object used to fill all fields on the frontend
  let indexOfRoute = 0;
  if (maxHops) {
    indexOfRoute = allPathsSwaps.findIndex((route: any) => route.length <= maxHops);
  }
  const bestRoute = allPathsSwaps[indexOfRoute];

  const offerAmount = offerAssetAmount ? offerAssetAmount : bestRoute[0].offerAmount;
  const offerAssetPoolIndex = allPools[bestRoute[0].p].poolAssets.findIndex((asset: any) => {
    if (asset.info.token) {
      return asset.info.token.contract_addr === bestRoute[0].x;
    }
    return asset.info.native_token.denom === bestRoute[0].x;
  });
  const offerAmountInUSD = toUSDAmount(
    allAssets[bestRoute[0].x].price!,
    offerAmount,
    allPools[bestRoute[0].p].assetDecimals[offerAssetPoolIndex]
  );
  const askAmount = askAssetAmount ? askAssetAmount : bestRoute[bestRoute.length - 1].askAmount;
  const askAssetPoolIndex = allPools[bestRoute[bestRoute.length - 1].p].poolAssets.findIndex((asset: any) => {
    if (asset.info.token) {
      return asset.info.token.contract_addr === bestRoute[bestRoute.length - 1].y;
    }
    return asset.info.native_token.denom === bestRoute[bestRoute.length - 1].y;
  });
  const askAmountInUSD = toUSDAmount(
    allAssets[bestRoute[bestRoute.length - 1].y].price!,
    askAmount,
    allPools[bestRoute[bestRoute.length - 1].p].assetDecimals[askAssetPoolIndex]
  );
  const offerAmountInputFormated = div(offerAmount.toString(), Math.pow(10, allAssets[offerAsset].decimals));
  const askAmountInputFormated = div(askAmount.toString(), Math.pow(10, allAssets[askAsset].decimals));

  const minimumReceived = bestRoute[bestRoute.length - 1].ignoreMinimumReceiveAmount
    ? offerAmountInputFormated
    : minus(askAmountInputFormated, div(times(askAmountInputFormated, slippageTolerance), 100));

  // NOTE: This is irrelevant now because it will always be a trade
  // only if route is 1 hop, and it's a mint, then minimumReceived = askAmount
  // if (bestRoute.length === 1 && bestRoute[0].derivativeOperation === "mint") {
  //   minimumReceived = askAmountInputFormated;
  // }

  const minimumReceivedInUSD = toUSDAmount(
    allAssets[bestRoute[bestRoute.length - 1].y].price!,
    BigInt(
      BigNumber(minimumReceived)
        .times(Math.pow(10, allAssets[askAsset].decimals))
        .decimalPlaces(0, BigNumber.ROUND_FLOOR)
        .toString(10)
    ),
    allPools[bestRoute[bestRoute.length - 1].p].assetDecimals[askAssetPoolIndex]
  );

  let feePer = "0";
  let feeInUSD = 0;
  let expectedCashbackAmount = "0";
  for (const hop of bestRoute) {
    feePer = BigNumber(feePer)
      .plus(BigNumber(hop.feeAmount).div(BigNumber(hop.askAmount).plus(hop.feeAmount)).times(100))
      .decimalPlaces(2)
      .toString();
    feeInUSD += toUSDAmount(allAssets[hop.y].price!, hop.feeAmount, allAssets[hop.y].decimals);
    expectedCashbackAmount = plus(expectedCashbackAmount, div(hop.expectedCashbackMinted.toString(), Math.pow(10, 6)));
  }

  const cashbackAXVAmount = cashbackToAXV(cashback, BigInt(parseInt(times(expectedCashbackAmount, Math.pow(10, 6)))));

  const expectedCashbackInUSD =
    cashback && cashback.extraFields.reward_source
      ? toUSDAmount(allAssets[cashback.extraFields.reward_source].price!, cashbackAXVAmount, cashback.config.decimals)
      : 0;

  const inputDollarAmount = BigNumber(bestRoute[0].offerAmount)
    .div(Math.pow(10, allAssets[bestRoute[0].x].decimals))
    .times(allAssets[bestRoute[0].x].price);
  const outputDollarAmount = BigNumber(bestRoute[bestRoute.length - 1].askAmount)
    .div(Math.pow(10, allAssets[bestRoute[bestRoute.length - 1].y].decimals))
    .times(allAssets[bestRoute[bestRoute.length - 1].y].price);
  const feeDollarAmount = BigNumber(feeInUSD);
  const marketImpactPer = inputDollarAmount
    .div(outputDollarAmount.plus(feeDollarAmount))
    .minus(1)
    .times(100)
    .toString();

  // price impact calc
  // To calc priceImpact need to calc a swap of the same assets on the same route with a small amount
  // then compare the small ratio with cur ratio
  // 1$ worth of offer asset
  const smallOfferAmount = BigInt(
    BigNumber(1)
      .div(allAssets[bestRoute[0].x].price)
      .times(Math.pow(10, allAssets[bestRoute[0].x].decimals))
      .decimalPlaces(0)
      .toString(10)
  );

  const simulatedSwapSmallAmount = simulateSwaps(
    allPools,
    allContracts,
    cashbackMinter,
    allAssets,
    statusCounters,
    cashback,
    smallOfferAmount,
    bestRoute.map(bestRouteHop => {
      return {
        x: bestRouteHop.x,
        y: bestRouteHop.y,
        p: bestRouteHop.p,
      };
    })
  );
  const smallRatio = BigNumber(
    normalize_amount(
      simulatedSwapSmallAmount[simulatedSwapSmallAmount.length - 1].askAmount,
      allAssets[simulatedSwapSmallAmount[simulatedSwapSmallAmount.length - 1].y].decimals
    ).toString(10)
  ).div(
    BigNumber(
      normalize_amount(
        simulatedSwapSmallAmount[0].offerAmount,
        allAssets[simulatedSwapSmallAmount[0].x].decimals
      ).toString(10)
    )
  );

  const swapRatio = BigNumber(
    normalize_amount(
      bestRoute[bestRoute.length - 1].askAmount,
      allAssets[bestRoute[bestRoute.length - 1].y].decimals
    ).toString(10)
  ).div(BigNumber(normalize_amount(bestRoute[0].offerAmount, allAssets[bestRoute[0].x].decimals).toString(10)));

  const priceImpact = smallRatio.div(swapRatio).minus(1).times(100);
  const priceImpactPer = priceImpact.lt(0) ? "0" : priceImpact.toString(10);

  return {
    offerAmount: offerAmountInputFormated,
    offerAmountInUSD,
    askAmount: askAmountInputFormated,
    askAmountInUSD,
    minimumReceived,
    minimumReceivedInUSD,
    offerToAskRatio: offerAmountInputFormated !== "0" ? div(askAmountInputFormated, offerAmountInputFormated) : "-",
    askToOfferRatio: askAmountInputFormated !== "0" ? div(offerAmountInputFormated, askAmountInputFormated) : "-",
    feePer,
    feeInUSD,
    expectedCashbackAmount,
    expectedCashbackAXVAmount:
      cashback && cashback.extraFields.reward_source
        ? div(cashbackAXVAmount.toString(), Math.pow(10, allAssets[cashback.extraFields.reward_source].decimals))
        : "0",
    expectedCashbackInUSD,
    marketImpactPer: marketImpactPer !== "-" ? parseFloat(marketImpactPer).toFixed(2) : marketImpactPer,
    priceImpactPer: priceImpactPer ? parseFloat(priceImpactPer).toFixed(2) : "-",
    route: bestRoute,
  };
};

const simulateSwaps = (
  allPools: any,
  allContracts: any,
  cashbackMinter: any,
  allAssets: any,
  statusCounters: any,
  cashback: any,
  offerAssetAmount: any,
  path: any
) => {
  const pathSwaps: any = [];
  let gasEstimation = 0;

  // offer amount in each hop will be the amount returned by the previous swap hop
  let offerAmount = offerAssetAmount;

  for (const hop of path) {
    if (pathSwaps.length > 0) {
      offerAmount = pathSwaps[pathSwaps.length - 1].askAmount;
    }
    const pool = allPools[hop.p];

    if ("standard" === pool.type) {
      const { returnAmount, spreadAmount, feeAmount } = computeStandardSwap(pool, hop, offerAmount);

      const expectedCashbackMinted = cashbackMinter
        ? computeCashback(allAssets, pool, cashbackMinter, hop.x, offerAmount)
        : BigInt(0);

      const funds = [];
      const offeredAmount = offerAmount;
      pool.poolAssets.forEach(asset => {
        if (asset.info.native_token && asset.info.native_token.denom === hop.x) {
          funds.push({
            denom: asset.info.native_token.denom,
            amount: offeredAmount.toString(),
          });
        }
      });

      pathSwaps.push({
        ...hop,
        offerAmount,
        askAmount: returnAmount,
        feeAmount,
        spreadAmount,
        expectedCashbackMinted,
        hopTx: {
          standard_hop_info: {
            pool: {
              id: pool.poolId,
            },
            from_asset_index: pool.poolAssets.findIndex((asset: IPoolAsset) => {
              if (asset.info.token) {
                return asset.info.token.contract_addr === hop.x;
              }
              return asset.info.native_token.denom === hop.x;
            }),
          },
        },
        funds,
      });

      gasEstimation += GAS_ESTIMATION_STATIC_TRADE_1_HOP_STANDARD;
    }

    if ("stable" === pool.type) {
      const xAssetMode = !!pool.settings.xasset_mode_minter;

      const {
        returnAmount: askAmount,
        swap_to_asset_amount,
        feeAmount,
      } = xAssetMode ? computeStableSwapXAssetMode(pool, hop, offerAmount) : computeStableSwap(pool, hop, offerAmount);

      const expectedCashbackMinted = cashbackMinter
        ? computeCashback(
            allAssets,
            pool,
            cashbackMinter,
            hop.x,
            xAssetMode ? BigInt(swap_to_asset_amount) : offerAmount
          )
        : BigInt(0);

      // NOTE: With the introduction of xAsset Mode, the pool is the one responsible for minting or not
      //       So the derivativeOperation will always be "trade" from now on
      const derivativeOperation = "trade";
      // const derivativeOperation = checkDerivativeMintOrTrade(
      //   allAssets,
      //   allContracts,
      //   cashback,
      //   hop,
      //   offerAmount,
      //   askAmount,
      //   expectedCashbackMinted,
      //   pool
      // );

      const funds = [];
      const offeredAmount = offerAmount;
      pool.poolAssets.forEach(asset => {
        if (asset.info.native_token && asset.info.native_token.denom === hop.x) {
          funds.push({
            denom: asset.info.native_token.denom,
            amount: offeredAmount.toString(),
          });
        }
      });

      // NOTE: With the introduction of xAsset Mode, the pool is the one responsible for minting or not
      //       So the derivativeOperation will always be "trade" from now on

      // if (derivativeOperation === "mint") {
      //   pathSwaps.push({
      //     ...hop,
      //     offerAmount,
      //     askAmount: offerAmount,
      //     feeAmount: BigInt(0),
      //     spreadAmount: BigInt(0),
      //     expectedCashbackMinted: BigInt(0),
      //     derivativeOperation,
      //     hopTx: {
      //       mint_staking_derivative: {
      //         contract_addr: allAssets[hop.y].derivativeContract,
      //         offer_asset: pool.poolAssets.find((asset: IPoolAsset) => {
      //           if (asset.info.token) {
      //             return asset.info.token.contract_addr === hop.x;
      //           }
      //           return asset.info.native_token.denom === hop.x;
      //         })!.info,
      //         ask_asset: pool.poolAssets.find((asset: IPoolAsset) => {
      //           if (asset.info.token) {
      //             return asset.info.token.contract_addr === hop.y;
      //           }
      //           return asset.info.native_token.denom === hop.y;
      //         })!.info,
      //       },
      //     },
      //     funds,
      //   });
      // } else {
      pathSwaps.push({
        ...hop,
        offerAmount,
        askAmount,
        feeAmount,
        spreadAmount: BigInt(0),
        expectedCashbackMinted,
        derivativeOperation,
        // ignore minimum received amount if on xAssetMode doing a nativeAsset => xAsset swap
        ignoreMinimumReceiveAmount: xAssetMode && funds.length === 1,
        hopTx: {
          stable_hop_info: {
            pool: {
              id: pool.poolId,
            },
            from_asset_index: pool.poolAssets.findIndex((asset: any) => {
              if (asset.info.token) {
                return asset.info.token.contract_addr === hop.x;
              }
              return asset.info.native_token.denom === hop.x;
            }),
            to_asset_index: pool.poolAssets.findIndex((asset: any) => {
              if (asset.info.token) {
                return asset.info.token.contract_addr === hop.y;
              }
              return asset.info.native_token.denom === hop.y;
            }),
          },
        },
        funds,
      });

      gasEstimation += GAS_ESTIMATION_STATIC_TRADE_1_HOP_STABLE;
      //}
    }

    if ("hybrid" === pool.type) {
      const { toAmount, feeAmount } = computeHybridSwap(pool, hop, offerAmount);

      const expectedCashbackMinted = cashbackMinter
        ? computeCashback(allAssets, pool, cashbackMinter, hop.x, offerAmount)
        : BigInt(0);

      const funds = [];
      const offeredAmount = offerAmount;
      pool.poolAssets.forEach(asset => {
        if (asset.info.native_token && asset.info.native_token.denom === hop.x) {
          funds.push({
            denom: asset.info.native_token.denom,
            amount: offeredAmount.toString(),
          });
        }
      });

      pathSwaps.push({
        ...hop,
        offerAmount,
        askAmount: toAmount,
        feeAmount,
        spreadAmount: BigInt(0),
        expectedCashbackMinted,
        derivativeOperation: null,
        hopTx: {
          ratio_hop_info: {
            pool: {
              id: pool.poolId,
            },
            from_asset_index: pool.poolAssets.findIndex((asset: any) => {
              if (asset.info.token) {
                return asset.info.token.contract_addr === hop.x;
              }
              return asset.info.native_token.denom === hop.x;
            }),
          },
        },
        funds,
      });

      gasEstimation += GAS_ESTIMATION_STATIC_TRADE_1_HOP_HYBRID;
    }
  }

  // calc final amount in USD
  const finalAskAmountUSD = toUSDAmount(
    allAssets[pathSwaps[pathSwaps.length - 1].y].price!,
    pathSwaps[pathSwaps.length - 1].askAmount,
    allAssets[pathSwaps[pathSwaps.length - 1].y].decimals
  );

  try {
    // calc estimated gas fees
    if (path.length > 1) gasEstimation += GAS_ESTIMATION_STATIC_TRADE_ROUTER_OVERHEAD * path.length;
    const estimatedFeesAmount = estimatedFees(gasEstimation, statusCounters.estimatedFeesReference);
    const gasAsset = allAssets[statusCounters.estimatedFeesReference.estimatedFee[0].denom];
    const estimatedFeesUSD = BigNumber(estimatedFeesAmount)
      .div(Math.pow(10, gasAsset.decimals))
      .times(gasAsset.price)
      .toNumber();

    // put a field on the last hop to show the final amount in USD including the estimated gas fee that will be used for sorting for best route
    if (estimatedFeesUSD > 0)
      pathSwaps[pathSwaps.length - 1].estimatedFinalAskAmountUSD_withGasFee = finalAskAmountUSD - estimatedFeesUSD;
  } catch (error) {
    console.error("Error estimating gas fee", error);
    pathSwaps[pathSwaps.length - 1].estimatedFinalAskAmountUSD_withGasFee = finalAskAmountUSD;
  }

  return pathSwaps;
};
export default swapSimulation;
