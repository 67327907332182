import { MsgExecuteContract } from "cosmjs-types/cosmwasm/wasm/v1/tx";
import { MsgExecuteContractEncodeObject } from "@cosmjs/cosmwasm-stargate";
import Long from "long";
import { useAppSelector } from "@axvdex/state";
import { postFeeGrantTx } from "@axvdex/api/user";
import { selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { getFeeGrantSigners } from "@axvdex/state/wallet/walletThunks";

import Button from "./common/Button";

interface DashboardNavItemsProps {
  hideText?: boolean;
  hideIndex?: number;
}

function NavDebugItems(_props: DashboardNavItemsProps) {
  const walletInfo = useAppSelector(selectWalletInfo);

  return (
    <div className="navSidebarMenu">
      <Button
        extraClassName="btnDisconnectWallet"
        title="Test1"
        btnVariant="icon"
        icon={"Test4"}
        onClick={async () => {
          const executeMsg = {
            receive: {
              sender: walletInfo.walletAddress,
              amount: "1000", // 0.001
              msg: Buffer.from(
                JSON.stringify({
                  hops: [
                    {
                      mint_staking_derivative: {
                        contract_addr: "archway1cgmtk73kqz7se0wdxa05mew2gsm3rganqrzntsp7f2d33yg3caqsx2dycc",
                        offer_asset: {
                          native_token: {
                            denom: "ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
                          },
                        },
                        ask_asset: {
                          token: {
                            contract_addr: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                          },
                        },
                      },
                    },
                    {
                      standard_hop_info: {
                        offer_asset_info: {
                          token: {
                            contract_addr: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                          },
                        },
                        ask_asset_info: {
                          token: {
                            contract_addr: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
                          },
                        },
                      },
                    },
                    {
                      stable_hop_info: {
                        asset_infos: [
                          { native_token: { denom: "aconst" } },
                          {
                            token: {
                              contract_addr: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
                            },
                          },
                        ],
                        from_asset_index: 1,
                        to_asset_index: 0,
                      },
                    },
                  ],
                  minimum_receive: "0",
                })
              ).toString("base64"),
            },
          };

          const msgSend = MsgExecuteContract.fromPartial({
            msg: Buffer.from(JSON.stringify(executeMsg)),
            sender: walletInfo.walletAddress,
            contract: "archway1hae4yld2cry226gwfdkycn73x0vfdlu3w79kycay77sk3x9sznysxnt6ua",
            funds: [
              {
                denom: "ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
                amount: "1000",
              },
            ],
          });

          const msg: MsgExecuteContractEncodeObject = {
            typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
            value: msgSend,
          };

          const { stargateSigner, queryService } = await getFeeGrantSigners();

          const estimatedGasFee = 3200000;
          const { estimatedFee } = await window.client.getEstimateTxFees(estimatedGasFee);

          let accNumber;

          const acc = await stargateSigner.getAccount(walletInfo.walletAddress);
          if (acc === null) {
            const accounts = await queryService.Accounts({
              pagination: {
                countTotal: true,
                limit: Long.fromNumber(1),
                key: new Uint8Array(),
                offset: Long.fromNumber(0),
                reverse: false,
              },
            });
            accNumber = accounts.pagination.total.toNumber() - 1;
          } else {
            accNumber = acc.accountNumber;
          }

          const txRaw = await stargateSigner.sign(
            walletInfo.walletAddress,
            [msg],
            {
              ...estimatedFee,
              granter: "archway1mh3xk4yvqy32w8s3a37a0gjnuxv0d3fcny2zgq",
              payer: "",
            },
            "",
            {
              accountNumber: accNumber,
              sequence: 0,
              chainId: process.env.REACT_APP_ARCHWAY_NETWORK,
            }
          );

          // const decodedAuth = AuthInfo.decode(Uint8Array.from(txRaw.authInfoBytes));
          // const decodedBody = TxBody.decode(Uint8Array.from(txRaw.bodyBytes));
          // const signatures = [Uint8Array.from(txRaw.signatures[0])];

          // - sign amino does not work with fee granter field (unlikely because on the signAmino code it takes into account this field)

          // when it works:
          // - it worked when delete the granter field! Only when deleting this field it works on the signature verification

          // is this related to the payed field being on the sign message??? Something checking from the signing

          await postFeeGrantTx({
            txRaw: {
              bodyBytes: Array.from(txRaw.bodyBytes),
              authInfoBytes: Array.from(txRaw.authInfoBytes),
              signatures: [Array.from(txRaw.signatures[0])],
            },
          });

          //await stargateSigner.broadcastTx(TxRaw.encode(txRaw).finish());
        }}
      />
    </div>
  );
}

export default NavDebugItems;
