import { useState } from "react";
import clsx from "clsx";
import FeegrantContainer from "@axvdex/components/trade/FeegrantContainer";
import { useAppSelector } from "@axvdex/state";
import { selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import StatusMessages from "@axvdex/components/StatusMessages";
import useLanguage from "@axvdex/hooks/useLanguage";
import MainLayout from "../layouts/MainLayout";
import styles from "../styles/Trade.module.scss";
import TradeContainer from "components/trade/TradeContainer";

function Trade() {
  const walletInfo = useAppSelector(selectWalletInfo);
  const { i18 } = useLanguage();
  const [feeGrantAssetsToTrade, setFeeGrantAssetsToTrade] = useState(null);

  return (
    <MainLayout
      pageClass={clsx(styles.trade, "trade")}
      headTitle={i18("Trade", "trade.headTitle")}
      headDescription={i18("Trade your favourites on Astrovault", "trade.headDescription")}
    >
      <StatusMessages />
      <div className="tradePageWrapper">
        <section className="tradeSectionForm">
          <h1 className="tradeSectionFormTitle">{i18("Trade", "tradeView.title")}</h1>
          <TradeContainer feeGrantAssetsToTrade={feeGrantAssetsToTrade} />
        </section>

        {walletInfo &&
          walletInfo.isConnected &&
          (walletInfo.walletConnectSequence === 0 || walletInfo.walletConnectSequence === null) && (
            <FeegrantContainer setFeeGrantAssetsToTrade={setFeeGrantAssetsToTrade} />
          )}
      </div>
    </MainLayout>
  );
}

export default Trade;
