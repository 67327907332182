import { IAsset, IPool } from "@axvdex/utils/interfaces";
import { fetchWrapper } from "./index";

export const getListOfPools = (): Promise<{ data?: Array<IPool>; ok: boolean }> => {
  return fetchWrapper(
    "/pool" + (process.env.REACT_APP_SUB_MODE ? "?env=" + process.env.REACT_APP_SUB_MODE.toLowerCase() : "")
  );
};

export const getListOfAssets = (): Promise<{ data?: Array<IAsset>; ok: boolean }> =>
  fetchWrapper(
    "/asset" + (process.env.REACT_APP_SUB_MODE ? "?env=" + process.env.REACT_APP_SUB_MODE.toLowerCase() : "")
  );
