import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import { ChevronLeft, ChevronRight } from "react-feather";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  ColumnDef,
} from "@tanstack/react-table";
import useLanguage from "@axvdex/hooks/useLanguage";
import { ReactComponent as IcnHandle } from "../assets/icons/icn-handle.svg";
import Button from "./common/Button";

interface TableDnDProps<Data> {
  tableId: string;
  customData: Data[];
  customColumns: ColumnDef<Data>[];
  pagination: { pageIndex: number; pageSize: number };
  setPagination: React.Dispatch<
    React.SetStateAction<{
      pageIndex: number;
      pageSize: number;
    }>
  >;
  extraClassName?: string;
  mobileResponsiveStyling?: "isResponsive" | "isResponsiveNoThVisible"; // table display block | table display block w/ th not visible
  onOrderUpdate?: (updatedData: string[]) => void;
}

// noinspection JSUnusedLocalSymbols
function DashboardDndPaginationTable<Data>({
  tableId,
  customData,
  customColumns,
  extraClassName,
  mobileResponsiveStyling,
  onOrderUpdate,
  pagination,
  setPagination,
}: TableDnDProps<Data>) {
  const { i18 } = useLanguage();

  const [data, setData] = useState([]);
  const [columns] = useState(() => [...customColumns]);

  const reorderRow = (startIndex: number, endIndex: number) => {
    const result = Array.from(data);
    const [removed] = result.splice(startIndex + pagination.pageIndex * pagination.pageSize, 1);
    result.splice(endIndex + pagination.pageIndex * pagination.pageSize, 0, removed);
    setData(result);
    onOrderUpdate(result.map(res => res.id));
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    reorderRow(result.source.index, result.destination.index);
  };

  useEffect(() => {
    setData([...customData]);
  }, [customData]);

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id, //good to have guaranteed unique row ids/keys for rendering
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    autoResetPageIndex: false,
    onPaginationChange: setPagination,
    state: { pagination },
  });

  const paginationBulletsButtons = Array.from({ length: table.getPageCount() }, (_, i) => (
    <li key={i}>
      <button
        className={clsx("btnTablePaginationBullet", pagination.pageIndex === i && "isCurrent")}
        onClick={() => table.setPageIndex(i)}
        title={`${i18("Go to page", "global.pagination.goToPage")} ${i + 1}`}
      />
    </li>
  ));

  return (
    <>
      <section className={`dashboardGridItemSectionTable`}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId={tableId} direction="vertical">
            {(provided, snapshot) => (
              <table
                className={clsx(
                  "table",
                  extraClassName && extraClassName,
                  mobileResponsiveStyling && mobileResponsiveStyling,
                  snapshot.isDraggingOver && "isDragging"
                )}
              >
                <thead>
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                      ))}
                      <th className="tableCta">
                        {/*temporary fix for accessibility*/}
                        <span className="visuallyHidden">{i18("Drag", "dashboard.dndtable.drag")}</span>
                        {/*<Button*/}
                        {/*  btnVariant="icon"*/}
                        {/*  btnColor="purple"*/}
                        {/*  icon={<RotateCcw />}*/}
                        {/*  title="Reset table"*/}
                        {/*  onClick={rerender}*/}
                        {/*/>*/}
                      </th>
                    </tr>
                  ))}
                </thead>
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {table.getRowModel().rows.map((row, index) => (
                    <Draggable key={`${tableId}-row-${row.id}`} draggableId={`${tableId}-row-${row.id}`} index={index}>
                      {(provided, snapshot) => {
                        return (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={snapshot.isDragging ? "isDragging" : ""}
                          >
                            {row.getVisibleCells().map(cell => (
                              <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                            ))}
                            <td className="tableCta">
                              {row.original?.unDraggable ? (
                                <div
                                  className="btn"
                                  data-variant="icon"
                                  data-color="purple"
                                  style={{ visibility: "hidden" }}
                                >
                                  <IcnHandle />
                                </div>
                              ) : (
                                <button
                                  className="btn"
                                  data-variant="icon"
                                  data-color="purple"
                                  title={i18("Drag row", "dashboard.dndtable.dragRow")}
                                  {...provided.dragHandleProps}
                                >
                                  <IcnHandle />
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      }}
                    </Draggable>
                  ))}

                  {provided.placeholder}
                </tbody>
              </table>
            )}
          </Droppable>
        </DragDropContext>
      </section>

      {table.getPageCount() > 1 && (
        <section className="dashboardGridItemsSectionTablePagination">
          <Button
            extraClassName="btnTablePagination previous"
            title={i18("Previous page", "global.pagination.previousPage")}
            btnVariant="icon"
            btnColor="dark-medium"
            icon={<ChevronLeft />}
            onClick={table.previousPage}
            disabled={!table.getCanPreviousPage()}
          />

          <ul className="tablePaginationBulletBtnList">{paginationBulletsButtons.map(bullet => bullet)}</ul>

          <Button
            extraClassName="btnTablePagination next"
            title={i18("Next page", "global.pagination.nextPage")}
            btnVariant="icon"
            btnColor="dark-medium"
            icon={<ChevronRight />}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          />
        </section>
      )}
    </>
  );
}

export default DashboardDndPaginationTable;
