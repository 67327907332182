import { useEffect, useState } from "react";
import clsx from "clsx";

import { isEqual } from "lodash";
import ReactSlider from "react-slider";
import BigNumber from "bignumber.js";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import {
  selectAssets,
  selectContracts,
  selectGRVT8Balance,
  selectIsWalletLoading,
  selectUser,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import { loadState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";
import { executeGRVT8BurnAction, updateGRVT8Balance } from "@axvdex/state/wallet/walletThunks";
import { DummyGravitateInfo } from "@axvdex/mocks/DummyData";
import useLanguage from "@axvdex/hooks/useLanguage";
import { IContract } from "@axvdex/utils/interfaces";
import { IWalletInfo } from "@axvdex/state/wallet/initialState";
import styles from "../styles/DashboardMarket.module.scss";
import DragHandle from "./common/DragHandle";
import CustomLoader from "./common/CustomLoader";
import CustomNumericInput from "./form-element/CustomNumericInput";
import Button from "./common/Button";
import AstrovaultTokenIcon from "./common/AstrovaultTokenIcon";
import { useMediaQuery } from "usehooks-ts";
import { responsiveBreakpoints } from "@axvdex/constants";
import { hasPermitStored } from "@axvdex/utils/localStorage";

function DashboardGravitate() {
  const { i18 } = useLanguage();
  const contracts = useAppSelector(selectContracts);
  const walletInfo = useAppSelector(selectWalletInfo, isEqual);
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);
  const [sliderValue, setSliderValue] = useState(100);
  const dispatch = useAppDispatch();
  const assets = useAppSelector(selectAssets);
  const user = useAppSelector(selectUser);
  const grvt8Balance = useAppSelector(selectGRVT8Balance);
  const [input, setInput] = useState(grvt8Balance);

  const AXV = Object.values(assets).find(asset => asset.symbol === "AXV");
  const cashback = contracts.cashback;
  const [isLoading, setIsLoading] = useState(false);
  const [showNotConnectedData, setshowNotConnectedData] = useState(false);
  const isLoadingWallet = useAppSelector(selectIsWalletLoading, isEqual);

  useEffect(() => {
    !isLoadingWallet && walletInfo.isConnected && getGRVT8Balance();
  }, [walletInfo.isConnected, isLoadingWallet, contracts]);

  useEffect(() => {
    if (!isLoadingWallet && !walletInfo.isConnected) {
      setIsLoading(false);
      setshowNotConnectedData(true);
    } else {
      setshowNotConnectedData(false);
      setIsLoading(true);
    }
  }, [walletInfo.isConnected, isLoadingWallet]);

  const handleBurnGRVT8 = async () => {
    setIsLoading(true);
    AXV &&
      walletInfo &&
      (await dispatch(
        executeGRVT8BurnAction({
          walletAddress: walletInfo.walletAddress,
          cashbackAddr: cashback?.address,
          axvAddr: AXV.address,
          amount: BigNumber(input).times(Math.pow(10, 6)).decimalPlaces(0).toString(10),
          i18,
        })
      ));
    await getGRVT8Balance();
    setIsLoading(false);
  };

  const getGRVT8Balance = async () => {
    if (contracts.cashback) {
      setIsLoading(true);
      const balance = await dispatch(
        updateGRVT8Balance({ grvt8Contract: contracts.cashback, walletAddress: walletInfo.walletAddress })
      );
      setInput(balance.payload as string);
      setSliderValue(100);
      setIsLoading(false);
    }
  };

  return (
    <section className={clsx(styles.dashboardGridItemMarket, "dashboardGridItemMarket")}>
      <div className="dashboardGridItemTitle">
        <h2>{i18("My Gravitate", "dashboard.gravitate.title")}</h2>
        <div
          style={
            showNotConnectedData ||
            (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) ||
            !user.isUserDetailsLoad
              ? { pointerEvents: "none", filter: "blur(0.3rem)" }
              : {}
          }
        >
          <DragHandle extraClassName="ghostDragHandle" />
        </div>
      </div>
      <section className="withGradientBorder" style={{ filter: !user.isUserDetailsLoad ? "blur(0.3rem)" : undefined }}>
        <div className="grvt8Container">
          {
            // TODO: ON MOBILE BREAKPOINT PASS THE BUTTON TO ANOTHER ROW
          }
          {
            <div className="row" style={{ display: !isMobileBreakpoint ? "flex" : undefined }}>
              <div className="column">
                <div style={{ justifyContent: "center", padding: "1em", paddingBottom: "1.6em" }}>
                  <span
                    className="tokenIcon"
                    title={i18("GRVT8", "dashboard.gravitate.icon.title")}
                    aria-label={i18("GRVT8", "dashboard.gravitate.icon.title")}
                  >
                    <img
                      src={
                        // eslint-disable-next-line @typescript-eslint/no-var-requires
                        require(`../assets/tokens/logo-grvt8.svg`).default
                      }
                      alt={i18("GRVT8 Balance", "dashboard.gravitate.icon.alt")}
                    />
                  </span>
                  <span className="tokenName" style={{ marginLeft: ".5em" }}>
                    {i18("GRVT8", "symbol.grvt8")}
                    <small style={{ color: "var(--warm-grey)" }}>
                      {" "}
                      (1:
                      {cashback && cashback.extraFields.reward_balance && cashback.extraFields.totalSupply && AXV
                        ? BigNumber(1)
                            .times(Math.pow(10, 6))
                            .times(cashback?.extraFields?.reward_balance ?? 0)
                            .div(cashback?.extraFields?.totalSupply !== "0" ? cashback?.extraFields?.totalSupply : 1)
                            .div(Math.pow(10, AXV?.decimals ?? 0))
                            .decimalPlaces(4)
                            .toFixed(4)
                        : ""}{" "}
                      AXV)
                    </small>
                  </span>
                </div>
                <div style={{ justifyContent: "center", textAlign: "left", padding: "1em", textAlignLast: "left" }}>
                  {isLoading ? (
                    <div style={{ margin: "auto", textAlign: "center" }}>
                      <CustomLoader size="xs" />
                    </div>
                  ) : (
                    <CustomNumericInput
                      extraClassName="tradeToTokenUsdFormGroup gradientText"
                      style={{ fontSize: "var(--px22)" }}
                      name="trade_to_usd"
                      labelText={i18("USD trade amount", "trade.form.toToken.input.label")}
                      hiddenLabel={true}
                      placeholder={"0"}
                      value={input}
                      disabled={true}
                    />
                  )}

                  {/* <CustomNumericInput
                extraClassName="burnGRVT8Input"
                name="deposit_to_usd"
                labelText={i18("Enter Amount", "dashboard.gravitate.input.label")}
                hiddenLabel={true}
                placeholder={"0"}
                value={input}
                disabled
              /> */}
                </div>
              </div>
              <div className="column">
                <div
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                    paddingTop: ".95em",
                    paddingBottom: ".95em",
                    textAlignLast: "center",
                  }}
                >
                  <ReactSlider
                    className="horizontalSlider"
                    thumbClassName="horizontalSliderThumb"
                    trackClassName="horizontalSliderTrack"
                    value={sliderValue}
                    onChange={value => {
                      setSliderValue(value);
                      setInput("" + (Number(grvt8Balance) * value) / 100);
                    }}
                    renderThumb={(props, state) => (
                      <div {...props}>
                        <span className="thumbNowValue">{state.valueNow}%</span>
                        <span className="thumbHandle" />
                      </div>
                    )}
                  />
                </div>

                <div style={{ justifyContent: "center", textAlign: "center", textAlignLast: "center" }}>
                  {" "}
                  <Button
                    extraClassName="grvt8BurnButton"
                    btnColor="dark-medium"
                    title={i18("Collect Rewards", "dashboard.gravitate.btn.label")}
                    icon={<AstrovaultTokenIcon />}
                    onClick={() => handleBurnGRVT8()}
                    isFullWidth={true}
                    disabled={
                      !cashback ||
                      cashback.extraFields.totalSupply === "0" ||
                      BigNumber(input)
                        .times(Math.pow(10, 6))
                        .times(cashback.extraFields.reward_balance)
                        .div(cashback.extraFields.totalSupply)
                        .eq(0)
                    }
                  >
                    <>
                      {i18(
                        `Claim ${
                          cashback && cashback.extraFields.reward_balance && cashback.extraFields.totalSupply && AXV
                            ? BigNumber(input)
                                .times(Math.pow(10, 6))
                                .times(cashback?.extraFields?.reward_balance ?? 0)
                                .div(
                                  cashback?.extraFields?.totalSupply !== "0" ? cashback?.extraFields?.totalSupply : 1
                                )
                                .div(Math.pow(10, AXV?.decimals ?? 0))
                                .decimalPlaces(4)
                                .toFixed(4)
                            : ""
                        }`,
                        "dashboard.gravitate.btn.text",
                        {
                          amount:
                            cashback && cashback.extraFields.reward_balance && cashback.extraFields.totalSupply && AXV
                              ? BigNumber(input)
                                  .times(Math.pow(10, 6))
                                  .times(cashback?.extraFields?.reward_balance ?? 0)
                                  .div(
                                    cashback?.extraFields?.totalSupply !== "0" ? cashback?.extraFields?.totalSupply : 1
                                  )
                                  .div(Math.pow(10, AXV?.decimals ?? 0))
                                  .decimalPlaces(4)
                                  .toFixed(4)
                              : "-",
                        }
                      )}
                    </>
                  </Button>
                </div>
              </div>
            </div>
          }
        </div>
      </section>
    </section>
  );
}

export default DashboardGravitate;
