import clsx from "clsx";
import styles from "../styles/Header.module.scss";
import HeaderNavItems from "./HeaderNavItems";

function Header() {
  return (
    <>
      <header className={clsx(styles.header, "header")}>
        <HeaderNavItems />
      </header>
    </>
  );
}

export default Header;
