import { useState, useCallback, useRef, useEffect } from "react";
import clsx from "clsx";
import { useMediaQuery, useOnClickOutside } from "usehooks-ts";
import { loadState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";
import { useAppSelector } from "@axvdex/state";
import { selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";

import useLanguage from "@axvdex/hooks/useLanguage";
import { responsiveBreakpoints } from "../constants";
import { useGlobalModalContext } from "../providers/GlobalModalProvider";
import styles from "../styles/MobileMenu.module.scss";
import Logo from "./common/Logo";
import NavItems from "./NavItems";
import ButtonMenu from "./common/ButtonMenu";
import UserWallet from "./user/UserWallet";
import FooterNavItems from "./FooterNavItems";

function MobileMenu() {
  const { i18 } = useLanguage();
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);
  const { modal } = useGlobalModalContext();
  const walletInfo = useAppSelector(selectWalletInfo);

  const mobileMenuRef = useRef(null);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const handleToggleMobileMenu = useCallback(() => {
    setOpenMobileMenu(openMobileMenu => !openMobileMenu);
  }, []);
  const handleCloseMobileMenu = useCallback(() => setOpenMobileMenu(false), []);

  useEffect(() => {
    if (isMobileBreakpoint) {
      document.body.classList.toggle("mobileMenuIsOpen", openMobileMenu);
    } else {
      document.body.classList.remove("mobileMenuIsOpen");
      setOpenMobileMenu(false);
    }
  }, [openMobileMenu, setOpenMobileMenu, isMobileBreakpoint]);

  useEffect(() => {
    if (modal.isOpen) {
      setOpenMobileMenu(false);
      document.body.classList.remove("mobileMenuIsOpen");
    }
  }, [modal, setOpenMobileMenu]);

  useOnClickOutside(mobileMenuRef, handleCloseMobileMenu);

  return (
    <section className={clsx(styles.sectionMobileMenu, "sectionMobileMenu")} ref={mobileMenuRef}>
      <ButtonMenu
        extraClassName={clsx(
          openMobileMenu && "isOpen",
          !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[
            process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress
          ] && "hasBadge"
        )}
        onClick={handleToggleMobileMenu}
        title={openMobileMenu ? i18("Close", "mobileMenu.btnMenu.close") : i18("Menu", "mobileMenu.btnMenu.open")}
      >
        {!loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)[
          process.env.REACT_APP_ARCHWAY_NETWORK + "_" + walletInfo.walletAddress
        ] && (
          <span className="badge red">
            <span className="badgeIcon">!</span>
          </span>
        )}
      </ButtonMenu>

      <aside className={clsx("mobileMenu", openMobileMenu && "menuIsOpen")}>
        <div className="mobileMenuWrapper">
          <div className="mobileMenuInner">
            <div className="mobileMenuHeader">
              <Logo />
              <UserWallet walletInfo={walletInfo} onClick={null} />
            </div>

            <div className="mobileMenuContent">
              <NavItems />
              <FooterNavItems />
            </div>
          </div>
        </div>
      </aside>
    </section>
  );
}

export default MobileMenu;
