import { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useMediaQuery, useOnClickOutside } from "usehooks-ts";
import FooterUtilitiesNavItems from "@axvdex/components/FooterUtilitiesNavItems";

import useLanguage from "@axvdex/hooks/useLanguage";
import { responsiveBreakpoints } from "../constants";
import styles from "../styles/FooterUtilitiesMenu.module.scss";
import ButtonMenu from "./common/ButtonMenu";

function FooterUtilitiesMenu() {
  const { i18 } = useLanguage();
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);
  const footerMenuRef = useRef(null);
  const [openFooterMenu, setOpenFooterMenu] = useState(false);
  const handleToggleFooterMenu = useCallback(() => {
    setOpenFooterMenu(openFooterMenu => !openFooterMenu);
  }, []);
  const handleCloseFooterMenu = useCallback(() => setOpenFooterMenu(false), []);

  useEffect(() => {
    if (isMobileBreakpoint) {
      setOpenFooterMenu(false);
    }
  }, [openFooterMenu, setOpenFooterMenu, isMobileBreakpoint]);

  useOnClickOutside(footerMenuRef, handleCloseFooterMenu);

  return (
    <section className={clsx(styles.sectionFooterUtilitiesMenu, "sectionFooterUtilitiesMenu")} ref={footerMenuRef}>
      <ButtonMenu
        extraClassName={clsx(openFooterMenu && "isOpen", "btnMenuFooter")}
        onClick={handleToggleFooterMenu}
        title={
          openFooterMenu
            ? i18("Close menu", "footerUtitilitesMenu.btnMenu.close")
            : i18("Open menu", "footerUtitilitesMenu.btnMenu.open")
        }
      >
        <span className="footerNavItemsListText">{i18("Menu", "footerUtitilitesMenu.btnMenu")}</span>
      </ButtonMenu>

      <aside className={clsx("footerUtilitiesMenu", openFooterMenu && "isOpen")}>
        <div className="footerUtilitiesMenuWrapper">
          <div className="footerUtilitiesMenuInner">
            <FooterUtilitiesNavItems />
          </div>
        </div>
      </aside>
    </section>
  );
}

export default FooterUtilitiesMenu;
