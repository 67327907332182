import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactNode } from "react";
import useLanguage from "@axvdex/hooks/useLanguage";

interface nestedSubSection {
  name: string;
  url?: string;
  suffix?: ReactNode;
}

interface Item {
  name: string;
  url?: string;
  suffix?: ReactNode;
  nestedSubSections?: nestedSubSection[];
}

interface HelpSubPageSidebarProps {
  summaryTitle?: string;
  extraClassName?: string;
  items?: Item[];
  hardBack?: boolean;
}

function HelpSubPageSidebar({ summaryTitle, extraClassName, items, hardBack }: HelpSubPageSidebarProps) {
  const { i18 } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <aside className={clsx("helpSubPageSidebar", extraClassName && extraClassName)}>
      <div className="helpSubPageSidebarWrapper">
        <div className="buttonContainer">
          <a
            className="btn"
            data-color="gradient"
            data-variant="link"
            onClick={() => {
              if (hardBack) {
                navigate(-1);
              } else {
                navigate("/help");
              }
            }}
          >
            <i className="feather icon-arrow-left" />
            {hardBack && location?.state?.from
              ? i18(`Back to ${location?.state?.from}`, "help.SubPageSidebar.back.link.text.backTo", {
                  from: location?.state?.from,
                })
              : i18("Back to Help", "help.SubPageSidebar.back.link.text.backToHelp")}
          </a>
        </div>

        <p className="textGrey">
          {i18(
            `${"undefined" === typeof summaryTitle ? "Content" : summaryTitle} Summary`,
            "help.SubPageSidebar.summaryTitle",
            { summaryTitle: "undefined" === typeof summaryTitle ? "Content" : summaryTitle }
          )}
        </p>
        {items && (
          <ul>
            {items.map((item: Item, idx) => (
              <li key={idx}>
                <a
                  href={item.url}
                  rel="noreferrer"
                  title={`Skip to section ${item.name}`}
                  className="btn"
                  data-variant="link"
                  data-size="sm"
                >
                  {item.name}
                  {item.suffix}
                </a>

                {item.nestedSubSections && (
                  <ul>
                    {item.nestedSubSections.map((subItem, subIdx) => (
                      <li key={subIdx}>
                        <a
                          href={subItem.url}
                          rel="noreferrer"
                          title={i18(`Skip to ${subItem.name}`, "help.SubPageSidebar.skipTo.link.title", {
                            name: subItem.name,
                          })}
                          className="btn"
                          data-variant="link"
                          data-size="sm"
                        >
                          {subItem.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </aside>
  );
}

export default HelpSubPageSidebar;
