import clsx from "clsx";

import { ReactComponent as IcnToken } from "../../assets/icons/icn-astrovault.svg";
import styles from "../../styles/AstrovaultTokenIcon.module.scss";

interface AstrovaultTokenIconProps {
  isGradientBorder?: boolean;
}

function AstrovaultTokenIcon({ isGradientBorder }: AstrovaultTokenIconProps) {
  return (
    <span className={clsx(styles.astrovaultTokenIcon, "astrovaultTokenIcon", isGradientBorder && "borderGradient")}>
      <IcnToken />
    </span>
  );
}

export default AstrovaultTokenIcon;
