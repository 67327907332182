import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BigNumber from "bignumber.js";
import { AlertTriangle } from "react-feather";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import {
  selectAssetBalances,
  selectAssets,
  selectChains,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import {
  executeIbcTransfer,
  getIbcDepositClientSigner,
  updateNativeBalance,
  updateTokenBalance,
} from "@axvdex/state/wallet/walletThunks";
import { getFeeGrantOptions } from "@axvdex/api/user";
import imgSanitize from "@axvdex/utils/imgSanitize";
import useLanguage from "@axvdex/hooks/useLanguage";
import rpcAliveCheck from "@axvdex/utils/rpcAliveCheck";
import { getTokenList } from "../forms/tradeHelpers";
import Button from "../common/Button";
import CustomSelect, { ListItemsProps } from "../form-element/CustomSelect";

export default function FeegrantContainer({ setFeeGrantAssetsToTrade }: any) {
  const { i18 } = useLanguage();
  const navigate = useNavigate();
  const walletInfo = useAppSelector(selectWalletInfo);
  const chains = useAppSelector(selectChains);
  const assets = useAppSelector(selectAssets);
  const assetBalances = useAppSelector(selectAssetBalances);
  const dispatch = useAppDispatch();
  const [feeGrantOptions, setFeeGrantOptions] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState<ListItemsProps>({
    label: "ATOM",
    value: "ATOM",
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    optionPrefix: <img src={require(`../../assets/tokens/logo-atom.svg`).default} alt={""} />,
  });
  const [selectedAssetState, setSelectedAssetState] = useState(null);
  const [ibcDepositDetails, setIbcDepositDetails] = useState(null);
  const [ibcDepositLoading, setIbcDepositLoading] = useState<boolean>(false);

  useEffect(() => {
    getFeeGrant();
  }, []);

  const getFeeGrant = async () => {
    const options = await getFeeGrantOptions();
    setFeeGrantOptions(options.data.feeGrants);
  };

  useEffect(() => {
    walletInfo.isConnected && Object.keys(assets).length > 0 && feeGrantOptions && getSelectedAssetBalance();
  }, [selectedAsset, walletInfo, feeGrantOptions]);

  const getIBCDepositDetails = async (selectedAsset, newSelectedAssetState) => {
    setIbcDepositDetails(null);
    const feeGrantOptionAsset = feeGrantOptions.assets.find(
      feeGrantAsset => feeGrantAsset.symbol === selectedAsset.value
    );
    const srcChain = chains.find(chain => chain.chainId === feeGrantOptionAsset.chainId);
    // on deposit the destination chain is always archway
    const destChain = chains.find(chain => chain.chainId === process.env.REACT_APP_ARCHWAY_NETWORK);

    const { acc_address, balance, client } = await getIbcDepositClientSigner(
      feeGrantOptionAsset.chainId,
      await rpcAliveCheck(srcChain),
      newSelectedAssetState.denom_trace.base_denom,
      srcChain
    );

    setIbcDepositDetails({
      srcChain,
      destChain,
      senderAddr: acc_address,
      senderBalance: balance,
      senderClient: client,
    });
  };

  const getSelectedAssetBalance = async () => {
    if (!assetBalances[process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM]) {
      dispatch(
        updateNativeBalance({
          denom: process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM,
          userAddress: walletInfo.walletAddress,
        })
      );
    }
    for (const id in assets) {
      if (assets[id].symbol === selectedAsset.value) {
        setSelectedAssetState(assets[id]);
        if (!assetBalances[id]) {
          if (!assets[id].isNative) {
            dispatch(updateTokenBalance({ tokenAddress: assets[id].address, userAddress: walletInfo.walletAddress }));
          } else {
            dispatch(updateNativeBalance({ denom: assets[id].denom, userAddress: walletInfo.walletAddress }));
          }
        }
        getIBCDepositDetails(selectedAsset, assets[id]);
        break;
      }
    }
  };

  useEffect(() => {
    if (
      feeGrantOptions &&
      assetBalances[process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM] === "0" &&
      feeGrantOptions.enabled
    )
      window.scrollTo({ top: 999999999999999, behavior: "smooth" });
  }, [feeGrantOptions]);

  if (
    !feeGrantOptions ||
    assetBalances[process.env.REACT_APP_ARCHWAY_MINIMAL_DENOM] !== "0" ||
    (feeGrantOptions && !feeGrantOptions.enabled)
  )
    return <></>;

  const feeGrantAsset = feeGrantOptions.assets.find(feeGrantAsset => feeGrantAsset.symbol === selectedAsset.value);

  const displayDeposit =
    selectedAssetState &&
    assetBalances[selectedAssetState.id] &&
    BigNumber(assetBalances[selectedAssetState.id]).lt(
      BigNumber(feeGrantAsset.tradeMinAmount || feeGrantAsset.minAmount)
    ) &&
    ibcDepositDetails;
  let depositAmount, depositSrcChain;
  if (displayDeposit) {
    depositAmount = BigNumber(feeGrantAsset.minAmount).div(Math.pow(10, selectedAssetState.decimals));
    depositSrcChain = ibcDepositDetails ? ibcDepositDetails.srcChain.displayName : "";
  }

  const displayTrade =
    selectedAssetState &&
    assetBalances[selectedAssetState.id] &&
    BigNumber(assetBalances[selectedAssetState.id]).gte(
      BigNumber(feeGrantAsset.tradeMinAmount || feeGrantAsset.minAmount)
    );
  let tradeAmount;
  if (displayTrade) {
    tradeAmount = BigNumber(feeGrantAsset.tradeMinAmount || feeGrantAsset.minAmount).div(
      Math.pow(10, selectedAssetState.decimals)
    );
  }

  const displayBalance =
    ibcDepositDetails && BigNumber(ibcDepositDetails.senderBalance).lt(feeGrantAsset.minAmount) && !ibcDepositLoading;
  let balanceAmount, balanceSrcChain;
  if (displayBalance) {
    balanceAmount = BigNumber(ibcDepositDetails.senderBalance).div(Math.pow(10, selectedAssetState.decimals));
    balanceSrcChain = ibcDepositDetails ? ibcDepositDetails.srcChain.displayName : "";
  }

  const handleSelectAsset = (newValue: ListItemsProps) => {
    setSelectedAsset(newValue);
  };

  return (
    <section className="feeGrantSection withGradientBorder">
      <div className="feeGrantSectionHeader">
        <p>{i18("Bootstrap your wallet using our fee grant!", "trade.feeGrant.header")}</p>

        <CustomSelect
          name="available_fee_grant_assets"
          items={feeGrantOptions.assets.map(feeGrantAsset => {
            return {
              label: feeGrantAsset.symbol,
              value: feeGrantAsset.symbol,
              optionPrefix: <img src={imgSanitize(feeGrantAsset.symbol)} alt={""} />,
            };
          })}
          value={selectedAsset}
          hiddenLabel={true}
          onChange={handleSelectAsset}
          labelText={i18("Select fee grant", "trade.feeGrant.selectAsset.label")}
          required
        />
      </div>

      <div className="feeGrantSectionActions">
        {displayDeposit && (
          <>
            <Button
              btnColor="gradient"
              text={
                ibcDepositLoading
                  ? i18("Executing...", "trade.feeGrant.executing")
                  : i18(
                      `Deposit ${depositAmount} ${selectedAsset.value} via IBC from ${depositSrcChain}`,
                      `trade.feeGrant.deposit`,
                      {
                        amount: depositAmount,
                        symbol: selectedAsset.value,
                        chain: depositSrcChain,
                      }
                    )
              }
              onClick={async () => {
                setIbcDepositLoading(true);
                await dispatch(
                  executeIbcTransfer({
                    sendingClient: ibcDepositDetails.senderClient,
                    sendingAddrInput: ibcDepositDetails.senderAddr,
                    // for deposit the destination is the wallet in archway
                    destUserAddress: walletInfo.walletAddress,
                    denomToSend: selectedAssetState.denom_trace.base_denom,
                    amount: feeGrantOptions.assets.find(feeGrantAsset => feeGrantAsset.symbol === selectedAsset.value)
                      .minAmount,
                    channel: ibcDepositDetails.srcChain.ibcRecvChannel,
                    fee: {
                      denom: ibcDepositDetails.srcChain.feeCurrencies[0].coinMinimalDenom,
                      amount: "5000",
                    },
                    // on deposit, we want to check if the packet is a timeout on the from chain
                    srcChain: {
                      chainId: ibcDepositDetails.srcChain.chainId,
                      restURL: ibcDepositDetails.srcChain.rest,
                      explorerURL: ibcDepositDetails.srcChain.explorerURL,
                      rpcURL: ibcDepositDetails.srcChain.rpc,
                      isEVM: ibcDepositDetails.srcChain.isEVM,
                    },
                    // on deposit, we want to check if the packet has arrived to archway
                    dstChain: {
                      chainId: ibcDepositDetails.dstChain.chainId,
                      restURL: ibcDepositDetails.destChain.rest,
                      explorerURL: ibcDepositDetails.destChain.explorerURL,
                      rpcURL: ibcDepositDetails.destChain.rpc,
                      isEVM: ibcDepositDetails.destChain.isEVM,
                    },
                    // on deposit, we need to only update the balance of the destination asset, with the denom of it on archway chain
                    assetBalancesToUpdate: {
                      userAddress: walletInfo.walletAddress,
                      tokens: [],
                      natives: [selectedAssetState.denom],
                    },
                    i18,
                  })
                );
                setIbcDepositLoading(false);
              }}
              disabled={
                ibcDepositLoading ||
                !ibcDepositDetails ||
                (ibcDepositDetails &&
                  BigNumber(ibcDepositDetails.senderBalance).lt(
                    feeGrantOptions.assets.find(feeGrantAsset => feeGrantAsset.symbol === selectedAsset.value).minAmount
                  ))
              }
            />

            {displayBalance && (
              <small className="feeGrantSectionActionsBalance">
                <span className="feeGrantSectionActionsIcon">
                  <AlertTriangle />
                </span>
                <span>
                  {i18(
                    `Balance: ${balanceAmount} ${selectedAsset.value} on ${balanceSrcChain}`,
                    "trade.feeGrant.balance",
                    {
                      amount: balanceAmount,
                      symbol: selectedAsset.value,
                      chain: balanceSrcChain,
                    }
                  )}
                </span>
              </small>
            )}
          </>
        )}
        {displayTrade && (
          <Button
            btnColor="gradient"
            text={i18(`Trade ${tradeAmount} ${selectedAsset.value} to ARCH`, "trade.feeGrant.tradeToArch", {
              amount: tradeAmount,
              symbol: selectedAsset.value,
            })}
            onClick={() => {
              const tradeTokenList = getTokenList(assets, assetBalances, [])[1];
              const fromAsset = tradeTokenList.find(tradeToken => tradeToken.value === selectedAsset.value);
              const toAsset = tradeTokenList.find(
                tradeToken => tradeToken.value === process.env.REACT_APP_ARCHWAY_DENOM
              );

              const feeGrantAsset = feeGrantOptions.assets.find(
                feeGrantAsset => feeGrantAsset.symbol === selectedAsset.value
              );
              setFeeGrantAssetsToTrade({
                fromAsset,
                toAsset,
                fromAmount: BigNumber(feeGrantAsset.tradeMinAmount || feeGrantAsset.minAmount)
                  .div(Math.pow(10, selectedAssetState.decimals))
                  .toString(10),
              });

              navigate(`/trade`);
            }}
          />
        )}
      </div>
    </section>
  );
}
