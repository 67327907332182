import clsx from "clsx";
import { Trans } from "react-i18next";
import useLanguage from "@axvdex/hooks/useLanguage";
import MainLayout from "../layouts/MainLayout";
import styles from "../styles/Legal.module.scss";

function LegalTOU() {
  const { i18 } = useLanguage();
  return (
    <MainLayout
      pageClass={clsx(styles.legal, "legal")}
      headTitle={i18("TERMS OF USE", "legal.tou.headTitle")}
      headDescription={i18("Terms of Use", "legal.tou.headDescription")}
    >
      <h1>{i18("TERMS OF USE", "legal.tou.termsOfUse")}</h1>

      <h3>{i18("General", "legal.tou.general.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.general.text.0">
          These terms and conditions (&quot;<strong>Terms</strong>&quot;) govern the use of the Site (defined below) and
          the Services (defined below). These Terms also include any guidelines, announcements, additional terms,
          policies, and disclaimers made available or issued by us from time to time. These Terms constitute a binding
          and enforceable legal contract between The Sustainable Liquidity Foundation and its affiliates (&quot;
          <strong>Astrovault</strong>&quot;, &quot;<strong>we</strong>&quot;, &quot;<strong>us</strong>&quot;) and you,
          an end user of the services (&quot;<strong>you</strong>&quot; or &quot;<strong>User</strong>&quot;) at
          https://astrovault.io/ (&quot;<strong>Services</strong>&quot;). By accessing, using or clicking on our website
          (and all related subdomains) or its mobile applications (&quot;<strong>Site</strong>&quot;) or accessing,
          using or attempting to use the Services, you agree that you have read, understood, and to are bound by these
          Terms and that you comply with the requirements listed herein. If you do not agree to all of these Terms or
          comply with the requirements herein, please do not access or use the Site or the Services. In addition, when
          using some features of the Services, you may be subject to specific additional terms and conditions applicable
          to those features.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.general.text.1">
          We may modify, suspend or discontinue the Site or the Services at any time and without notifying you. We may
          also change, update, add or remove provisions of these Terms from time to time. Any and all modifications or
          changes to these Terms will become effective upon publication on our Site or release to Users. Therefore, your
          continued use of our Services is deemed your acceptance of the modified Terms and rules. If you do not agree
          to any changes to these Terms, please do not access or use the Site or the Services. We note that these Terms
          between you and us do not enumerate or cover all rights and obligations of each party, and do not guarantee
          full alignment with needs arising from future development. Therefore, platform rules, guidelines and all other
          agreements entered into separately between you and us are deemed supplementary terms that are an integral part
          of these Terms and shall have the same legal effect. Your use of the Site or Services is deemed your
          acceptance of any supplementary terms too.
        </Trans>
      </p>

      <h3>{i18("Eligibility", "legal.tou.eligibility.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.eligibility.text.0">
          By registering using our Services, you represent and warrant that:
        </Trans>
        <ul>
          <li>
            <Trans i18nKey="legal.tou.eligibility.text.1">
              as an individual, legal person, or other organization, you have full legal capacity and authority to agree
              and bind yourself to these Terms;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.eligibility.text.2">
              you are at least 18 or are of legal age to form a binding contract under applicable laws;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.eligibility.text.3">
              your use of the Services is not prohibited by applicable law, and at all times compliant with applicable
              law, including but not limited to regulations on anti-money laundering, anti-corruption, and
              counter-terrorist financing (&quot;<strong>CTF</strong>&quot;);
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.eligibility.text.4">
              you have not been previously suspended or removed from using our Services;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.eligibility.text.5">
              if you act as an employee or agents of a legal entity, and enter into these Terms on their behalf, you
              represent and warrant that you have all the necessary rights and authorizations to bind such legal entity;
              and
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.eligibility.text.6">
              you are solely responsible for use of the Services and, if applicable, for all activities that occur on or
              through your user account.
            </Trans>
          </li>
        </ul>
      </p>

      <h3>{i18("Identity Verification", "legal.tou.identityVerification.header")}</h3>
      <p>
        [
        <Trans i18nKey="legal.tou.identityVerification.text.0">
          Given that Astrovault is a permissionless and fully decentralised platform for token sales, we have no role in
          enforcing anti-money laundering (&quot;<strong>AML</strong>&quot;) and know-your-customer checks (&quot;
          <strong>KYC</strong>&quot;) by default. We, however, understand the need of some token projects to require AML
          and KYC procedures on their token sale participants and we do provide AML and KYC tools for fundraising
          entities using Astrovault to enforce such procedures on their users, if the token projects choose to do so at
          their own discretion. The token projects will determine whether any User will satisfy the AML and KYC
          procedures in their absolute sole discretion.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.identityVerification.text.1">
          Please note that we cannot control, nor will we be liable or responsible for, the AML and KYC procedures to be
          conducted by the token projects, the safekeeping of any AML and KYC documentation or a breach of any AML and
          KYC laws, rules or regulations thereof, or any other act or omission pertaining to it and any token project’s
          compliance with applicable privacy laws or regulations. We advise you to read our privacy policy and the
          respective privacy policy of any relevant third party and use your best discretion.
        </Trans>
        ]
      </p>

      <p>
        <Trans i18nKey="legal.tou.identityVerification.text.2">
          We and our affiliates may, but are not obligated to, collect and verify information about you in order to keep
          appropriate record of our customers, protect us and the community from fraudulent users, and identify traces
          of money laundering, terrorist financing, fraud and other financial crimes, or for other lawful purposes.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.identityVerification.text.3">
          Notwithstanding the above minimum verification procedures, we may require you to provide or verify additional
          information before permitting you to use any Service. We may also suspend, restrict, block or terminate your
          access to any or all of the Services, if we reasonably suspect you of using our Services in connection with
          any prohibited use or business, or our Services is subject to any pending litigation, investigation, or
          government proceeding and/or we perceive a heightened risk of legal or regulatory non-compliance associated
          with your activity; or you take any action that we deem as circumventing our controls, including, but not
          limited to, abusing promotions which we may offer from time to time.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.identityVerification.text.4">
          In addition to providing such information, you agree to allow us to keep a record of any information that we
          have collected from you for a duration of five (5) years from the date you first access our Services. You also
          authorize us to share your submitted information and documentation to third parties to verify the authenticity
          of such information. We may also conduct necessary investigations directly or through a third party to verify
          your identity or protect you and/or us from financial crimes, such as fraud, and to take necessary action
          based on the results of such investigations. We will collect, use and share such information in accordance
          with our privacy policy.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.identityVerification.text.5">
          After registration, you must ensure that the information is true, complete, and timely updated when changed.
          If there are any grounds for believing that any of the information you provided is incorrect, false, outdated
          or incomplete, we reserve the right to send you a notice to demand correction, directly delete the relevant
          information, and as the case may be, terminate all or part of the Services we provide for you. You shall be
          fully liable for any loss or expense caused to us during your use of the Services. You hereby acknowledge and
          agree that you have the obligation to keep all the information accurate, update and correct at all times.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.identityVerification.text.6">
          We reserve the right to confiscate any and all funds that are found to be in violation of relevant and
          applicable AML or CFT laws and regulations, and to cooperate with the competent authorities when and if
          necessary.
        </Trans>
      </p>

      <h3>{i18("Restrictions", "legal.tou.restrictions.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.restrictions.text.0">
          You shall not use the Services in any manner except as expressly permitted in these Terms. Without limiting
          the generality of the preceding sentence, you may NOT:
        </Trans>
        <ul>
          <li>
            <Trans i18nKey="legal.tou.restrictions.text.1">
              use the Services in any dishonest or unlawful manner, for fraudulent or malicious activities, or in any
              manner inconsistent with these Terms;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.restrictions.text.2">violate applicable laws or regulations in any manner;</Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.restrictions.text.3">
              infringe any proprietary rights, including but not limited to copyrights, patents, trademarks, or trade
              secrets of Astrovault;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.restrictions.text.4">
              use the Services to transmit any data or send or upload any material that contains viruses, Trojan horses,
              worms, time-bombs, keystroke loggers, spyware, adware, or any other harmful programmes or computer code
              designed to adversely affect the operation of any computer software or hardware;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.restrictions.text.5">
              use any deep linking, web crawlers, bots, spiders or other automatic devices, programs, scripts,
              algorithms or methods, or any similar or equivalent manual processes to access, obtain, copy, monitor,
              replicate or bypass the Site or the Services;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.restrictions.text.6">
              make any back-up or archival copies of the Site or any part thereof, including disassembling or
              de-compilation of the Site;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.restrictions.text.7">
              violate public interests, public morals, or the legitimate interests of others, including any actions that
              would interfere with, disrupt, negatively affect, or prohibit other Users from using the Services;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.restrictions.text.8">
              use the Services for market manipulation (such as pump and dump schemes, wash trading, self-trading, front
              running, quote stuffing, and spoofing or layering, regardless of whether prohibited by law);
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.restrictions.text.9">
              attempt to access any part or function of the Site without authorization, or connect to the Site or
              Services or any Astrovault servers or any other systems or networks of any the Services provided through
              the services by hacking, password mining or any other unlawful or prohibited means;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.restrictions.text.10">
              probe, scan or test the vulnerabilities of the Site or Services or any network connected to the
              properties, or violate any security or authentication measures on the Site or Services or any network
              connected thereto;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.restrictions.text.11">
              reverse look-up, track or seek to track any information of any other Users or visitors of the Site or
              Services;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.restrictions.text.12">
              take any actions that imposes an unreasonable or disproportionately large load on the infrastructure of
              systems or networks of the Site or Services, or the infrastructure of any systems or networks connected to
              the Site or Services;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.restrictions.text.13">
              use any devices, software or routine programs to interfere with the normal operation of any transactions
              of the Site or Services, or any other person’s use of the Site or Services; or
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.restrictions.text.14">
              forge headers, impersonate, or otherwise manipulate identification, to disguise your identity or the
              origin of any messages or transmissions you send to Astrovault or the Site.
            </Trans>
          </li>
        </ul>
      </p>

      <p>
        <Trans i18nKey="legal.tou.restrictions.text.15">
          By accessing the Services, you agree that we have the right to investigate any violation of these Terms,
          unilaterally determine whether you have violated these Terms, and take actions under relevant regulations
          without your consent or prior notice.
        </Trans>
      </p>

      <h3>{i18("Astrovault Services, Staking and Outbid Sale", "legal.tou.services.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.services.text.0">
          Astrovault will be conducting the public sale of its own native token $AVX and other tokens launching on
          Archway through its gamified launchpad platform known as &quot;Outbid&quot; (&quot;<strong>Outbid</strong>
          &quot;), where microsales are conducted (&quot;<strong>Outbid Sale</strong>&quot;). The series of micro-sales
          will occur consequently with varying levels of pricing and vesting periods. There will be a maximum of 10
          Outbid Sale rounds and only whitelisted Users will be able to participate in each Outbid Sale. The first
          Outbid Sale round will be limited to liquidity providers on Astrovault and $JKL stakers. For each subsequent
          Outbid Sale round, it will also include any bidders within the previous rounds.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.services.text.1">
          The Outbid Sale will accept payments for purchasing $AXV tokens in stablecoins (USDC/IST) and $ATOM tokens.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.services.text.2">
          The first Outbid sale will start with the lowest $AXV token price and vesting period, and for each subsequent
          Outbid Sale the price per $AXV token will be increased and an incremental one month will apply to the vesting
          period for the prior Outbid Sale round&lsquo;s tokens. This process will repeat for each auction or Outbid
          Sale round. As each Outbid Sale round completes, the next round will commence immediately and all tokens from
          Outbid Sale rounds will have an additional vesting period of one month added to it.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.services.text.3">
          At the end of the last Outbid Sale, the remaining AXV tokens not purchased will be allocated to the last User
          who had purchased the $AXV Tokens. Once the last thirty (30) minutes of the last Outbid Sale have started, the
          duration will be extended by another thirty (30) minutes each time that a User buys tickets.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.services.text.4">
          Once the Outbid Sale is completed, the Users having purchased the first ten percent (10%) of $AXV Tokens will
          be rewarded with a bonus ten percent (10%) of rewards.
        </Trans>
      </p>

      <p style={{ padding: "1.5em 0" }}>
        <Trans i18nKey="legal.tou.services.text.5">
          Set out below is an illustrative example of a full public sale on Outbid.
        </Trans>
        <table className="table dashboardHistoryTable">
          <thead>
            <tr>
              <th>Auction 1 -&gt;</th>
              <th>Auction 2 -&gt;</th>
              <th>Auction 3 -&gt;</th>
              <th>Auction 4 -&gt;</th>
              <th>Auction 5 -&gt;</th>
              <th>...</th>
              <th>Auction 10</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>
                  <span className="textGrey">Vesting:</span> 9 Months
                </div>
                <span className="textGrey">Valuation:</span> $15.0m
              </td>
              <td>
                <div>
                  <span className="textGrey">Vesting:</span> 8 Months
                </div>
                <span className="textGrey">Valuation:</span> $16.2m
              </td>
              <td>
                <div>
                  <span className="textGrey">Vesting:</span> 7 Months
                </div>
                <span className="textGrey">Valuation:</span> $17.5m
              </td>
              <td>
                <div>
                  <span className="textGrey">Vesting:</span> 6 Months
                </div>
                <span className="textGrey">Valuation:</span> $18.9m
              </td>
              <td>
                <div>
                  <span className="textGrey">Vesting:</span> 5 Months
                </div>
                <span className="textGrey">Valuation:</span> $20.4m
              </td>
              <td>...</td>
              <td>
                <div>
                  <span className="textGrey">Vesting:</span> No vesting
                </div>
                <span className="textGrey">Valuation:</span> $30.0m
              </td>
            </tr>
            <tr>
              <td colSpan={7}>
                <span className="textGrey">Maximum number of auctions:</span> 10
              </td>
            </tr>
            <tr>
              <td colSpan={7}>
                <span className="textGrey">Pricing of auction:</span>{" "}
                <ul>
                  <li>&quot;Auction 1&quot; initiates with an FDV of $15.0m</li>
                  <li>&quot;Each auction applies an 8% premium on the pricing of the previous auction</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td colSpan={7}>
                <span className="textGrey">Duration of Vesting Period:</span>{" "}
                <ul>
                  <li>The final auction on the process will have immediate vesting</li>
                  <li>Tokens are locked 1 month for each successive filled offering round</li>
                </ul>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={7}>
                <span className="textGrey">Table 1:</span> An illustrative example of a full public sale on Outbid
              </td>
            </tr>
          </tfoot>
        </table>
      </p>

      <p>
        <Trans i18nKey="legal.tou.services.text.7">
          You acknowledge that we cannot promise or guarantee the certain amount of the reward receiving from staking
          your $AXV Tokens in order to engage in any fundraising project event. You shall conduct your own due diligence
          work in relation to each fundraising project and fully understand the significant information and conditions
          prior to participate such event such as the allocation date, the underlying technology, the features and
          functions of such project tokens.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.services.text.8">
          You further acknowledge and agree that we have sole discretion to reject your participation in staking, in the
          event that you are unable to fulfil your identity verification requirements or for any other suspicious
          activity detected while participating in our Services.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.services.text.10">
          We shall not be liable for any losses caused by timing differences associated with the actual delivery of any
          Initial DEX Offering (&quot;<strong>IDO</strong>&quot;) tokens. You acknowledge and agree that we reserve the
          right to amend any terms related to any specific staking protocol implemented by us at any time in our sole
          discretion. We shall not be liable for any losses due to your misunderstanding of the terms and conditions of
          our staking protocol terms and changes to such terms.
        </Trans>
      </p>

      <h3>{i18("Termination", "legal.tou.termination.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.termination.text.0">
          Astrovault may terminate, suspend, or modify your access to the Services, or any portion thereof, immediately
          and at any point, at its sole discretion. Astrovault will not be liable to you or to any third party for any
          termination, suspension, or modification of your access to the Services. Upon termination of your access to
          the Services, these Terms shall terminate, except for those clauses that expressly or are intended to survive
          termination or expiry.
        </Trans>
      </p>

      <h3>{i18("Disclaimers", "legal.tou.disclaimers.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.disclaimers.text.0">
          OUR SERVICES ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS WITHOUT ANY
          REPRESENTATION OR WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY. YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU
          HAVE NOT RELIED UPON ANY OTHER STATEMENT OR AGREEMENT, WHETHER WRITTEN OR ORAL, WITH RESPECT TO YOUR USE AND
          ACCESS OF THE SERVICES.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.disclaimers.text.1">
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF TITLE,
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT. ASTROVAULT DOES NOT MAKE ANY
          REPRESENTATIONS OR WARRANTIES THAT ACCESS TO THE SITE, ANY PART OF THE SERVICES, INCLUDING MOBILE SERVICES, OR
          ANY OF THE MATERIALS CONTAINED THEREIN, WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY, OR ERROR-FREE AND WILL NOT
          BE LIABLE FOR ANY LOSSES RELATING THERETO. ASTROVAULT DOES NOT REPRESENT OR WARRANT THAT THE SITE, THE
          SERVICES OR ANY MATERIALS OF ASTROVAULT ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, ERROR-FREE, OR FREE OF
          VIRUSES OR OTHER HARMFUL COMPONENTS.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.disclaimers.text.2">
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NONE OF ASTROVAULT OR ITS AFFILIATES AND THEIR RESPECTIVE
          SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR
          CONTRACTORS WILL BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, INTANGIBLE OR CONSEQUENTIAL LOSSES
          OR DAMAGES ARISING OUT OF OR RELATING TO:
        </Trans>
        <ul>
          <li>
            <Trans i18nKey="legal.tou.disclaimers.text.3">
              ANY PERFORMANCE OR NON-PERFORMANCE OF THE SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED
              BY OR ON BEHALF OF ASTROVAULT OR ITS AFFILIATES;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.disclaimers.text.4">
              ANY AUTHORIZED OR UNAUTHORIZED USE OF THE SITE OR SERVICES, OR IN CONNECTION WITH THIS AGREEMENT;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.disclaimers.text.5">
              ANY INACCURACY, DEFECT OR OMISSION OF ANY DATA OR INFORMATION ON THE SITE;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.disclaimers.text.6">
              ANY ERROR, DELAY OR INTERRUPTION IN THE TRANSMISSION OF SUCH DATA;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.disclaimers.text.7">
              ANY DAMAGES INCURRED BY ANY ACTIONS, OMISSIONS OR VIOLATIONS OF THESE TERMS BY ANY THIRD PARTIES; OR
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.disclaimers.text.8">
              ANY DAMAGE CAUSED BY ILLEGAL ACTIONS OF OTHER THIRD PARTIES OR ACTIONS WITHOUT AUTHORIZED BY ASTROVAULT.
            </Trans>
          </li>
        </ul>
      </p>

      <p>
        <Trans i18nKey="legal.tou.disclaimers.text.9">
          EVEN IF ASTROVAULT KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING THE
          FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE, EXCEPT TO THE EXTENT OF A FINAL JUDICIAL
          DETERMINATION THAT SUCH DAMAGES WERE A RESULT OF OUR GROSS NEGLIGENCE, ACTUAL FRAUD, WILLFUL MISCONDUCT OR
          INTENTIONAL VIOLATION OF LAW OR EXCEPT IN JURISDICTIONS THAT DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          INCIDENTAL OR CONSEQUENTIAL DAMAGES. THIS PROVISION WILL SURVIVE THE TERMINATION OF THESE TERMS.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.disclaimers.text.10">
          WE MAKE NO WARRANTY AS TO THE MERIT, LEGALITY OR JURIDICAL NATURE OF ANY TOKEN SOLD ON OUR PLATFORM (INCLUDING
          WHETHER OR NOT IT IS CONSIDERED A SECURITY OR FINANCIAL INSTRUMENT UNDER ANY APPLICABLE SECURITIES LAWS).
        </Trans>
      </p>

      <h3>{i18("Intellectual Property", "legal.tou.intellectualProperty.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.intellectualProperty.text.0">
          All present and future copyright, title, interests in and to the Services, registered and unregistered
          trademarks, design rights, unregistered designs, database rights and all other present and future intellectual
          property rights and rights in the nature of intellectual property rights that exist in or in relation to the
          use and access of the Site and the Services are owned by or otherwise licensed to Astrovault. Subject to your
          compliance with these Terms, we grant you a non-exclusive, non-sub license, and any limited license to merely
          use or access the Site and the Services in the permitted hereunder.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.intellectualProperty.text.1">
          Except as expressly stated in these Terms, nothing in these Terms should be construed as conferring any right
          in or license to our or any other third party’s intellectual rights.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.intellectualProperty.text.2">
          If and to the extent that any such intellectual property rights are vested in you by operation of law or
          otherwise, you agree to do any and all such acts and execute any and all such documents as we may reasonably
          request in order to assign such intellectual property rights back to us.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.intellectualProperty.text.3">
          You agree and acknowledge that all content on the Site must not be copied or reproduced, modified,
          redistributed, used, created for derivative works, or otherwise dealt with for any other reason without being
          granted a written consent from us.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.intellectualProperty.text.4">
          Third parties participating on the Site may permit us to utilise trademarks, copyrighted material, and other
          intellectual property associated with their businesses. We will not warrant or represent that the content of
          the Site does not infringe the rights of any third party.
        </Trans>
      </p>

      <h3>{i18("Independent Parties", "legal.tou.independentParties.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.independentParties.text.0">
          Astrovault is an independent contractor but not an agent of you in the performance of these Terms. These Terms
          shall not be interpreted as facts or evidence of an association, joint venture, partnership or franchise
          between the parties.
        </Trans>
      </p>

      <h3>{i18("Indemnification", "legal.tou.indemnification.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.indemnification.text.0">
          You agree to indemnify and hold harmless Astrovault and its affiliates and their respective shareholders,
          members, directors, officers, employees, attorneys, agents, representatives, suppliers or contractors from and
          against any potential or actual claims, actions, proceedings, investigations, demands, suits, costs, expenses
          and damages (including attorneys&rsquo; fees, fines or penalties imposed by any regulatory authority) arising
          out of or related to:
        </Trans>
        <ul>
          <li>
            <Trans i18nKey="legal.tou.indemnification.text.1">
              your use of, or conduct in connection with, the Site or Services;
            </Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.indemnification.text.2">your breach or our enforcement of these Terms; or</Trans>
          </li>
          <li>
            <Trans i18nKey="legal.tou.indemnification.text.3">
              your violation of any applicable law, regulation, or rights of any third party during your use of the Site
              or Services.
            </Trans>
          </li>
        </ul>
      </p>

      <p>
        <Trans i18nKey="legal.tou.indemnification.text.4">
          If you are obligated to indemnify Astrovault and its affiliates and their respective shareholders, members,
          directors, officers, employees, attorneys, agents, representatives, suppliers or contractors pursuant to these
          Terms, Astrovault will have the right, in its sole discretion, to control any action or proceeding and to
          determine whether Astrovault wishes to settle, and if so, on what terms.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.indemnification.text.5">
          Your obligations under this indemnification provision will continue even after these Terms have expired or
          been terminated.
        </Trans>
      </p>

      <h3>{i18("Confidentiality", "legal.tou.confidentiality.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.confidentiality.text.0">
          You acknowledge that the Services contain Astrovault and its affiliates’ trade secrets and confidential
          information. You agree to hold and maintain the Services in confidence, and not to furnish any other person
          any confidential information of the Services or the Site. You agree to use a reasonable degree of care to
          protect the confidentiality of the Services. You will not remove or alter any of Astrovault or its
          affiliates&rsquo; proprietary notices. Your obligations under this provision will continue even after these
          Terms have expired or been terminated.
        </Trans>
      </p>

      <h3>{i18("Anti-Money Laundering", "legal.tou.antiMoneyLaundering.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.antiMoneyLaundering.text.0">
          Astrovault expressly prohibits and rejects the use of the Site or the Services for any form of illicit
          activity, including money laundering, terrorist financing or trade sanctions violations. By using the Site or
          the Services, you represent that you are not involved in any such activity.
        </Trans>
      </p>

      <h3>{i18("Force Majeure", "legal.tou.forceMajeure.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.forceMajeure.text.0">
          Astrovault shall have no liability to you if it is prevented from or delayed in performing its obligations or
          from carrying on its Services and business, by acts, events, omissions or accidents beyond its reasonable
          control, including, without limitation, strikes, failure of a utility service or telecommunications network,
          act of God, war, riot, civil commotion, malicious damage, compliance with any law or governmental order, rule,
          regulation, or direction.
        </Trans>
      </p>

      <h3>{i18("Jurisdiction and Governing Law", "legal.tou.jurisdiction.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.jurisdiction.text.0">
          The parties shall attempt in good faith to mutually resolve any and all disputes, whether of law or fact, and
          of any nature whatsoever arising from or with respect to these Terms. These Terms and any dispute or claim
          arising out of or in connection with the Services or the Site shall be governed by, and construed in
          accordance with, the laws of the Republic of Panama.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.jurisdiction.text.2">
          Any Dispute arising out of or related to these Terms is personal to you and us and will be resolved solely
          through individual arbitration and will not be brought as a class arbitration, class action or any other type
          of representative proceeding. There will be no class arbitration or arbitration in which an individual
          attempts to resolve a dispute as a representative of another individual or group of individuals. Further, a
          dispute cannot be brought as a class or other type of representative action, whether within or outside of
          arbitration, or on behalf of any other individual or group of individuals.
        </Trans>
      </p>

      <h3>{i18("Severability", "legal.tou.severability.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.severability.text.0">
          If any provision of these Terms is determined by any court or other competent authority to be unlawful or
          unenforceable, the other provisions of these Terms will continue in effect.
        </Trans>
      </p>

      <p>
        <Trans i18nKey="legal.tou.severability.text.1">
          If any unlawful or unenforceable provision would be lawful or enforceable if part of it were deleted, that
          part will be deemed to be deleted, and the rest of the provision will continue in effect (unless that would
          contradict the clear intention of the clause, in which case the entirety of the relevant provision will be
          deemed to be deleted).
        </Trans>
      </p>

      <h3>{i18("Notices", "legal.tou.notices.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.notices.text.0">
          All notices, requests, demands, and determinations for us under these Terms (other than routine operational
          communications) shall be sent to eswood [at] astrovault [dot] io
        </Trans>
      </p>

      <h3>{i18("Assignment", "legal.tou.assignment.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.assignment.text.0">
          You may not assign or transfer any right to use the Services or any of your rights or obligations under these
          Terms without prior written consent from Astrovault, including any right or obligation related to the
          enforcement of laws or the change of control. Astrovault may assign or transfer any or all of its rights or
          obligations under these Terms, in whole or in part, without notice or obtaining your consent or approval.
        </Trans>
      </p>

      <h3>{i18("Third Party Rights", "legal.tou.thirdPartyRights.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.thirdPartyRights.text.0">
          No third party shall have any rights to enforce any terms contained herein.
        </Trans>
      </p>

      <h3>{i18("Third Party Website Disclaimer", "legal.tou.thirdPartyWebsiteDisclaimer.header")}</h3>
      <p>
        <Trans i18nKey="legal.tou.thirdPartyWebsiteDisclaimer.text.0">
          Any links to third party websites from our Services does not imply endorsement by us of any product, service,
          information or disclaimer presented therein, nor do we guarantee the accuracy of the information contained on
          them. If you suffer loss from using such third party product and service, we will not be liable for such loss.
          In addition, since we have no control over the terms of use or privacy policies of third-party websites, you
          should carefully read and understand those policies.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="legal.tou.thirdPartyWebsiteDisclaimer.text.1">
          BY MAKING USE OF OUR SERVICES, YOU ACKNOWLEDGE AND AGREE THAT: (A) YOU ARE AWARE OF THE RISKS ASSOCIATED WITH
          TRANSACTIONS OF ENCRYPTED OR DIGITAL TOKENS OR CRYPTOCURRENCIES WITH A CERTAIN VALUE THAT ARE BASED ON
          BLOCKCHAIN AND CRYPTOGRAPHY TECHNOLOGIES AND ARE ISSUED AND MANAGED IN A DECENTRALIZED FORM (“DIGITIAL
          CURRENCIES”); (B) YOU SHALL ASSUME ALL RISKS RELATED TO THE USE OF THE SERVICES AND TRANSACTIONS OF DIGITAL
          CURRENCIES; AND (C) ASTROVAULT SHALL NOT BE LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES. AS WITH ANY ASSET,
          THE VALUES OF DIGITAL CURRENCIES ARE VOLATILE AND MAY FLUCTUATE SIGNIFICANTLY AND THERE IS A SUBSTANTIAL RISK
          OF ECONOMIC LOSS WHEN PURCHASING, HOLDING OR INVESTING IN DIGITAL CURRENCIES.
        </Trans>
      </p>
    </MainLayout>
  );
}

export default LegalTOU;
