import { SigningStargateClient } from "@cosmjs/stargate";
import { fromBase64 } from "@cosmjs/encoding";
import { makeAuthInfoBytes, makeSignDoc } from "@cosmjs/proto-signing";
import { Int53 } from "@cosmjs/math";
import { Any } from "cosmjs-types/google/protobuf/any";
import { PubKey } from "cosmjs-types/cosmos/crypto/secp256k1/keys";
import { TxRaw } from "cosmjs-types/cosmos/tx/v1beta1/tx";
import { loadState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";

const signAndBroadcastEVM = async (
  client: SigningStargateClient,
  fee: {
    amount: { denom: string; amount: string }[];
    gas: string;
  },
  srcChain: {
    chainId: string;
    restURL: string;
  },
  signerAddress: string,
  messages: any,
  memo?: string
) => {
  const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
  let walletExtensionClient = window[walletExtension];

  if ("cosmostation" === walletExtension) {
    walletExtensionClient = window.cosmostation.providers.keplr;
  }

  const accountRes = await fetch(srcChain.restURL + "/cosmos/auth/v1beta1/accounts/" + signerAddress);
  const accountResJSON = await accountRes.json();
  const { account_number, sequence } = accountResJSON.account.base_account;
  const offlineSigner = await walletExtensionClient.getOfflineSignerAuto(srcChain.chainId);
  const accountFromSigner = (await offlineSigner.getAccounts()).find(account => account.address === signerAddress);

  if (!accountFromSigner) {
    throw new Error("Failed to retrieve account from signer");
  }
  const pubkeyBytes = accountFromSigner.pubkey;

  // Custom typeUrl for EVMOS
  const pubk = Any.fromPartial({
    typeUrl: "/ethermint.crypto.v1.ethsecp256k1.PubKey",
    value: PubKey.encode({
      key: pubkeyBytes,
    }).finish(),
  });

  const txBodyEncodeObject = {
    typeUrl: "/cosmos.tx.v1beta1.TxBody",
    value: {
      messages: messages,
      memo: memo ?? "",
    },
  };

  const txBodyBytes = client.registry.encode(txBodyEncodeObject);
  const gasLimit = Int53.fromString(fee.gas).toNumber();
  const authInfoBytes = makeAuthInfoBytes([{ pubkey: pubk, sequence }], fee.amount, gasLimit, "", "");
  const signDoc = makeSignDoc(txBodyBytes, authInfoBytes, srcChain.chainId, account_number);
  const { signature, signed } = await offlineSigner.signDirect(signerAddress, signDoc);

  return await client.broadcastTx(
    TxRaw.encode({
      bodyBytes: signed.bodyBytes,
      authInfoBytes: signed.authInfoBytes,
      signatures: [fromBase64(signature.signature)],
    }).finish()
  );
};

export default signAndBroadcastEVM;
