import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { CheckCircle, ExternalLink, Sliders } from "react-feather";
import { IAsset } from "@axvdex/utils/interfaces";
import useLanguage from "@axvdex/hooks/useLanguage";
import Button from "../common/Button";
import CustomModal from "../common/CustomModal";
import MyAssetsManageTokenWithdrawForm from "../forms/MyAssetsManageTokenWithdrawForm";
import MyAssetsManageTokenDepositForm from "../forms/MyAssetsManageTokenDepositForm";
import styles from "../../styles/MyAssetsManageTokensModal.module.scss";
import { TableAssetsProps } from "../DashboardMyAssets";
import MyAssetsManageTokenDerivativeForm from "../forms/MyAssetsManageTokenDerivativeForm";
import { selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { useAppSelector } from "@axvdex/state";

function MyAssetsManageTokensModal({ asset, isOnlyIcon }: { asset: TableAssetsProps | null; isOnlyIcon: boolean }) {
  const { i18 } = useLanguage();
  const walletInfo = useAppSelector(selectWalletInfo);
  const [selectedAsset, setSelectedAsset] = useState<IAsset>();

  useEffect(() => {
    setSelectedAsset(asset);
  }, []);

  const [manageTokenModalIsOpen, setManageTokenModalIsOpen] = useState(false);
  const handleToggleManageTokenModal = useCallback(() => {
    setManageTokenModalIsOpen(manageTokenModalIsOpen => !manageTokenModalIsOpen);
  }, []);
  const handleCloseManageTokenModal = () => setManageTokenModalIsOpen(false);

  // unless using a previous saved tab state, if the selected asset is a derivative, set active tab 0
  const [activeTab, setActiveTab] = useState(() => {
    // asset.isNative && !asset.isSourceDenom && !asset.xDerivativeTarget && !asset.isDerivative ? 1 : 0
    try {
      return !asset.isNative && asset.isDerivative ? 0 : 1;
    } catch {
      return 1; // Set default tab to Deposit IBC
    }
  });
  const [subActiveTab, setSubActiveTab] = useState(0);
  const handleTabClick = (index: number) => setActiveTab(index);
  const handleSubTabClick = (index: number) => {
    if (1 === index) {
      // when clicking on index 1 = Kado stuff, so we open its widget on new tab
      let kadoParams = "";
      if (1 === activeTab) {
        // Deposit Fiat
        kadoParams = `product=BUY&onPayAmount=100&productList=BUY,SELL&network=ARCHWAY&onRevCurrency=ARCH&offPayCurrency=NOBLE&networkList=ARCHWAY,NOBLE,COSMOS%20HUB&onToAddress=${walletInfo.walletAddress}`;
      } else if (2 === activeTab) {
        // Withdrawal Fiat
        kadoParams = `product=SELL&offPayAmount=100&productList=BUY,SELL&network=ARCHWAY&onRevCurrency=ARCH&offPayCurrency=NOBLE&networkList=ARCHWAY,NOBLE,COSMOS%20HUB&offFromAddress=${walletInfo.walletAddress}`;
      }
      if (0 < kadoParams.length) {
        window.open(
          "https://app.kado.money?apiKey=e60dd69a-7dea-4a88-8b45-13d388823ef5&theme=dark&" + kadoParams,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=480,height=620"
        );
      }
    }
  };
  const ManageTokensTabList = [];
  if (
    selectedAsset &&
    ((selectedAsset.isNative && !selectedAsset.isSourceDenom) ||
      selectedAsset.xDerivativeTarget ||
      selectedAsset.isDerivative)
  ) {
    // if the asset is a native one it can be transferred with IBC, so we add ibc actions
    // if the asset HAS a derivative it can be used to mint a derivative
    let mintOrUnmint = i18("Get xAsset", "dashboard.managetokens.mintDerivative.title");
    if (selectedAsset.isDerivative) {
      mintOrUnmint = i18("Unmint xAsset", "dashboard.managetokens.unmintDerivative.title");
    }
    ManageTokensTabList.push({
      name: mintOrUnmint,
      content: (
        <MyAssetsManageTokenDerivativeForm
          onCloseModal={handleCloseManageTokenModal}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
        />
      ),
    });
    ManageTokensTabList.push({
      name: i18("Deposit", "dashboard.managetokens.ibcDeposit.title"),
      content: <MyAssetsManageTokenDepositForm asset={asset} onCloseModal={handleCloseManageTokenModal} />,
    });
    ManageTokensTabList.push({
      name: i18("Withdraw", "dashboard.managetokens.ibcWithdraw.title"),
      content: <MyAssetsManageTokenWithdrawForm asset={asset} onCloseModal={handleCloseManageTokenModal} />,
    });
  }

  if (!selectedAsset) {
    ManageTokensTabList.push({
      name: i18(`Get xAsset`, "managetokens.derivative.ManageTokensTabList.GetxAsset.title"),
      content: (
        <MyAssetsManageTokenDerivativeForm
          onCloseModal={handleCloseManageTokenModal}
          selectedAsset={null}
          setSelectedAsset={setSelectedAsset}
        />
      ),
    });
    ManageTokensTabList.push({
      name: i18(`Deposit`, "managetokens.derivative.ManageTokensTabList.DepositfFomIBC.title"),
      content: <MyAssetsManageTokenDepositForm asset={null} onCloseModal={handleCloseManageTokenModal} />,
    });
    ManageTokensTabList.push({
      name: i18(`Withdraw`, "managetokens.derivative.ManageTokensTabList.WithdrawToIBC.title"),
      content: <MyAssetsManageTokenWithdrawForm asset={null} onCloseModal={handleCloseManageTokenModal} />,
    });
  }

  const unmintLength = asset?.unmints?.unmints?.length;
  const readyUnmints = asset?.unmints?.readyUnmints?.length;
  return (
    <>
      {isOnlyIcon ? (
        <span className="manageWithStatus" style={{ position: "relative" }}>
          <Button
            btnVariant="icon"
            btnColor="purple"
            icon={<Sliders />}
            title={i18("Manage tokens", "dashboard.managetokens.button.title")}
            onClick={handleToggleManageTokenModal}
          />
          {readyUnmints || unmintLength ? (
            <>
              {readyUnmints ? (
                <span
                  className="badge claim"
                  title={i18(`Claim ${readyUnmints} unmints`, "dashboard.managetokens.claim.title", {
                    readyUnmints: readyUnmints,
                  })}
                  aria-label={i18(`Claim ${readyUnmints} unmints`, "dashboard.managetokens.claim", {
                    readyUnmints: readyUnmints,
                  })}
                  onClick={handleToggleManageTokenModal}
                >
                  <CheckCircle />
                </span>
              ) : (
                <span
                  className="badge unmints"
                  title={i18(`${unmintLength} unminting`, "dashboard.managetokens.unmint.title", {
                    unmintLength: unmintLength,
                  })}
                  aria-label={i18(`View ${unmintLength} unminting`, "dashboard.managetokens.unmint", {
                    unmintLength: unmintLength,
                  })}
                  onClick={handleToggleManageTokenModal}
                >
                  <span className="badgeNumber">{unmintLength}</span>
                </span>
              )}
            </>
          ) : null}
        </span>
      ) : (
        <Button
          style={{ width: "100%" }}
          title={i18("Manage tokens", "dashboard.managetokens.button.title")}
          //btnVariant="icon"
          btnColor="gradient"
          icon={
            <div className="inlineFlexbox">
              <Sliders /> {i18("Manage deposits", "dashboard.managetokens.button.text")}
            </div>
          }
          onClick={handleToggleManageTokenModal}
        />
      )}

      <CustomModal
        isOpen={manageTokenModalIsOpen}
        onClose={handleCloseManageTokenModal}
        extraClassName={clsx(styles.myAssetsManageTokensModal, "myAssetsManageTokensModal")}
      >
        <section className="sectionModalHeader">
          <h2 className="h2">{i18("Manage your assets", "dashboard.managetokens.title")}</h2>

          <p className="textGrey">
            {/* {t("TEXT HERE.....")}
            <br />
            {t("TEXT HERE.....")} */}
          </p>
        </section>

        <section className="sectionModalTabs">
          <ul className="btnGroup navTabs" style={{ marginBottom: activeTab !== 0 ? ".25em" : undefined }}>
            {ManageTokensTabList.map((item, idx) => (
              <li key={idx}>
                <Button
                  text={item.name}
                  title={`${i18("Select", "dashboard.managetokens.select")} ${item.name}`}
                  extraClassName={clsx(idx === activeTab && "isActive")}
                  onClick={() => handleTabClick(idx)}
                />
              </li>
            ))}
          </ul>

          {activeTab !== 0 && ( // for Deposit and Withdrawal tabs we show a subsection
            <ul className="btnGroup navTabs" style={{ fontSize: ".85em" }}>
              {[{ name: "IBC" }, { name: "Fiat" }].map((item, idx) => (
                <li key={item.name + idx}>
                  <Button
                    text={item.name}
                    title={`${i18("Select", "dashboard.managetokens.select")} ${item.name}`}
                    extraClassName={clsx(idx === subActiveTab && "isActive")}
                    onClick={() => handleSubTabClick(idx)}
                    icon={idx === 1 ? <ExternalLink /> : undefined}
                  />
                </li>
              ))}
            </ul>
          )}

          <div className="tabContent">
            {ManageTokensTabList[activeTab] ? ManageTokensTabList[activeTab].content : <></>}
          </div>
        </section>
      </CustomModal>
    </>
  );
}

export default MyAssetsManageTokensModal;
