import useLanguage from "@axvdex/hooks/useLanguage";
import CustomToast from "../common/CustomToast";
import Button from "../common/Button";

interface ToastProps {
  openToast: boolean;
  isSuccess: boolean;
  isInfo?: boolean | false;
  msg: string;
  txLink: string | null;
  toastID: string;
}

function Toaster({ openToast, isSuccess, isInfo, msg, txLink, toastID }: ToastProps) {
  const { i18 } = useLanguage();

  return (
    <CustomToast
      key={msg + txLink + toastID}
      type={isInfo ? "info" : isSuccess ? "success" : "error"}
      containerId="txContainerId"
      toastId={msg + txLink + toastID}
      openToast={openToast}
      extraButton={
        <>
          {txLink && (
            <Button
              btnColor="gradient"
              text={i18("Link", "toast.tx.link")}
              onClick={() => {
                window.open(txLink, "_blank", "noopener,noreferrer");
              }}
            />
          )}
        </>
      }
    >
      <h1>{isInfo ? i18("Info", "toast.info.title") : i18("Transaction result", "toast.tx.title")}</h1>

      <p className="textGrey">{msg}</p>
    </CustomToast>
  );
}

export default Toaster;
