import { X } from "react-feather";
import clsx from "clsx";
import Modal, { Props } from "react-modal";
import useLanguage from "@axvdex/hooks/useLanguage";
import styles from "../../styles/CustomModal.module.scss";
import Button from "./Button";

interface CustomModalProps extends Props {
  isOpen: boolean;
  onClose: () => void;
  extraClassName?: string;
  modalSize?: "sm" | "md" | "lg" | "xl";
  modalType?: "right" | "left";
  closeButton?: boolean;
}

function CustomModal({
  isOpen,
  onClose,
  extraClassName,
  modalSize,
  modalType,
  children,
  closeButton = true,
  ...props
}: CustomModalProps) {
  const { i18 } = useLanguage();
  return (
    <Modal
      {...props}
      isOpen={isOpen}
      onRequestClose={onClose}
      closeTimeoutMS={100}
      parentSelector={() => document.body}
      appElement={document.getElementById("root") as HTMLElement}
      portalClassName={clsx(styles.customModal, "customModal", extraClassName, modalType && modalType)}
      overlayClassName="customModalOverlay"
      className={clsx("customModalContent", modalSize ? modalSize : "sm")}
    >
      <div className="customModalWrapper">
        {closeButton && (
          <Button
            extraClassName="btnModalClose"
            title={i18("Close", "customModal.btnClose.title")}
            btnVariant="icon"
            icon={<X />}
            onClick={onClose}
          />
        )}

        <div className="customModalBody">{children}</div>
      </div>
    </Modal>
  );
}

export default CustomModal;
