import { RegExpMatcher, englishDataset, englishRecommendedTransformers } from "obscenity";
import { INicknameInfo } from "./interfaces";

const manuallyBannedNames = new Set();

const matcher = new RegExpMatcher({
  ...englishDataset.build(),
  ...englishRecommendedTransformers,
});

const bannedNames = new Set<string>();

export default (nicknames: { [key: string]: INicknameInfo }): { [key: string]: INicknameInfo } => {
  const sanitizedNicknames = {};
  for (const [key, value] of Object.entries(nicknames)) {
    sanitizedNicknames[key] = {
      ...value,
      nickname: !isBannedNickname(value.nickname) ? value.nickname : "******",
    };
  }
  return sanitizedNicknames;
};

export const sanitizeNickname = (nickname: string, userObject: any) => {
  return isBannedNickname(nickname) ? "******" : noNicknamesCheck(nickname, userObject);
};

export const isBannedNickname = (text: string) => {
  if (matcher.hasMatch(text)) return true;
  if (bannedNames.has(text)) return true;
  if (bannedNames.has(text.toLowerCase())) return true;
  if (bannedNames.has(text.toUpperCase())) return true;
  if (manuallyBannedNames.has(text)) return true;

  return false;
};

// to add new banned names in run time, called from the websocket
export const addToBannedNames = async (text: string) => {
  textVariants(text).forEach(text => bannedNames.add(text));
};

// initial request list of banned nicknames
export const loadInitListOfBannedNames = async (listOfBanned: string[]) => {
  listOfBanned.forEach(text => textVariants(text).forEach(text => bannedNames.add(text)));
};

const textVariants = (text: string) => {
  return [
    text,
    text.toUpperCase(),
    text.toLowerCase(),
    text.split("").join("_").slice(0, 16),
    text.split("").join("-").slice(0, 16),
  ];
};

export const noNicknamesCheck = (nickname: string, userObject: any) =>
  userObject?.myGlobalSettings?.noDisplayNicknames ? "******" : nickname;
