import { useEffect } from "react";
import clsx from "clsx";
import { useGlobalModalContext } from "@axvdex/providers/GlobalModalProvider";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";

import styles from "../styles/MobileNav.module.scss";
import Logo from "./common/Logo";
import NavItems from "./NavItems";
import MobileMenu from "./MobileMenu";

function MobileNav() {
  const { showModal } = useGlobalModalContext();

  const { modal } = useGlobalModalContext();

  useEffect(() => {
    if (!loadState(WHITE_LIST_PERSISTED_STATE_KEYS.agreedCompliance) && !modal.isOpen)
      showModal("complianceModal", {
        connectWalletAfter: false,
      });
  }, [modal]);

  return (
    <nav className={clsx(styles.mobileNav, "mobileNav")}>
      <div className="mobileNavWrapper">
        <Logo hideMonogram={true} />
        <NavItems hideText={true} hideIndex={0} />
        <MobileMenu />
      </div>
    </nav>
  );
}

export default MobileNav;
