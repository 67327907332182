import clsx from "clsx";
import styles from "../styles/Footer.module.scss";
import FooterNavItems from "./FooterNavItems";

function Footer() {
  return (
    <>
      <footer className={clsx(styles.footer, "footer")}>
        <FooterNavItems />
      </footer>
    </>
  );
}

export default Footer;
