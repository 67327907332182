import { TableAssetsProps } from "@axvdex/components/DashboardMyAssets";

export const DummyUserInfo = {
  name: "Wallet",
  lastName: "Name",
  walletId: "0x32F5FA48465E92F19B4E5A1B7443FC197C2E868F",
  userRewardsAmount: 0,
};

export const DummyGravitateInfo = {
  balance: "123145.123123",
};

export const dashboardMyAssetsList: TableAssetsProps[] = [
  {
    id: "aconst",
    label: "CONST",
    symbol: "CONST",
    decimals: 18,
    isNative: true,
    usdConversion: 1223442,
    tokenAmount: 12345,
    isFavourite: true,
    row: 0,
    unDraggable: false,
    isDerivative: false,
    isPriceWatch: false,
    isHidden: false,
    extraFields: null,
    lockedAmount: null,
  },
  {
    id: "axl",
    label: "AXL",
    symbol: "AXL",
    decimals: 6,
    isNative: true,
    usdConversion: 32131,
    tokenAmount: 12,
    isFavourite: false,
    row: 1,
    unDraggable: false,
    isDerivative: false,
    isPriceWatch: false,
    isHidden: false,
    extraFields: null,
    lockedAmount: null,
  },
  {
    id: "atom",
    label: "ATOM",
    symbol: "ATOM",
    decimals: 6,
    isNative: true,
    usdConversion: 123123123,
    tokenAmount: 12341,
    isFavourite: false,
    row: 2,
    unDraggable: false,
    isDerivative: false,
    isPriceWatch: false,
    isHidden: false,
    extraFields: null,
    lockedAmount: null,
  },
  {
    id: "osmo",
    label: "OSMO",
    symbol: "OSMO",
    decimals: 6,
    isNative: true,
    usdConversion: 123123,
    tokenAmount: 333,
    isFavourite: false,
    row: 3,
    unDraggable: false,
    isDerivative: false,
    isPriceWatch: false,
    isHidden: false,
    extraFields: null,
    lockedAmount: null,
  },
  {
    id: "xatom",
    label: "xATOM",
    symbol: "xATOM",
    decimals: 6,
    isNative: true,
    usdConversion: 1231,
    tokenAmount: 13,
    isFavourite: true,
    row: 4,
    unDraggable: false,
    isDerivative: true,
    isPriceWatch: false,
    isHidden: false,
    extraFields: null,
    lockedAmount: null,
  },
  {
    id: "xconst",
    label: "xCONST",
    symbol: "xCONST",
    decimals: 18,
    isNative: true,
    usdConversion: 0,
    tokenAmount: 0,
    isFavourite: false,
    row: 5,
    unDraggable: false,
    isDerivative: true,
    isPriceWatch: false,
    isHidden: false,
    extraFields: null,
    lockedAmount: null,
  },
];

export const dashboardMyHistoryList = [
  {
    id: 0,
    selected: false,
    swapTokens:
      "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka_archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
    log: {
      block_height: 448586,
      contract_addr: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
      msg: {
        send: {
          contract: "archway15etxyy99x0smdnl96q66vjk584a0h3n0sh3vv2vxqnuxch76kq7qz3frac",
          amount: "100000000000000000",
          msg: "eyJzd2FwIjp7ImV4cGVjdGVkX3JldHVybiI6IjQzMjkifX0=",
        },
      },
      msg_type: "swap",
      raw_log: [
        {
          events: [
            {
              type: "execute",
              attributes: [
                {
                  key: "_contract_address",
                  value: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
                },
                {
                  key: "_contract_address",
                  value: "archway15etxyy99x0smdnl96q66vjk584a0h3n0sh3vv2vxqnuxch76kq7qz3frac",
                },
                {
                  key: "_contract_address",
                  value: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                },
                {
                  key: "_contract_address",
                  value: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                },
                {
                  key: "_contract_address",
                  value: "archway105ml67ymwlznz8twk2nyxu5xaqnsp2h24t50995ucp8ffu5wut7q6xsaef",
                },
                {
                  key: "_contract_address",
                  value: "archway1z3unpq9s5v2zp6djghwk359ya0upt6rzwpz8d48z38rxztazclfqlcxy9e",
                },
              ],
            },
            {
              type: "message",
              attributes: [
                {
                  key: "action",
                  value: "/cosmwasm.wasm.v1.MsgExecuteContract",
                },
                {
                  key: "module",
                  value: "wasm",
                },
                {
                  key: "sender",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
              ],
            },
            {
              type: "wasm",
              attributes: [
                {
                  key: "_contract_address",
                  value: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
                },
                {
                  key: "action",
                  value: "send",
                },
                {
                  key: "from",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
                {
                  key: "to",
                  value: "archway15etxyy99x0smdnl96q66vjk584a0h3n0sh3vv2vxqnuxch76kq7qz3frac",
                },
                {
                  key: "amount",
                  value: "100000000000000000",
                },
                {
                  key: "_contract_address",
                  value: "archway15etxyy99x0smdnl96q66vjk584a0h3n0sh3vv2vxqnuxch76kq7qz3frac",
                },
                {
                  key: "action",
                  value: "swap",
                },
                {
                  key: "ask_asset",
                  value: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                },
                {
                  key: "ask_pool",
                  value: "1895486",
                },
                {
                  key: "buybackburn_amount",
                  value: "8",
                },
                {
                  key: "commission_amount",
                  value: "0",
                },
                {
                  key: "offer_amount",
                  value: "100000000000000000",
                },
                {
                  key: "offer_asset",
                  value: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
                },
                {
                  key: "offer_pool",
                  value: "42733104763749439454",
                },
                {
                  key: "receiver",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
                {
                  key: "return_amount",
                  value: "4417",
                },
                {
                  key: "sender",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
                {
                  key: "spread_amount",
                  value: "10",
                },
                {
                  key: "_contract_address",
                  value: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                },
                {
                  key: "action",
                  value: "transfer",
                },
                {
                  key: "amount",
                  value: "4417",
                },
                {
                  key: "from",
                  value: "archway15etxyy99x0smdnl96q66vjk584a0h3n0sh3vv2vxqnuxch76kq7qz3frac",
                },
                {
                  key: "to",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
                {
                  key: "_contract_address",
                  value: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                },
                {
                  key: "action",
                  value: "transfer",
                },
                {
                  key: "amount",
                  value: "8",
                },
                {
                  key: "from",
                  value: "archway15etxyy99x0smdnl96q66vjk584a0h3n0sh3vv2vxqnuxch76kq7qz3frac",
                },
                {
                  key: "to",
                  value: "archway1uszrh8tu9t5a47gxsmn4wywr4q7fetw8dcdrq9e5av9d6xvxt3msuras40",
                },
                {
                  key: "_contract_address",
                  value: "archway105ml67ymwlznz8twk2nyxu5xaqnsp2h24t50995ucp8ffu5wut7q6xsaef",
                },
                {
                  key: "action",
                  value: "receive_swap_data",
                },
                {
                  key: "amount",
                  value: "25078",
                },
                {
                  key: "result",
                  value: "success",
                },
                {
                  key: "_contract_address",
                  value: "archway1z3unpq9s5v2zp6djghwk359ya0upt6rzwpz8d48z38rxztazclfqlcxy9e",
                },
                {
                  key: "action",
                  value: "mint",
                },
                {
                  key: "amount",
                  value: "25078",
                },
                {
                  key: "to",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
              ],
            },
          ],
        },
      ],
      timestamp: "2023-06-14T20:20:33.984Z",
    },
    timestamp: "2023-06-14T20:20:33.984Z",
    date: "14/06/2023",
    time: "21:20",
    fromToken: {
      address: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
      amount: "0.1",
      symbol: "xCONST",
    },
    toToken: {
      address: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
      amount: "0.004417",
      symbol: "xATOM",
    },
    draggable: false,
  },
  {
    id: 1,
    selected: true,
    swapTokens:
      "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka_ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
    log: {
      block_height: 445725,
      contract_addr: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
      msg: {
        receive: {
          sender: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
          amount: "100000000000000000",
          msg: "eyJob3BzIjpbeyJtaW50X3N0YWtpbmdfZGVyaXZhdGl2ZSI6eyJjb250cmFjdF9hZGRyIjoiYXJjaHdheTFlMmh0YWYwM2Z1OXk5ZXo0aGM4dWt5M3J6dm5hZ2g3d25qOHNuMmU3aGdzanF3d2M0eDZzNTV0d3RwIiwib2ZmZXJfYXNzZXQiOnsibmF0aXZlX3Rva2VuIjp7ImRlbm9tIjoiYWNvbnN0In19LCJhc2tfYXNzZXQiOnsidG9rZW4iOnsiY29udHJhY3RfYWRkciI6ImFyY2h3YXkxc2R6YWFzMDA2OG40MnhrOG5kbTY5NTlncHU2bjA5dGFqbWV1cTd2YWs4dDlxdDVqcnA2c2pqdG5rYSJ9fX19LHsic3RhbmRhcmRfaG9wX2luZm8iOnsib2ZmZXJfYXNzZXRfaW5mbyI6eyJ0b2tlbiI6eyJjb250cmFjdF9hZGRyIjoiYXJjaHdheTFzZHphYXMwMDY4bjQyeGs4bmRtNjk1OWdwdTZuMDl0YWptZXVxN3Zhazh0OXF0NWpycDZzamp0bmthIn19LCJhc2tfYXNzZXRfaW5mbyI6eyJ0b2tlbiI6eyJjb250cmFjdF9hZGRyIjoiYXJjaHdheTFrODA1ZDhwYWp3aGRhNTZndmFzNGNlcHZrZmNmazVma3c3Y3Bjbng2eGp0em10NDNxam1xNzZlbncwIn19fX0seyJzdGFibGVfaG9wX2luZm8iOnsiYXNzZXRfaW5mb3MiOlt7Im5hdGl2ZV90b2tlbiI6eyJkZW5vbSI6ImliYy8yNTQxODY0NkMwMTdEMzc3QURGMzIwMkZGMUU0MzU5MEQwREFFMzM0NkU1OTRFOEQ3ODE3NkExMzlBOTI4Rjg4In19LHsidG9rZW4iOnsiY29udHJhY3RfYWRkciI6ImFyY2h3YXkxazgwNWQ4cGFqd2hkYTU2Z3ZhczRjZXB2a2ZjZms1Zmt3N2NwY254NnhqdHptdDQzcWptcTc2ZW53MCJ9fV0sImZyb21fYXNzZXRfaW5kZXgiOjEsInRvX2Fzc2V0X2luZGV4IjowfX1dLCJtaW5pbXVtX3JlY2VpdmUiOiI0MjE0In0=",
        },
      },
      msg_type: "swap",
      raw_log: [
        {
          events: [
            {
              type: "coin_received",
              attributes: [
                {
                  key: "receiver",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "amount",
                  value: "100000000000000000aconst",
                },
                {
                  key: "amount",
                  value: "100000000000000000aconst",
                },
                {
                  key: "receiver",
                  value: "archway1e2htaf03fu9y9ez4hc8uky3rzvnagh7wnj8sn2e7hgsjqwwc4x6s55twtp",
                },
                {
                  key: "amount",
                  value: "903529664913447620aconst",
                },
                {
                  key: "receiver",
                  value: "archway1e2htaf03fu9y9ez4hc8uky3rzvnagh7wnj8sn2e7hgsjqwwc4x6s55twtp",
                },
                {
                  key: "amount",
                  value: "100000000000000000aconst",
                },
                {
                  key: "receiver",
                  value: "archway1fl48vsnmsdzcv85q5d2q4z5ajdha8yu3qs4tnq",
                },
                {
                  key: "amount",
                  value: "4435ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
                },
                {
                  key: "receiver",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "amount",
                  value: "2ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
                },
                {
                  key: "receiver",
                  value: "archway1vjt5hsgneptmdtxyadm440qdf6r5y56fmurf3k870qrvjk4pfgxqdpkea9",
                },
                {
                  key: "amount",
                  value: "4435ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
                },
                {
                  key: "receiver",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
              ],
            },
            {
              type: "coin_spent",
              attributes: [
                {
                  key: "spender",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
                {
                  key: "amount",
                  value: "100000000000000000aconst",
                },
                {
                  key: "amount",
                  value: "100000000000000000aconst",
                },
                {
                  key: "spender",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "amount",
                  value: "903529664913447620aconst",
                },
                {
                  key: "spender",
                  value: "archway1jv65s3grqf6v6jl3dp4t6c9t9rk99cd8j5ccrg",
                },
                {
                  key: "amount",
                  value: "100000000000000000aconst",
                },
                {
                  key: "spender",
                  value: "archway1e2htaf03fu9y9ez4hc8uky3rzvnagh7wnj8sn2e7hgsjqwwc4x6s55twtp",
                },
                {
                  key: "amount",
                  value: "4435ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
                },
                {
                  key: "spender",
                  value: "archway1z4ayqwrmhk62gf4se38sq2r0vxnfzqxk0qse4sr3gfe3xpc9xnnqnevc29",
                },
                {
                  key: "amount",
                  value: "2ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
                },
                {
                  key: "spender",
                  value: "archway1z4ayqwrmhk62gf4se38sq2r0vxnfzqxk0qse4sr3gfe3xpc9xnnqnevc29",
                },
                {
                  key: "amount",
                  value: "4435ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
                },
                {
                  key: "spender",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
              ],
            },
            {
              type: "delegate",
              attributes: [
                {
                  key: "amount",
                  value: "100000000000000000aconst",
                },
                {
                  key: "new_shares",
                  value: "100000000000000000.000000000000000000",
                },
                {
                  key: "validator",
                  value: "archwayvaloper1sk23ewl2kzfu9mfh3sdh6gpm9xkq56m7tjnl25",
                },
              ],
            },
            {
              type: "execute",
              attributes: [
                {
                  key: "_contract_address",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "_contract_address",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "_contract_address",
                  value: "archway1e2htaf03fu9y9ez4hc8uky3rzvnagh7wnj8sn2e7hgsjqwwc4x6s55twtp",
                },
                {
                  key: "_contract_address",
                  value: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
                },
                {
                  key: "_contract_address",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "_contract_address",
                  value: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
                },
                {
                  key: "_contract_address",
                  value: "archway15etxyy99x0smdnl96q66vjk584a0h3n0sh3vv2vxqnuxch76kq7qz3frac",
                },
                {
                  key: "_contract_address",
                  value: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                },
                {
                  key: "_contract_address",
                  value: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                },
                {
                  key: "_contract_address",
                  value: "archway105ml67ymwlznz8twk2nyxu5xaqnsp2h24t50995ucp8ffu5wut7q6xsaef",
                },
                {
                  key: "_contract_address",
                  value: "archway1z3unpq9s5v2zp6djghwk359ya0upt6rzwpz8d48z38rxztazclfqlcxy9e",
                },
                {
                  key: "_contract_address",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "_contract_address",
                  value: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                },
                {
                  key: "_contract_address",
                  value: "archway1z4ayqwrmhk62gf4se38sq2r0vxnfzqxk0qse4sr3gfe3xpc9xnnqnevc29",
                },
                {
                  key: "_contract_address",
                  value: "archway105ml67ymwlznz8twk2nyxu5xaqnsp2h24t50995ucp8ffu5wut7q6xsaef",
                },
                {
                  key: "_contract_address",
                  value: "archway1z3unpq9s5v2zp6djghwk359ya0upt6rzwpz8d48z38rxztazclfqlcxy9e",
                },
                {
                  key: "_contract_address",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
              ],
            },
            {
              type: "message",
              attributes: [
                {
                  key: "action",
                  value: "/cosmwasm.wasm.v1.MsgExecuteContract",
                },
                {
                  key: "module",
                  value: "wasm",
                },
                {
                  key: "sender",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
              ],
            },
            {
              type: "transfer",
              attributes: [
                {
                  key: "recipient",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "sender",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
                {
                  key: "amount",
                  value: "100000000000000000aconst",
                },
                {
                  key: "amount",
                  value: "100000000000000000aconst",
                },
                {
                  key: "recipient",
                  value: "archway1e2htaf03fu9y9ez4hc8uky3rzvnagh7wnj8sn2e7hgsjqwwc4x6s55twtp",
                },
                {
                  key: "sender",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "amount",
                  value: "903529664913447620aconst",
                },
                {
                  key: "recipient",
                  value: "archway1e2htaf03fu9y9ez4hc8uky3rzvnagh7wnj8sn2e7hgsjqwwc4x6s55twtp",
                },
                {
                  key: "sender",
                  value: "archway1jv65s3grqf6v6jl3dp4t6c9t9rk99cd8j5ccrg",
                },
                {
                  key: "amount",
                  value: "4435ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
                },
                {
                  key: "recipient",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "sender",
                  value: "archway1z4ayqwrmhk62gf4se38sq2r0vxnfzqxk0qse4sr3gfe3xpc9xnnqnevc29",
                },
                {
                  key: "amount",
                  value: "2ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
                },
                {
                  key: "recipient",
                  value: "archway1vjt5hsgneptmdtxyadm440qdf6r5y56fmurf3k870qrvjk4pfgxqdpkea9",
                },
                {
                  key: "sender",
                  value: "archway1z4ayqwrmhk62gf4se38sq2r0vxnfzqxk0qse4sr3gfe3xpc9xnnqnevc29",
                },
                {
                  key: "amount",
                  value: "4435ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
                },
                {
                  key: "recipient",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
                {
                  key: "sender",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
              ],
            },
            {
              type: "wasm",
              attributes: [
                {
                  key: "_contract_address",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "action",
                  value: "handle_route",
                },
                {
                  key: "_contract_address",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "action",
                  value: "execute_hop",
                },
                {
                  key: "_contract_address",
                  value: "archway1e2htaf03fu9y9ez4hc8uky3rzvnagh7wnj8sn2e7hgsjqwwc4x6s55twtp",
                },
                {
                  key: "action",
                  value: "mint_derivative",
                },
                {
                  key: "amount",
                  value: "100000000000000000",
                },
                {
                  key: "per_difference",
                  value: "10000",
                },
                {
                  key: "rewards",
                  value: "903529664913447620",
                },
                {
                  key: "validator",
                  value: "archwayvaloper1sk23ewl2kzfu9mfh3sdh6gpm9xkq56m7tjnl25",
                },
                {
                  key: "_contract_address",
                  value: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
                },
                {
                  key: "action",
                  value: "mint",
                },
                {
                  key: "amount",
                  value: "100000000000000000",
                },
                {
                  key: "to",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "_contract_address",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "action",
                  value: "execute_hop",
                },
                {
                  key: "_contract_address",
                  value: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
                },
                {
                  key: "action",
                  value: "send",
                },
                {
                  key: "amount",
                  value: "100000000000000000",
                },
                {
                  key: "from",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "to",
                  value: "archway15etxyy99x0smdnl96q66vjk584a0h3n0sh3vv2vxqnuxch76kq7qz3frac",
                },
                {
                  key: "_contract_address",
                  value: "archway15etxyy99x0smdnl96q66vjk584a0h3n0sh3vv2vxqnuxch76kq7qz3frac",
                },
                {
                  key: "action",
                  value: "swap",
                },
                {
                  key: "ask_asset",
                  value: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                },
                {
                  key: "ask_pool",
                  value: "1899932",
                },
                {
                  key: "buybackburn_amount",
                  value: "8",
                },
                {
                  key: "commission_amount",
                  value: "0",
                },
                {
                  key: "offer_amount",
                  value: "100000000000000000",
                },
                {
                  key: "offer_asset",
                  value: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
                },
                {
                  key: "offer_pool",
                  value: "42633104763749439454",
                },
                {
                  key: "receiver",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "return_amount",
                  value: "4438",
                },
                {
                  key: "sender",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "spread_amount",
                  value: "10",
                },
                {
                  key: "_contract_address",
                  value: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                },
                {
                  key: "action",
                  value: "transfer",
                },
                {
                  key: "amount",
                  value: "4438",
                },
                {
                  key: "from",
                  value: "archway15etxyy99x0smdnl96q66vjk584a0h3n0sh3vv2vxqnuxch76kq7qz3frac",
                },
                {
                  key: "to",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "_contract_address",
                  value: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                },
                {
                  key: "action",
                  value: "transfer",
                },
                {
                  key: "amount",
                  value: "8",
                },
                {
                  key: "from",
                  value: "archway15etxyy99x0smdnl96q66vjk584a0h3n0sh3vv2vxqnuxch76kq7qz3frac",
                },
                {
                  key: "to",
                  value: "archway1uszrh8tu9t5a47gxsmn4wywr4q7fetw8dcdrq9e5av9d6xvxt3msuras40",
                },
                {
                  key: "_contract_address",
                  value: "archway105ml67ymwlznz8twk2nyxu5xaqnsp2h24t50995ucp8ffu5wut7q6xsaef",
                },
                {
                  key: "action",
                  value: "receive_swap_data",
                },
                {
                  key: "amount",
                  value: "24842",
                },
                {
                  key: "result",
                  value: "success",
                },
                {
                  key: "_contract_address",
                  value: "archway1z3unpq9s5v2zp6djghwk359ya0upt6rzwpz8d48z38rxztazclfqlcxy9e",
                },
                {
                  key: "action",
                  value: "mint",
                },
                {
                  key: "amount",
                  value: "24842",
                },
                {
                  key: "to",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "_contract_address",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "action",
                  value: "execute_hop",
                },
                {
                  key: "_contract_address",
                  value: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                },
                {
                  key: "action",
                  value: "send",
                },
                {
                  key: "amount",
                  value: "4438",
                },
                {
                  key: "from",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "to",
                  value: "archway1z4ayqwrmhk62gf4se38sq2r0vxnfzqxk0qse4sr3gfe3xpc9xnnqnevc29",
                },
                {
                  key: "_contract_address",
                  value: "archway1z4ayqwrmhk62gf4se38sq2r0vxnfzqxk0qse4sr3gfe3xpc9xnnqnevc29",
                },
                {
                  key: "action",
                  value: "swap",
                },
                {
                  key: "assets_fee_amount",
                  value: '["2", "0"]',
                },
                {
                  key: "from_acc_addr",
                  value: '"archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc"',
                },
                {
                  key: "from_assets_amount",
                  value: '["0", "4438"]',
                },
                {
                  key: "to_acc_addr",
                  value: '"archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc"',
                },
                {
                  key: "to_assets_amount",
                  value: '["4435", "0"]',
                },
                {
                  key: "_contract_address",
                  value: "archway105ml67ymwlznz8twk2nyxu5xaqnsp2h24t50995ucp8ffu5wut7q6xsaef",
                },
                {
                  key: "action",
                  value: "receive_swap_data",
                },
                {
                  key: "amount",
                  value: "38477",
                },
                {
                  key: "result",
                  value: "success",
                },
                {
                  key: "_contract_address",
                  value: "archway1z3unpq9s5v2zp6djghwk359ya0upt6rzwpz8d48z38rxztazclfqlcxy9e",
                },
                {
                  key: "action",
                  value: "mint",
                },
                {
                  key: "amount",
                  value: "38477",
                },
                {
                  key: "to",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "_contract_address",
                  value: "archway1cmcz4nc5gcnnpgacv7hp3tuum8t5x30hl4uaqyuw4vp67kf586cs8rl8cc",
                },
                {
                  key: "action",
                  value: "finalize_route",
                },
              ],
            },
            {
              type: "withdraw_rewards",
              attributes: [
                {
                  key: "amount",
                  value: "903529664913447620aconst",
                },
                {
                  key: "validator",
                  value: "archwayvaloper1sk23ewl2kzfu9mfh3sdh6gpm9xkq56m7tjnl25",
                },
              ],
            },
          ],
        },
      ],
      timestamp: "2023-06-14T15:47:18.089Z",
    },
    timestamp: "2023-06-14T15:47:18.089Z",
    date: "14/06/2023",
    time: "16:47",
    fromToken: {
      address: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
      amount: "0.1",
      symbol: "xCONST",
    },
    toToken: {
      address: "ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
      amount: "0.004435",
      symbol: "ATOM",
    },
    draggable: false,
  },
  {
    id: 2,
    selected: false,
    swapTokens:
      "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka_archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
    log: {
      block_height: 442814,
      contract_addr: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
      msg: {
        send: {
          contract: "archway17m2xzahlzgks7defurvectvs952dy5pmc7z4d3t8cpu6nnefp05qfewwgy",
          amount: "100000000000000000",
          msg: "eyJzd2FwIjp7ImV4cGVjdGVkX3JldHVybiI6IjQ5OTU0In19",
        },
      },
      msg_type: "swap",
      raw_log: [
        {
          events: [
            {
              type: "execute",
              attributes: [
                {
                  key: "_contract_address",
                  value: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
                },
                {
                  key: "_contract_address",
                  value: "archway17m2xzahlzgks7defurvectvs952dy5pmc7z4d3t8cpu6nnefp05qfewwgy",
                },
                {
                  key: "_contract_address",
                  value: "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
                },
                {
                  key: "_contract_address",
                  value: "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
                },
                {
                  key: "_contract_address",
                  value: "archway105ml67ymwlznz8twk2nyxu5xaqnsp2h24t50995ucp8ffu5wut7q6xsaef",
                },
                {
                  key: "_contract_address",
                  value: "archway1z3unpq9s5v2zp6djghwk359ya0upt6rzwpz8d48z38rxztazclfqlcxy9e",
                },
              ],
            },
            {
              type: "message",
              attributes: [
                {
                  key: "action",
                  value: "/cosmwasm.wasm.v1.MsgExecuteContract",
                },
                {
                  key: "module",
                  value: "wasm",
                },
                {
                  key: "sender",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
              ],
            },
            {
              type: "wasm",
              attributes: [
                {
                  key: "_contract_address",
                  value: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
                },
                {
                  key: "action",
                  value: "send",
                },
                {
                  key: "from",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
                {
                  key: "to",
                  value: "archway17m2xzahlzgks7defurvectvs952dy5pmc7z4d3t8cpu6nnefp05qfewwgy",
                },
                {
                  key: "amount",
                  value: "100000000000000000",
                },
                {
                  key: "_contract_address",
                  value: "archway17m2xzahlzgks7defurvectvs952dy5pmc7z4d3t8cpu6nnefp05qfewwgy",
                },
                {
                  key: "action",
                  value: "swap",
                },
                {
                  key: "ask_asset",
                  value: "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
                },
                {
                  key: "ask_pool",
                  value: "8061278",
                },
                {
                  key: "buybackburn_amount",
                  value: "102",
                },
                {
                  key: "commission_amount",
                  value: "0",
                },
                {
                  key: "offer_amount",
                  value: "100000000000000000",
                },
                {
                  key: "offer_asset",
                  value: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
                },
                {
                  key: "offer_pool",
                  value: "15683162929426872341",
                },
                {
                  key: "receiver",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
                {
                  key: "return_amount",
                  value: "50973",
                },
                {
                  key: "sender",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
                {
                  key: "spread_amount",
                  value: "325",
                },
                {
                  key: "_contract_address",
                  value: "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
                },
                {
                  key: "action",
                  value: "transfer",
                },
                {
                  key: "amount",
                  value: "50973",
                },
                {
                  key: "from",
                  value: "archway17m2xzahlzgks7defurvectvs952dy5pmc7z4d3t8cpu6nnefp05qfewwgy",
                },
                {
                  key: "to",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
                {
                  key: "_contract_address",
                  value: "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
                },
                {
                  key: "action",
                  value: "transfer",
                },
                {
                  key: "amount",
                  value: "102",
                },
                {
                  key: "from",
                  value: "archway17m2xzahlzgks7defurvectvs952dy5pmc7z4d3t8cpu6nnefp05qfewwgy",
                },
                {
                  key: "to",
                  value: "archway1uszrh8tu9t5a47gxsmn4wywr4q7fetw8dcdrq9e5av9d6xvxt3msuras40",
                },
                {
                  key: "_contract_address",
                  value: "archway105ml67ymwlznz8twk2nyxu5xaqnsp2h24t50995ucp8ffu5wut7q6xsaef",
                },
                {
                  key: "action",
                  value: "receive_swap_data",
                },
                {
                  key: "amount",
                  value: "24842",
                },
                {
                  key: "result",
                  value: "success",
                },
                {
                  key: "_contract_address",
                  value: "archway1z3unpq9s5v2zp6djghwk359ya0upt6rzwpz8d48z38rxztazclfqlcxy9e",
                },
                {
                  key: "action",
                  value: "mint",
                },
                {
                  key: "amount",
                  value: "24842",
                },
                {
                  key: "to",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
              ],
            },
          ],
        },
      ],
      timestamp: "2023-06-14T11:09:11.595Z",
    },
    timestamp: "2023-06-14T11:09:11.595Z",
    date: "14/06/2023",
    time: "12:09",
    fromToken: {
      address: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
      amount: "0.1",
      symbol: "xCONST",
    },
    toToken: {
      address: "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
      amount: "0.050973",
      symbol: "xAXL",
    },
    draggable: false,
  },
  {
    id: 3,
    selected: false,
    swapTokens:
      "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0_ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
    log: {
      block_height: 442162,
      contract_addr: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
      msg: {
        send: {
          contract: "archway1z4ayqwrmhk62gf4se38sq2r0vxnfzqxk0qse4sr3gfe3xpc9xnnqnevc29",
          amount: "1000",
          msg: "eyJzd2FwIjp7InN3YXBfdG9fYXNzZXRfaW5kZXgiOjAsImV4cGVjdGVkX3JldHVybiI6Ijk3OSJ9fQ==",
        },
      },
      msg_type: "swap",
      raw_log: [
        {
          events: [
            {
              type: "coin_received",
              attributes: [
                {
                  key: "amount",
                  value: "999ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
                },
                {
                  key: "receiver",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
              ],
            },
            {
              type: "coin_spent",
              attributes: [
                {
                  key: "amount",
                  value: "999ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
                },
                {
                  key: "spender",
                  value: "archway1z4ayqwrmhk62gf4se38sq2r0vxnfzqxk0qse4sr3gfe3xpc9xnnqnevc29",
                },
              ],
            },
            {
              type: "execute",
              attributes: [
                {
                  key: "_contract_address",
                  value: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                },
                {
                  key: "_contract_address",
                  value: "archway1z4ayqwrmhk62gf4se38sq2r0vxnfzqxk0qse4sr3gfe3xpc9xnnqnevc29",
                },
                {
                  key: "_contract_address",
                  value: "archway105ml67ymwlznz8twk2nyxu5xaqnsp2h24t50995ucp8ffu5wut7q6xsaef",
                },
                {
                  key: "_contract_address",
                  value: "archway1z3unpq9s5v2zp6djghwk359ya0upt6rzwpz8d48z38rxztazclfqlcxy9e",
                },
              ],
            },
            {
              type: "message",
              attributes: [
                {
                  key: "action",
                  value: "/cosmwasm.wasm.v1.MsgExecuteContract",
                },
                {
                  key: "module",
                  value: "wasm",
                },
                {
                  key: "sender",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
              ],
            },
            {
              type: "transfer",
              attributes: [
                {
                  key: "amount",
                  value: "999ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
                },
                {
                  key: "recipient",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
                {
                  key: "sender",
                  value: "archway1z4ayqwrmhk62gf4se38sq2r0vxnfzqxk0qse4sr3gfe3xpc9xnnqnevc29",
                },
              ],
            },
            {
              type: "wasm",
              attributes: [
                {
                  key: "_contract_address",
                  value: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
                },
                {
                  key: "action",
                  value: "send",
                },
                {
                  key: "from",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
                {
                  key: "to",
                  value: "archway1z4ayqwrmhk62gf4se38sq2r0vxnfzqxk0qse4sr3gfe3xpc9xnnqnevc29",
                },
                {
                  key: "amount",
                  value: "1000",
                },
                {
                  key: "_contract_address",
                  value: "archway1z4ayqwrmhk62gf4se38sq2r0vxnfzqxk0qse4sr3gfe3xpc9xnnqnevc29",
                },
                {
                  key: "action",
                  value: "swap",
                },
                {
                  key: "assets_fee_amount",
                  value: '["0", "0"]',
                },
                {
                  key: "from_acc_addr",
                  value: '"archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz"',
                },
                {
                  key: "from_assets_amount",
                  value: '["0", "1000"]',
                },
                {
                  key: "to_acc_addr",
                  value: '"archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz"',
                },
                {
                  key: "to_assets_amount",
                  value: '["999", "0"]',
                },
                {
                  key: "_contract_address",
                  value: "archway105ml67ymwlznz8twk2nyxu5xaqnsp2h24t50995ucp8ffu5wut7q6xsaef",
                },
                {
                  key: "action",
                  value: "receive_swap_data",
                },
                {
                  key: "amount",
                  value: "8670",
                },
                {
                  key: "result",
                  value: "success",
                },
                {
                  key: "_contract_address",
                  value: "archway1z3unpq9s5v2zp6djghwk359ya0upt6rzwpz8d48z38rxztazclfqlcxy9e",
                },
                {
                  key: "action",
                  value: "mint",
                },
                {
                  key: "amount",
                  value: "8670",
                },
                {
                  key: "to",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
              ],
            },
          ],
        },
      ],
      timestamp: "2023-06-14T10:06:51.094Z",
    },
    timestamp: "2023-06-14T10:06:51.094Z",
    date: "14/06/2023",
    time: "11:06",
    fromToken: {
      address: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
      amount: "0.001",
      symbol: "xATOM",
    },
    toToken: {
      address: "ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
      amount: "0.000999",
      symbol: "ATOM",
    },
    draggable: false,
  },
  {
    id: 4,
    selected: true,
    swapTokens:
      "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu_ibc/85CE72EE820A66F0ABD5EE3907A34E243E4BE2D6CFAEB4C08DF85BD6C0784FA2",
    log: {
      block_height: 442151,
      contract_addr: "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
      msg: {
        send: {
          contract: "archway1c47kvyrcc6xzac5t97chz74thufarh05zvav2sgh63zv8fjev2zsgxeqwk",
          amount: "1000",
          msg: "eyJzd2FwIjp7InN3YXBfdG9fYXNzZXRfaW5kZXgiOjAsImV4cGVjdGVkX3JldHVybiI6Ijk3OSJ9fQ==",
        },
      },
      msg_type: "swap",
      raw_log: [
        {
          events: [
            {
              type: "coin_received",
              attributes: [
                {
                  key: "amount",
                  value: "999ibc/85CE72EE820A66F0ABD5EE3907A34E243E4BE2D6CFAEB4C08DF85BD6C0784FA2",
                },
                {
                  key: "receiver",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
              ],
            },
            {
              type: "coin_spent",
              attributes: [
                {
                  key: "amount",
                  value: "999ibc/85CE72EE820A66F0ABD5EE3907A34E243E4BE2D6CFAEB4C08DF85BD6C0784FA2",
                },
                {
                  key: "spender",
                  value: "archway1c47kvyrcc6xzac5t97chz74thufarh05zvav2sgh63zv8fjev2zsgxeqwk",
                },
              ],
            },
            {
              type: "execute",
              attributes: [
                {
                  key: "_contract_address",
                  value: "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
                },
                {
                  key: "_contract_address",
                  value: "archway1c47kvyrcc6xzac5t97chz74thufarh05zvav2sgh63zv8fjev2zsgxeqwk",
                },
                {
                  key: "_contract_address",
                  value: "archway105ml67ymwlznz8twk2nyxu5xaqnsp2h24t50995ucp8ffu5wut7q6xsaef",
                },
                {
                  key: "_contract_address",
                  value: "archway1z3unpq9s5v2zp6djghwk359ya0upt6rzwpz8d48z38rxztazclfqlcxy9e",
                },
              ],
            },
            {
              type: "message",
              attributes: [
                {
                  key: "action",
                  value: "/cosmwasm.wasm.v1.MsgExecuteContract",
                },
                {
                  key: "module",
                  value: "wasm",
                },
                {
                  key: "sender",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
              ],
            },
            {
              type: "transfer",
              attributes: [
                {
                  key: "amount",
                  value: "999ibc/85CE72EE820A66F0ABD5EE3907A34E243E4BE2D6CFAEB4C08DF85BD6C0784FA2",
                },
                {
                  key: "recipient",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
                {
                  key: "sender",
                  value: "archway1c47kvyrcc6xzac5t97chz74thufarh05zvav2sgh63zv8fjev2zsgxeqwk",
                },
              ],
            },
            {
              type: "wasm",
              attributes: [
                {
                  key: "_contract_address",
                  value: "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
                },
                {
                  key: "action",
                  value: "send",
                },
                {
                  key: "from",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
                {
                  key: "to",
                  value: "archway1c47kvyrcc6xzac5t97chz74thufarh05zvav2sgh63zv8fjev2zsgxeqwk",
                },
                {
                  key: "amount",
                  value: "1000",
                },
                {
                  key: "_contract_address",
                  value: "archway1c47kvyrcc6xzac5t97chz74thufarh05zvav2sgh63zv8fjev2zsgxeqwk",
                },
                {
                  key: "action",
                  value: "swap",
                },
                {
                  key: "assets_fee_amount",
                  value: '["0", "0"]',
                },
                {
                  key: "from_acc_addr",
                  value: '"archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz"',
                },
                {
                  key: "from_assets_amount",
                  value: '["0", "1000"]',
                },
                {
                  key: "to_acc_addr",
                  value: '"archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz"',
                },
                {
                  key: "to_assets_amount",
                  value: '["999", "0"]',
                },
                {
                  key: "_contract_address",
                  value: "archway105ml67ymwlznz8twk2nyxu5xaqnsp2h24t50995ucp8ffu5wut7q6xsaef",
                },
                {
                  key: "action",
                  value: "receive_swap_data",
                },
                {
                  key: "amount",
                  value: "389",
                },
                {
                  key: "result",
                  value: "success",
                },
                {
                  key: "_contract_address",
                  value: "archway1z3unpq9s5v2zp6djghwk359ya0upt6rzwpz8d48z38rxztazclfqlcxy9e",
                },
                {
                  key: "action",
                  value: "mint",
                },
                {
                  key: "amount",
                  value: "389",
                },
                {
                  key: "to",
                  value: "archway1akmvyvwxw6t7k8qe89j9tje4ax2dxnymrazwyz",
                },
              ],
            },
          ],
        },
      ],
      timestamp: "2023-06-14T10:05:48.253Z",
    },
    timestamp: "2023-06-14T10:05:48.253Z",
    date: "14/06/2023",
    time: "11:05",
    fromToken: {
      address: "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
      amount: "0.001",
      symbol: "xAXL",
    },
    toToken: {
      address: "ibc/85CE72EE820A66F0ABD5EE3907A34E243E4BE2D6CFAEB4C08DF85BD6C0784FA2",
      amount: "0.000999",
      symbol: "AXL",
    },
    draggable: false,
  },
];

export const dashboardAssetsList = {
  aconst: { price: 0.2 },
  archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka: { price: 0.2 },
  "ibc/85CE72EE820A66F0ABD5EE3907A34E243E4BE2D6CFAEB4C08DF85BD6C0784FA2": { price: 0.5 },
  archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu: { price: 0.5 },
};

export const dashboardMyPoolsList = [
  {
    address: "archway1wrkyxm3u6qz7n7mnws5235kqeuq6u0httlpl7x8cf93e3r0w96psc3addl",
    assetDecimals: [18, 18],
    cashback: true,
    disabled: false,
    factory: "archway1rplmhmn4k26hd9t35jnccdnad2c70a037xjcj7tv7dfn7skzcttsdltu7z",
    id: "stable_aconst_archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
    liquidity_token: "archway13rx5sfx7sjm7qp4z423y6cc7dp7srt8njapqzupk6dn82h026xyqtmn4fk",
    lockups: "archway1e3cp384pc6luq8e95sw2fqsc32pyylhq55q7x5ddx2wke5xnx2wqty55g7",
    lp_staking: "archway1l6h3ax43a5jdmwwza983fvg6lf9yw8jltm4a3wz6294j3pwkzfhq3fjm96",
    lp_staking_info: {
      owner_settings: {
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
      },
      lockup_duration: null,
      tvl: "27340000",
      reward_sources: [],
    },
    metrics: {
      assets_volume: ["0", "4473161445778059425"],
      assets_accumulated_fees: ["39053257096380281", "16600000000000000"],
    },
    owner_settings: {
      is_deposit_enabled: true,
      is_withdrawal_to_lockup_enabled: true,
      is_swap_enabled: true,
    },
    poolAssets: [
      {
        info: {
          native_token: {
            denom: "aconst",
          },
        },
        amount: "9196838554221940575",
      },
      {
        info: {
          token: {
            contract_addr: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
          },
        },
        amount: "18143161445778059425",
      },
    ],
    settings: {
      lockup: {
        fee_decay_multiplier_nom: "10",
        fee_decay_multiplier_denom: "100",
        fee_decay_step_duration: 86400,
      },
      withdrawal_to_lockup: {
        a_fee_param: 230,
        ignore_fee_param: 2500,
      },
      swap: {
        a_fee_param: 250,
        b_fee_param: 300,
      },
      collector_addr: "archway1vjt5hsgneptmdtxyadm440qdf6r5y56fmurf3k870qrvjk4pfgxqdpkea9",
    },
    total_share: "27340000",
    type: "stable",
    userPoolInfo: {
      config: {
        owner: "archway1zgwkcty2d8vwjtqtc58d529d6n7ttklfc0mn5nlxsue7und08llswpkmqt",
        pool: "archway1wrkyxm3u6qz7n7mnws5235kqeuq6u0httlpl7x8cf93e3r0w96psc3addl",
        owner_settings: {
          is_deposit_enabled: true,
          is_withdrawal_enabled: true,
        },
        lockup_duration: null,
        inc_token: "archway13rx5sfx7sjm7qp4z423y6cc7dp7srt8njapqzupk6dn82h026xyqtmn4fk",
      },
      reward_sources: [],
      total_locked: "27340000",
    },
    poolAddress: "archway1wrkyxm3u6qz7n7mnws5235kqeuq6u0httlpl7x8cf93e3r0w96psc3addl",
    tokenPair: "CONST / xCONST",
    apr: "TODO",
    tvl: "27340000",
    tvlInDollars: 6.620233101944533,
    assets: [
      {
        isNative: true,
        isDerivative: false,
        isPriceWatch: true,
        isHidden: false,
        id: "aconst",
        createdAt: "2023-05-20T16:06:00.725Z",
        decimals: 18,
        denom: "aconst",
        xDerivativeTarget: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
        image: "/static/tokens/ARCH.png",
        isSourceDenom: true,
        label: "Constantine(native)",
        standardPoolPriceSource: {
          address: "archway15etxyy99x0smdnl96q66vjk584a0h3n0sh3vv2vxqnuxch76kq7qz3frac",
          offerAssetDenom: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
        },
        symbol: "CONST",
        updatedAt: "2023-06-14T21:23:45.768Z",
        price: 0.24214459041494266,
        tokenName: "CONST",
      },
      {
        isNative: false,
        isDerivative: true,
        isPriceWatch: false,
        isHidden: false,
        label: "xCONST",
        address: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
        createdAt: "2023-05-20T16:06:06.177Z",
        decimals: 18,
        derivativeContract: "archway1e2htaf03fu9y9ez4hc8uky3rzvnagh7wnj8sn2e7hgsjqwwc4x6s55twtp",
        derivativeSource: "aconst",
        equalToOtherIDPriceSource: "aconst",
        id: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
        image: "/static/tokens/xARCH.png",
        symbol: "xCONST",
        totalSupply: "0",
        updatedAt: "2023-06-14T21:23:45.847Z",
        price: 0.24214459041494266,
        tokenName: "xCONST",
      },
    ],
    assetsSymbol: ["CONST", "xCONST"],
  },
  {
    address: "archway1c47kvyrcc6xzac5t97chz74thufarh05zvav2sgh63zv8fjev2zsgxeqwk",
    assetDecimals: [6, 6],
    cashback: true,
    disabled: false,
    factory: "archway1rplmhmn4k26hd9t35jnccdnad2c70a037xjcj7tv7dfn7skzcttsdltu7z",
    id: "stable_ibc/85CE72EE820A66F0ABD5EE3907A34E243E4BE2D6CFAEB4C08DF85BD6C0784FA2_archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
    liquidity_token: "archway1xegdwshantfzzxcg7nx3nca8djk6uvqh9j7c9xzs5hzlmdjfugzqvhef2a",
    lockups: "archway1ffy3p8mm2yhaf325pkl8tceh2fvzhw04x96vxnuyrmwdec84v70q8n3uaq",
    lp_staking: "archway1ygxntncylqew37wlfl54e9fg9xkgr9xx2mevphpkarmjxlv7vz3s0ha607",
    lp_staking_info: {
      owner_settings: {
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
      },
      lockup_duration: null,
      tvl: "10000000",
      reward_sources: [],
    },
    metrics: {
      assets_volume: ["110000", "2000"],
      assets_accumulated_fees: ["0", "63"],
    },
    owner_settings: {
      is_deposit_enabled: true,
      is_withdrawal_to_lockup_enabled: true,
      is_swap_enabled: true,
    },
    poolAssets: [
      {
        info: {
          native_token: {
            denom: "ibc/85CE72EE820A66F0ABD5EE3907A34E243E4BE2D6CFAEB4C08DF85BD6C0784FA2",
          },
        },
        amount: "5108002",
      },
      {
        info: {
          token: {
            contract_addr: "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
          },
        },
        amount: "4892002",
      },
    ],
    settings: {
      lockup: {
        fee_decay_multiplier_nom: "10",
        fee_decay_multiplier_denom: "100",
        fee_decay_step_duration: 86400,
      },
      withdrawal_to_lockup: {
        a_fee_param: 230,
        ignore_fee_param: 2500,
      },
      swap: {
        a_fee_param: 250,
        b_fee_param: 300,
      },
      collector_addr: "archway1vjt5hsgneptmdtxyadm440qdf6r5y56fmurf3k870qrvjk4pfgxqdpkea9",
    },
    total_share: "10000000",
    type: "stable",
    poolAddress: "archway1c47kvyrcc6xzac5t97chz74thufarh05zvav2sgh63zv8fjev2zsgxeqwk",
    tokenPair: "AXL / xAXL",
    apr: "TODO",
    tvl: "10000000",
    tvlInDollars: 3.8034315213719996,
    assets: [
      {
        isNative: true,
        isDerivative: false,
        isPriceWatch: true,
        isHidden: false,
        label: "Axelar(TESTNET)",
        createdAt: "2023-05-20T16:29:04.301Z",
        decimals: 6,
        denom: "ibc/85CE72EE820A66F0ABD5EE3907A34E243E4BE2D6CFAEB4C08DF85BD6C0784FA2",
        denom_trace: {
          path: "transfer/channel-8",
          base_denom: "uaxl",
        },
        geckoIDPriceSource: "axelar",
        xDerivativeTarget: "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
        id: "ibc/85CE72EE820A66F0ABD5EE3907A34E243E4BE2D6CFAEB4C08DF85BD6C0784FA2",
        image: "/static/tokens/AXL.png",
        staticPriceSource: null,
        symbol: "AXL",
        updatedAt: "2023-06-14T21:28:00.349Z",
        price: 0.380343,
        tokenName: "AXL",
      },
      {
        isNative: false,
        isDerivative: true,
        isPriceWatch: false,
        isHidden: false,
        label: "xAXL",
        address: "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
        createdAt: "2023-05-20T16:29:12.920Z",
        decimals: 6,
        derivativeContract: "archway1n2mzvp32etadh7ljehkf5qts2a6ytrpkkstslnuesm30lgzg9snsrrx8r4",
        derivativeSource: "ibc/85CE72EE820A66F0ABD5EE3907A34E243E4BE2D6CFAEB4C08DF85BD6C0784FA2",
        equalToOtherIDPriceSource: "ibc/85CE72EE820A66F0ABD5EE3907A34E243E4BE2D6CFAEB4C08DF85BD6C0784FA2",
        id: "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
        image: "/static/tokens/xAXL.png",
        symbol: "xAXL",
        totalSupply: "0",
        updatedAt: "2023-06-14T21:28:00.968Z",
        price: 0.380343,
        tokenName: "xAXL",
      },
    ],
    assetsSymbol: ["AXL", "xAXL"],
  },
];

export const dashboardMarketList = [
  {
    isNative: true,
    isDerivative: false,
    isPriceWatch: true,
    isHidden: false,
    id: "aconst",
    createdAt: "2023-05-20T16:06:00.725Z",
    decimals: 18,
    denom: "aconst",
    xDerivativeTarget: "archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka",
    image: "/static/tokens/ARCH.png",
    isSourceDenom: true,
    label: "Constantine(native)",
    standardPoolPriceSource: {
      address: "archway15etxyy99x0smdnl96q66vjk584a0h3n0sh3vv2vxqnuxch76kq7qz3frac",
      offerAssetDenom: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
    },
    symbol: "CONST",
    updatedAt: "2023-06-14T21:55:42.293Z",
    price: 0.24120886183440415,
    key: "aconst",
  },
  {
    isNative: false,
    isDerivative: false,
    isPriceWatch: true,
    isHidden: true,
    label: "Astrovault",
    address: "archway1qrvfsfdyw79g8zxepkafrsj53xvz2v072mk6kqe3yj82n8ahznpssuzzc5",
    createdAt: "2023-05-20T16:01:44.763Z",
    decimals: 6,
    id: "archway1qrvfsfdyw79g8zxepkafrsj53xvz2v072mk6kqe3yj82n8ahznpssuzzc5",
    image: "/static/tokens/AXV.png",
    staticPriceSource: 0.05,
    symbol: "AXV",
    totalSupply: "0",
    updatedAt: "2023-06-14T21:55:42.599Z",
    price: 0.05,
    key: "archway1qrvfsfdyw79g8zxepkafrsj53xvz2v072mk6kqe3yj82n8ahznpssuzzc5",
  },
  {
    isNative: true,
    isDerivative: false,
    isPriceWatch: true,
    isHidden: false,
    label: "Axelar(TESTNET)",
    createdAt: "2023-05-20T16:29:04.301Z",
    decimals: 6,
    denom: "ibc/85CE72EE820A66F0ABD5EE3907A34E243E4BE2D6CFAEB4C08DF85BD6C0784FA2",
    denom_trace: {
      path: "transfer/channel-8",
      base_denom: "uaxl",
    },
    geckoIDPriceSource: "axelar",
    xDerivativeTarget: "archway1eecw6a8yd9mcxwt3gah0ttdhrg7u7l98dv40pc7fszlzfqrgxnmq0y2zyu",
    id: "ibc/85CE72EE820A66F0ABD5EE3907A34E243E4BE2D6CFAEB4C08DF85BD6C0784FA2",
    image: "/static/tokens/AXL.png",
    staticPriceSource: null,
    symbol: "AXL",
    updatedAt: "2023-06-14T21:55:41.827Z",
    price: 0.38179,
    key: "ibc/85CE72EE820A66F0ABD5EE3907A34E243E4BE2D6CFAEB4C08DF85BD6C0784FA2",
  },
  {
    isNative: true,
    isDerivative: false,
    isPriceWatch: true,
    isHidden: false,
    label: "OSMO",
    createdAt: "2023-06-05T16:15:36.305Z",
    decimals: 6,
    denom: "ibc/05AC4BBA78C5951339A47DD1BC1E7FC922A9311DF81C85745B1C162F516FF2F1",
    denom_trace: {
      path: "transfer/channel-3",
      base_denom: "uosmo",
    },
    geckoIDPriceSource: "osmosis",
    id: "ibc/05AC4BBA78C5951339A47DD1BC1E7FC922A9311DF81C85745B1C162F516FF2F1",
    image: "/static/tokens/OSMO.svg",
    staticPriceSource: null,
    symbol: "OSMO",
    updatedAt: "2023-06-14T21:55:41.976Z",
    price: 0.450674,
    key: "ibc/05AC4BBA78C5951339A47DD1BC1E7FC922A9311DF81C85745B1C162F516FF2F1",
  },
  {
    isNative: true,
    isDerivative: false,
    isPriceWatch: true,
    isHidden: false,
    label: "ATOM",
    createdAt: "2023-05-27T09:56:57.236Z",
    decimals: 6,
    denom: "ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
    denom_trace: {
      path: "transfer/channel-7",
      base_denom: "uatom",
    },
    geckoIDPriceSource: "cosmos",
    xDerivativeTarget: "archway1k805d8pajwhda56gvas4cepvkfcfk5fkw7cpcnx6xjtzmt43qjmq76enw0",
    id: "ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
    image: "/static/tokens/ATOM.svg",
    staticPriceSource: null,
    symbol: "ATOM",
    updatedAt: "2023-06-14T21:55:41.894Z",
    price: 8.42,
    key: "ibc/25418646C017D377ADF3202FF1E43590D0DAE3346E594E8D78176A139A928F88",
  },
  {
    isNative: true,
    isDerivative: false,
    isPriceWatch: true,
    isHidden: false,
    label: "USDC.axl",
    createdAt: "2023-06-05T18:06:46.171Z",
    decimals: 6,
    denom: "ibc/F91EA2C0A23697A1048E08C2F787E3A58AC6F706A1CD2257A504925158CFC0F3",
    denom_trace: {
      path: "transfer/channel-8",
      base_denom: "uausdc",
    },
    geckoIDPriceSource: "axlusdc",
    id: "ibc/F91EA2C0A23697A1048E08C2F787E3A58AC6F706A1CD2257A504925158CFC0F3",
    image: "/static/tokens/USDC.png",
    staticPriceSource: null,
    symbol: "USDC.axl",
    updatedAt: "2023-06-14T21:55:42.055Z",
    price: 0.99783,
    key: "ibc/F91EA2C0A23697A1048E08C2F787E3A58AC6F706A1CD2257A504925158CFC0F3",
  },
];

export const DummyCollectRewardsModalList = [
  {
    tokens: [{ tokenName: "JUNO" }, { tokenName: "ARCH" }, { tokenName: "SEI" }, { tokenName: "CRO" }],
    rewardAmount: 10,
  },
  { tokens: [{ tokenName: "JUNO" }, { tokenName: "ARCH" }], rewardAmount: 5 },
];

export const PoolList = [
  {
    id: 1,
    rewards: {
      tokens: [
        { tokenName: "ATOM", tokenAmount: 4 },
        { tokenName: "ARCH", tokenAmount: 4 },
        { tokenName: "SEI", tokenAmount: 1 },
        { tokenName: "CRO", tokenAmount: 2 },
        { tokenName: "JUNO", tokenAmount: 6 },
        { tokenName: "JUNO", tokenAmount: 6 },
        { tokenName: "JUNO", tokenAmount: 6 },
        { tokenName: "JUNO", tokenAmount: 6 },
        { tokenName: "JUNO", tokenAmount: 6 },
        { tokenName: "JUNO", tokenAmount: 6 },
        { tokenName: "JUNO", tokenAmount: 6 },
      ],
      apr: 0,
      usdTvl: 0.05,
      type: "Cashback",
    },
  },
  {
    id: 2,
    rewards: {
      tokens: [
        { tokenName: "ARCH", tokenAmount: 4 },
        { tokenName: "xARCH", tokenAmount: 4 },
      ],
      apr: 0,
      usdTvl: 0.05,
      type: "Cashback",
    },
  },
  {
    id: 3,
    rewards: {
      tokens: [
        { tokenName: "ARCH", tokenAmount: 4 },
        { tokenName: "xARCH", tokenAmount: 4 },
      ],
      apr: 0,
      usdTvl: 0.05,
      type: "Cashback",
    },
  },
  {
    id: 4,
    rewards: {
      tokens: [
        { tokenName: "ARCH", tokenAmount: 4 },
        { tokenName: "xARCH", tokenAmount: 4 },
      ],
      apr: 0,
      usdTvl: 0.05,
      type: "Cashback",
    },
  },
  {
    id: 5,
    rewards: {
      tokens: [
        { tokenName: "ARCH", tokenAmount: 4 },
        { tokenName: "xARCH", tokenAmount: 4 },
      ],
      apr: 0,
      usdTvl: 0.05,
      type: "Cashback",
    },
  },
];

export const DummyStatisticsList = [
  {
    id: "aconst",
    label: "CONST",
    symbol: "CONST",
    currentRevenue: 119210197,
    expectedRevenue: 12556892,
  },
  {
    id: "axl",
    label: "AXL",
    symbol: "AXL",
    currentRevenue: 119210197,
    expectedRevenue: 12556892,
  },
  {
    id: "atom",
    label: "ATOM",
    symbol: "ATOM",
    currentRevenue: 119210197,
    expectedRevenue: 12556892,
  },
  {
    id: "osmo",
    label: "OSMO",
    symbol: "OSMO",
    currentRevenue: 119210197,
    expectedRevenue: 12556892,
  },
  {
    id: "xatom",
    label: "xATOM",
    symbol: "xATOM",
    currentRevenue: 119210197,
    expectedRevenue: 12556892,
  },
  {
    id: "xconst",
    label: "xCONST",
    symbol: "xCONST",
    currentRevenue: 119210197,
    expectedRevenue: 12556892,
  },
];
