export const computeCashback = (
  assets: any,
  pool: any,
  cashbackMinter: any,
  offerAssetID: string,
  amountInput: bigint
) => {
  if (!cashbackMinter.extraFields.pools.includes(pool.address)) return BigInt(0);

  const assetCashbackMinter = cashbackMinter.extraFields.assets.find((asset: any) => asset.id === offerAssetID);

  if (!assetCashbackMinter) return BigInt(0);
  // convert amountIn to 6 decimals
  let amount = amountInput;
  if (assets[offerAssetID].decimals > 6) {
    amount = amountInput / BigInt(Math.pow(10, assets[offerAssetID].decimals - 6));
  }

  return (amount * BigInt(assetCashbackMinter.ratio)) / BigInt(100000000);
};

export const cashbackToAXV = (cashback: any, cashbackAmount: bigint) => {
  if (cashbackAmount > BigInt(0)) {
    // cashback created on this tx is added to the totalSupply of cashback token,
    // and it simulates the mint to calc the value of the tokens minted
    const newTotalSupply = BigInt(cashback.extraFields.totalSupply) + cashbackAmount;
    return (cashbackAmount * BigInt(cashback.extraFields.reward_balance)) / newTotalSupply;
  }
  return BigInt(0);
};
