import Youtube from "react-youtube";
import { useMediaQuery } from "usehooks-ts";
// import useLanguage from "@axvdex/hooks/useLanguage";
// import { useAppDispatch } from "@axvdex/state";
import { responsiveBreakpoints } from "@axvdex/constants";
import CustomModal from "../common/CustomModal";

function SaleDocsModal({ saleDocsModalOpen, setSaleDocsModalOpen, outbidSelected }: any) {
  // const { i18 } = useLanguage();
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobileSm);

  const renderSpecificDocs = () => {
    if ("Lunarspace" === outbidSelected.project) {
      const salePeriod = "24 hours";
      const vipPeriod = "10 minutes";
      const bonusPeriod = "10 minutes";
      const bonusPeriodExt = "10 minutes";
      const bonusMinTickets = "50";
      const earlyNet = "50%";
      const earlyNetBonus = "6% of tickets";
      const randomBid = "1,000 AXV";
      const finalCloser = "500 AXV";
      const tokensAccepted = "USDC.nobl, USDC.axl, IST, ATOM, CORE, ARCH, and AXV";
      const tokenLaunch = "LSE";
      const project = "Lunarspace (LSE)";

      return (
        <>
          <h3>Game flow</h3>
          <ul>
            <li>Tickets can be purchased using: {tokensAccepted}</li>
            <li>
              Each sale round is {salePeriod}, with the first {vipPeriod} exclusively reserved for VIP listed members
            </li>
            <li>
              The general public (non VIP listed members) can participate if a sale does not sell out during the first{" "}
              {vipPeriod} of bidding
            </li>
            <li>Wallets making a bid at any time are added to the VIP list for future sales</li>
            <li>
              The last bidder gets remaining bonus bag 💰 tickets if a sale does not fill in {salePeriod} after the last
              round. However, in the last {bonusPeriod} of a sale, BONUS TIME is triggered to extend the sale, giving
              new bidders the opportunity to participate and claim the bonus bag 💰
            </li>
            <li>
              Each bid during BONUS TIME resets the timer to {bonusPeriodExt} and the minimum amount of purchasable
              tickets increases by {bonusMinTickets}. Bidding continues until either the time runs out or the sale fills
            </li>
            <li>
              Filling the round during BONUS TIME rewards early contributors to the round. The first {earlyNet} of
              purchased tickets in the round will receive an additional {earlyNetBonus} on top of their purchase, which
              incentivizes them to also participate and fill the round during BONUS TIME
            </li>
          </ul>
          <h3>Prizes for every sale round</h3>
          <ul>
            <li>A random bid will win one Archie NFT</li>
            <li>Random bids receive up to {randomBid} as an additional reward</li>
            <li>First place on the leaderboard will win one Archie NFT and one Gold Astronaut NFT</li>
            <li>Second place on the leaderboard will win one Derpie NFT and one Silver Astronaut NFT</li>
            <li>Third place on the leaderboard will win one Qwoyn NFT and one Bronze Astronaut NFT</li>
            <li>The FINAL bidder to close a round receives {finalCloser} as an additional reward</li>
          </ul>
          <h3>Game ending</h3>
          <ul>
            <li style={{ margin: "1.5em 0 0" }}>
              After the last round, vesting period is triggered, all vesting schedules and leaderboards are established.
              Participants are be able to claim their tokens and or NFTs
            </li>
            <li>
              The last round does <strong>not</strong> have vesting, therefore, participants can claim their tokens as
              soon as the {tokenLaunch} token goes live in the days following the end. However, earlier rounds have a
              different vesting schedule calculated based on its distance from the last sale. There is one month more of
              vesting in an earlier sale compared to the sale that comes after it
              <p style={{ margin: "1em 0" }}>
                Set out below is an illustrative example of the {project} public sale on Outbid.
                <table className="table dashboardHistoryTable">
                  <thead>
                    <tr>
                      <th>Sale 1 -&gt;</th>
                      <th>Sale 2 -&gt;</th>
                      <th>Sale 3 -&gt;</th>
                      <th>Sale 4 -&gt;</th>
                      <th>Sale 5 -&gt;</th>
                      <th>Sale 6</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="spaced">
                          <div className="textGrey">Vesting:</div>5 Months
                        </div>
                        <div className="textGrey">Valuation:</div>
                        $750,000
                      </td>
                      <td>
                        <div className="spaced">
                          <div className="textGrey">Vesting:</div>4 Months
                        </div>
                        <div className="textGrey">Valuation:</div>
                        $810,000
                      </td>
                      <td>
                        <div className="spaced">
                          <div className="textGrey">Vesting:</div>3 Months
                        </div>
                        <div className="textGrey">Valuation:</div>
                        $874,800
                      </td>
                      <td>
                        <div className="spaced">
                          <div className="textGrey">Vesting:</div>2 Months
                        </div>
                        <div className="textGrey">Valuation:</div>
                        $944,784
                      </td>
                      <td>
                        <div className="spaced">
                          <div className="textGrey">Vesting:</div>1 Month
                        </div>
                        <div className="textGrey">Valuation:</div>
                        $1,020,367
                      </td>
                      <td>
                        <div className="spaced">
                          <div className="textGrey">Vesting:</div>
                          No vesting
                        </div>
                        <div className="textGrey">Valuation:</div>
                        $1,101,996
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7}>
                        <span className="textGrey">Maximum number of auctions:</span> 6
                      </td>
                    </tr>
                  </tbody>
                </table>
              </p>
            </li>
          </ul>
          <h3>Sale allocations</h3>{" "}
          <ul>
            <li>Sale 1: 50,000 tickets for 6,666,667 LSE tokens, valuation of $0.0075 per LSE</li>
            <li>Sale 2: 50,000 tickets for 6,172,840 LSE tokens, valuation of $0.0081 per LSE</li>
            <li>Sale 3: 50,000 tickets for 5,715,592 LSE tokens, valuation of $0.0087 per LSE</li>
            <li>Sale 4: 50,000 tickets for 5,292,215 LSE tokens, valuation of $0.0094 per LSE</li>
            <li>Sale 5: 50,000 tickets for 4,900,199 LSE tokens, valuation of $0.0102 per LSE</li>
            <li>Sale 6: 50,000 tickets for 4,537,221 LSE tokens, valuation of $0.0110 per LSE</li>
          </ul>
          <h3>Game tips</h3>{" "}
          <ul>
            <li>
              Tickets in Sale 1 cost less than tickets in Sale 2, tickets in Sale 2 cost less than Sale 3, and so on
            </li>
            <li>Tickets in Sale 1 are vested for a longer time than tickets in Sale 2 and Sale 3, and so on</li>
            <li>
              Ebay this is not. Waiting last second to buy tickets is very risky because it takes a random time for
              every bid to register and get accepted after the wallet payment is confirmed
            </li>
            <li>
              Being part of the first half of capital in a round is more rewarding than chasing the jackpot (6% bonus)
              if the round does fill
            </li>
          </ul>
        </>
      );
    } else if ("Astrovault" === outbidSelected.project) {
      return (
        <>
          <h3>Game flow</h3>
          <p>Sale round 4+</p>
          <ul>
            <li>Rounds lasted 24 hours, with the first 10 minutes exclusively reserved for whitelisted members</li>
            <li>
              If a sale did not sell out after the first 10 minutes of bidding, all non-whitelisted members and the
              general public participated
            </li>
            <li>Any user who participated in any sale were added to the whitelist for future sales</li>
            <li style={{ margin: "1.5em 0 0" }}>
              {" "}
              If a sale did not fill in 24 hours, it automatically became the last round, the last bidder got all
              remaining tickets (bonus bag 💰). However, in the last 15-minutes of a sale, BONUS TIME was triggered to
              extend the sale, giving new bidders the opportunity to participate and claim the bonus bag 💰
            </li>
            <li>
              Each user bid during BONUS TIME reset the timer to 15-minutes and the minimum purchasable amount increased
              by 50 tickets. Bidding continued until the timer ran out
            </li>
            <li>
              Filling the round during BONUS TIME would have rewarded early contributors to the round. The first 50% of
              tickets purchased in the round would have received an additional 6% on top of their purchase, which
              incentivized them to also participate and fill the round on BONUS TIME
            </li>
            <li>The final bidder to close a round would have received 1000 ARCH as an additional REWARD!</li>
            <li>However, there was one lucky winner because people got Outbid trying to bid too late</li>
          </ul>
          <p>Sale round 1-3</p>
          <ul>
            <li>Rounds lasted 48 hours, with the first 2 hours exclusively reserved for whitelisted members</li>
            <li>
              If a sale did not sell out after the first two hours of bidding, all non-whitelisted members and the
              general public participated
            </li>
            <li>Any user who participated in any sale were added to the whitelist for future sales</li>
            <li style={{ margin: "1.5em 0 0" }}>
              If a sale did not fill in 48 hours, it automatically became the last round, and the last bidder would have
              got all remaining tickets (bonus bag 💰). However, in the last 30-minutes of a sale, BONUS TIME was
              triggered to extend the sale, giving new bidders the opportunity to participate and claim the bonus bag 💰
            </li>
            <li>
              Each user bid during BONUS TIME reset the timer to 30-minutes and the minimum purchasable amount increased
              by 100 tickets. Bidding continued until the sale round filled
            </li>
            <li>
              Filling the round during BONUS TIME rewarded early contributors to the round. The first 10% of tickets
              purchased in the round received an additional 10% on top of their purchase, which incentivized them to
              also participate and fill the round on BONUS TIME
            </li>
          </ul>
          <p>All sale rounds continue here...</p>
          <ul>
            <li style={{ margin: "1.5em 0 0" }}>
              After the last round, vesting period is triggered and all vesting schedules and leaderboards will be
              established. Participants will be able to claim their tokens and or NFTs
            </li>
            <li>
              The last round does <strong>not</strong> have vesting, therefore, participants can claim their tokens as
              soon as the AXV token goes live in the days following the end. However, earlier rounds will have a
              different vesting schedule calculated based on its distance from the last sale. There is one month more of
              vesting in an earlier sale compared to the sale that comes after it
              <p style={{ margin: "1em 0" }}>
                Set out below is an illustrative example of a public Outbid campaign.
                <table className="table dashboardHistoryTable">
                  <thead>
                    <tr>
                      <th>Sale 1 -&gt;</th>
                      <th>Sale 2 -&gt;</th>
                      <th>Sale 3 -&gt;</th>
                      <th>Sale 4 -&gt;</th>
                      <th>Sale 5 -&gt;</th>
                      <th>...</th>
                      <th>Sale 10</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="spaced">
                          <div className="textGrey">Vesting:</div>9 Months
                        </div>
                        <div className="textGrey">Valuation:</div>
                        $15.0m
                      </td>
                      <td>
                        <div className="spaced">
                          <div className="textGrey">Vesting:</div>8 Months
                        </div>
                        <div className="textGrey">Valuation:</div>
                        $16.2m
                      </td>
                      <td>
                        <div className="spaced">
                          <div className="textGrey">Vesting:</div>7 Months
                        </div>
                        <div className="textGrey">Valuation:</div>
                        $17.5m
                      </td>
                      <td>
                        <div className="spaced">
                          <div className="textGrey">Vesting:</div>6 Months
                        </div>
                        <div className="textGrey">Valuation:</div>
                        $18.9m
                      </td>
                      <td>
                        <div className="spaced">
                          <div className="textGrey">Vesting:</div>5 Months
                        </div>
                        <div className="textGrey">Valuation:</div>
                        $20.4m
                      </td>
                      <td>...</td>
                      <td>
                        <div className="spaced">
                          <div className="textGrey">Vesting:</div>
                          No vesting
                        </div>
                        <div className="textGrey">Valuation:</div>
                        $30.0m
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7}>
                        <span className="textGrey">Maximum number of auctions:</span> 10
                      </td>
                    </tr>
                  </tbody>
                </table>
              </p>
            </li>
          </ul>
          <h3>Sale allocations + vesting schedule on campaign completion</h3>{" "}
          <ul>
            <li>Sale 1: 75,000 tickets for 1,000,000 AXV tokens, valuation of $0.075 per AXV, vesting is 4 months</li>
            <li>Sale 2: 81,000 tickets for 1,000,000 AXV tokens, valuation of $0.0810 per AXV, vesting is 3 months</li>
            <li>Sale 3: 87,480 tickets for 1,000,000 AXV tokens, valuation of $0.0875 per AXV, vesting is 2 months</li>
            <li>Sale 4: 50,000 tickets for 529,221 AXV tokens, valuation of $0.0945 per AXV, vesting is 1 months</li>
            <li style={{ color: "#00ff00" }}>
              Sale 5: 50,000 tickets for 490,020 AXV tokens, valuation of $0.1020 per AXV, no vesting period
            </li>
            <li className="strike textGrey">
              Sale 6: 50,000 tickets for 453,722 AXV tokens, valuation of $0.1102 per AXV
            </li>
            <li className="strike textGrey">
              Sale 7: 50,000 tickets for 420,113 AXV tokens, valuation of $0.1190 per AXV
            </li>
            <li className="strike textGrey">
              Sale 8: 50,000 tickets for 388,994 AXV tokens, valuation of $0.1285 per AXV
            </li>
            <li className="strike textGrey">
              Sale 9: 50,000 tickets for 360,179 AXV tokens, valuation of $0.1388 per AXV
            </li>
            <li className="strike textGrey">
              Sale 10: 50,000 tickets for 333,499 AXV tokens, valuation of $0.1499 per AXV
            </li>
          </ul>
        </>
      );
    }
  };

  return (
    <>
      <CustomModal
        extraClassName="outbidGuide"
        isOpen={saleDocsModalOpen}
        onClose={() => setSaleDocsModalOpen(false)}
        modalSize="lg"
      >
        <section className="sectionModalHeader">
          <h2 className="h2">{"Outbid"}</h2>
        </section>
        <div style={{ fontSize: "1em" }}>
          <p>Outbid is a gamified fundraising platform that combines the thrill of auctions with crowdfunding.</p>
          <div style={{ display: "flex", placeContent: "center" }}>
            <Youtube
              videoId="9aWk-G6vnKw"
              opts={{
                height: isMobileBreakpoint ? "120" : "350",
                width: isMobileBreakpoint ? "230" : "720",
                playerVars: {
                  autoplay: 0,
                  controls: 1,
                  playlist: "9aWk-G6vnKw,LdDcooCejt4,",
                  rel: 0,
                  loop: 1,
                },
              }}
              id="video_1"
            />
          </div>
          <br />
          <p>
            Projects and DAOs are enabled to raise community funds through a series of micro-sales. These sales occur
            consecutively, with each sale being more expensive than the previous one, driving up the valuation and
            ensuring that early supporters benefit from the best pricing.
          </p>
          {renderSpecificDocs()}
          <hr />
        </div>
      </CustomModal>
    </>
  );
}

export default SaleDocsModal;
