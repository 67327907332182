import { ReactNode } from "react";
import clsx from "clsx";

interface HelpSubPageSectionProps {
  extraClassName?: string;
  sectionId: string;
  sectionTitle: string;
  children: ReactNode;
  suffix?: ReactNode;
}

function HelpSubPageSection({ sectionId, sectionTitle, children, extraClassName, suffix }: HelpSubPageSectionProps) {
  return (
    <section className={clsx("helpSubPageSection", extraClassName && extraClassName)} id={sectionId}>
      <h2>
        {sectionTitle}&nbsp;{suffix}
      </h2>
      {children}
    </section>
  );
}

export default HelpSubPageSection;
