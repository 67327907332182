import { useMemo, useState } from "react";
import { findIndex } from "lodash";
import { Lightbox } from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import clsx from "clsx";
import { Trans } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import HelpSubPageSidebar from "@axvdex/components/HelpSubPageSidebar";
import HelpSubPageSection from "@axvdex/components/HelpSubPageSection";
import LogoGravitate from "@axvdex/assets/images/help/dashboard/logo-gravitate-white.svg";
import useLanguage from "@axvdex/hooks/useLanguage";
import MainLayout from "../layouts/MainLayout";
import styles from "../styles/Help.module.scss";

function HelpPool() {
  const { i18 } = useLanguage();

  const sectionSummary = [
    {
      name: i18("Pools", "help.pool.sectionSummary.pools.name"),
      url: "#pools",
      nestedSubSections: [
        {
          name: i18("Search and display", "help.pool.sectionSummary.pools.nestedSubSections.searchAndDisplay.name"),
          url: "#poolsPoolSorting",
        },
      ],
    },
    {
      name: i18("Standard Pools", "help.pool.sectionSummary.standardPools.name"),
      url: "#standardPools",
      nestedSubSections: [
        {
          name: i18("Deposit", "help.pool.sectionSummary.standardPools.nestedSubSections.deposit.name"),
          url: "#standardPoolsDeposit",
        },
        {
          name: i18("Withdraw", "help.pool.sectionSummary.standardPools.nestedSubSections.withdraw.name"),
          url: "/help/pool#standardPoolsWithdraw",
        },
      ],
    },
    {
      name: i18("Stable Pools", "help.pool.sectionSummary.stablePools.name"),
      url: "#stablePools",
      nestedSubSections: [
        {
          name: i18("Deposit", "help.pool.sectionSummary.stablePools.nestedSubSections.deposit.name"),
          url: "/help/pool#stablePoolsDeposit",
        },
        {
          name: i18("Withdraw", "help.pool.sectionSummary.stablePools.nestedSubSections.withdraw.name"),
          url: "#stablePoolsWithdraw",
        },
      ],
    },
  ];

  const [index, setIndex] = useState(-1);

  const slides = useMemo(() => {
    return [
      // add images with id passed to onImageClick function to open the lightbox on that image, and add caption to the image
      {
        src: require("../assets/images/help/pool/Picture1.webp"),
        id: "pool/Picture1",
        title: i18("Pool page", "help.pool.slides.picture1.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture2.webp"),
        id: "pool/Picture2",
        title: i18("Pool display by type", "help.pool.slides.picture2.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture3.webp"),
        id: "pool/Picture3",
        title: i18("Pool sort by", "help.pool.slides.picture3.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture4.webp"),
        id: "pool/Picture4",
        title: i18("Pool search", "help.pool.slides.picture4.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture5.webp"),
        id: "pool/Picture5",
        title: i18("Pool display choices", "help.pool.slides.picture5.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture6.webp"),
        id: "pool/Picture6",
        title: i18("Pool display standard pools", "help.pool.slides.picture6.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture7.webp"),
        id: "pool/Picture7",
        title: i18("Pool card", "help.pool.slides.picture7.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture8.webp"),
        id: "pool/Picture8",
        title: i18("Pool toggle favourite", "help.pool.slides.picture8.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture9.webp"),
        id: "pool/Picture9",
        title: i18("Pool open details button", "help.pool.slides.picture9.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture10.webp"),
        id: "pool/Picture10",
        title: i18("Pool details", "help.pool.slides.picture10.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture11.webp"),
        id: "pool/Picture11",
        title: i18("Pool Details :: Favourite button", "help.pool.slides.picture11.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture12.webp"),
        id: "pool/Picture12",
        title: i18("Pool details Deposit/Withdraw", "help.pool.slides.picture12.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture13.webp"),
        id: "pool/Picture13",
        title: i18("Pool approve xConst pop-up", "help.pool.slides.picture13.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture14.webp"),
        id: "pool/Picture14",
        title: i18("Pool approve xConst transaction success", "help.pool.slides.picture14.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture15.webp"),
        id: "pool/Picture15",
        title: i18("Pool details Deposit", "help.pool.slides.picture15.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture16.webp"),
        id: "pool/Picture16",
        title: i18("Pool details deposit pop-up", "help.pool.slides.picture16.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture17.webp"),
        id: "pool/Picture17",
        title: i18("Pool details deposit success", "help.pool.slides.picture17.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture18.webp"),
        id: "pool/Picture18",
        title: i18("Pool details Withdraw", "help.pool.slides.picture18.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture19.webp"),
        id: "pool/Picture19",
        title: i18("Pool Stable Pools", "help.pool.slides.picture19.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture20.webp"),
        id: "pool/Picture20",
        title: i18("Pool Stable card", "help.pool.slides.picture20.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture22.webp"),
        id: "pool/Picture22",
        title: i18("Stable pool details", "help.pool.slides.picture22.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture23.webp"),
        id: "pool/Picture23",
        title: i18("Stable pool favourite button", "help.pool.slides.picture23.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture24.webp"),
        id: "pool/Picture24",
        title: i18("Stable pool deposit tab", "help.pool.slides.picture24.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture25.webp"),
        id: "pool/Picture25",
        title: i18("Stable pool deposit pop-up", "help.pool.slides.picture25.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture26.webp"),
        id: "pool/Picture26",
        title: i18("Stable pool deposit success", "help.pool.slides.picture26.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture27.webp"),
        id: "pool/Picture27",
        title: i18("Stable Pool Withdraw", "help.pool.slides.picture27.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture28.webp"),
        id: "pool/Picture28",
        title: i18("Stable pool instant Withdrawal button", "help.pool.slides.picture28.title"),
      },
      {
        src: require("../assets/images/help/pool/Picture29.webp"),
        id: "pool/Picture29",
        title: i18("Stable pool lockups", "help.pool.slides.picture29.title"),
      },
    ];
  }, []);

  const onImageClick = (imageName: string) => {
    const index_ = findIndex(slides, { id: imageName });
    setIndex(index_);
  };

  return (
    <MainLayout
      pageClass={clsx(styles.help, "help", "helpPool")}
      headTitle={i18("Help :: Pools", "help.pool.headTitle")}
      headDescription={i18("Help for pools on Astrovault", "help.pool.headDescription")}
    >
      <div className="helpPageWrapper">
        <div className="sectionWrapperLg">
          <section className="helpSectionHeader withGradientBorderBottom">
            <h1>{i18("Exploring Pool", "help.pool.section.title")}</h1>

            <p>
              <Trans i18nKey="help.pool.section.text.0">
                The pools view allow you to participate in Liquidity Pools. Liquidity pools allow you to deposit assets
                and earn rewards, known as “yield”, from your deposits. Read more about{" "}
                <a
                  href="https://www.coindesk.com/learn/what-are-liquidity-pools/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn"
                  data-color="gradient"
                  data-variant="link"
                >
                  liquidity pools
                </a>
                .
              </Trans>
            </p>
          </section>

          <div className="helpSubPageGrid">
            <HelpSubPageSidebar summaryTitle={i18("Pool", "help.pool.sidebar.title")} items={sectionSummary} />

            <div className="helpSubPageContent">
              <HelpSubPageSection
                sectionId="pools"
                sectionTitle={i18("Pools", "help.pool.subpage.section.pools.title")}
              >
                <p>
                  <Trans i18nKey="help.pool.pools.text.0">
                    Let&#8217;s take a look at an overview of these pools before diving into the benefits of the pools
                    themselves.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture1.webp`)}
                  onClick={() => onImageClick("pool/Picture1")}
                  alt={i18("Pool Page", "help.pool.picture1.alt")}
                  width={1570}
                  height={706}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <h3 id="poolsPoolSorting">{i18("Search and display", "help.pool.pools.searchAndDisplay.title")}</h3>

                <p>
                  <Trans i18nKey="help.pool.pools.text.1">
                    Along the top of the pools page are some drop down menus to explore.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture2.webp`)}
                  onClick={() => onImageClick("pool/Picture2")}
                  alt={i18("Pool Sort by Type", "help.pool.picture2.alt")}
                  width={501}
                  height={355}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey="help.pool.pools.text.2">
                    Here we can select which pool type we would like to see. We will delve into the differences between
                    these pools later.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.pools.text.3">The next drop down allows sorting.</Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture3.webp`)}
                  onClick={() => onImageClick("pool/Picture3")}
                  alt={i18("Pool Sort by", "help.pool.picture3.alt")}
                  width={498}
                  height={278}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey="help.pool.pools.text.4">
                    Pools can be sorted by <strong>Total Value Locked (TVL)</strong>, or by the{" "}
                    <strong>Annual Percentage Rate (APR)</strong> offered. Please refer to the{" "}
                    <HashLink
                      to={"/help/pool#standardPools"}
                      rel="noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      Standard Pool
                    </HashLink>{" "}
                    or{" "}
                    <HashLink
                      to={"/help/pool#stablePools"}
                      rel="noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      Stable Pool
                    </HashLink>{" "}
                    section for further explanation.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.pools.text.5">
                    We can also select pools for display using the search function.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture4.webp`)}
                  onClick={() => onImageClick("pool/Picture4")}
                  alt={i18("Pool Search", "help.pool.picture4.alt")}
                  width={496}
                  height={205}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey="help.pool.pools.text.6">
                    Here, only pools with assets matching the search input will display.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.pools.text.7">
                    Finally, the following options are available on the right.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture5.webp`)}
                  onClick={() => onImageClick("pool/Picture5")}
                  alt={i18("Pool Top Right Side Buttons", "help.pool.picture5.alt")}
                  width={510}
                  height={105}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey={"help.pool.pools.text.8"}>
                    The default is to show &quot;All&quot; pools. This can be changed to show your &quot;Favourite&quot;
                    pools, or only pools that you are actively participating in using &quot;My Pools&quot;.
                  </Trans>
                </p>
              </HelpSubPageSection>

              <HelpSubPageSection
                extraClassName="withGradientBorderTop"
                sectionId="standardPools"
                sectionTitle={i18("Standard Pools", "help.pool.subpage.section.standardPools.title")}
              >
                <p>
                  <Trans i18nKey="help.pool.standardPools.text.0">
                    Standard Pools involve two distinct assets. The price of these assets may move in opposition of each
                    other, such as one increasing in value while the other is decreasing. Or they may move in concert,
                    where both go up or down at the same time.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.1">
                    There will be a difference though as even when these assets move in concert, they will move at
                    different rates. For example, one asset may go up by a $1 while the other may only go up $.50.
                    Liquidity pools are complex, and an in-depth explanation is beyond the scope of this help. Please
                    refer to this{" "}
                    <a
                      href="https://academy.binance.com/en/articles/what-are-liquidity-pools-in-defi"
                      target="_blank"
                      rel="noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      Binance article
                    </a>{" "}
                    or this{" "}
                    <a
                      href="https://www.coindesk.com/learn/what-are-liquidity-pools/"
                      target="_blank"
                      rel="noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      CoinDesk article
                    </a>{" "}
                    to learn more.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.2">
                    For <strong>Standard Pools</strong> both assets must be deposited.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.3">
                    Let&#8217;s select the <strong>Standard Pools</strong> and see what happens!
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture6.webp`)}
                  onClick={() => onImageClick("pool/Picture6")}
                  alt={i18("Pool Available Standard Pools", "help.pool.picture6.alt")}
                  width={1430}
                  height={319}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.4">
                    Here we see the currently available <strong>Standard Pools</strong>. More are added all the time so
                    make sure to check back.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.5">
                    We use the following pool to look at some features:
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture7.webp`)}
                  onClick={() => onImageClick("pool/Picture7")}
                  alt={i18("Pool card", "help.pool.picture7.alt")}
                  width={485}
                  height={305}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.6">
                    Here we see what assets are involved in this pool. In this case it&#8217;s two derivative or
                    &quot;x&quot; assets. <strong>xARCH</strong> the derivative of <strong>ARCH</strong> and{" "}
                    <strong>xATOM</strong> the derivative of <strong>ATOM</strong> the main Cosmos token.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.7">
                    To the right is the star button used to mark this pool as a favourite.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture8.webp`)}
                  onClick={() => onImageClick("pool/Picture8")}
                  alt={i18("Pool toggle favourite", "help.pool.picture8.alt")}
                  width={40}
                  height={40}
                  loading="lazy"
                  className="sm lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.8">
                    This will be most useful as the pool list grows.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.9">
                    Before we look into the pool details, let&#8217;s look at the bottom.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.10">
                    The label given to the type of this pool is &quot;Standard Pool&quot;. Trades that use Standard
                    pools receive Gravitate{" "}
                    <span className="iconWrapper">
                      (
                      <img
                        src={LogoGravitate}
                        alt={i18("GRVT8 - Gravitate Icon", "grvt8.icon.alt")}
                        width={10}
                        height={14}
                        loading="lazy"
                        className="isIcon GRVT8"
                      />
                      &nbsp;GRVT8)
                    </span>{" "}
                    rewards. Astrovault offers great incentives for its users.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.11">
                    Below that is the APR (Annual Percentage Rate, learn more about{" "}
                    <a
                      href="https://www.investopedia.com/terms/a/apr.asp"
                      target="_blank"
                      rel="noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      APR
                    </a>
                    ). APR is the rate of return or &quot;yield&quot; received for participating in a pool for a year.
                    However, an entire year of pool participation is not required because the yield is proportional to
                    the length of time your assets are left in a pool.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.12">
                    Finally, the last piece of information showcases the TVL (Total Value Locked, learn more about{" "}
                    <a
                      href="https://www.coindesk.com/learn/why-tvl-matters-in-defi-total-value-locked-explained/"
                      target="_blank"
                      rel="noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      TVL
                    </a>
                    ). As this screenshot is from the pre-release of Astrovault, the amount of tokens in these pools is
                    equivalent to $16.62. TVL can be used as an indicator of how popular a pool is.
                  </Trans>
                </p>

                <h3 id="standardPoolsDeposit">
                  {i18("Standard Pools - Deposit", "help.pool.standardPools.standardPoolsDeposit.title")}
                </h3>

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.13">
                    Let&#8217;s look at how we can participate in these pools. Click on a pool tile and you will be
                    brought to the following.
                  </Trans>
                </p>

                <p>
                  <img
                    src={require(`../assets/images/help/pool/Picture9.webp`)}
                    onClick={() => onImageClick("pool/Picture9")}
                    alt={i18("Open Pool Details Button", "help.pool.picture9.alt")}
                    width={40}
                    height={40}
                    loading="lazy"
                    className="sm lightboxImageOpener"
                  />
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture10.webp`)}
                  onClick={() => onImageClick("pool/Picture10")}
                  alt={i18("Pool Details", "help.pool.picture10.alt")}
                  width={788}
                  height={1317}
                  loading="lazy"
                  className="md80 hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.14">
                    This is a <strong>xCONST</strong> and <strong>USDC.axl</strong> standard pool. Looking at this view
                    we can see the familiar star to favourite this pool, and underneath that we can see the amount of
                    each asset in the pool.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture11.webp`)}
                  onClick={() => onImageClick("pool/Picture11")}
                  alt={i18("Pool Details Favourite Button", "help.pool.picture11.alt")}
                  width={40}
                  height={40}
                  loading="lazy"
                  className="sm lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.15">
                    Let us now see how we can participate in this pool.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture12.webp`)}
                  onClick={() => onImageClick("pool/Picture12")}
                  alt={i18("Pool Details Deposit/Withdraw", "help.pool.picture12.alt")}
                  width={668}
                  height={596}
                  loading="lazy"
                  className="md80 hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.16">
                    For this pool we must approve the contract for the derivative xCONST. So, click the &quot;Approve
                    xCONST&quot;.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture13.webp`)}
                  onClick={() => onImageClick("pool/Picture13")}
                  alt={i18("Pool Approve xConst Result", "help.pool.picture13.alt")}
                  width={361}
                  height={636}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.17">
                    Once again, our wallet will generate a pop-up for us to approve the transaction.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture14.webp`)}
                  onClick={() => onImageClick("pool/Picture14")}
                  alt={i18("Pool Approve xConst Transaction Success", "help.pool.picture14.alt")}
                  width={412}
                  height={262}
                  loading="lazy"
                  className="md hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.18">
                    After this has been done, let&#8217;s put some tokens in the pool.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture15.webp`)}
                  onClick={() => onImageClick("pool/Picture15")}
                  alt={i18("Pool Details Deposit", "help.pool.picture15.alt")}
                  width={677}
                  height={619}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.19">
                    We put 5 in the amount box, and <strong>Astrovault</strong> will automatically calculate the amount
                    of <strong>USDC.axl</strong> needed to participate in the pool. Standard Pools require{" "}
                    <strong>both</strong> assets to be deposited.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.20">
                    Let&#8217;s click deposit and we will be greeted by our wallet with a familiar pop-up.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture16.webp`)}
                  onClick={() => onImageClick("pool/Picture16")}
                  alt={i18("Pool Details Deposit Result", "help.pool.picture16.alt")}
                  width={360}
                  height={633}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.21">
                    And once again we get a familiar message from <strong>Astrovault</strong>:
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture17.webp`)}
                  onClick={() => onImageClick("pool/Picture17")}
                  alt={i18("Pool Details Deposit Result Success", "help.pool.picture17.alt")}
                  width={415}
                  height={338}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.22">
                    After your deposit, you will receive a token that represents the amount of <strong>xCONST</strong>{" "}
                    and <strong>USDC.axl</strong>.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.23">
                    This token will have its own amount separate from the amount of <strong>xCONST</strong> and{" "}
                    <strong>USDC.axl</strong> you have in the pool.
                  </Trans>
                </p>

                <h3 id="standardPoolsWithdraw">
                  {i18("Standard Pools - Withdraw", "help.pool.standardPools.standardPoolsWithdraw.title")}
                </h3>

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.24">
                    When you decide to take your assets back from the pool, click on the withdraw tab.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture18.webp`)}
                  onClick={() => onImageClick("pool/Picture18")}
                  alt={i18("Pool Details Withdraw", "help.pool.picture18.alt")}
                  width={753}
                  height={729}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.pool.standardPools.text.25">
                    The slider on the bottom is particular to standard pools. In this example, 45% of stored assets are
                    withdrawn. The slider is used to effortlessly ensure both assets are removed in proportion to each
                    other.
                  </Trans>
                </p>
              </HelpSubPageSection>

              <HelpSubPageSection
                extraClassName="withGradientBorderTop"
                sectionId="stablePools"
                sectionTitle={i18("Stable Pools", "help.pool.subpage.section.stablePools.title")}
              >
                <p>
                  <Trans i18nKey="help.pool.stablePools.text.0">
                    While standard pools hold pairs of assets that can differ in price and where the prices can change
                    independently of each other, stable pools hold two or more assets that are tied together.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.1">Take a look at the current stable pools.</Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture19.webp`)}
                  onClick={() => onImageClick("pool/Picture19")}
                  alt={i18("Pool Stable Pools", "help.pool.picture19.alt")}
                  width={1428}
                  height={420}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.2">
                    Stable pools specifically bring together assets of equal value. These pools can contain stable coins
                    (
                    <a
                      href="https://www.investopedia.com/terms/s/stablecoin.asp"
                      target="_blank"
                      rel="noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      read more here
                    </a>
                    ), plus other assets with associated derivative “x” assets. Derivative assets can be minted 1:1 from
                    Layer 1 assets and both have the same value.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.3">
                    Stable coins try to maintain the peg to a $1 value at all times. Due to this, they should have the
                    same value and can be pooled together on a stable pool.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture20.webp`)}
                  onClick={() => onImageClick("pool/Picture20")}
                  alt={i18("Pool Stable Pool", "help.pool.picture20.alt")}
                  width={503}
                  height={338}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.4">
                    <strong>ARCH</strong> and <strong>xARCH</strong> have equal value so their pool type is called
                    stable.
                  </Trans>
                </p>

                <p>&nbsp;</p>
                <p>
                  <Trans i18nKey="help.pool.stablePools.text.5">
                    Let&#8217;s delve into these pools and learn more about them.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.6">
                    We have the name of the pool, in this case <strong>CONST</strong> and <strong>xCONST</strong>.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.7">
                    It&#8217;s labelled a stable pool and provides Gravitate{" "}
                    <span className="iconWrapper">
                      (
                      <img
                        src={LogoGravitate}
                        alt={i18("GRVT8 - Gravitate Icon", "grvt8.icon.alt")}
                        width={10}
                        height={14}
                        loading="lazy"
                        className="isIcon GRVT8"
                      />
                      &nbsp;GRVT8)
                    </span>{" "}
                    rewards to trades.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.8">
                    There is an Annual Percentage Rate yield (Learn more about{" "}
                    <a
                      href="https://www.investopedia.com/terms/a/apr.asp"
                      target="_blank"
                      rel="noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      APR here
                    </a>
                    ).
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.9">
                    And the pool has a Total Value Locked (Learn more about{" "}
                    <a
                      href="https://www.coindesk.com/learn/why-tvl-matters-in-defi-total-value-locked-explained/"
                      target="_blank"
                      rel="noreferrer"
                      className="btn"
                      data-color="gradient"
                      data-variant="link"
                    >
                      TVL here
                    </a>
                    ).
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.10">Clicking on a pool tile displays the following:</Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture22.webp`)}
                  onClick={() => onImageClick("pool/Picture22")}
                  alt={i18("Stable Pool Details", "help.pool.picture22.alt")}
                  width={781}
                  height={1160}
                  loading="lazy"
                  className="md80 hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.11">
                    Here we can see the pool balance of CONST and xCONST, see the familiar star to favourite this pool,
                    and 2 buttons <strong>Deposit</strong>, and <strong>Withdraw</strong>.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture23.webp`)}
                  onClick={() => onImageClick("pool/Picture23")}
                  alt={i18("Stable Pool Favourite Button", "help.pool.picture23.alt")}
                  width={40}
                  height={40}
                  loading="lazy"
                  className="sm lightboxImageOpener"
                />

                <h3 id="stablePoolsDeposit">
                  {i18("Stable Pools - Deposit", "help.pool.stablePools.stablePoolsDeposit.title")}
                </h3>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.12">
                    Looking at the <strong>Deposit</strong> view we can see something interesting.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.13">
                    Inputting a value of 5 <strong>CONST</strong> does not require a comparable value of{" "}
                    <strong>xCONST</strong> in order to <strong>Deposit</strong> into the pool.{" "}
                    <strong>Stable Pools</strong> can be one sided pools. This makes it unique when compared to Standard
                    Pools.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture24.webp`)}
                  onClick={() => onImageClick("pool/Picture24")}
                  alt={i18("Stable Pool Deposit Tab", "help.pool.picture24.alt")}
                  width={765}
                  height={708}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.14">
                    We can also see the amount already deposited into the pool right above the <strong>Deposit</strong>{" "}
                    button.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.15">
                    Clicking &quot;Deposit&quot; creates a wallet pop-up.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture25.webp`)}
                  onClick={() => onImageClick("pool/Picture25")}
                  alt={i18("Stable Pool Deposit Result", "help.pool.picture25.alt")}
                  width={354}
                  height={634}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.16">
                    Approving the transaction should result in an <strong>Astrovault</strong> message.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture26.webp`)}
                  onClick={() => onImageClick("pool/Picture26")}
                  alt={i18("Stable Pool Deposit Result Success", "help.pool.picture26.alt")}
                  width={413}
                  height={324}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener md"
                />

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.17">Awesome!</Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.18">Let&#8217;s now look at the Withdraw tab.</Trans>
                </p>

                <h3 id="stablePoolsWithdraw">
                  {i18("Stable Pools - Withdraw", "help.pool.stablePools.stablePoolsWithdraw.title")}
                </h3>

                <img
                  src={require(`../assets/images/help/pool/Picture27.webp`)}
                  onClick={() => onImageClick("pool/Picture27")}
                  alt={i18("Stable Pool Withdraw", "help.pool.picture27.alt")}
                  width={791}
                  height={1203}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.19">There are more choices under the Withdraw tab.</Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.20">
                    First, &quot;Deposited&quot; shows how much you have deposited into the pool.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.21">
                    Second, there is a box that you can check for <strong>Instant Withdrawal</strong>.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.22">Let&#8217;s discuss what this means.</Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.23">
                    You have two choices when withdrawing. An Instant Withdrawal, which is subject to an additional fee,
                    or a regular withdrawal, which can avoid up to 100% of the additional fee.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.24">Take a look here.</Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture28.webp`)}
                  onClick={() => onImageClick("pool/Picture28")}
                  alt={i18("Stable Pool Withdraw button option", "help.pool.picture28.alt")}
                  width={698}
                  height={911}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.25">
                    The normal Withdrawal fee is quite low, but withdrawing instantly incurs a lockup fee of 1% of the
                    asset(s) retrieved from the pool.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.26">
                    However, this additional soft lockup fee can be avoided with a little time.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.27">
                    Let&#8217;s hit withdrawal <strong>without Instant Withdrawal</strong> checked, and click on the
                    lockup tab.
                  </Trans>
                </p>

                <img
                  src={require(`../assets/images/help/pool/Picture29.webp`)}
                  onClick={() => onImageClick("pool/Picture29")}
                  alt={i18("Stable Pool Lockups", "help.pool.picture29.alt")}
                  width={698}
                  height={911}
                  loading="lazy"
                  className="hasMargin lightboxImageOpener"
                />

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.28">
                    Here we have some new information, we can see that the 1% fee from{" "}
                    <strong>Instant Withdrawal</strong> reduces over time. This reduction is by .1% per day for 10 days.
                    Meaning after 2 days your withdrawal is subject to a .8% fee, after 6 days a .4% fee and after 10
                    days there is no fee at all. You can click the <strong>Claim</strong> button at any point in the
                    process to obtain your assets.
                  </Trans>
                </p>

                <p>
                  <Trans i18nKey="help.pool.stablePools.text.29">
                    <strong>Astrovault</strong> flexibility!
                  </Trans>
                </p>
              </HelpSubPageSection>
            </div>
          </div>
        </div>
      </div>
      <Lightbox
        index={index}
        slides={slides}
        open={index >= 0}
        close={() => setIndex(-1)}
        plugins={[Captions, Thumbnails]}
        className={styles.lightbox}
      />
    </MainLayout>
  );
}

export default HelpPool;
