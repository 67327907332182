import { InputHTMLAttributes, ReactNode } from "react";
import clsx from "clsx";
import useLanguage from "@axvdex/hooks/useLanguage";

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  extraClassName?: string;
  type?: string;
  name: string;
  disabled?: boolean;
  labelText: string;
  hiddenLabel?: boolean;
  helpText?: ReactNode;
  description?: string;
}

function CustomInput({
  extraClassName,
  type,
  name,
  disabled,
  labelText,
  hiddenLabel,
  helpText,
  description,
  ...props
}: CustomInputProps) {
  const { i18 } = useLanguage();

  // do NOT i18 labelText here, should only be done at the callee
  return (
    <div className={clsx("formGroup", extraClassName && extraClassName)}>
      <label className={clsx(disabled && "disabled", hiddenLabel && "visuallyHidden")} htmlFor={`id_${name}`}>
        {labelText && (typeof "" === typeof labelText ? labelText : i18("label", "customInput.label"))}
      </label>

      {description && (
        <div style={{ marginBottom: ".5em" }}>
          <small>{description}</small>
        </div>
      )}

      <input
        className="buttonInput"
        type={type ? type : "text"}
        id={`id_${name}`}
        name={name}
        disabled={disabled}
        {...props}
      />

      {helpText && <div className="helpText">{helpText}</div>}
    </div>
  );
}

export default CustomInput;
