import { postLogError } from "@axvdex/api/misc";

// Send error log to server if its "status code is 5" from rpc
export default async (address: string, queryMsg: any) => {
  try {
    return window.client.queryContractSmart(address, queryMsg);
  } catch (e) {
    console.log(e.message);
    sendLogError(e.message);
    throw e;
  }
};

export const sendLogError = async (error: string) => {
  if (error.includes("status code 5")) {
    postLogError({ error });
  }
};
