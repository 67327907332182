import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "./views/Dashboard";
import Trade from "./views/Trade";
import Pool from "./views/Pool";
import Vote from "./views/Vote";
import Statistics from "./views/Statistics";
import Legal from "./views/Legal";
import LegalTOU from "./views/LegalTOU";
import Help from "./views/Help";
import About from "./views/About";
import HelpDashboard from "./views/HelpDashboard";
import HelpTrade from "./views/HelpTrade";
import HelpPool from "./views/HelpPool";
import HelpWallet from "./views/HelpWallet";
import HelpTokens from "./views/HelpTokens";
import Error404 from "./views/Error404";
import Landing from "./views/Landing";
import Farm from "./views/Farm";
import Outbid from "./views/Outbid";
// import OutbidComingSoon from "./views/OutbidComingSoon";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return null;
};

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/mobileHook/:walletName" element={<Dashboard />} />
        <Route path="/trade" element={<Trade />} />
        <Route path="/pool/:poolId?" element={<Pool />} />
        <Route path="/farm/:farmAddress?" element={<Farm />} />
        <Route path="/vote" element={<Vote />} />
        <Route path="/about" element={<About />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/statistics" element={<Statistics />} />
        {/* <Route path="/outbid" element={<OutbidComingSoon />} /> */}
        <Route path="/outbid/:label?/:saleID?" element={<Outbid />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/legal/tou" element={<LegalTOU />} />
        <Route path="/help">
          <Route index element={<Help />} />
          <Route path="dashboard" element={<HelpDashboard />} />
          <Route path="trade" element={<HelpTrade />} />
          <Route path="pool" element={<HelpPool />} />
          <Route path="wallet" element={<HelpWallet />} />
          <Route path="tokens" element={<HelpTokens />} />
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
