import { denormalize_amount, normalize_amount } from "@axvdex/utils/formatNumber";

export const computeHybridSwap = (pool: any, hop: any, from_asset_amount_input: bigint) => {
  const PRECISION = BigInt(1000000);
  const swap_from_asset_index = pool.poolAssets.findIndex((asset: any) => {
    if (asset.info.token) {
      return asset.info.token.contract_addr === hop.x;
    }
    return asset.info.native_token.denom === hop.x;
  });

  const swap_to_asset_index = pool.poolAssets.findIndex((asset: any) => {
    if (asset.info.token) {
      return asset.info.token.contract_addr === hop.y;
    }
    return asset.info.native_token.denom === hop.y;
  });

  // transpose rust swap code swap simulation
  // so, each pool can have different swap calc configurations in hybrid

  if (!pool.hybridSwapDetails) throw Error("hybridSwapDetails not found on pool object");
  if (pool.hybridRatioDetails.ratio === undefined || pool.hybridRatioDetails.ratio === null)
    throw Error("ratio not found on pool object");

  if (pool.hybridSwapDetails.method === 0) {
    const current_ratio = { ratio: pool.hybridRatioDetails.ratio };
    const pool_swap_params = pool.hybridSwapDetails.default_params;

    const asset1_pool_amount =
      swap_from_asset_index === 0
        ? BigInt(pool.poolAssets[0].amount) + from_asset_amount_input
        : pool.poolAssets[0].amount;
    const asset2_pool_amount =
      swap_from_asset_index === 1
        ? BigInt(pool.poolAssets[1].amount) + from_asset_amount_input
        : pool.poolAssets[1].amount;

    // normalize both pool amounts to the same decimal places (18) so the ratio makes sense
    const pools_amount = [
      normalize_amount(asset1_pool_amount, pool.assetDecimals[0]),
      normalize_amount(asset2_pool_amount, pool.assetDecimals[1]),
    ];

    // also normalize the from_asset_amount
    const from_asset_amount = normalize_amount(from_asset_amount_input, pool.assetDecimals[swap_from_asset_index]);

    // calc to_asset amount
    // if the from asset is the index 0 the ratio is applied with division, else is multiplication
    // this is because the ratio value is always the ratio of token1 related to token0!
    let to_asset_amount: bigint;
    if (swap_from_asset_index === 0) {
      to_asset_amount = (from_asset_amount * PRECISION) / BigInt(current_ratio.ratio);
    } else {
      to_asset_amount = (from_asset_amount * BigInt(current_ratio.ratio)) / PRECISION;
    }

    const ratio_amounts = [pools_amount[0], pools_amount[1]];

    // check if to_asset_amount is greater than the available assets on the pool, if it is we fix that to the max (basically depleting the pool)
    if (to_asset_amount > ratio_amounts[swap_to_asset_index]) {
      to_asset_amount = ratio_amounts[swap_to_asset_index];
    }
    // else {
    //   to_asset_amount = to_asset_amount;
    // }

    // add from_asset_amount to the pool current amount
    ratio_amounts[swap_from_asset_index] += from_asset_amount;
    // subtract to_asset_amount from the pool current amount
    ratio_amounts[swap_to_asset_index] -= to_asset_amount;

    // get the real pool ratio according to the current ratio configured
    ratio_amounts[1] = (ratio_amounts[1] * BigInt(current_ratio.ratio)) / PRECISION;

    // formula for fee calculation according to the real pool ratio
    // Fee = 0.09% * (Pin/Pout)
    let fee: bigint;

    if (ratio_amounts[swap_to_asset_index] != BigInt(0)) {
      fee =
        (BigInt(pool_swap_params.a_fee_param) *
          ((ratio_amounts[swap_from_asset_index] * PRECISION) / ratio_amounts[swap_to_asset_index])) /
        PRECISION;
    } else {
      // if the pool would be depleted fix the fee at 100%
      fee = BigInt(100000000);
    }

    // apply fee to the to_amount to get the to_amount_w_fees and fee_amount
    const fee_amount = (to_asset_amount * fee) / BigInt(100) / PRECISION;

    // denormalize everything
    const from_asset_amount_denormalized = denormalize_amount(
      from_asset_amount,
      pool.assetDecimals[swap_from_asset_index]
    );

    const to_asset_amount_denormalized = denormalize_amount(to_asset_amount, pool.assetDecimals[swap_to_asset_index]);

    const fee_amount_denormalized = denormalize_amount(fee_amount, pool.assetDecimals[swap_to_asset_index]);

    const from_amount = from_asset_amount_denormalized;
    //const to_amount_without_fee = to_asset_amount_denormalized;
    const to_amount_minus_fee =
      fee_amount_denormalized > to_asset_amount_denormalized
        ? BigInt(0)
        : to_asset_amount_denormalized - fee_amount_denormalized;
    //const fee_amount = fee_amount_denormalized;

    return {
      fromAmount: from_amount,
      toAmount: to_amount_minus_fee,
      feeAmount: fee_amount_denormalized,
    };
  }

  throw Error("swap method hybrid not programmed!");
};
